import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Card, CardContent, Button, Link, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align content to the left
    overflow: 'hidden', // Hide content exceeding the container
  },
  horizontalScrollWrapper: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    animation: 'scrollLeft 30s linear infinite', // Adjust the animation duration as needed
    whiteSpace: 'nowrap',
    padding: '16px 0',
  },
  threatContainer: {
    minWidth: '300px',
    margin: '0 8px',
  },
  '@keyframes scrollLeft': {
    '0%': {
      transform: 'translateX(100%)', // Start from the right edge
    },
    '100%': {
      transform: 'translateX(-100%)', // Scroll to the left edge
    },
  },
}));

function HybridScroll() {
  const classes = useStyles();

  const [threatData, setThreatData] = useState([]);
  const [filterLevel, setFilterLevel] = useState(0);

  useEffect(() => {
    axios
      .get('https://www.hybrid-analysis.com/feed?json')
      .then((response) => {
        const filteredData = response.data.data.filter(
          (item) => item.threatlevel >= filterLevel
        );
        setThreatData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [filterLevel]);

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Container className={classes.root}>
      <Typography variant="h4">Threat Feed</Typography>
      <TextField
        label="Filter by Threat Level"
        type="number"
        value={filterLevel}
        onChange={(e) => setFilterLevel(parseInt(e.target.value))}
      />
      <div className={classes.horizontalScrollWrapper}>
        {threatData.map((item) => (
          <Card key={item.sha256} className={classes.threatContainer}>
            <CardContent>
            <Typography variant="h6" component="div">
            Threat Level: {item.threatlevel}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            MD5: {item.md5}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            SHA1: {item.sha1}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            SHA256: {item.sha256}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Analysis Start Time: {formatDate(item.analysis_start_time)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Submit Name:{' '}
            <Link
              href={item.reporturl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.submitname}
            </Link>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Domains: {item.domains ? item.domains.join(', ') : 'N/A'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Hosts: {item.hosts ? item.hosts.join(', ') : 'N/A'}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href={item.reporturl}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Report
          </Button>
            </CardContent>
          </Card>
        ))}
      </div>
    </Container>
  );
}

export default HybridScroll;