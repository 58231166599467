import React from 'react';

const TerraformInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#623CE4', // Terraform brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const buttonStyle = {
    backgroundColor: '#232323',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Simplify Infrastructure with Terraform</h1>
      <p>
        Terraform is an open-source infrastructure as code (IaC) tool that allows you to define and provision infrastructure resources using a declarative configuration language. It helps organizations automate the deployment and management of infrastructure.
      </p>
      <h2 style={subheadingStyle}>Key Features of Terraform:</h2>
      <ul>
        <li>Declarative configuration for infrastructure</li>
        <li>Support for multiple cloud providers and services</li>
        <li>Version control and collaboration with VCS</li>
        <li>Resource dependency management and state tracking</li>
        <li>Community-contributed modules and providers</li>
        <li>HashiCorp-backed with active development</li>
      </ul>
      <button onClick={() => window.open("https://www.terraform.io/", "_blank")} style={buttonStyle}>Explore Terraform</button>
    </div>
  );
};

export default TerraformInfo;