import React from 'react';

const RedHatVirtualizationInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#EE0000', // Red Hat brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const buttonStyle = {
    backgroundColor: '#232323',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Experience Red Hat Virtualization</h1>
      <p>
        Red Hat Virtualization is an open-source virtualization platform designed for enterprise workloads. It provides a secure and scalable virtualization infrastructure, making it easier to manage and deploy virtual machines.
      </p>
      <h2 style={subheadingStyle}>Key Features of Red Hat Virtualization:</h2>
      <ul>
        <li>Open-source and based on KVM technology</li>
        <li>Centralized management with Red Hat Manager</li>
        <li>High availability and disaster recovery options</li>
        <li>Integrated with Red Hat Enterprise Linux</li>
        <li>Support for traditional and cloud-native workloads</li>
        <li>Strong focus on security and compliance</li>
      </ul>
      <button onClick={() => window.open("https://www.redhat.com/en/virtualization", "_blank")} style={buttonStyle}>Explore Red Hat Virtualization</button>
    </div>
  );
};

export default RedHatVirtualizationInfo;