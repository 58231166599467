import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const MalpediaPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the Malpedia link in a new tab/window
    window.open('https://malpedia.caad.fkie.fraunhofer.de/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Explore Malware Intelligence with Malpedia!</h2>
          <p>Discover a comprehensive platform for tracking, analyzing, and understanding malware samples.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Malpedia?</h3>
        <ul>
          <li>Access a curated repository of detailed malware analysis.</li>
          <li>Track and study malware families, variants, and behaviors.</li>
          <li>Benefit from community contributions and research.</li>
          <li>Stay informed about evolving malware threats.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Users Say</h3>
        <div className="testimonial">
          <p>"Malpedia is my go-to resource for understanding and analyzing malware. It's an indispensable platform for researchers."</p>
          <p>- John Smith, Security Analyst</p>
        </div>
        <div className="testimonial">
          <p>"The depth of information available on Malpedia has been invaluable in our threat intelligence efforts."</p>
          <p>- Jane Doe, Cybersecurity Professional</p>
        </div>
      </div>
    </div>
  );
};

export default MalpediaPromoBanner;