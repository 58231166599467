import React from 'react';

function GoogleInformationalPage() {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <h1>Google Dorking - A Guide to Responsible Searching</h1>
      <p>
        Google Dorking is a powerful technique that leverages advanced search operators to refine and enhance your Google searches.
        It is an invaluable tool for finding specific information on the internet quickly and efficiently.
        Whether you're a researcher, a student, or simply someone seeking answers, Google is your gateway to a vast sea of knowledge.
      </p>

      <h2>Common Google Dork Operators</h2>
      <ul>
        <li>
          <strong>Site-specific search:</strong> Use <code>site:example.com keyword</code> to search for a keyword within a specific website.
        </li>
        <li>
          <strong>File type search:</strong> Use <code>filetype:pdf keyword</code> to find PDF files containing a specific keyword.
        </li>
        <li>
          <strong>Inurl search:</strong> Use <code>inurl:admin login</code> to search for URLs that contain "admin" and "login."
        </li>
        <li>
          <strong>Intitle search:</strong> Use <code>intitle:index.of "parent directory"</code> to find directories with an "index of" page.
        </li>
        <li>
          <strong>Related sites search:</strong> Use <code>related:example.com</code> to find websites related to a specific domain.
        </li>
      </ul>

      <h2>Responsible Use of Google Dorking</h2>
      <p>
        While Google Dorking is a valuable tool, it's crucial to use it responsibly and ethically.
        Always respect the terms of service of websites and online platforms.
        Avoid accessing sensitive or private information without proper authorization.
        By using Google Dorking responsibly, you can harness its power to unlock a wealth of information while maintaining ethical standards.
      </p>
    </div>
  );
}

export default GoogleInformationalPage;