import React from 'react';

const VirusTotalIframe = () => {
  const iframeContainerStyle = {
    position: 'relative',
    width: '100%',
    height: '100vw', // Set the height as needed
  };

  const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none', // Remove iframe border
  };

  return (
    <div style={iframeContainerStyle}>
      <iframe
        title="VirusTotal"
        src="https://www.virustotal.com/gui/home/upload"
        style={iframeStyle}
        allowFullScreen
      />
    </div>
  );
};

export default VirusTotalIframe;