import React from 'react';

const OSCPInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'black', // Default text color
  };

  // Adjust text color for dark mode
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    containerStyle.color = 'white'; // Set text color for dark mode
  }

  const headingStyle = {
    color: '#333',
  };

  const subheadingStyle = {
    color: '#555',
  };

  const linkStyle = {
    color: '#007bff',
    textDecoration: 'none',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>OSCP Certification</h1>
      <p>
        The Offensive Security Certified Professional (OSCP) is a highly
        regarded penetration testing certification offered by Offensive
        Security. It is designed for security professionals who want to
        demonstrate their expertise in conducting penetration tests and
        identifying vulnerabilities.
      </p>
      <h2 style={subheadingStyle}>Key Information:</h2>
      <ul>
        <li>Hands-on, 24-hour exam</li>
        <li>Exploitation of vulnerabilities</li>
        <li>Real-world scenarios</li>
        <li>Comprehensive exam report</li>
      </ul>
      <p>
        For more details about the OSCP certification and registration, please
        visit the official website:
        <a
          href="https://www.offensive-security.com/pwk-oscp/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          OSCP Official Page
        </a>
      </p>
    </div>
  );
};

export default OSCPInfo;