import React from 'react';

const Capev2GitHub = () => {
  const containerStyle = {
    backgroundColor: '#f0f0f0',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '500px',
    margin: '0 auto',
  };

  const headerStyle = {
    fontSize: '24px',
    marginBottom: '20px',
  };

  const paragraphStyle = {
    fontSize: '18px',
    marginBottom: '20px',
  };

  const buttonStyle = {
    backgroundColor: '#24292e',
    color: '#ffffff',
    padding: '10px 20px',
    borderRadius: '5px',
    textDecoration: 'none',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headerStyle}>Welcome to CAPEv2</h1>
      <p style={paragraphStyle}>
        CAPEv2 is an open-source malware analysis platform that provides powerful dynamic analysis capabilities. It empowers researchers to analyze and dissect malware samples in a secure and controlled environment.
      </p>
      <p style={paragraphStyle}>
        You can find the source code for CAPEv2 on GitHub:
        <br />
        <a
          href="https://github.com/kevoreilly/CAPEv2"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#0366d6' }}
        >
          https://github.com/kevoreilly/CAPEv2
        </a>
      </p>
      <p style={paragraphStyle}>
        Explore additional analysis capabilities with CapeSandbox:
        <br />
        <a
          href="https://capesandbox.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#0366d6' }}
        >
          https://capesandbox.com
        </a>
      </p>
      <button
        onClick={() => window.open('https://capesandbox.com', '_blank')}
        style={buttonStyle}
      >
        Open CapeSandbox in a New Tab
      </button>
    </div>
  );
};

export default Capev2GitHub;