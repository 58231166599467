import React from 'react';
import '../CSS/CheatSheets.css'; // Import your custom CSS for styling

const CheatSheets = () => {
  return (
    <div className="cheat-sheets">
      <h1>Malware Archaeology Cheat Sheets</h1>
      <iframe
        src="https://www.malwarearchaeology.com/cheat-sheets"
        title="Cheat Sheets"
        className="cheat-sheets-iframe"
      ></iframe>
    </div>
  );
};

export default CheatSheets;