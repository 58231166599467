import React from 'react';
import './privacy.css';

const UserGuidelines = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="policy-title">User Guidelines Policy for nuitx.com</h1>

      <section className="policy-section">
        <h2 className="section-title">1. Registration and Account Usage:</h2>
        <ul className="policy-list">
          <li>Users must provide accurate and up-to-date information during the registration process.</li>
          <li>Each user is responsible for maintaining the confidentiality of their account credentials and should not share them with others.</li>
          <li>Users must promptly report any unauthorized access or suspicious activity on their account to nuitx.com.</li>
        </ul>
      </section>

      <section className="policy-section">
        <h2 className="section-title">2. Content Sharing and Feedback:</h2>
        <ul className="policy-list">
          <li>Users are encouraged to share their findings, insights, and analysis related to stocks on the platform.</li>
          <li>Content shared should be relevant, accurate, and comply with applicable laws and regulations.</li>
          <li>Users should respect intellectual property rights and not post copyrighted material without proper authorization or attribution.</li>
          <li>Feedback and comments shared by users should be constructive, respectful, and relevant to the discussion.</li>
        </ul>
      </section>

      <section className="policy-section">
        <h2 className="section-title">3. Prohibited Activities:</h2>
        <ul className="policy-list">
          <li>Users must not engage in any illegal, fraudulent, or malicious activities on nuitx.com.</li>
          <li>Users should not impersonate or misrepresent themselves as someone else.</li>
          <li>Posting or sharing of spam, advertisements, or promotional content unrelated to the platform is strictly prohibited.</li>
          <li>Users must not attempt to disrupt or manipulate the platform's functionality or interfere with other users' experiences.</li>
        </ul>
      </section>

      <section className="policy-section">
        <h2 className="section-title">4. Compliance with Laws and Regulations:</h2>
        <ul className="policy-list">
          <li>Users are responsible for complying with all applicable laws, regulations, and guidelines while using nuitx.com.</li>
          <li>Users must not engage in any activities that violate insider trading laws or other securities regulations.</li>
          <li>nuitx.com reserves the right to cooperate with legal authorities and disclose user information as required by law.</li>
        </ul>
      </section>

      <section className="policy-section">
        <h2 className="section-title">5. Reporting Violations:</h2>
        <ul className="policy-list">
          <li>Users should promptly report any violations of the User Guidelines Policy to nuitx.com.</li>
          <li>nuitx.com will investigate reported violations and take appropriate action, which may include warning, suspension, or termination of user accounts.</li>
        </ul>
      </section>

      <section className="policy-section">
        <h2 className="section-title">6. Changes to User Guidelines:</h2>
        <ul className="policy-list">
          <li>nuitx.com reserves the right to modify or update the User Guidelines Policy at any time.</li>
          <li>Users will be notified of any significant changes to the policy, and continued use of the platform implies acceptance of the updated guidelines.</li>
        </ul>
      </section>

      <p className="privacy-footer">
        Failure to comply with the User Guidelines Policy may result in temporary or permanent suspension of user privileges on nuitx.com. It is essential for all users to adhere to these guidelines to ensure a safe, informative, and collaborative environment for all participants.
      </p>
    </div>
  );
};

export default UserGuidelines;