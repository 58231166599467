import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUpvote, toggleDownvote } from '../reducers/userPageReducer';
import { notify } from '../reducers/notificationReducer';
import { UpvoteButton, DownvoteButton } from './VoteButtons';
import TimeAgo from 'timeago-react';
import ReactHtmlParser from 'react-html-parser';
import { trimLink, prettifyLink, fixUrl } from '../utils/formatUrl';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CommentIcon from '@material-ui/icons/Comment';
import getErrorMsg from '../utils/getErrorMsg';
import { Paper, Typography, Link, Button } from '@material-ui/core';
import { useUserPostCardStyles } from '../styles/muiStyles';
import { makeStyles } from '@material-ui/core/styles';
const UserPostCard = ({ post, user, isMobile }) => {
  const classes = useUserPostCardStyles();
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state);

  const {
    id,
    title,
    postType,
    linkSubmission,
    imageSubmission,
    imageTextSubmission,
    textSubmission,
    subreddit,
    author,
    upvotedBy,
    downvotedBy,
    pointsCount,
    comments,
    commentCount,
    createdAt,
    updatedAt,
  } = post;

  const useStyles = makeStyles((theme) => ({
    mainPaper: {
      // Your existing styles here
    },
    // Add the animated border styles
    animatedBorder: {
      animation: '$borderAnimation 4s linear infinite',
    },
    '@keyframes borderAnimation': {
      '0%': {
        boxShadow: `0 0 7px 0px ${theme.palette.error.main}`,
      },
      '33%': {
        boxShadow: `0 0 7px 5px ${theme.palette.primary.main}`,
      },
      '66%': {
        boxShadow: `0 0 7px 5px ${theme.palette.info.main}`,
      },
      '100%': {
        boxShadow: `0 0 7px 0px ${theme.palette.error.main}`,
      },
    },
  }));

  const animatedBorderClasses = useStyles();

  const textContent = textSubmission; // Store the text content in a variable

  // Function to get the first 5 sentences from text content
  const getFirstFiveSentences = (text) => {
    if (!text) return '';
  
    // Split the text into sentences based on periods (.), exclamation marks (!), or question marks (?)
    const sentences = text.trim().split(/([.!?]+)/);
  
    // Select the first 5 sentences and join them back with their respective punctuation to form a string
    const firstFiveSentences = sentences.slice(0, 10).join('');
  
    return firstFiveSentences;
  };

  const isUpvoted = user && upvotedBy.includes(user.id);
  const isDownvoted = user && downvotedBy.includes(user.id);

  const handleUpvoteToggle = async () => {
    try {
      if (isUpvoted) {
        const updatedUpvotedBy = upvotedBy.filter((u) => u !== user.id);
        dispatch(toggleUpvote(id, updatedUpvotedBy, downvotedBy));
      } else {
        const updatedUpvotedBy = [...upvotedBy, user.id];
        const updatedDownvotedBy = downvotedBy.filter((d) => d !== user.id);
        dispatch(toggleUpvote(id, updatedUpvotedBy, updatedDownvotedBy));
      }
    } catch (err) {
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  const handleDownvoteToggle = async () => {
    try {
      if (isDownvoted) {
        const updatedDownvotedBy = downvotedBy.filter((d) => d !== user.id);
        dispatch(toggleDownvote(id, updatedDownvotedBy, upvotedBy));
      } else {
        const updatedDownvotedBy = [...downvotedBy, user.id];
        const updatedUpvotedBy = upvotedBy.filter((u) => u !== user.id);
        dispatch(toggleDownvote(id, updatedDownvotedBy, updatedUpvotedBy));
      }
    } catch (err) {
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  return (
    <Paper className={`${classes.mainPaper} ${animatedBorderClasses.animatedBorder}`}>
      <div className={classes.votesWrapper}>
        <UpvoteButton
          user={user}
          body={post}
          handleUpvote={handleUpvoteToggle}
          size={isMobile ? 'small' : 'medium'}
        />
        <Typography
          variant="body1"
          style={{
            color: isUpvoted
              ? '#33ff33'
              : isDownvoted
              ? '#ff3333'
              : darkMode
              ? '#000000'
              : '#333',
            fontWeight: 600,
          }}
        >
          {pointsCount}
        </Typography>
        <DownvoteButton
          user={user}
          body={post}
          handleDownvote={handleDownvoteToggle}
          size={isMobile ? 'small' : 'medium'}
        />
      </div>
      <div
        className={classes.postInfo}
        component={RouterLink}
        to={`/comments/${id}`}
      >
        <Typography variant="subtitle2">
        <Link component={RouterLink} to={`/i/${subreddit.subredditName}`}>
                {`${subreddit.subredditName} `}
              </Link>
          <Typography variant="caption" className={classes.userAndDate}>
            • Posted by
            <Link component={RouterLink} to={`/u/${author.username}`}>
              {` ${author.username} `}
            </Link>
            • <TimeAgo datetime={new Date(createdAt)} />
            {createdAt !== updatedAt && (
              <em>
                {' • edited'} <TimeAgo datetime={new Date(updatedAt)} />
              </em>
            )}
          </Typography>
        </Typography>
        <Typography variant="h5" className={classes.title}>
        <Link component={RouterLink} to={`/comments/${id}`}>
        {title}
              </Link>
        </Typography>{postType === 'Image' && (
          <a
            href={`/comments/${id}`}
            alt={title}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.imagePost}
          >
            <img
              alt={title}
              src={imageSubmission.imageLink}
              className={classes.image}
            />
          </a>
        )}
        {postType === 'Text' && (
          // Display the first 5 sentences for text posts
          <Typography variant="body2" className={classes.textContent}>
            {getFirstFiveSentences(textContent)}
          </Typography>
        )}

        <div>
          <Button
            color="primary"
            size="small"
            startIcon={<CommentIcon />}
            className={classes.commentsBtn}
            component={RouterLink}
            to={`/comments/${id}`}
          >
            {commentCount} Comments
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default UserPostCard;