import React from 'react';

const AzureInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#0072C6', // Azure brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const buttonStyle = {
    backgroundColor: '#232323',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Empower Your Business with Microsoft Azure</h1>
      <p>
        Microsoft Azure is a leading cloud computing platform and service offered by Microsoft. It provides a wide range of cloud-based solutions, including computing, analytics, storage, and more, to help businesses innovate and grow.
      </p>
      <h2 style={subheadingStyle}>Key Benefits of Azure:</h2>
      <ul>
        <li>Scalable and flexible cloud resources</li>
        <li>Comprehensive suite of services and tools</li>
        <li>Hybrid cloud solutions for seamless integration</li>
        <li>Strong security and compliance features</li>
        <li>AI and machine learning capabilities</li>
        <li>Global data center presence</li>
      </ul>
      <button onClick={() => window.open("https://azure.microsoft.com/", "_blank")} style={buttonStyle}>Explore Azure</button>
    </div>
  );
};

export default AzureInfo;