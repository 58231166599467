import React from 'react';
import './yeah.css';

// Define data for programming languages and their rankings
const programmingLanguages = [
  { name: 'JavaScript', rank: 1 },
  { name: 'Python', rank: 2 },
  { name: 'Java', rank: 3 },
  { name: 'C#', rank: 4 },
  { name: 'PHP', rank: 5 },
  // Add more languages and ranks as needed
];

function ProgrammingOverview() {
  return (
    <div className="programming-overview">
      <h1>Programming Overview</h1>

      {/* Section 1: What is Programming? */}
      <section className="section">
        <h2>What is Programming?</h2>
        <p>Programming is the process of giving instructions to a computer to perform tasks or solve problems. It involves writing code using programming languages.</p>
      </section>

      {/* Section 2: Applications of Programming */}
      <section className="section">
        <h2>Applications of Programming</h2>
        <ul>
          <li>Web Development</li>
          <li>Mobile App Development</li>
          <li>Data Science and Analytics</li>
          <li>Artificial Intelligence and Machine Learning</li>
          <li>Game Development</li>
          <li>Embedded Systems</li>
          <li>Robotics</li>
          {/* Add more applications */}
        </ul>
      </section>

      {/* Section 3: Facets of Programming */}
      <section className="section">
        <h2>Facets of Programming</h2>
        <ul>
          <li>Algorithms and Data Structures</li>
          <li>Programming Paradigms (e.g., Object-Oriented, Functional)</li>
          <li>Software Development Life Cycle</li>
          <li>Debugging and Testing</li>
          <li>Version Control</li>
          {/* Add more facets */}
        </ul>
      </section>

      {/* Section 4*/}


        <section className="section">
        <h2>Historical Examples</h2>
        <ol>
          <li>
            <strong>The Invention of Fortran (1957)</strong><br />
            Fortran, short for "Formula Translation," was the first high-level programming language, developed by IBM for scientific and engineering calculations.
          </li>
          <li>
            <strong>The Birth of the World Wide Web (1991)</strong><br />
            Tim Berners-Lee created the World Wide Web, introducing HTML, HTTP, and the first web browser, paving the way for modern web development.
          </li>
          <li>
            <strong>The Launch of Linux (1991)</strong><br />
            Linus Torvalds released the Linux kernel, leading to the development of the Linux operating system, which became a cornerstone of open-source software.
          </li>
          <li>
            <strong>The Emergence of Java (1995)</strong><br />
            Sun Microsystems introduced Java, an object-oriented language known for its portability, security, and use in various applications.
          </li>
          <li>
            <strong>The Rise of Open Source with Apache (1999)</strong><br />
            The Apache Software Foundation was established, contributing to the growth of open-source software and web server technology.
          </li>
          <li>
            <strong>The Advent of Cloud Computing (2000s)</strong><br />
            Cloud computing platforms like Amazon Web Services (AWS) and Google Cloud revolutionized infrastructure and application deployment.
          </li>
          <li>
            <strong>The Mobile App Boom (2000s)</strong><br />
            The launch of iOS and Android platforms led to the mobile app development boom, changing how we interact with technology.
          </li>
          <li>
            <strong>Breakthroughs in AI and Machine Learning (2010s)</strong><br />
            Advancements in deep learning and neural networks fueled AI applications in areas like natural language processing and computer vision.
          </li>
          <li>
            <strong>Major Cybersecurity Incidents (Various Years)</strong><br />
            High-profile cybersecurity breaches highlighted the importance of secure programming and data protection.
          </li>
          <li>
            <strong>Programming in Space Exploration (Various Missions)</strong><br />
            Programming plays a critical role in controlling spacecraft and collecting data in space exploration missions.
          </li>
        </ol>
      </section>

      {/* Section 5: Programming Language Rankings */}
      <section className="section">
        <h2>Programming Language Rankings</h2>
        <p>Programming languages are ranked based on factors like popularity, community support, and usage statistics. Here are some of the top programming languages:</p>
        <ol>
          {programmingLanguages.map((language, index) => (
            <li key={index}>
              <strong>{language.name}</strong> (Ranked #{language.rank})
            </li>
          ))}
        </ol>
      </section>
    </div>
  );
}

export default ProgrammingOverview;