import React, { useEffect, useState } from 'react';
import '../../css/feeds.css';

const RSSFeedsSoMany = () => {
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    const fetchFeedUrls = async () => {
      try {
        const response = await fetch('/api/somany'); // Update the URL to match your server endpoint
        if (response.ok) {
          const feedUrls = await response.json();
          fetchFeeds(feedUrls); // Fetch the feeds once you have the URLs
        } else {
          console.error('Error fetching feed URLs:', response.status);
        }
      } catch (error) {
        console.error('Error fetching feed URLs:', error);
      }
    };

    fetchFeedUrls();

    // Refresh the feed URLs every 5 minutes (adjust the interval as needed)
    const interval = setInterval(fetchFeedUrls, 5 * 60 * 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const fetchFeeds = async (feedUrls) => {
    try {
      const promises = feedUrls.map(async (url) => {
        try {
          const response = await fetch(url);
          if (response.ok) {
            return response.json();
          } else {
            console.error('Error fetching feed:', response.status);
            return null;
          }
        } catch (error) {
          console.error('Error fetching feed:', error);
          return null;
        }
      });

      const fetchedFeeds = await Promise.all(promises);
      setFeeds((prevFeeds) => [...prevFeeds, ...fetchedFeeds.filter((feed) => feed !== null)]);
    } catch (error) {
      console.error('Error fetching feeds:', error);
    }
  };

  return (
    <div className="app">
      <div className="feeds-container">
        {feeds.map((feed, index) => (
          <div key={index} className="rss-feed-item">
            <h3>{feed.title}</h3>
            <ul>
              {feed.items.map((item) => (
                <li key={item.link}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>{item.title}</a>
                  {item.contentSnippet && <p>{item.contentSnippet}</p>}
                  {item.enclosure && (
                    <img src={item.enclosure.url} alt="Preview" />
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <amp-auto-ads type="adsense" data-ad-client="ca-pub-3504607292095587">
      </amp-auto-ads>
    </div>
  );
};

export default RSSFeedsSoMany;