import React from 'react';

const RevShellsIframe = () => {
  const iframeStyle = {
    width: '100%',
    height: '100vw', // Adjust the height as needed
    border: 'none', // Remove the iframe border if desired
  };

  return (
    <div>
      <header>
      </header>
      <section className="iframe-container">
        <iframe
          src="https://www.revshells.com/"
          title="RevShells"
          style={iframeStyle}
        />
      </section>
    </div>
  );
};

export default RevShellsIframe;