import React from 'react';
import './yeah.css';

function DataScienceDashboard() {
  return (
    <div className="data-science-dashboard">
      <header>
        <h1>Intro to Data Science</h1>
      </header>
      <section className="overview">
        <h2>Overview</h2>
        <p>Data science is a multidisciplinary field that leverages scientific methods, algorithms, and data analysis to extract actionable insights and knowledge from structured and unstructured data.</p>
      </section>
      <section className="applications">
        <h2>Key Applications</h2>
        <ul>
          <li><strong>Predictive Analytics:</strong> Using historical data to make predictions about future events or trends.</li>
          <li><strong>Machine Learning:</strong> Developing algorithms that enable computers to learn from data and make decisions without explicit programming.</li>
          <li><strong>Data Visualization:</strong> Creating visual representations of data to facilitate understanding and decision-making.</li>
          <li><strong>Natural Language Processing (NLP):</strong> Analyzing and processing human language data, enabling chatbots, sentiment analysis, and more.</li>
          <li><strong>Recommender Systems:</strong> Suggesting products, services, or content based on user behavior and preferences.</li>
          <li><strong>Image and Video Analysis:</strong> Analyzing visual data for object recognition, facial recognition, and more.</li>
          {/* Add more applications */}
        </ul>
      </section>
      <section className="facets">
        <h2>Facets of Data Science</h2>
        <ul>
          <li><strong>Data Collection:</strong> Gathering data from various sources, such as databases, APIs, sensors, and more.</li>
          <li><strong>Data Cleaning:</strong> Preprocessing data to handle missing values, outliers, and inconsistencies.</li>
          <li><strong>Exploratory Data Analysis (EDA):</strong> Understanding data through statistical and visual analysis.</li>
          <li><strong>Feature Engineering:</strong> Creating meaningful features from raw data to improve machine learning model performance.</li>
          <li><strong>Model Building:</strong> Developing and training machine learning models using algorithms like regression, decision trees, and neural networks.</li>
          <li><strong>Model Evaluation:</strong> Assessing model performance using metrics like accuracy, precision, recall, and F1-score.</li>
          <li><strong>Deployment:</strong> Implementing data-driven solutions in real-world applications, often involving web or mobile interfaces.</li>
          {/* Add more facets */}
        </ul>
      </section>
      <section className="tools">
        <h2>Tools and Technologies</h2>
        <p>Data scientists use a variety of tools and technologies, including:</p>
        <ul>
          <li>Programming Languages (e.g., Python, R)</li>
          <li>Data Analysis Libraries (e.g., Pandas, NumPy)</li>
          <li>Machine Learning Frameworks (e.g., TensorFlow, PyTorch)</li>
          <li>Data Visualization Tools (e.g., Matplotlib, Tableau)</li>
          <li>Big Data Technologies (e.g., Hadoop, Spark)</li>
          {/* Add more tools */}
        </ul>
      </section>
      <section className="challenges">
        <h2>Challenges in Data Science</h2>
        <p>Data science is not without its challenges:</p>
        <ul>
          <li>Data Privacy and Ethics</li>
          <li>Data Quality and Bias</li>
          <li>Model Interpretability</li>
          <li>Scalability</li>
          <li>Continuous Learning</li>
          {/* Add more challenges */}
        </ul>
      </section>
      <section className="industries">
        <h2>Industries</h2>
        <p>Data science is applied in various industries, including:</p>
        <ul>
          <li>Healthcare</li>
          <li>Finance</li>
          <li>E-commerce</li>
          <li>Marketing</li>
          <li>Transportation</li>
          {/* Add more industries */}
        </ul>
      </section>
      {/* Add more sections for in-depth information, visualizations, and interactive elements */}
      <footer>
        <p>Explore the fascinating world of data science.</p>
      </footer>
    </div>
  );
}

export default DataScienceDashboard;