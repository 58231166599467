import React from 'react';

const UdacityInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#FF5722', // Udacity brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Advance Your Career with Udacity</h1>
      <p>
        Udacity is an online education platform that offers tech-focused courses
        designed to advance your career. Whether you're looking to master
        cutting-edge skills or earn a nanodegree, Udacity provides a path to
        success in technology.
      </p>
      <h2 style={subheadingStyle}>What Udacity Offers:</h2>
      <ul>
        <li>Nanodegree programs in tech and business</li>
        <li>Real-world projects and hands-on learning</li>
        <li>Expert mentors and personalized career support</li>
        <li>Flexible learning to fit your schedule</li>
      </ul>
      <p>
        Launch your tech career with Udacity:
        <a
          href="https://www.udacity.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Udacity Website
        </a>
      </p>
    </div>
  );
};

export default UdacityInfo;