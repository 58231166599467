import React from 'react';

function CybersecurityReddit() {
  // List of cybersecurity-related subreddits
  const cybersecuritySubreddits = [
    {
      name: 'r/cybersecurity',
      description: 'General cybersecurity discussions and news.',
    },
    {
      name: 'r/netsec',
      description: 'Network security discussions and research.',
    },
    {
      name: 'r/AskNetsec',
      description: 'A place to ask questions related to cybersecurity.',
    },
    {
      name: 'r/hacking',
      description: 'Discussions about hacking and ethical hacking.',
    },
    {
      name: 'r/privacy',
      description: 'Topics related to online privacy and data protection.',
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <h1>Reddit: Join the Cybersecurity Community</h1>
      <p>
        Reddit hosts a vibrant community of cybersecurity enthusiasts, professionals, and learners.
        Explore the following cybersecurity-related subreddits to engage in discussions, seek advice, and stay updated:
      </p>

      <ul>
        {cybersecuritySubreddits.map((subreddit, index) => (
          <li key={index}>
            <strong>{subreddit.name}</strong>: {subreddit.description}
          </li>
        ))}
      </ul>

      <p>
        Join these subreddits to be a part of the global cybersecurity conversation.
        Click the links below to access the respective subreddits:
      </p>

      <ul>
        {cybersecuritySubreddits.map((subreddit, index) => (
          <li key={index}>
            <a
              href={`https://www.reddit.com/${subreddit.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {subreddit.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CybersecurityReddit;