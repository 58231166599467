import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RSSFeedsHomeLeft from '../feeds/LeftRssFeed';
import PostFormModal from '../PostFormModal';
import PostList from '../PostList';
import TopSubsPanel from '../leaderboards/TopSubsPanel';
import TopMeritPanel from '../leaderboards/TopMeritPanel';
import TopIOCSubmittersPanel from '../leaderboards/TopIOCPanel';
import TopExploitSubmittersPanel from '../leaderboards/TopExploitsPanel';
import TopQuerySubmittersPanel from '../leaderboards/TopQueriesPanel';
import TopCloudSubmittersPanel from '../leaderboards/TopCloudPanel';
import TopProgSubmittersPanel from '../leaderboards/TopProgPanel';
import TopDataSciSubmittersPanel from '../leaderboards/TopDataSciPanel';
import TopLessonsSubmittersPanel from '../leaderboards/TopLessonsPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
    minHeight: '100vh', // Ensure full viewport height
    color: '#ffffff', // Text color
    fontFamily: 'Arial, sans-serif', // Choose an appropriate font
  },
  feedContainer: {
    width: '21vw',
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide on tablets
    },
  },
  postsContainer: {
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'column',
    width: '55vw',
    [theme.breakpoints.down('sm')]: {
      width: '100vw', // Set to 100vw on mobile devices
      marginLeft: 0, // Remove margin on mobile
    },
  },
  topListsContainer: {

    background: 'transparent', // Make the feed container transparent
    marginLeft: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '23vw',
    [theme.breakpoints.down('sm')]: {
      width: '100vw', // Set to 100vw on mobile devices
      marginLeft: 0, // Remove margin on mobile
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide on tablets
    },
  },
}));

const HomeFormat = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
  <div className={classes.feedContainer} style={{ marginTop: '25px' }}>
    <RSSFeedsHomeLeft />
    <TopCloudSubmittersPanel />
    <TopProgSubmittersPanel />
    <TopDataSciSubmittersPanel />
    <TopIOCSubmittersPanel />
  </div>
  <div className={classes.postsContainer} style={{ marginTop: '25px' }}>
    <PostFormModal />
    <PostList />
  </div>
      <div className={classes.topListsContainer}>
        <TopSubsPanel />
        <TopMeritPanel />
        <TopLessonsSubmittersPanel />
        <TopExploitSubmittersPanel />
        <TopQuerySubmittersPanel />
      </div>
      <amp-auto-ads type="adsense" data-ad-client="ca-pub-3504607292095587">
        {/* Ad component */}
      </amp-auto-ads>
    </div>
  );
};

export default HomeFormat;