import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const BlueTeamTwitterPromoBanner = () => {
  const twitterAccounts = [
    {
      title: '@BlueTeamVillage',
      link: 'https://x.com/BlueTeamVillage', // Replace with an actual Twitter account link
    },
    {
      title: '@CyberDefenders',
      link: 'https://x.com/CyberDefenders', // Replace with an actual Twitter account link
    },
    // Add more Twitter accounts here
  ];

  const twitterHashtags = [
    {
      title: '#BlueTeam',
      link: 'https://x.com/hashtag/BlueTeam', // Replace with an actual Twitter hashtag link
    },
    {
      title: '#Cybersecurity',
      link: 'https://x.com/hashtag/Cybersecurity', // Replace with an actual Twitter hashtag link
    },
    '#InfoSec',
    '#ThreatHunting',
    '#IncidentResponse',
    '#SOC',
    '#DFIR',
    '#CyberDefense',
    '#SecurityOps',
    '#CyberAware',
    '#ZeroTrust',
    '#SecurityAwareness',
    '#SecurityResearch',
    '#SecurityCommunity',
    // Add more Twitter hashtags here
  ];

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Connect with Blue Teamers on Twitter!</h2>
          <p>Stay updated on blue team cybersecurity discussions, insights, and trends via Twitter accounts and hashtags.</p>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Twitter for Blue Team?</h3>
        <ul>
          <li>Follow experts and enthusiasts in the blue team cybersecurity field.</li>
          <li>Access real-time insights, news, and industry developments.</li>
          <li>Engage in discussions and share your own knowledge.</li>
          <li>Stay connected with a vibrant online community.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Twitter Accounts</h3>
        <ul>
          {twitterAccounts.map((account, index) => (
            <li key={index}>
              <a href={account.link} target="_blank" rel="noopener noreferrer">
                {account.title}
              </a>
            </li>
          ))}
        </ul>
        <h3>Twitter Hashtags</h3>
        <ul>
          {twitterHashtags.map((hashtag, index) => (
            <li key={index}>
              {typeof hashtag === 'string' ? (
                <a
                  href={`https://x.com/hashtag/${hashtag.replace('#', '')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {hashtag}
                </a>
              ) : (
                <a href={hashtag.link} target="_blank" rel="noopener noreferrer">
                  {hashtag.title}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Twitter Users Say</h3>
        <div className="testimonial">
          <p>"Twitter is an amazing platform for staying updated on blue team cybersecurity trends and connecting with peers."</p>
          <p>- John Smith, Blue Team Enthusiast</p>
        </div>
        <div className="testimonial">
          <p>"Engaging on Twitter has led to valuable connections and opened up opportunities to learn from the community."</p>
          <p>- Jane Doe, Cybersecurity Professional</p>
        </div>
      </div>
    </div>
  );
};

export default BlueTeamTwitterPromoBanner;