import React, { useState } from 'react';
import './mentorguide.css'; // Import your CSS file for styling

function MentorshipGuide() {
  // State variables to track which section of the guide is currently displayed
  const [section, setSection] = useState('importance');

  // Function to handle section changes
  const changeSection = (newSection) => {
    setSection(newSection);
  };

  return (
    <div className="mentorship-guide">
      <header>
        <h1>Mentorship Guide</h1>
      </header>
      <nav>
        <ul>
          <li
            className={section === 'importance' ? 'active' : ''}
            onClick={() => changeSection('importance')}
          >
            Importance of Mentorship
          </li>
          <li
            className={section === 'curves' ? 'active' : ''}
            onClick={() => changeSection('curves')}
          >
            The World of Cyber is Full of Curves
          </li>
          <li
            className={section === 'beginners' ? 'active' : ''}
            onClick={() => changeSection('beginners')}
          >
            Mentorship for Beginners
          </li>
          <li
            className={section === 'plateau' ? 'active' : ''}
            onClick={() => changeSection('plateau')}
          >
            Mentorship for Those on a Plateau
          </li>
          <li
            className={section === 'purpose' ? 'active' : ''}
            onClick={() => changeSection('purpose')}
          >
            The Greater Purpose of Mentorship
          </li>
        </ul>
      </nav>
      <main>
        {/* Render content based on the selected section */}
        {section === 'importance' && (
          <div className="section-content" style={{ color: 'black' }}>
            <h2>The Importance of Mentorship in Cyber</h2>
            <br/>
            <p>
              a. Knowledge Transfer: The cyber world is vast and ever-evolving. A mentor can help bridge the knowledge gap by sharing their expertise and insights gained through years of experience.
            </p>
            <br/>
            <p>
              b. Skill Development: Mentors can provide guidance on developing both technical and soft skills crucial for success in cyber, such as problem-solving, communication, and critical thinking.
            </p>
            <br/>
            <p>
              c. Networking: Mentors can introduce you to valuable industry connections, opening doors to new opportunities and collaborations.
            </p>
            <br/>
            <p>
              d. Career Advancement: A mentor can provide guidance on career planning, helping you set goals and navigate the complex world of cyber job markets.
            </p>
            <br/>
            <p>
              e. Emotional Support: The cyber journey can be challenging. Mentors offer emotional support, helping you stay motivated and resilient in the face of setbacks.
            </p>
          </div>
        )}
        {section === 'curves' && (
          <div className="section-content" style={{ color: 'black' }}>
            <h2>The World of Cyber is Full of Curves</h2>
            <br/>
            <p>
              a. Cyber is Dynamic: The cyber landscape constantly changes with new threats, technologies, and regulations. It's crucial to adapt and learn continuously.
            </p>
            <br/>
            <p>
              b. Learn from Mistakes: Making mistakes is an inevitable part of the cyber world. Mentorship can help you turn these setbacks into valuable learning experiences.
            </p>
            <br/>
            <p>
              c. Diversity of Paths: There is no one-size-fits-all approach to success in cyber. Mentors can help you explore different career paths and find the one that suits you best.
            </p>
          </div>
        )}
        {section === 'beginners' && (
          <div className="section-content" style={{ color: 'black' }}>
            <h2>Mentorship for Beginners</h2>
            <br/>
            <p>
              a. Finding a Mentor: Look for mentors in your network, at educational institutions, or through professional organizations. Online communities and forums can also be great places to connect with potential mentors.
            </p>
            <br/>
            <p>
              b. Setting Goals: Define your objectives and what you hope to achieve with mentorship. Be open and honest about your needs and expectations.
            </p>
            <br/>
            <p>
              c. Be Teachable: Approach mentorship with an open mind. Be willing to learn, accept constructive criticism, and implement feedback.
            </p>
          </div>
        )}
        {section === 'plateau' && (
          <div className="section-content" style={{ color: 'black' }}>
            <h2>Mentorship for Those on a Plateau</h2>
            <br/>
            <p>
              a. Self-Assessment: Identify areas where you feel stuck or where you want to improve. Discuss these areas with your mentor to create a tailored plan for growth.
            </p>
            <br/>
            <p>
              b. Break Down Goals: Work with your mentor to break down larger goals into manageable steps. This can help you regain momentum and overcome plateaus.
            </p>
            <br/>
            <p>
              c. Seek New Perspectives: Mentors can offer fresh perspectives and insights that can reignite your passion and creativity in the cyber world.
            </p>
          </div>
        )}
        {section === 'purpose' && (
          <div className="section-content" style={{ color: 'black' }}>
            <h2>The Greater Purpose of Mentorship</h2>
            <br/>
            <p>
              a. Paying it Forward: As you progress in your cyber journey, consider becoming a mentor to others. Passing on your knowledge and experience can be incredibly rewarding.
            </p>
            <br/>
            <p>
              b. Building a Stronger Community: Mentorship fosters a sense of community and collaboration within the cyber field, making it more resilient and innovative.
            </p>
            <br/>
            <p>
              c. Advancing the Industry: Through mentorship, we can collectively raise the standard of cyber practices, creating a safer digital environment for all.
            </p>
          </div>
        )}
      </main>
    </div>
  );
}

export default MentorshipGuide;