import React from 'react';

const JoeSandboxAnalysis = () => {
  return (
    <div>
      <h1>Joe Sandbox Windows Analysis</h1>
      <p>
        Joe Sandbox is a malware analysis platform that provides dynamic
        analysis of suspicious files. It helps researchers and analysts
        understand the behavior of malware in a controlled environment.
      </p>
      <a
        href="https://www.joesandbox.com/#windows"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: 'block',
          backgroundColor: '#007bff',
          color: '#ffffff',
          padding: '10px',
          borderRadius: '5px',
          textDecoration: 'none',
          textAlign: 'center',
          maxWidth: '300px',
          margin: 'auto',
          marginTop: '20px',
        }}
      >
        Explore Joe Sandbox Windows Analysis
      </a>
    </div>
  );
};

export default JoeSandboxAnalysis;