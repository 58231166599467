import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const BlueTeamFacebookPromoBanner = () => {
  const facebookGroups = [
    {
      title: 'Blue Team Professionals',
      link: 'https://www.facebook.com/groups/123456/', // Replace with an actual Facebook group link
    },
    {
      title: 'Cybersecurity Defense Community',
      link: 'https://www.facebook.com/groups/123456/', // Replace with an actual Facebook group link
    },
    // Add more Facebook groups here
  ];

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Connect with Blue Teamers on Facebook!</h2>
          <p>Discover Facebook groups for blue team cybersecurity professionals to engage, discuss, and learn.</p>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Facebook Groups?</h3>
        <ul>
          <li>Engage in discussions with a community of blue team enthusiasts.</li>
          <li>Share insights, experiences, and resources with like-minded professionals.</li>
          <li>Stay informed about the latest trends, tools, and best practices.</li>
          <li>Build relationships and collaborate on blue team challenges.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Facebook Groups for Blue Team</h3>
        <ul>
          {facebookGroups.map((group, index) => (
            <li key={index}>
              <a href={group.link} target="_blank" rel="noopener noreferrer">
                {group.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Group Members Say</h3>
        <div className="testimonial">
          <p>"Joining Facebook groups has connected me with a vibrant community of blue team professionals."</p>
          <p>- John Smith, Blue Team Enthusiast</p>
        </div>
        <div className="testimonial">
          <p>"Interacting with other members in Facebook groups has helped me expand my knowledge and network."</p>
          <p>- Jane Doe, Cybersecurity Professional</p>
        </div>
      </div>
    </div>
  );
};

export default BlueTeamFacebookPromoBanner;