import React from 'react';

const IBMCloudInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#0078D4', // IBM Cloud brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const buttonStyle = {
    backgroundColor: '#232323',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Innovate with IBM Cloud</h1>
      <p>
        IBM Cloud is a robust cloud computing platform offered by IBM. It provides a wide array of cloud services and solutions, enabling businesses to build, deploy, and manage applications with ease.
      </p>
      <h2 style={subheadingStyle}>Key Features of IBM Cloud:</h2>
      <ul>
        <li>Hybrid and multicloud capabilities</li>
        <li>Advanced AI and data analytics</li>
        <li>Blockchain and IoT services</li>
        <li>Serverless computing with Cloud Functions</li>
        <li>Enterprise-grade security and compliance</li>
        <li>Developer-friendly tools and services</li>
      </ul>
      <button onClick={() => window.open("https://www.ibm.com/cloud", "_blank")} style={buttonStyle}>Explore IBM Cloud</button>
    </div>
  );
};

export default IBMCloudInfo;