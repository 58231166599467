import React from 'react';
import '../CSS/CertificationRoadmap.css'; // Import your custom CSS for styling

const CertificationRoadmap = () => {
  return (
    <div className="certification-roadmap">
      <h1>Cybersecurity Certification Roadmap</h1>
      <p>Here's a comprehensive roadmap of cybersecurity certifications:</p>
      <iframe
        src="https://pauljerimy.com/security-certification-roadmap/"
        title="Certification Roadmap"
        className="certification-iframe"
      ></iframe>
    </div>
  );
};

export default CertificationRoadmap;