import React from 'react';
import { useField } from 'formik';
import { TextField, FormControlLabel, Radio } from '@material-ui/core';

export const TextInput = ({
  placeholder,
  label,
  type,
  required,
  fullWidth,
  InputProps,
  multiline,
  rows,
  rowsMax,
  variant,
  size,
  disabled,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';

  // Add a CSS class conditionally for xs screens
  const textFieldClass = fullWidth ? 'fullWidthTextField' : '';

  return (
    <TextField
      placeholder={placeholder}
      label={label}
      type={type}
      InputProps={InputProps}
      required={required}
      fullWidth={fullWidth}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      variant={variant}
      size={size}
      disabled={disabled}
      {...field}
      helperText={errorText}
      error={!!errorText}
      className={textFieldClass} // Apply the conditional CSS class
    />
  );
};

export const RadioInput = ({ label, ...props }) => {
  const [field] = useField(props);
  return <FormControlLabel {...field} control={<Radio />} label={label} />;
};

// Define your CSS as a string
const cssForXSScreens = `
  /* Define styles for xs screens */
  @media (max-width: 600px) {
    .fullWidthTextField {
      // Add your specific styles for xs screens here
      background-color: lightgray; /* Example background color */
      /* Add more styles as needed... */
    }
  }
`;

// Create a style element and set its innerHTML to the CSS string
const styleElement = document.createElement('style');
styleElement.innerHTML = cssForXSScreens;

// Append the style element to the document's head
document.head.appendChild(styleElement);
