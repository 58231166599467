import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PStackRead.css'; // Create a CSS file for styling

const StacksReadForm = () => {
  const [exploitResults, setExploitResults] = useState([]);
  const [filteredExploitResults, setFilteredExploitResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 1000;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFeedback, setselectedFeedback] = useState('');

  useEffect(() => {
    // Fetch exploit results from the MongoDB collection
    const fetchExploitResults = async () => {
      try {
        const response = await axios.get(`/api/stacks-search?page=${currentPage}`);
        setExploitResults(response.data.results);
      } catch (error) {
        console.error('Error fetching exploit results:', error);
      }
    };

    fetchExploitResults();
  }, [currentPage]);

  useEffect(() => {
    // Filter exploit results based on search term and selected OS
    const filteredResults = exploitResults.filter((result) => {
      const searchString = `${result.lessonLearned} ${result.aresult} ${result.dresult} ${result.steps} ${result.eresult}`;
      const includesSearchTerm = searchString.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesselectedFeedback = selectedFeedback ? result.selectedFeedback === selectedFeedback : true;
      return includesSearchTerm && matchesselectedFeedback;
    });

    setFilteredExploitResults(filteredResults);
  }, [searchTerm, selectedFeedback, exploitResults]);

  const handleLoadRecent = () => {
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    const recentResults = exploitResults.filter((result) => {
      const resultDate = new Date(result.currentDate);
      return resultDate.getTime() >= thirtyDaysAgo.getTime();
    });

    setFilteredExploitResults(recentResults);
  };

  const handleLoadByselectedFeedback = (selectedFeedback) => {
    setselectedFeedback(selectedFeedback);
  };

  return (
    <div className="exploit-search-container">
      <h2>Programming/Technology Stacks</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="button-group">
        <div className="exploit-os-buttons">
          {Array.from(new Set(exploitResults.map((result) => result.selectedFeedback))).map((selectedFeedback) => (
            <button
              key={selectedFeedback}
              className={`load-button ${selectedFeedback === selectedFeedback ? 'selected' : ''}`}
              onClick={() => handleLoadByselectedFeedback(selectedFeedback)}
            >
              {selectedFeedback}
            </button>
          ))}
        </div>
      </div>
      <div className="exploit-results">
        {filteredExploitResults.map((result, index) => (
          <div key={result._id} className="exploit-result">
            <div className="exploit-header">
              <span className="user">{result.username}</span>
              <span className="date">{result.currentDate}</span>
            </div>
            <div className="exploit-details">
              <span className="lesson-learned">Name: {result.lessonLearned}</span>
              <br />
              <span className="feeling">State: {result.selectedFeedback}</span>
              <br />
              <span className="name">Purpose: {result.dresult}</span>
              <br />
              <br />
              <span className="steps">
                {result.steps.map((step, stepIndex) => (
                  <span key={stepIndex}> {stepIndex + 1}: {step}</span>
                ))}
              </span>
              <br />
              <span className="result">Potential: {result.aresult}</span>
              <br />
              <span className="result">Evidence: {result.eresult}</span>
              <br />
              
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


export default StacksReadForm;