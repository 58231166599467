import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchPosts,
  loadMorePosts,
  toggleUpvote,
  toggleDownvote,
} from '../reducers/postReducer';
import { notify } from '../reducers/notificationReducer';
import PostCard from './PostCard';
import SortTabBar from './SortTabBar';
import LoadMoreButton from './LoadMoreButton';
import getErrorMsg from '../utils/getErrorMsg';

import {
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import '../css/tabbarposts.css';
import '../css/postcard.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    borderRadius: '0.5rem',
    boxShadow: theme.shadows[3],
  },
  sortTabBarContainer: {
    marginBottom: '1rem',
    width: '52vw',
  },
  postsContainer: {
    display: 'column',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '1rem',
  },
  noSubscribedPosts: {
    textAlign: 'center',
    padding: '2rem',
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.5rem',
    boxShadow: theme.shadows[3],
    animation: '$fadeSlideIn 0.5s ease-in-out',
  },
  '@keyframes fadeSlideIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-20px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  loadingSpinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12em',
    color: '#000000', // Set color to black
  },
  spinner: {
    animation: '$rotate 1.5s linear infinite', // Add rotation animation
  },
  text: {
    marginTop: '1em',
    color: '#555555', // Set color for the text
    fontWeight: 'bold',
    fontSize: '1.2em',
    textTransform: 'uppercase',
    textAlign: 'center', // Center align the text
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  // Define styles for the button
  goToTopButton: {
    position: 'fixed',
    bottom: '70px',
    right: '47vw',
    backgroundColor: 'transparent',
    color: 'white',
    padding: '12px 24px',
    borderRadius: '4px',
    cursor: 'pointer',
    zIndex: 1000,
    display: 'none', // Initially hidden
    animation: '$glow 7s infinite, $colorChange 7s infinite', // Glowing effect and color change
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '8px',
      height: '8px',
      background: 'transparent',
      borderRadius: '50%',
      top: '0',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  // Keyframes for glowing animation
  '@keyframes glow': {
    '0%': {
      boxShadow: '0 0 10px 5px rgba(255, 0, 0, 0.5), 0 0 20px 10px rgba(0, 0, 255, 0.5), 0 0 30px 15px rgba(128, 0, 128, 0.5)',
    },
    '50%': {
      boxShadow: '0 0 20px 10px rgba(255, 0, 0, 0.5), 0 0 40px 20px rgba(0, 0, 255, 0.5), 0 0 60px 30px rgba(128, 0, 128, 0.5)',
    },
    '100%': {
      boxShadow: '0 0 10px 5px rgba(255, 0, 0, 0.5), 0 0 20px 10px rgba(0, 0, 255, 0.5), 0 0 30px 15px rgba(128, 0, 128, 0.5)',
    },
  },
  // Keyframes for background color change animation
  '@keyframes colorChange': {
    '0%': {
      backgroundColor: 'purple',
    },
    '50%': {
      backgroundColor: 'black',
    },
    '100%': {
      backgroundColor: 'purple',
    },
  },
}));

const PostList = () => {
  const [sortBy, setSortBy] = useState('hot');
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [dragY, setDragY] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const posts = useSelector((state) => state.posts);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleScroll = () => {
    // Show the "Go to top" button when scrolled down
    const goToTopButton = document.getElementById('goToTopButton');
    if (goToTopButton) {
      if (window.scrollY > 777) {
        goToTopButton.style.display = 'block';
      } else {
        goToTopButton.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    window.addEventListener('scroll', handleScroll); // Listen for scroll events

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);

      window.removeEventListener('scroll', handleScroll); // Remove scroll event listener
    };
  }, []);

  const handleTabChange = async (e, newValue) => {
    try {
      setPageLoading(true);
      await dispatch(fetchPosts(newValue));
      setSortBy(newValue);
      setPageLoading(false);

      if (page !== 1) {
        setPage(1);
      }
    } catch (err) {
      setPageLoading(false);
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  const handleLoadPosts = async () => {
    try {
      setLoadingMore(true);
      await dispatch(loadMorePosts(sortBy, page + 1));
      setPage((prevState) => prevState + 1);
      setLoadingMore(false);
    } catch (err) {
      setLoadingMore(false);
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  const handleRefresh = async () => {
    try {
      setRefreshing(true);
      await dispatch(fetchPosts(sortBy)); // Fetch the posts to refresh the content
      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  const handleTouchStart = (e) => {
    setDragging(true);
    setDragY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (dragging) {
      const deltaY = e.touches[0].clientY - dragY;

      if (deltaY > 50 && !refreshing) {
        // The user has dragged down by at least 50px, and refresh is not in progress
        handleRefresh();
      }
    }
  };

  const handleTouchEnd = () => {
    setDragging(false);
  };

  // Use a state variable to track whether the device is mobile or desktop
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // You can adjust the breakpoint as needed

  useEffect(() => {
    // Update the isMobile state when the window is resized
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // You can adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderGoToTopButton = () => {
    return (
      <div
        id="goToTopButton"
        className={classes.goToTopButton}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          handleRefresh(); // Refresh the posts when scrolling to the top
        }}
      >
        Go to Top
      </div>
    );
  };

  // Apply different styles or behavior based on the isMobile state
  return (
    <div className={classes.root}>
      <div className={classes.sortTabBarContainer}>
        <SortTabBar
          sortBy={sortBy}
          handleTabChange={handleTabChange}
          subscribedTab={true}
          user={user}
        />
      </div>
      <div className={classes.postsContainer}>
        {posts && posts.results && !pageLoading ? (
          posts.results.map((post, index) => (
            <PostCard
              post={post}
              key={index}
              toggleUpvote={toggleUpvote}
              toggleDownvote={toggleDownvote}
              className="postCard"
            />
          ))
        ) : (
          <div className={classes.loadingSpinner}>
            <div className={classes.spinner}></div>
            <CircularProgress size="6em" disableShrink className={classes.spinner} />
          </div>
        )}
      </div>
      {sortBy === 'subscribed' && posts.results.length === 0 && (
        <div className={classes.noSubscribedPosts}>
          <Typography variant="h5" color="secondary">
            No Posts Found
          </Typography>
          <Typography variant="h6" color="secondary">
            Subscribe to more subs if you haven't!
          </Typography>
        </div>
      )}
      {posts && 'next' in posts && !pageLoading && (
        <LoadMoreButton handleLoadPosts={handleLoadPosts} loading={loadingMore} />
      )}
      {refreshing && (
        <div className={classes.loadingSpinner}>
          <CircularProgress size="6em" disableShrink className={classes.spinner} />
        </div>
      )}
       {renderGoToTopButton()}
    </div>
  );
};

export default PostList;