import React from 'react';

const CodecademyInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#1A8FE3', // Codecademy brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Learn to Code with Codecademy</h1>
      <p>
        Codecademy is an online learning platform that specializes in teaching
        coding skills. Whether you're a beginner or an experienced coder,
        Codecademy offers interactive coding lessons and projects to help you
        build your programming skills.
      </p>
      <h2 style={subheadingStyle}>What Codecademy Offers:</h2>
      <ul>
        <li>Interactive coding lessons in multiple programming languages</li>
        <li>Hands-on projects and coding challenges</li>
        <li>Learning tracks for specific skills and career paths</li>
        <li>Supportive community and peer learning</li>
      </ul>
      <p>
        Start your coding journey with Codecademy:
        <a
          href="https://www.codecademy.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Codecademy Website
        </a>
      </p>
    </div>
  );
};

export default CodecademyInfo;