import React from 'react';
import './yeah.css';

// Define a component for IT management frameworks
const Framework = ({ name, summary, resourceLink }) => (
  <div>
    <h2>{name}</h2>
    <p>{summary}</p>
    <a href={resourceLink} target="_blank" rel="noopener noreferrer">Learn More</a>
  </div>
);

// Define a component for policies
const Policy = ({ name, summary, resourceLink }) => (
  <div>
    <h2>{name}</h2>
    <p>{summary}</p>
    <a href={resourceLink} target="_blank" rel="noopener noreferrer">Learn More</a>
  </div>
);

// Sample data for IT management frameworks
const frameworks = [
    {
      name: 'DevOps',
      summary: 'DevOps is a set of practices that combines software development (Dev) and IT operations (Ops) to shorten the systems development life cycle and provide continuous delivery with high software quality.',
      resourceLink: 'https://en.wikipedia.org/wiki/DevOps',
    },
    {
      name: 'Waterfall Model',
      summary: 'The Waterfall Model is a traditional software development methodology in which progress flows linearly through a sequence of phases, and each phase must be completed before the next one begins.',
      resourceLink: 'https://en.wikipedia.org/wiki/Waterfall_model',
    },
    {
      name: 'Agile Methodology',
      summary: 'Agile is a flexible and iterative approach to software development that emphasizes collaboration, customer feedback, and the ability to adapt to changing requirements.',
      resourceLink: 'https://en.wikipedia.org/wiki/Agile_software_development',
    },
    {
      name: 'ITIL (Information Technology Infrastructure Library)',
      summary: 'ITIL is a framework for IT service management that provides a set of best practices and processes to align IT services with the needs of the business.',
      resourceLink: 'https://www.axelos.com/best-practice-solutions/itil',
    },
    {
      name: 'COBIT (Control Objectives for Information and Related Technologies)',
      summary: 'COBIT is a framework for the governance and management of enterprise IT. It helps organizations achieve their goals through effective and innovative use of IT.',
      resourceLink: 'https://www.isaca.org/resources/cobit',
    },
    {
      name: 'TOGAF (The Open Group Architecture Framework)',
      summary: 'TOGAF is an enterprise architecture methodology and framework used to improve business efficiency. It provides a comprehensive approach for designing, planning, implementing, and governing enterprise information architecture.',
      resourceLink: 'https://www.opengroup.org/togaf',
    },
    {
      name: 'ISO 27001',
      summary: 'ISO 27001 is an international standard for information security management systems (ISMS). It provides a systematic approach for managing and protecting sensitive information within organizations.',
      resourceLink: 'https://www.iso.org/isoiec-27001-information-security.html',
    },
    {
      name: 'CMMI (Capability Maturity Model Integration)',
      summary: 'CMMI is a framework for process improvement that helps organizations optimize their processes for better performance, quality, and efficiency.',
      resourceLink: 'https://cmmiinstitute.com/cmmi',
    },
    {
      name: 'NIST Cybersecurity Framework',
      summary: 'The NIST Cybersecurity Framework is a set of guidelines and best practices for managing cybersecurity risk. It provides a framework for organizations to strengthen their cybersecurity posture.',
      resourceLink: 'https://www.nist.gov/cyberframework',
    },
    {
      name: 'Scrum',
      summary: 'Scrum is an agile project management framework that focuses on delivering value in short development cycles called sprints. It emphasizes collaboration, transparency, and adaptability.',
      resourceLink: 'https://www.scrum.org/',
    },
  ];

// Sample data for policies
const policies = [
    {
      name: 'FIPS (Federal Information Processing Standards)',
      summary: 'FIPS is a set of standards for federal government computer systems and is used to ensure the security and interoperability of IT systems.',
      resourceLink: 'https://csrc.nist.gov/publications/fips',
    },
    {
      name: 'PCI DSS (Payment Card Industry Data Security Standard)',
      summary: 'PCI DSS is a set of security standards designed to ensure that all companies that accept, process, store, or transmit credit card information maintain a secure environment.',
      resourceLink: 'https://www.pcisecuritystandards.org/',
    },
    {
      name: 'HIPAA (Health Insurance Portability and Accountability Act)',
      summary: 'HIPAA establishes national standards for the security and privacy of protected health information (PHI). It applies to healthcare organizations and their business associates.',
      resourceLink: 'https://www.hhs.gov/hipaa/',
    },
    {
      name: 'ISO 27001',
      summary: 'ISO 27001 is an international standard for information security management systems (ISMS). It provides a systematic approach for managing and protecting sensitive information within organizations.',
      resourceLink: 'https://www.iso.org/isoiec-27001-information-security.html',
    },
    {
      name: 'GDPR (General Data Protection Regulation)',
      summary: 'GDPR is a European Union regulation that governs the protection of personal data. It gives individuals control over their personal data and imposes strict requirements on organizations that process this data.',
      resourceLink: 'https://gdpr.eu/',
    },
    {
      name: 'NIST SP 800-53',
      summary: 'NIST Special Publication 800-53 provides security controls and guidelines for federal information systems and organizations. It helps protect sensitive government information.',
      resourceLink: 'https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-53r5.pdf',
    },
    {
      name: 'CIS Controls (Center for Internet Security Controls)',
      summary: 'CIS Controls are a set of best practices for securing organizations IT systems and data. They are widely used for enhancing cybersecurity posture.',
      resourceLink: 'https://www.cisecurity.org/controls/',
    },
    {
      name: 'NERC CIP (North American Electric Reliability Corporation Critical Infrastructure Protection)',
      summary: 'NERC CIP standards are cybersecurity requirements for the North American electric grid. They aim to ensure the reliability and security of critical infrastructure.',
      resourceLink: 'https://www.nerc.com/pa/Stand/Pages/CIPStandards.aspx',
    },
    {
      name: 'NIST Cybersecurity Framework',
      summary: 'The NIST Cybersecurity Framework is a set of guidelines and best practices for managing cybersecurity risk. It provides a framework for organizations to strengthen their cybersecurity posture.',
      resourceLink: 'https://www.nist.gov/cyberframework',
    },
    {
      name: 'SOC 2 (System and Organization Controls 2)',
      summary: 'SOC 2 is an auditing standard designed to assess and report on the controls related to the security, availability, processing integrity, confidentiality, and privacy of customer data.',
      resourceLink: 'https://www.aicpa.org/interestareas/frc/assuranceadvisoryservices/aicpasoc2report.html',
    },
    {
      name: 'CMMC (Cybersecurity Maturity Model Certification)',
      summary: 'CMMC is a set of cybersecurity requirements for contractors working with the U.S. Department of Defense (DoD). It aims to enhance the protection of controlled unclassified information (CUI).',
      resourceLink: 'https://www.acq.osd.mil/cmmc/',
    },
    {
      name: 'ITAR (International Traffic in Arms Regulations)',
      summary: 'ITAR controls the export and import of defense-related articles and services. It imposes strict regulations on the export of items that are deemed to be of military significance.',
      resourceLink: 'https://www.pmddtc.state.gov/ddtc_public?id=ddtc_kb_article_page&sys_id=bd72b7dbdb42d30044f9ff621f961981',
    },
    {
      name: 'FERPA (Family Educational Rights and Privacy Act)',
      summary: 'FERPA is a U.S. federal law that protects the privacy of student education records. It grants parents and eligible students the right to access and control their educational records.',
      resourceLink: 'https://www2.ed.gov/policy/gen/guid/fpco/ferpa/index.html',
    },
    {
      name: 'ISO 20000-1',
      summary: 'ISO 20000-1 is an international standard for IT service management. It defines the requirements for a service management system and helps organizations deliver quality IT services.',
      resourceLink: 'https://www.iso.org/iso-20000-service-management.html',
    },
    {
      name: 'NIST SP 800-171',
      summary: 'NIST Special Publication 800-171 provides security requirements for protecting controlled unclassified information (CUI) in non-federal systems and organizations.',
      resourceLink: 'https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-171r2.pdf',
    },
    {
      name: 'ISO 22301',
      summary: 'ISO 22301 is an international standard for business continuity management. It helps organizations plan for and manage disruptions to ensure business continuity.',
      resourceLink: 'https://www.iso.org/iso-22301-business-continuity.html',
    },
    {
      name: 'NIST SP 800-37',
      summary: 'NIST Special Publication 800-37 provides guidelines for the risk management framework (RMF) for federal information systems. It helps organizations manage security risks effectively.',
      resourceLink: 'https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-37r2.pdf',
    },
    {
      name: 'ISO 9001',
      summary: 'ISO 9001 is an international standard for quality management systems (QMS). It helps organizations ensure the consistent quality of their products and services.',
      resourceLink: 'https://www.iso.org/iso-9001-quality-management.html',
    },
    {
      name: 'NIST SP 800-63',
      summary: 'NIST Special Publication 800-63 provides guidelines for electronic authentication. It covers the secure management of identity and authentication in digital systems.',
      resourceLink: 'https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-63-3.pdf',
    },
    {
      name: 'ISO 31000',
      summary: 'ISO 31000 is an international standard for risk management. It provides principles, framework, and a process for managing risk effectively in organizations.',
      resourceLink: 'https://www.iso.org/iso-31000-risk-management.html',
    },
    {
      name: 'FERC Standards (Federal Energy Regulatory Commission Standards)',
      summary: 'FERC standards govern the reliability and security of the North American bulk power system. They aim to ensure the stability and resilience of the electrical grid.',
      resourceLink: 'https://www.ferc.gov/legal/maj-ord-reg/fed-land-base-text.asp',
    },
    {
      name: 'ISO 50001',
      summary: 'ISO 50001 is an international standard for energy management systems (EnMS). It helps organizations improve energy performance and reduce energy costs.',
      resourceLink: 'https://www.iso.org/iso-50001-energy-management.html',
    },
    {
      name: 'NIST SP 800-61',
      summary: 'NIST Special Publication 800-61 provides guidelines for computer security incident handling. It helps organizations detect, respond to, and recover from cybersecurity incidents.',
      resourceLink: 'https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-61r2.pdf',
    },
    {
      name: 'ISO 14001',
      summary: 'ISO 14001 is an international standard for environmental management systems (EMS). It helps organizations reduce their environmental impact and promote sustainability.',
      resourceLink: 'https://www.iso.org/iso-14001-environmental-management.html',
    },
    {
      name: 'NIST SP 800-66',
      summary: 'NIST Special Publication 800-66 provides guidelines for implementing the HIPAA Security Rule. It helps covered entities and business associates protect electronic protected health information (ePHI).',
      resourceLink: 'https://nvlpubs.nist.gov/nistpubs/Legacy/SP/nistspecialpublication800-66.pdf',
    },
    {
      name: 'ISO 10002',
      summary: 'ISO 10002 is an international standard for customer satisfaction management. It provides guidelines for handling complaints and improving customer satisfaction.',
      resourceLink: 'https://www.iso.org/iso-10002-customer-satisfaction.html',
    },
    {
      name: 'NIST SP 800-88',
      summary: 'NIST Special Publication 800-88 provides guidelines for media sanitization. It helps organizations securely erase sensitive information from media and devices.',
      resourceLink: 'https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-88r1.pdf',
    },
  ];

function ManagementPol() {
  return (
    <div className="App1">
      <h1>IT Management Frameworks</h1>
      <div className="framework1">
        {frameworks.map((framework, index) => (
          <Framework key={index} {...framework} />
        ))}
      </div>
      <h1>Policies</h1>
      <div className="policy1">
        {policies.map((policy, index) => (
          <Policy key={index} {...policy} />
        ))}
      </div>
    </div>
  );
}

export default ManagementPol;