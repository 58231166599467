import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const GoogleCloudLoggingPromoBanner = () => {
  const handleButtonClick = () => {
    // Open Google Cloud website link for Logging and Monitoring in a new tab/window
    window.open('https://cloud.google.com/logging', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Monitor with Google Cloud Logging and Monitoring!</h2>
          <p>Gain insights into your Google Cloud resources and applications with powerful logging and monitoring capabilities.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Google Cloud Logging and Monitoring?</h3>
        <ul>
          <li>Collect and analyze logs from Google Cloud services and applications.</li>
          <li>Visualize performance metrics and trends to optimize your cloud environment.</li>
          <li>Set up alerts and notifications based on predefined conditions.</li>
          <li>Enhance the reliability and security of your Google Cloud workloads.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Cloud Users Say</h3>
        <div className="testimonial">
          <p>"Google Cloud Logging and Monitoring has transformed the way we monitor and troubleshoot our cloud applications."</p>
          <p>- John Smith, Cloud Architect</p>
        </div>
        <div className="testimonial">
          <p>"The deep insights and customizable dashboards of Google Cloud Logging and Monitoring have greatly improved our operations."</p>
          <p>- Jane Doe, Site Reliability Engineer</p>
        </div>
      </div>
    </div>
  );
};

export default GoogleCloudLoggingPromoBanner;
