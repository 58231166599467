import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './css/userentries.css';

const UserEntries = () => {
  
  const { username } = useParams();
  const [entries, setEntries] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    // Fetch user entries using the username from the URL params
    fetch(`/api/user-entries?username=${username}`)
      .then((response) => response.json())
      .then((data) => setEntries(data.entries))
      .catch((error) => console.error('Error fetching user entries:', error));
  }, [username]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % entries.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? entries.length - 1 : prevSlide - 1
    );
  };

  const formatLocalDate = (utcDateStr) => {
    const utcDate = new Date(utcDateStr);
    const localDate = utcDate.toLocaleString(); // Format in the user's local time zone
    return localDate;
  };

  return (
    <div>
      <h2 style={{ 
  background: 'linear-gradient(180deg, white, #ccc)', /* White to grey gradient */
  display: 'center', /* Set to inline-block to fit content width */
  padding: '10px', /* Add some padding for spacing */
  borderRadius: '5px', /* Add rounded corners */
  color: 'black', /* Text color */
  marginRight: '1vw',
}}>
  {username}'s Work
</h2>
      <div className="navigation">
        <button style={{ color: 'black' }} onClick={prevSlide}>Previous</button>
        <button style={{ color: 'black' }} onClick={nextSlide}>Next</button>
      </div>
      {entries.length > 0 ? (
        <div className="slide">
          <div style={{ color: 'black' }}>
            <strong>Date:</strong> {formatLocalDate(entries[currentSlide]?.currentDate)}
          </div>
          {entries[currentSlide]?.redToolName && (
            <div style={{ color: 'black' }}>
              <strong>Red Tool Name:</strong> {entries[currentSlide]?.redToolName}
            </div>
          )}
          {entries[currentSlide]?.redToolLink && (
            <div style={{ color: 'black' }}>
              <strong>Red Tool Link:</strong> {entries[currentSlide]?.redToolLink}
            </div>
          )}
          {entries[currentSlide]?.cloudToolName && (
            <div style={{ color: 'black' }}>
              <strong>Cloud Tool Name:</strong> {entries[currentSlide]?.cloudToolName}
            </div>
          )}
          {entries[currentSlide]?.cloudToolLink && (
            <div style={{ color: 'black' }}>
              <strong>Cloud Tool Link:</strong> {entries[currentSlide]?.cloudToolLink}
            </div>
          )}
          {entries[currentSlide]?.datasciToolName && (
            <div style={{ color: 'black' }}>
              <strong>Data Science Tool Name:</strong> {entries[currentSlide]?.datasciToolName}
            </div>
          )}
          {entries[currentSlide]?.datasciToolLink && (
            <div style={{ color: 'black' }}>
              <strong>Data Science Tool Link:</strong> {entries[currentSlide]?.datasciToolLink}
            </div>
          )}
          {entries[currentSlide]?.lessonLearned && (
            <div style={{ color: 'black' }}>
              <strong>Stack Name:</strong> {entries[currentSlide]?.lessonLearned}
            </div>
          )}
          {entries[currentSlide]?.steps && (
            <div style={{ color: 'black' }}>
              <strong>Stack Technologies:</strong>
              {entries[currentSlide].steps.map((step, index) => (
                <div key={index}>{step}</div>
              ))}
            </div>
          )}
          {entries[currentSlide]?.selectedFeedback && (
            <div style={{ color: 'black' }}>
              <strong>State:</strong> {entries[currentSlide]?.selectedFeedback}
            </div>
          )}
          {entries[currentSlide]?.dresult && (
            <div style={{ color: 'black' }}>
              <strong>Purpose:</strong> {entries[currentSlide]?.dresult}
            </div>
          )}
          {entries[currentSlide]?.aresult && (
            <div style={{ color: 'black' }}>
              <strong>Potential:</strong> {entries[currentSlide]?.aresult}
            </div>
          )}
          {entries[currentSlide]?.eresult && (
            <div style={{ color: 'black' }}>
              <strong>Evidence:</strong> {entries[currentSlide]?.eresult}
            </div>
          )}
          {entries[currentSlide]?.programmingToolName && (
            <div style={{ color: 'black' }}>
              <strong>Programming Tool Name:</strong> {entries[currentSlide]?.programmingToolName}
            </div>
          )}
          {entries[currentSlide]?.programmingToolLink && (
            <div style={{ color: 'black' }}>
              <strong>Programming Tool Link:</strong> {entries[currentSlide]?.programmingToolLink}
            </div>
          )}
          {entries[currentSlide]?.blueToolName && (
            <div style={{ color: 'black' }}>
              <strong>Blue Tool Name:</strong> {entries[currentSlide]?.blueToolName}
            </div>
          )}
          {entries[currentSlide]?.blueToolLink && (
            <div style={{ color: 'black' }}>
              <strong>Blue Tool Link:</strong> {entries[currentSlide]?.blueToolLink}
            </div>
          )}
          {entries[currentSlide]?.queryType && (
            <div style={{ color: 'black' }}>
              <strong>Query Type:</strong> {entries[currentSlide]?.queryType}
            </div>
          )}
          {entries[currentSlide]?.query && (
            <div style={{ color: 'black' }}>
              <strong>Query:</strong> {entries[currentSlide]?.query}
            </div>
          )}
          {entries[currentSlide]?.iocType && (
            <div style={{ color: 'black' }}>
              <strong>IOC Type:</strong> {entries[currentSlide]?.iocType}
            </div>
          )}
          {entries[currentSlide]?.iocValue && (
            <div style={{ color: 'black' }}>
              <strong>IOC Value:</strong> {entries[currentSlide]?.iocValue}
            </div>
          )}
          {entries[currentSlide]?.exploitOS && (
            <div style={{ color: 'black' }}>
              <strong>Exploit OS:</strong> {entries[currentSlide]?.exploitOS}
            </div>
          )}
          {entries[currentSlide]?.exploitLanguage && (
            <div style={{ color: 'black' }}>
              <strong>Exploit Language:</strong> {entries[currentSlide]?.exploitLanguage}
            </div>
          )}
          {entries[currentSlide]?.exploitValue && (
            <div style={{ color: 'black' }}>
              <strong>Exploit Value:</strong> {entries[currentSlide]?.exploitValue}
            </div>
          )}
          {entries[currentSlide]?.description && (
  <div style={{ color: 'black' }}>
    <strong>Description:</strong> {entries[currentSlide]?.description}
  </div>
)}
        </div>
      ) : (
        <div className="slide" style={{ color: 'black' }}>Not Yet</div>
      )}
    </div>
  );
};

export default UserEntries;