import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './exploitsubmitpage.css'; // Create a CSS file for additional styling

const BlueToolWriteForm = () => {
  const [username, setUsername] = useState('');
  const [blueToolName, setRedToolName] = useState('');
  const [blueToolLink, setRedToolLink] = useState('');
  const [description, setDescription] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [selectedDomain, setSelectedDomain] = useState('');

  useEffect(() => {
    const storedUser = localStorage.getItem('readifyUserKey');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUsername(parsedUser.username);
    }
  }, []);

  useEffect(() => {
    const updateCurrentDate = () => {
      const date = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      };
      const formattedDate = date.toLocaleString('en-US', options).replace(',', '');
      setCurrentDate(formattedDate);
    };

    updateCurrentDate();

    // Update the current date every minute
    const interval = setInterval(updateCurrentDate, 60000);

    return () => clearInterval(interval);
  }, []);

  const domainOptions = [
    'Network Security',
    'Endpoint Security',
    'Incident Response',
    'Forensics',
    'Security Operations Center (SOC)',
    'Threat Intelligence',
    'Vulnerability Management',
    'Identity and Access Management',
    'Data Loss Prevention',
    'Security Information and Event Management (SIEM)',
    'Security Awareness and Training',
    'Patch Management',
    'Security Policy and Compliance',
    'Cloud Security',
    'Mobile Device Security',
    'Web Application Security',
    'Email Security',
    'Endpoint Detection and Response (EDR)',
    'Physical Security',
    'Industrial Control Systems (ICS) Security',
    'Wireless Security',
    'Other'
    // Add more options as needed
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (description.length > 1000000) {
      alert('Description exceeds the maximum limit of 500 characters.');
      return;
    }

    try {
      const response = await axios.post('/api/submit-bluetool', {
        username,
        blueToolName,
        blueToolLink,
        description,
        currentDate,
        selectedDomain,
      });

      if (response.status === 201) {
        alert('Nice! It\'s in there, Tell your friends!');
      } else {
        alert('Error submitting.');
      }
    } catch (error) {
      console.error('Error submitting:', error);
    }

    // Reset the form
    setRedToolName('');
    setRedToolLink('');
    setDescription('');
    setSelectedDomain('');
  };

  return (
    <div className="exploit2-form-container">
      <form onSubmit={handleSubmit} className="form">
        <h2 className="form-title">Submit Blue Team Resource</h2>

        <div className="form-field">
          <label htmlFor="blueToolName">Resource Name:</label>
          <input
            type="text"
            id="blueToolName"
            value={blueToolName}
            onChange={(e) => setRedToolName(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <select
            id="domain"
            value={selectedDomain}
            onChange={(e) => setSelectedDomain(e.target.value)}
            required
          >
            <option value="">Select a Domain (Scroll Down)</option>
            {domainOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="form-field">
          <label htmlFor="blueToolLink">Resource Link:</label>
          <input
            type="text"
            id="blueToolLink"
            value={blueToolLink}
            onChange={(e) => setRedToolLink(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <button className="submit-button" type="submit">
            Place Resource
          </button>
        </div>
      </form>
    </div>
  );
};

export default BlueToolWriteForm;