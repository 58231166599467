import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const Hak5PromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://hak5.org/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Unleash Your Inner Technologist with Hak5!</h2>
          <p>Explore cutting-edge tools, tutorials, and community for hackers, makers, and enthusiasts.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Hak5?</h3>
        <ul>
          <li>Hands-on learning with innovative hacking tools.</li>
          <li>Tutorials on cybersecurity, penetration testing, and technology.</li>
          <li>Community forums for knowledge sharing and collaboration.</li>
          <li>Empower your technical skills and creativity.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Collaborators and Contributors</h3>
        <p>Hak5 collaborates with technologists, hackers, and creators to provide a diverse range of resources and content.</p>
        <ul>
          <li>Security researchers and penetration testers.</li>
          <li>Hardware and software enthusiasts.</li>
          <li>Open-source communities and projects.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What the Community Says</h3>
        <div className="testimonial">
          <p>"Hak5's tutorials and tools have expanded my knowledge in the cybersecurity field. It's an inspiring community to be a part of."</p>
          <p>- John Smith, Technologist</p>
        </div>
        <div className="testimonial">
          <p>"I've learned a lot from Hak5's videos and resources. They make complex topics accessible and fun to learn."</p>
          <p>- Jane Doe, DIY Enthusiast</p>
        </div>
      </div>
    </div>
  );
};

export default Hak5PromoBanner;