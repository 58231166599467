import React, { useEffect, useState } from 'react';
import '../../css/feeds.css';

const RSSFeedsGlobal = () => {
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    const fetchFeeds = async () => {
      try {
        const response = await fetch('/api/rss-feeds-us'); // Update the URL to match your server endpoint
        if (response.ok) {
          const fetchedFeeds = await response.json();
          setFeeds(fetchedFeeds);
        } else {
          console.error('Error fetching feeds:', response.status);
        }
      } catch (error) {
        console.error('Error fetching feeds:', error);
      }
    };

    fetchFeeds();

    // Refresh the feeds every 5 minutes (adjust the interval as needed)
    const interval = setInterval(fetchFeeds, 5 * 60 * 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="app"> {/* Added this line */}
      <div className="feeds-container"> {/* Changed class name from "rss-feeds" to "feeds-container" */}
        {feeds.map((feed, index) => (
          <div key={index} className="rss-feed-item">
            <h3>{feed.title}</h3>
            <ul>
              {feed.items.map((item) => (
                <li key={item.link}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>{item.title}</a>
                  {item.contentSnippet && <p>{item.contentSnippet}</p>}
                  {item.enclosure && (
                    <img src={item.enclosure.url} alt="Preview" />
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <amp-auto-ads type="adsense"
        data-ad-client="ca-pub-3504607292095587">
</amp-auto-ads>
    </div> /* Added closing tag */
  );
};

export default RSSFeedsGlobal;