import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const RichardBejtlichPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://taosecurity.com/', '_blank');
  };

  const books = [
    {
      title: 'The Practice of Network Security Monitoring',
      link: 'https://www.amazon.com/Practice-Network-Security-Monitoring-Understanding/dp/1593275099',
    },
    {
      title: 'Extrusion Detection: Security Monitoring for Internal Intrusions',
      link: 'https://www.amazon.com/Extrusion-Detection-Security-Monitoring-Intrusions/dp/0321349962',
    },
  ];

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Discover Cybersecurity with Richard Bejtlich!</h2>
          <p>Explore educational resources, books, and insights on Richard Bejtlich's website.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Richard Bejtlich?</h3>
        <ul>
          <li>Expertise in network security monitoring and incident response.</li>
          <li>Author of influential books on network security and intrusion detection.</li>
          <li>Engaging talks and expertise-sharing for security practitioners.</li>
          <li>Learn from a recognized cybersecurity thought leader.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Books by Richard Bejtlich</h3>
        <ul>
          {books.map((book, index) => (
            <li key={index}>
              <a href={book.link} target="_blank" rel="noopener noreferrer">
                {book.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What the Community Says</h3>
        <div className="testimonial">
          <p>"Richard Bejtlich's books have been instrumental in shaping my approach to network security monitoring. His insights are invaluable."</p>
          <p>- John Smith, Security Professional</p>
        </div>
        <div className="testimonial">
          <p>"I've learned so much from Richard Bejtlich's talks and books. His expertise and contributions are remarkable."</p>
          <p>- Jane Doe, Cybersecurity Enthusiast</p>
        </div>
      </div>
    </div>
  );
};

export default RichardBejtlichPromoBanner;
