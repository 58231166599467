import React from 'react';

const UdemyInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f8f8f8', // Light background color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'black',
  };

  const headingStyle = {
    color: '#333',
  };

  const subheadingStyle = {
    color: '#555',
  };

  const linkStyle = {
    color: '#007bff', // Udemy brand color
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Discover Learning on Udemy</h1>
      <p>
        Udemy is a leading online learning platform with thousands of courses
        covering a wide range of topics. Whether you're looking to acquire new
        skills or advance your career, Udemy offers courses for everyone.
      </p>
      <h2 style={subheadingStyle}>What Udemy Offers:</h2>
      <ul>
        <li>Thousands of courses in various domains</li>
        <li>Expert instructors and high-quality content</li>
        <li>Flexible learning at your own pace</li>
        <li>Access to lifetime course updates</li>
      </ul>
      <p>
        Explore the world of online learning by visiting Udemy:
        <a
          href="https://www.udemy.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Udemy Website
        </a>
      </p>
    </div>
  );
};

export default UdemyInfo;