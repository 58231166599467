import React from 'react';

const SkillshareInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#FF6D5A', // Skillshare brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore Learning on Skillshare</h1>
      <p>
        Skillshare is an online learning community that offers thousands of
        creative and practical courses. Whether you're interested in art,
        design, technology, or entrepreneurship, Skillshare provides a platform
        to explore your passions and develop new skills.
      </p>
      <h2 style={subheadingStyle}>What Skillshare Offers:</h2>
      <ul>
        <li>Thousands of courses in various creative fields</li>
        <li>Hands-on projects and assignments</li>
        <li>Access to expert instructors and industry professionals</li>
        <li>Opportunities to connect with a creative community</li>
      </ul>
      <p>
        Ignite your creativity and learn new skills with Skillshare:
        <a
          href="https://www.skillshare.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Skillshare Website
        </a>
      </p>
    </div>
  );
};

export default SkillshareInfo;