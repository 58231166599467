import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../LoadingSpinner';
import storageService from '../../utils/localStorage';

import {
  Paper,
  Typography,
  useMediaQuery,
  Link,
} from '@material-ui/core';
import { useSubPanelStyles } from '../../styles/muiStyles';
import { useTheme } from '@material-ui/core/styles';

const TopLessonsSubmittersPanel = () => {
  const { users } = useSelector((state) => state);
  const classes = useSubPanelStyles();
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(true);
  const [topSubmitters, setTopSubmitters] = useState([]);

  useEffect(() => {
    const fetchTopSubmitters = async () => {
      try {
        const response = await fetch('/api/lessons/top'); // Replace with your API endpoint
        const data = await response.json();
        if (response.ok) {
          setTopSubmitters(data.topIOCSubmitters);
        } else {
          console.error(data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchTopSubmitters();
  }, []);

  if (isNotDesktop) {
    return null;
  }

  const loggedUser = storageService.loadUser() || (users && users.user);

  const loadingUsers = !users || !users.topUsers;

  return (
    <Paper className={`mainPaper ${classes.mainPaper}`} style={{ backgroundColor: 'transparent' }}>
      <Paper className={`listPaper ${classes.listPaper}`}>
        <Typography variant="h5" color="secondary" className={`title ${classes.title}`}>
          Top Lessons 
        </Typography>
        {loading ? (
          <LoadingSpinner text="Fetching IOC submitters data..." />
        ) : (
          topSubmitters.map((submitter, i) => (
            <div key={submitter._id} className={`listWrapper ${classes.listWrapper}`}>
              <Typography variant="body2" className={`listItem ${classes.listItem}`}>
                {`${i + 1}. `}
                <Link
                  component={RouterLink}
                  to={`/u/${submitter._id}`} // Use the appropriate user identifier
                  color="primary"
                >
                  {submitter._id}
                </Link>
              </Typography>
              {/* Render additional information about the submitter */}
              <Typography variant="body2" className={`iocCount ${classes.iocCount}`}>
                {`Lessons: ${submitter.totalIOCCount}`}
              </Typography>
            </div>
          ))
        )}
      </Paper>
    </Paper>
  );
};

export default TopLessonsSubmittersPanel;