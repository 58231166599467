import React, { useState, useEffect } from 'react'; // Import useEffect
import axios from 'axios';
import './givecommendation.css';
import CommendationDisplay from './commendationsdisplay';
const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;

const CommendationSubmission = () => {
  const [selectedUsername, setSelectedUsername] = useState('');
  const [commendationText, setCommendationText] = useState('');
  const [rating, setRating] = useState(5); // Default rating of 5
  const [usersForCommendation, setUsersForCommendation] = useState([]); // Define usersForCommendation state

  const handleUsernameChange = (event) => {
    setSelectedUsername(event.target.value);
  };

  useEffect(() => {
    // Fetch the list of users who meet the criteria from your API
    const fetchUsersForCommendation = async () => {
      try {
        const response = await axios.post('/api/users-for-commendation', { loggedInUser });
        setUsersForCommendation(response.data.users);
      } catch (error) {
        console.error('Error fetching users for commendation:', error);
      }
    };

    fetchUsersForCommendation();
  }, []);

  const handleCommendationTextChange = (event) => {
    setCommendationText(event.target.value);
  };

  const handleRatingChange = (event) => {
    setRating(parseInt(event.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      await axios.post('/api/give-commendation', {
        sender: loggedInUser,
        receiver: selectedUsername,
        text: commendationText,
      });
  
      // Reset form fields
      setSelectedUsername('');
      setCommendationText('');
      setRating(5); // Reset rating to default
  
      // Show a success alert
      alert('Nice! Your Mentor or Mentee has received the Commendation!');
    } catch (error) {
      // Handle errors here
      console.error('Error sending commendation:', error);
  
      // Show an error alert
      alert('Error submitting commendation. Check to make sure a Mentor/Mentee is selected');
    }
  };

  return (
    <div className="commendation-container">
      <h2 className="commendation-heading">Give Commendation</h2>
      <form className="commendation-form" onSubmit={handleSubmit}>
        <div>
          <label className="commendation-label">Select User:</label>
          <select
            className="commendation-select"
            value={selectedUsername}
            onChange={handleUsernameChange}
          >
            <option value="">Select User</option>
            {usersForCommendation.map((user) => (
              <option key={user.username} value={user.username}>
                {user.username}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="commendation-label">Commendation:</label>
          <textarea
            className="commendation-textarea"
            value={commendationText}
            onChange={handleCommendationTextChange}
            rows="4"
            cols="50"
            required
          />
        </div>
        <div>
          <button className="commendation-button" type="submit">
            Submit
          </button>
        </div>
      </form>
      <CommendationDisplay />
    </div>
  );
};

export default CommendationSubmission;