
import { Route, Redirect } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser, signupUser } from "../../reducers/userReducer";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { TextInput } from "../FormikMuiFields";
import { notify } from "../../reducers/notificationReducer";
import AlertMessage from "../AlertMessage";
import getErrorMsg from "../../utils/getErrorMsg";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn"; // Icon for the LinkedIn reference

import {
  Button,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
  Link,
} from "@material-ui/core";
import { useAuthStyles } from "../../styles/muiStyles";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

// Import your background image
import backgroundImage from "../../assets/cbg.gif";

import "./AuthTwo.css"; // Import the CSS file

const validationSchemaSignup = yup.object({
  username: yup
    .string()
    .required("Required")
    .max(20, "Must be at most 20 characters")
    .min(3, "Must be at least 3 characters")
    .matches(
      /^[a-zA-Z0-9-_]*$/,
      "Only alphanumeric characters allowed, no spaces/symbols"
    ),
  email: yup.string().email("Invalid email address"),
  password: yup
    .string()
    .required("Required")
    .min(6, "Must be at least 6 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
  mentor: yup.boolean(),
  disciplines: yup.array().when('mentor', {
    is: true,
    then: yup.array().min(1, "At least one discipline should be selected"),
    otherwise: yup.array()
  }),
  reference1: yup.string().when('mentor', {
    is: true,
    then: yup.string().required("This reference is required"),
    otherwise: yup.string()
  }),
});

const validationSchemaLogin = yup.object({
  username: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

const AuthTwo = () => {
  const dispatch = useDispatch();
  const [authType, setAuthType] = useState("login");
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(null);
  const classes = useAuthStyles(authType)();
  const [isMentor, setIsMentor] = useState(false);
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);

  const handleDisciplineChange = (event) => {
    if (event.target.checked) {
      setSelectedDisciplines([...selectedDisciplines, event.target.name]);
    } else {
      setSelectedDisciplines(selectedDisciplines.filter((d) => d !== event.target.name));
    }
  };

  const handleLogin = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await dispatch(loginUser(values));
      dispatch(notify(`Welcome back ${values.username}!`, "success"));
      // Redirect once logged in
      window.location.href = "/";
    } catch (err) {
      setSubmitting(false);
      setError(getErrorMsg(err));
    }
  };

  const handleSignup = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const signupData = {
        ...values, // Include all form values
        email: values.email,
        mentor: isMentor,
        disciplines: selectedDisciplines,
        reference1: values.reference1,
        reference2: values.reference2,
        reference3: values.reference3,
      };
      await dispatch(signupUser(signupData));
      // Rest of your code...
    } catch (err) {
      setSubmitting(false);
      setError(getErrorMsg(err));
    }
  };

  // Check if the user is already authenticated (readifyUserKey in local storage)
  const isAuthenticated = !!localStorage.getItem("readifyUserKey");

  // If the user is authenticated, redirect to the homepage
  if (isAuthenticated) {
    window.location.href = "/";
    return null;
  }

  return (
    <div className="pageContainer1">
      <div className="authWrapper1">
        <Formik
          validateOnChange={true}
          initialValues={
            authType === "login"
              ? { username: "", password: "" }
              : {
                  username: "",
                  password: "",
                  email: "",
                  mentor: false,
                  disciplines: [],
                  reference1: "",
                  reference2: "",
                  reference3: "",
                }
          }
          onSubmit={authType === "login" ? handleLogin : handleSignup}
          validationSchema={
            authType === "login"
              ? validationSchemaLogin
              : validationSchemaSignup
          }
        >
          {({ isSubmitting }) => (
            <Form className="form">
              <Typography variant="h5" color="secondary" className="formTitle">
                {authType === "login" ? "Login" : "Sign Up"}
              </Typography>
              <div className="input">
                <PersonIcon className="inputIcon" color="primary" />
                <TextInput
                  name="username"
                  type="text"
                  placeholder="Enter username"
                  label="Username"
                  required
                  fullWidth
                  className="black-text"
                />
              </div>
              {authType === "signup" && (
                <div className="checkboxLabel">
                  <Typography variant="body1">
                    Would you like to be a Mentor?
                  </Typography>
                  <Button
                    variant="contained"
                    color={isMentor ? "default" : "default"}
                    onClick={() => setIsMentor(!isMentor)}
                    className="black-text"
                  >
                    {isMentor ? "No" : "Yes"}
                  </Button>
                  {isMentor && (
                    <div>
                      <div className="disciplinesList">
                        {["AI", "Angel Investor", "Systems Architect", "Auditing", "Blue Team", "Cloud", "Databases", "Data Science", "Entrepreneurship", "Forensics", "Internet of Things", "Linux", "Machine Learning", "Management", "Networking", "Physical Security", "Programming", "Red Team", "Reverse Engineering", "Strategy", "Systems Administration", "Virtualization", "Windows"].map((discipline) => (
                          <FormControlLabel
                            key={discipline}
                            control={
                              <Checkbox
                                checked={selectedDisciplines.includes(discipline)}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  if (checked) {
                                    setSelectedDisciplines([...selectedDisciplines, discipline]);
                                  } else {
                                    setSelectedDisciplines(selectedDisciplines.filter((d) => d !== discipline));
                                  }
                                }}
                                name={discipline}
                              />
                            }
                            label={discipline}
                          />
                        ))}
                      </div>
                      <div className="references">
                        <Typography variant="body1">References:</Typography>
                        <div className="input">
                          <LinkedInIcon className="inputIcon" color="black" />
                          <TextInput name="reference1" type="text" placeholder="One Reference is Required" required fullWidth />
                        </div>
                        <div className="input">
                          <LinkedInIcon className="inputIcon" color="black" />
                          <TextInput name="reference2" type="text" placeholder="LinkedIn/Etc" fullWidth />
                        </div>
                        <div className="input">
                          <LinkedInIcon className="inputIcon" color="black" />
                          <TextInput name="reference3" type="text" color="black" placeholder="Github/HackThebox/Etc" fullWidth />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {authType === "signup" && ( // Conditionally render email field for sign-up
                <div className="input">
                  <EmailIcon className="inputIcon" color="primary" />
                  <TextInput
                    name="email"
                    type="text"
                    placeholder="Enter email"
                    label="Email"
                    required
                    fullWidth
                    className="black-text"
                  />
                </div>
              )}
              <div className="input">
                <LockIcon className="inputIcon" color="primary" />
                <TextInput
                  name="password"
                  type={showPass ? "text" : "password"}
                  placeholder="Enter password"
                  label="Password"
                  required
                  className="black-text"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPass((prevState) => !prevState)}
                        >
                          {showPass ? (
                            <VisibilityOffIcon color="primary" />
                          ) : (
                            <VisibilityIcon color="primary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                size="large"
                startIcon={
                  authType === "login" ? <ExitToAppIcon /> : <PersonAddIcon />
                }
                className="submitButton"
                disabled={isSubmitting}
              >
                {authType === "login"
                  ? isSubmitting
                    ? "Logging In"
                    : "Login"
                  : isSubmitting
                  ? "Signing Up"
                  : "Sign Up"}
              </Button>
              <div className={`policyMessage ${classes.policyMessageMobile}`}>
  By {authType === "login" ? "logging in" : "signing up"}, you
  agree to the{" "}
  <a href="/guidelines" target="_blank" rel="noopener">
    User Guidelines{' '}
  </a>
  and{' '}
  <a href="/privacy" target="_blank" rel="noopener">
    Privacy Policy
  </a>
</div>
              <Button
                onClick={() =>
                  authType === "login"
                    ? setAuthType("signup")
                    : setAuthType("login")
                }
                fullWidth
                size="large"
                color="primary"
                variant="outlined"
                startIcon={
                  authType === "login" ? <PersonAddIcon /> : <ExitToAppIcon />
                }
                disabled={isSubmitting}
                className="toggleButton"
              >
                {authType === "login" ? "Sign Up" : "Login"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div>
        <AlertMessage
          error={error}
          severity="error"
          clearError={() => setError(null)}
        />
      </div>
    </div>
  );
};

export default AuthTwo;