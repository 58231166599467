import React from 'react';
import './yeah.css';

const programmingLanguages = [
    {
      name: 'JavaScript',
      description: 'JavaScript is a versatile scripting language used for web development.',
      link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
    },
    {
      name: 'Python',
      description: 'Python is a high-level programming language known for its readability and versatility.',
      link: 'https://www.python.org/',
    },
    {
      name: 'Java',
      description: 'Java is an object-oriented, class-based programming language used for a wide range of applications.',
      link: 'https://www.oracle.com/java/',
    },
    {
      name: 'C++',
      description: 'C++ is a general-purpose programming language with a focus on performance and system programming.',
      link: 'https://isocpp.org/',
    },
    {
      name: 'C#',
      description: 'C# is a modern, object-oriented language developed by Microsoft for Windows applications.',
      link: 'https://docs.microsoft.com/en-us/dotnet/csharp/',
    },
    {
      name: 'PHP',
      description: 'PHP is a server-side scripting language used for web development and dynamic web pages.',
      link: 'https://www.php.net/',
    },
    {
      name: 'Ruby',
      description: 'Ruby is a dynamic, object-oriented language known for its simplicity and productivity.',
      link: 'https://www.ruby-lang.org/',
    },
    {
      name: 'Swift',
      description: 'Swift is a powerful and intuitive language developed by Apple for iOS, macOS, watchOS, and tvOS app development.',
      link: 'https://developer.apple.com/swift/',
    },
    {
      name: 'Go (Golang)',
      description: 'Go, often referred to as Golang, is a statically typed language known for its efficiency and simplicity.',
      link: 'https://golang.org/',
    },
    {
      name: 'Rust',
      description: 'Rust is a systems programming language that aims to provide memory safety and performance.',
      link: 'https://www.rust-lang.org/',
    },
    {
      name: 'Kotlin',
      description: 'Kotlin is a statically typed language that runs on the Java Virtual Machine (JVM) and is officially supported for Android app development.',
      link: 'https://kotlinlang.org/',
    },
    {
      name: 'TypeScript',
      description: 'TypeScript is a statically typed superset of JavaScript that adds optional type annotations and other features.',
      link: 'https://www.typescriptlang.org/',
    },
    {
      name: 'Scala',
      description: 'Scala is a statically typed language that combines object-oriented and functional programming features.',
      link: 'https://www.scala-lang.org/',
    },
    {
      name: 'Perl',
      description: 'Perl is a versatile scripting language known for its text processing capabilities.',
      link: 'https://www.perl.org/',
    },
    {
      name: 'Haskell',
      description: 'Haskell is a functional programming language known for its strong type system and mathematical elegance.',
      link: 'https://www.haskell.org/',
    },
    {
      name: 'Lua',
      description: 'Lua is a lightweight, embeddable scripting language often used in game development and scripting.',
      link: 'https://www.lua.org/',
    },
    {
      name: 'Dart',
      description: 'Dart is a language developed by Google for building web, mobile, and desktop applications.',
      link: 'https://dart.dev/',
    },
    {
      name: 'R',
      description: 'R is a language and environment for statistical computing and graphics, commonly used in data analysis and visualization.',
      link: 'https://www.r-project.org/',
    },
    // Add more programming languages as needed
  ];

  const programmingTools = [
    {
      name: 'Visual Studio',
      description: 'Visual Studio is a popular integrated development environment (IDE) for Windows-based development, including C# and .NET.',
      link: 'https://visualstudio.microsoft.com/',
    },
    {
      name: 'IntelliJ IDEA',
      description: 'IntelliJ IDEA is a widely used IDE for Java development, known for its productivity features.',
      link: 'https://www.jetbrains.com/idea/',
    },
    {
      name: 'PyCharm',
      description: 'PyCharm is a powerful IDE for Python development with features like code analysis and debugging.',
      link: 'https://www.jetbrains.com/pycharm/',
    },
    {
      name: 'Xcode',
      description: 'Xcode is the official IDE for macOS and iOS app development, equipped with a graphical interface builder.',
      link: 'https://developer.apple.com/xcode/',
    },
    {
      name: 'Android Studio',
      description: 'Android Studio is the official IDE for Android app development, offering tools for designing, coding, and testing.',
      link: 'https://developer.android.com/studio',
    },
    {
      name: 'NetBeans',
      description: 'NetBeans is a free, open-source IDE for Java, PHP, and other languages with a modular architecture.',
      link: 'https://netbeans.apache.org/',
    },
    {
      name: 'Atom',
      description: 'Atom is a hackable text editor developed by GitHub, known for its extensive customization options and packages.',
      link: 'https://atom.io/',
    },
    {
      name: 'Vim',
      description: 'Vim is a highly configurable, text-based code editor favored by many developers for its efficiency.',
      link: 'https://www.vim.org/',
    },
    {
      name: 'Emacs',
      description: 'Emacs is a highly extensible text editor with its own ecosystem of extensions and modes.',
      link: 'https://www.gnu.org/software/emacs/',
    },
    {
      name: 'Eclipse',
      description: 'Eclipse is an integrated development environment (IDE) commonly used for Java development.',
      link: 'https://www.eclipse.org/',
    },
    {
      name: 'Sublime Text',
      description: 'Sublime Text is a lightweight and customizable text editor for code and markup.',
      link: 'https://www.sublimetext.com/',
    },
    {
      name: 'Visual Studio Code',
      description: 'Visual Studio Code (VSCode) is a popular, free, and open-source code editor developed by Microsoft.',
      link: 'https://code.visualstudio.com/',
    },
    {
      name: 'Notepad++',
      description: 'Notepad++ is a free source code editor and Notepad replacement that supports several languages.',
      link: 'https://notepad-plus-plus.org/',
    },
    {
      name: 'Jupyter Notebook',
      description: 'Jupyter Notebook is an open-source web application that allows interactive coding and data visualization.',
      link: 'https://jupyter.org/',
    },
    {
      name: 'Git',
      description: 'Git is a distributed version control system used for tracking changes in code.',
      link: 'https://git-scm.com/',
    },
    {
      name: 'GitHub',
      description: 'GitHub is a web-based platform for version control, code hosting, and collaboration on software projects.',
      link: 'https://github.com/',
    },
    {
      name: 'Docker',
      description: 'Docker is a platform for developing, shipping, and running applications in containers.',
      link: 'https://www.docker.com/',
    },
    {
      name: 'Postman',
      description: 'Postman is a popular API development and testing tool that simplifies API workflows.',
      link: 'https://www.postman.com/',
    },
    {
      name: 'npm',
      description: 'npm is the package manager for JavaScript and the world’s largest software registry.',
      link: 'https://www.npmjs.com/',
    },
    // Add more programming tools as needed
  ];



  function ProgrammingLanguagesAndTools() {
    return (
      <div className="programming-languages-and-tools">
        <h1>Programming Languages</h1>
        <ul>
          {programmingLanguages.map((language, index) => (
            <li key={index}>
              <strong>{language.name}</strong>: {language.description} (<a href={language.link} target="_blank" rel="noopener noreferrer">Learn More</a>)
            </li>
          ))}
        </ul>
  
        <h1>Programming Tools</h1>
        <ul>
          {programmingTools.map((tool, index) => (
            <li key={index}>
              <strong>{tool.name}</strong>: {tool.description} (<a href={tool.link} target="_blank" rel="noopener noreferrer">Learn More</a>)
            </li>
          ))}
        </ul>
      </div>
    );
  }

  export default ProgrammingLanguagesAndTools;