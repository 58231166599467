import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const ISC2CommunityPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the ISC2 Community link in a new tab/window
    window.open('https://www.isc2.org/Community', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Join the ISC2 Community!</h2>
          <p>Connect with certified professionals, share knowledge, and collaborate on security topics in the ISC2 Community.</p>
          <button onClick={handleButtonClick}>Join Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose the ISC2 Community?</h3>
        <ul>
          <li>Engage in discussions with ISC2-certified professionals worldwide.</li>
          <li>Access webinars, resources, and events to enhance your knowledge.</li>
          <li>Collaborate on security best practices and industry insights.</li>
          <li>Be part of a community dedicated to advancing cybersecurity.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Community Members Say</h3>
        <div className="testimonial">
          <p>"The ISC2 Community has been a valuable resource for staying updated on the latest security trends. I highly recommend joining!"</p>
          <p>- John Smith, CISSP Certified Professional</p>
        </div>
        <div className="testimonial">
          <p>"I've learned so much from connecting with fellow ISC2-certified professionals. The community support is exceptional."</p>
          <p>- Jane Doe, Security Practitioner</p>
        </div>
      </div>
    </div>
  );
};

export default ISC2CommunityPromoBanner;