import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const AzureSentinelPromoBanner = () => {
  const handleButtonClick = () => {
    // Open Azure Sentinel website link in a new tab/window
    window.open('https://azure.microsoft.com/en-us/services/azure-sentinel/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Secure with Microsoft Azure Sentinel!</h2>
          <p>Empower your security operations with cloud-native SIEM and SOAR capabilities using Microsoft Azure Sentinel.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Azure Sentinel?</h3>
        <ul>
          <li>Collect, analyze, and act on security data across your enterprise.</li>
          <li>Leverage AI and automation for threat detection and response.</li>
          <li>Integrate seamlessly with Microsoft and third-party solutions.</li>
          <li>Enhance your organization's security posture.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Security Professionals Say</h3>
        <div className="testimonial">
          <p>"Azure Sentinel has revolutionized our security operations. It provides a powerful platform for detecting and responding to threats."</p>
          <p>- John Smith, Security Analyst</p>
        </div>
        <div className="testimonial">
          <p>"The integration capabilities and automation features of Azure Sentinel make it a must-have for modern security teams."</p>
          <p>- Jane Doe, Cybersecurity Manager</p>
        </div>
      </div>
    </div>
  );
};

export default AzureSentinelPromoBanner;