import React from 'react';

function StackOverflowPage() {
  // List of programming-related Stack Overflow tags
  const programmingTags = [
    {
      name: 'javascript',
      description: 'JavaScript programming questions and answers.',
    },
    {
      name: 'python',
      description: 'Python programming discussions and solutions.',
    },
    {
      name: 'java',
      description: 'Java programming tips, tricks, and solutions.',
    },
    {
      name: 'reactjs',
      description: 'React.js development-related questions and guidance.',
    },
    {
      name: 'sql',
      description: 'SQL database queries and optimization discussions.',
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <h1>Stack Overflow: Your Programming Knowledge Hub</h1>
      <p>
        Stack Overflow is the go-to platform for programmers and developers to seek solutions, share knowledge, and collaborate with the global tech community.
        Explore programming-related topics and tags to find answers to your coding questions:
      </p>

      <ul>
        {programmingTags.map((tag, index) => (
          <li key={index}>
            <strong>{tag.name}</strong>: {tag.description}
          </li>
        ))}
      </ul>

      <p>
        Dive into these programming topics on Stack Overflow by clicking the links below:
      </p>

      <ul>
        {programmingTags.map((tag, index) => (
          <li key={index}>
            <a
              href={`https://stackoverflow.com/questions/tagged/${tag.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {tag.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default StackOverflowPage;