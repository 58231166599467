import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { UpvoteButton, DownvoteButton } from './VoteButtons';
import { notify } from '../reducers/notificationReducer';
import EditDeleteMenu from './EditDeleteMenu';
import getEditedThumbail from '../utils/cloudinaryTransform';
import { trimLink, prettifyLink, fixUrl } from '../utils/formatUrl';
import TimeAgo from 'timeago-react';
import getErrorMsg from '../utils/getErrorMsg';
import LinkIcon from '@material-ui/icons/Link';
import ShareIcon from '@material-ui/icons/Share';
import Snackbar from '@material-ui/core/Snackbar';
import { getCircularAvatar } from '../utils/cloudinaryTransform';
import {
  Paper,
  Avatar,
  Typography,
  useMediaQuery,
  CardMedia,
  Link,
  Button,
  makeStyles,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles((theme) => ({
  postCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: '1rem',
    marginBottom: '1rem',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  votesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '1rem',
    minWidth: '3rem',
  },
  animatedBorder: {
    animation: '$borderAnimation 4s linear infinite',
  },
  '@keyframes borderAnimation': {
    '0%': {
      boxShadow: `0 0 7px 0px ${theme.palette.error.main}`,
    },
    '33%': {
      boxShadow: `0 0 7px 5px ${theme.palette.primary.main}`,
    },
    '66%': {
      boxShadow: `0 0 7px 5px ${theme.palette.info.main}`,
    },
    '100%': {
      boxShadow: `0 0 7px 0px ${theme.palette.error.main}`,
    },
  },
  pointsCount: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  thumbnailWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    marginLeft: '0vw',
    marginBottom: '1vw',
    width: '100%',
    height: '333px',
    overflow: 'hidden',
    borderRadius: '4px',
    position: 'center', // Changed from 'center' to 'relative'
    '&:hover': {
      '& $thumbnailOverlay': {
        opacity: 1,
      },
    },
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'center',
  },
  thumbnailOverlay: {
    position: 'center', // Changed from 'center' to 'absolute'
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
  },
  thumbnailIcon: {
    width: '100%',
    height: '100%',
    color: theme.palette.text.secondary,
  },
  postInfoWrapper: {
    flexGrow: 1,
    marginLeft: '0vw', // Added marginLeft
  },
  title: {
    marginBottom: '0.5rem',
    fontWeight: 600,
    position: 'center',
    fontSize: '1.1rem',
    lineHeight: '1.2',
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  userAndDate: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginTop: '0.5rem', // Adjusted marginTop
  },
  commentsBtn: {
    marginRight: '0.5rem',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  submission: {
    marginTop: '0.5rem',
    fontSize: '0.9rem',
    lineHeight: '1.3',
  },
}));

const PostCard = ({ post, toggleUpvote, toggleDownvote, className }) => {
  const [authorInfo, setAuthorInfo] = useState(null);
  const {
    id,
    title,
    postType,
    textSubmission,
    linkSubmission,
    imageSubmission,
    imageTextSubmission,
    subreddit,
    author,
    upvotedBy,
    downvotedBy,
    pointsCount,
    commentCount,
    createdAt,
    updatedAt,
  } = post;

  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const { user, darkMode } = useSelector((state) => state);

  const isUpvoted = user && upvotedBy.includes(user.id);
  const isDownvoted = user && downvotedBy.includes(user.id);

  useEffect(() => {
    const fetchAuthorAvatar = async () => {
      try {
        const response = await fetch(`/api/authors/avatars/${author.username}`);
        if (response.ok) {
          const avatarData = await response.json();
          if (avatarData.avatar) {
            // If "avatar" property exists, set the avatar from the response
            setAuthorInfo({ ...authorInfo, avatar: avatarData.avatar });
          } else {
            // If there's no "avatar" property, set a default avatar here
            setAuthorInfo({ ...authorInfo, avatar: null }); // You can replace null with a default avatar URL
          }
        } else {
          // Handle the case where the response is not OK, e.g., show an error message
          console.error('Error fetching author avatar:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching author avatar:', error);
      }
    };
  
    fetchAuthorAvatar();
  }, [author.username]);

  console.log("authorInfo:", authorInfo);


  const handleUpvoteToggle = async () => {
    try {
      if (isUpvoted) {
        const updatedUpvotedBy = upvotedBy.filter((u) => u !== user.id);
        dispatch(toggleUpvote(id, updatedUpvotedBy, downvotedBy));
      } else {
        const updatedUpvotedBy = [...upvotedBy, user.id];
        const updatedDownvotedBy = downvotedBy.filter((d) => d !== user.id);
        dispatch(toggleUpvote(id, updatedUpvotedBy, updatedDownvotedBy));
      }
    } catch (err) {
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  const handleDownvoteToggle = async () => {
    try {
      if (isDownvoted) {
        const updatedDownvotedBy = downvotedBy.filter((d) => d !== user.id);
        dispatch(toggleDownvote(id, updatedDownvotedBy, upvotedBy));
      } else {
        const updatedDownvotedBy = [...downvotedBy, user.id];
        const updatedUpvotedBy = upvotedBy.filter((u) => u !== user.id);
        dispatch(toggleDownvote(id, updatedDownvotedBy, updatedUpvotedBy));
      }
    } catch (err) {
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  const linkToShow =
    postType === 'Link'
      ? linkSubmission
      : postType === 'Image'
      ? 'Text'
      : '';

  const formattedLink = trimLink(prettifyLink(linkToShow), 30);

  const getFirstThreeSentences = (text) => {
    if (!text) return '';

    const words = text.trim().split(/\s+/);
    if (words.length <= 25) {
      return text;
    } else {
      return words.slice(0, 17).join(' ') + '...';
    }
  };
  const getFirstFiveSentences = (text) => {
    if (!text) return '';

    const words = text.trim().split(/\s+/);
    if (words.length <= 25) {
      return text;
    } else {
      return words.slice(0, 100).join(' ') + '...';
    }
  };
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
};

    const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
};
  const handleShareButtonClick = async () => {
    
    const postLink = `${window.location.origin}/comments/${id}`; // Construct the post link
    try {
      // Attempt to copy the post link to the clipboard
      await navigator.clipboard.writeText(postLink);
  
      // Provide user feedback (e.g., show a success message)
      showSnackbar('Link copied to clipboard successfully!');
    } catch (error) {
      // Handle any errors (e.g., show an error message)
      console.error('Error copying link to clipboard:', error);
      showSnackbar('Error copying link to clipboard');
    }
  };

  const realavatar = author;
  const usertest = user;
console.log("username?:", usertest); // Log realavatar to check its structure and data
console.log("Realavatar:", realavatar); // Log realavatar to check its structure and data

  return (
    <Paper className={`${classes.postCard} ${classes.animatedBorder} ${className}`} variant="outlined">
      <div className={classes.postInfoWrapper}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
      {authorInfo && authorInfo.avatar ? (
  <Avatar
    alt={author.username}
    src={authorInfo.avatar ? getCircularAvatar(authorInfo.avatar) : ''}
    style={{ width: 42, height: 42, marginRight: '5px' }}
  />
) : (
  <Avatar style={{ backgroundColor: '#941a1c', width: 33, height: 33, marginRight: '5px' }}>
    <h1>{author.username}</h1>
  </Avatar>
)}
          <Typography variant="h6" className={postType === 'Text' ? classes.title : classes.title}>
            <Link component={RouterLink} to={`/comments/${id}`}>
              {title}
            </Link>
          </Typography>
        </div>
        <Typography variant="caption" className={classes.userAndDate}>
          <Link component={RouterLink} to={`/i/${subreddit.subredditName}`}>
            {subreddit.subredditName}{' '}
          </Link>
          Posted by{' '}
          <Link component={RouterLink} to={`/u/${author.username}`}>
            {author.username}
          </Link>{' '}
          • <TimeAgo datetime={new Date(createdAt)} />
          {createdAt !== updatedAt && '*'}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'left' }}>
          <Button
            startIcon={<CommentIcon />}
            className={classes.commentsBtn}
            component={RouterLink}
            to={`/comments/${id}`}
            size={isMobile ? 'small' : 'medium'}
          >
            {commentCount} comments
          </Button>
          <Button
        startIcon={<ShareIcon />}
        className={classes.shareBtn}
        onClick={handleShareButtonClick}
      >
        Share
      </Button>
          {user && user.id === author.id && (
            <EditDeleteMenu
              id={id}
              isMobile={isMobile}
              title={title}
              postType={postType}
              subreddit={subreddit}
              textSubmission={textSubmission}
              linkSubmission={linkSubmission}
            />
          )}
        </div>
        {/* Conditionally render the imageSubmission container */}
        {postType === 'Image' && (
          <div className={classes.thumbnailWrapper}>
            <Paper elevation={0} square className={classes.thumbnail}>
              <CardMedia
                className={classes.thumbnail}
                image={imageSubmission.imageLink}
                title={title}
                component={RouterLink}
                to={`/comments/${id}`}
              />
            </Paper>
          </div>
        )}
      </div>
      {postType === 'Text' && (
        <Typography variant="body2" className={classes.textSubmission}>
          {getFirstFiveSentences(textSubmission)}
        </Typography>
      )}
      {postType === 'Image' && (
        <Typography variant="body2" className={classes.textSubmission}>
          {getFirstThreeSentences(imageTextSubmission)}
        </Typography>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Paper>
  );
};

export default PostCard;
