import React, { useState } from 'react';
import './search.css';

function HybridSearch() {
  const [formData, setFormData] = useState({
    filename: '',
    filetype: '',
    filetype_desc: '',
    env_id: '',
    country: '',
    verdict: '',
    av_detect: '',
    vx_family: '',
    tag: '',
    date_from: '',
    date_to: '',
    port: '',
    host: '',
    domain: '',
    url: '',
    similar_to: '',
    context: '',
    imp_hash: '',
    ssdeep: '',
    authentihash: '',
    uses_tactic: '',
    uses_technique: '',
  });
  const [results, setResults] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const queryParams = new URLSearchParams();
    
    for (const key in formData) {
      if (formData[key]) {
        queryParams.append(key, formData[key]);
      }
    }
  
    try {
      const response = await fetch(`/api/search/terms?${queryParams.toString()}`);
      if (response.ok) {
        const data = await response.json();
        setResults(data);
      } else {
        console.error("Error from server:", response.statusText);
        setResults("Failed to fetch data from the server");
      }
    } catch (error) {
      console.error("Error:", error);
      setResults("An error occurred");
    }
  };

  return (
    <div>
      <h1>Search Hybrid Analysis</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="filename">Filename:</label>
        <input
          type="text"
          id="filename"
          name="filename"
          value={formData.filename}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="filetype">Filetype:</label>
        <select
          id="filetype"
          name="filetype"
          value={formData.filetype}
          onChange={handleInputChange}
        >
          <option value="64bits">64bits</option>
          <option value="android">android</option>
          {/* Add other options from the documentation here */}
        </select><br />

        <label htmlFor="filetype_desc">Filetype Description:</label>
        <input
          type="text"
          id="filetype_desc"
          name="filetype_desc"
          value={formData.filetype_desc}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="env_id">Environment Id:</label>
        <input
          type="text"
          id="env_id"
          name="env_id"
          value={formData.env_id}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="country">Country (3 digit ISO):</label>
        <input
          type="text"
          id="country"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="verdict">Verdict:</label>
        <input
          type="text"
          id="verdict"
          name="verdict"
          value={formData.verdict}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="av_detect">AV Multiscan range:</label>
        <input
          type="text"
          id="av_detect"
          name="av_detect"
          value={formData.av_detect}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="vx_family">AV Family Substring:</label>
        <input
          type="text"
          id="vx_family"
          name="vx_family"
          value={formData.vx_family}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="tag">Hashtag:</label>
        <input
          type="text"
          id="tag"
          name="tag"
          value={formData.tag}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="date_from">Date from (Y-m-d H:i):</label>
        <input
          type="text"
          id="date_from"
          name="date_from"
          value={formData.date_from}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="date_to">Date to (Y-m-d H:i):</label>
        <input
          type="text"
          id="date_to"
          name="date_to"
          value={formData.date_to}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="port">Port:</label>
        <input
          type="text"
          id="port"
          name="port"
          value={formData.port}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="host">Host:</label>
        <input
          type="text"
          id="host"
          name="host"
          value={formData.host}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="domain">Domain:</label>
        <input
          type="text"
          id="domain"
          name="domain"
          value={formData.domain}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="url">HTTP Request Substring:</label>
        <input
          type="text"
          id="url"
          name="url"
          value={formData.url}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="similar_to">Similar Samples:</label>
        <input
          type="text"
          id="similar_to"
          name="similar_to"
          value={formData.similar_to}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="context">Sample Context:</label>
        <input
          type="text"
          id="context"
          name="context"
          value={formData.context}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="imp_hash">imp_hash:</label>
        <input
          type="text"
          id="imp_hash"
          name="imp_hash"
          value={formData.imp_hash}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="ssdeep">ssdeep:</label>
        <input
          type="text"
          id="ssdeep"
          name="ssdeep"
          value={formData.ssdeep}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="authentihash">authentihash:</label>
        <input
          type="text"
          id="authentihash"
          name="authentihash"
          value={formData.authentihash}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="uses_tactic">Uses MITRE ATT&CK® Tactic:</label>
        <input
          type="text"
          id="uses_tactic"
          name="uses_tactic"
          value={formData.uses_tactic}
          onChange={handleInputChange}
        /><br />

        <label htmlFor="uses_technique">Uses MITRE ATT&CK® Technique:</label>
        <input
          type="text"
          id="uses_technique"
          name="uses_technique"
          value={formData.uses_technique}
          onChange={handleInputChange}
        /><br />

        <button type="submit">Search</button>
      </form>

      <div id="results" className="results-container">
  {results && results.result.length > 0 ? (
    <div>
      <h2 className="results-heading">Results:</h2>
      {results.result.map((item, index) => (
        <div key={index} className="result-item">
          <h3 className="result-title">Result {index + 1}</h3>
          <div className="result-details">
            <p><strong>Verdict:</strong> {item.verdict}</p>
            <p><strong>AV Detect:</strong> {item.av_detect}</p>
            <p><strong>Threat Score:</strong> {item.threat_score}</p>
            <p><strong>VX Family:</strong> {item.vx_family || "N/A"}</p>
            <p><strong>Job ID:</strong> {item.job_id}</p>
            <p><strong>SHA256:</strong> {item.sha256}</p>
            <p><strong>Environment ID:</strong> {item.environment_id}</p>
            <p><strong>Analysis Start Time:</strong> {item.analysis_start_time}</p>
            <p><strong>Submit Name:</strong> {item.submit_name}</p>
            <p><strong>Environment Description:</strong> {item.environment_description}</p>
            <p><strong>Size:</strong> {item.size}</p>
            <p><strong>Type:</strong> {item.type || "N/A"}</p>
            <p><strong>Type Short:</strong> {item.type_short || "N/A"}</p>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <p>No results yet.</p>
  )}
</div>
    </div>
  );
}

export default HybridSearch;