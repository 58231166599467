import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const HackerNewsPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://thehackernews.com/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Stay Informed with The Hacker News!</h2>
          <p>Explore the latest cybersecurity news, vulnerabilities, and research on TheHackerNews.com.</p>
          <button onClick={handleButtonClick}>Read Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose The Hacker News?</h3>
        <ul>
          <li>Comprehensive coverage of cybersecurity events and trends.</li>
          <li>In-depth analysis of hacking techniques and vulnerabilities.</li>
          <li>Insightful reports on security research and threat intelligence.</li>
          <li>Stay updated with the latest cyber threats and attacks.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Contributors and Experts</h3>
        <p>The Hacker News collaborates with cybersecurity experts, researchers, and organizations to deliver timely and accurate information. Our contributors include:</p>
        <ul>
          <li>Cybersecurity researchers and analysts.</li>
          <li>White hat hackers and penetration testers.</li>
          <li>Industry professionals and thought leaders.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Readers Say</h3>
        <div className="testimonial">
          <p>"The Hacker News is an invaluable resource for cybersecurity professionals. The articles are well-researched and insightful."</p>
          <p>- John Smith, Security Analyst</p>
        </div>
        <div className="testimonial">
          <p>"I rely on The Hacker News to keep me updated on the latest vulnerabilities and attacks. It's a must-read for anyone in security."</p>
          <p>- Jane Doe, Cybersecurity Enthusiast</p>
        </div>
      </div>
    </div>
  );
};

export default HackerNewsPromoBanner;