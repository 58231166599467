import React, { useState } from 'react';
import styles from './yeah.module.css';

const certificationsData = [
    {
      name: 'Certified Information Systems Security Professional (CISSP)',
      description: 'CISSP is an advanced cybersecurity certification that demonstrates expertise in information security and risk management. It covers various domains, including security and risk management, asset security, security architecture and engineering, communication and network security, identity and access management, security assessment and testing, security operations, and software development security.',
      difficulty: 'Difficult',
      resourceLink: 'https://www.isc2.org/Certifications/CISSP',
    },
    {
      name: 'Certified Information Security Manager (CISM)',
      description: 'CISM is a certification for information security management. It focuses on managing and governing an organization\'s information security program. CISM covers four domains: information security governance, risk management, information security program development and management, and information security incident management.',
      difficulty: 'Moderate',
      resourceLink: 'https://www.isaca.org/certifications/cism',
    },
    {
      name: 'Certified Information Systems Auditor (CISA)',
      description: 'CISA is a certification for auditing, control, and assurance of information systems. It emphasizes auditing, control, and assurance skills in the context of information systems. The CISA exam covers domains like information system auditing, governance and management of IT, information systems acquisition, development, and implementation, and protection of information assets.',
      difficulty: 'Moderate',
      resourceLink: 'https://www.isaca.org/certifications/cisa',
    },
    {
      name: 'Project Management Professional (PMP)',
      description: 'PMP is a certification for project managers. It validates the ability to manage projects effectively, from initiation to closure. PMP certification covers areas such as project integration, scope, schedule, cost, quality, resource, communication, risk, procurement, and stakeholder management.',
      difficulty: 'Moderate',
      resourceLink: 'https://www.pmi.org/certifications/project-management-pmp',
    },
    {
      name: 'Certified ScrumMaster (CSM)',
      description: 'CSM is a certification for Scrum Masters and Agile practitioners. It focuses on Agile principles and Scrum practices. CSM covers topics such as Scrum roles, events, artifacts, and values, as well as servant leadership and facilitation techniques.',
      difficulty: 'Easy',
      resourceLink: 'https://www.scrumalliance.org/get-certified/certified-scrummaster',
    },
    {
      name: 'Certified Information Security Management (CISM)',
      description: 'CISM is a certification for individuals who manage, design, oversee, and assess an enterprise\'s information security. It emphasizes information risk management and governance. CISM covers domains like information security governance, risk management, information security program development, and incident management.',
      difficulty: 'Moderate',
      resourceLink: 'https://www.isaca.org/certifications/cism',
    },
    {
      name: 'Certified Information Systems Auditor (CISA)',
      description: 'CISA is a certification for individuals who audit, control, monitor, and assess an organization\'s information technology and business systems. It focuses on information system control and assurance. CISA covers areas such as auditing, governance, acquisition, development, and implementation.',
      difficulty: 'Moderate',
      resourceLink: 'https://www.isaca.org/certifications/cisa',
    },
    {
      name: 'Certified Ethical Hacker (CEH)',
      description: 'CEH is a certification for ethical hackers and cybersecurity professionals. It validates skills in identifying and addressing cybersecurity vulnerabilities. CEH covers topics like ethical hacking, penetration testing, and vulnerability assessment.',
      difficulty: 'Moderate',
      resourceLink: 'https://www.eccouncil.org/programs/certified-ethical-hacker-ceh/',
    },
    {
      name: 'ITIL (Information Technology Infrastructure Library)',
      description: 'ITIL is a framework for IT service management. It focuses on aligning IT services with the needs of business. ITIL certification covers IT service lifecycle stages, processes, and best practices.',
      difficulty: 'Moderate',
      resourceLink: 'https://www.axelos.com/certifications/itil-certifications',
    },
    {
      name: 'Certified Business Continuity Professional (CBCP)',
      description: 'CBCP is a certification for individuals who develop, maintain, and manage an organization\'s business continuity and disaster recovery program. It covers business continuity planning, risk assessment, recovery strategies, and crisis management.',
      difficulty: 'Moderate',
      resourceLink: 'https://www.drii.org/certification/',
    },
    {
      name: 'Certified Information Systems Manager (CISM)',
      description: 'CISM is a certification for individuals who manage and oversee an enterprise\'s information security program. It focuses on information risk management and governance. CISM covers domains like information security governance, risk management, information security program development, and incident management.',
      difficulty: 'Moderate',
      resourceLink: 'https://www.isaca.org/certifications/cism',
    },
    // Add more certifications here
  ];

  function CERTSMAN() {
    const [sortedCertifications, setSortedCertifications] = useState(certificationsData);
  
    // Function to sort certifications by difficulty (descending)
    const sortByDifficultyDescending = () => {
      const sorted = [...sortedCertifications].sort((a, b) => {
        const difficultyOrder = { 'Easy': 1, 'Moderate': 2, 'Difficult': 3 };
        return difficultyOrder[b.difficulty] - difficultyOrder[a.difficulty];
      });
      setSortedCertifications(sorted);
    };
  
    return (
        <div className="App1">
        <h1>IT Management Certifications</h1>
        <button onClick={sortByDifficultyDescending}>Sort by Difficulty (Descending)</button>
        <ul>
          {sortedCertifications.map((certification, index) => (
            <li key={index}>
              <h2>{certification.name}</h2>
              <p>{certification.description}</p>
              <p>Difficulty: {certification.difficulty}</p>
              <a href={certification.resourceLink} target="_blank" rel="noopener noreferrer">Go to Cert</a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  
  export default CERTSMAN;