import React from 'react';

const SOCFrame = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '100vw',
    margin: '0 auto',
    padding: '0px',
  };

  const iframeStyle = {
    width: '100vw',
    height: '90vh',
    border: 'none', // Optionally, remove the border
  };

  return (
    <div style={containerStyle}>
      <iframe
        src="https://95d4138985a84f4692024aac43773701.us-central1.gcp.cloud.es.io:9243/"
        title="SOC Frame"
        style={iframeStyle}
      ></iframe>
    </div>
  );
};

export default SOCFrame;