import React from 'react';
import './bluecheatsheet.css'; // Import your CSS file

const BlueTeamCheatSheets = () => {
    const cheatSheets = [
        { title: 'Incident Response Cheat Sheet', link: 'https://github.com/denizciftci-sec/Threat-Hunting/blob/main/cheatsheet.md' },
        { title: 'Incident Handling Process', link: 'https://github.com/stefanbulof/dfircheatsheets/blob/master/Forensics.png' },
        { title: 'Windows Security Hardening', link: 'https://www.stigviewer.com/stig/windows_10/' },
        { title: 'Windows Security Checklist', link: 'https://www.cyber.gov.au/acsc/view-all-content/cyber-security-hardening-windows-10-version-1909' },
        { title: 'Linux Security Checklist', link: 'https://linux-audit.com/linux-security-checklist/' },
        { title: 'Linux Hardening Checklist', link: 'https://github.com/trimstray/linux-hardening-checklist' },
        { title: 'Network Security Cheatsheet', link: 'https://github.com/coreb1t/awesome-pentest-cheat-sheets/blob/master/network/network-security.md' },
        { title: 'CIS Critical Security Controls', link: 'https://www.cisecurity.org/controls/' },
        { title: 'OWASP Cheat Sheet Series', link: 'https://github.com/OWASP/CheatSheetSeries' },
        { title: 'AWS Security Best Practices', link: 'https://d1.awsstatic.com/whitepapers/AWS_Security_Best_Practices.pdf' },
        { title: 'Azure Security Best Practices', link: 'https://learn.microsoft.com/en-us/azure/security/fundamentals/best-practices-and-patterns' },
        { title: 'Logging Cheat Sheet', link: 'https://github.com/OWASP/CheatSheetSeries/blob/master/cheatsheets/Logging_Cheat_Sheet.md' },
        { title: 'SIEM Best Practices', link: 'https://github.com/cyb3rxp/awesome-soc' },
        { title: 'SANS Internet Storm Center Diary', link: 'https://isc.sans.edu/' },
        { title: 'Great PDF', link: 'https://github.com/chrisjd20/Blue-Team-Cheat-Sheets/blob/master/BTCSwGSEnotes.pdf' },
      ];

      return (
        <div className="bluecheat-container">
          <h1>Blue Team Cheat Sheets</h1>
          {cheatSheets.map((cheatSheet, index) => (
            <div key={index} className="cheat-sheet">
              <h2>{cheatSheet.title}</h2>
              <a href={cheatSheet.link} target="_blank" rel="noopener noreferrer" className="cheat-sheet-button">
                View Cheat Sheet
              </a>
              <hr />
            </div>
          ))}
        </div>
      );
    };

export default BlueTeamCheatSheets;