import React, { useState } from 'react';
import axios from 'axios';
import '../../css/supportpage.css';
import discordpng from '../../assets/discord1.png'

const SupportPage = () => {
  const [username, setUsername] = useState('');
  const [subject, setSubject] = useState('');
  const [issue, setIssue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleIssueChange = (e) => {
    const { value } = e.target;
    if (value.length <= 300) {
      setIssue(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Validate input fields
    if (!username || !subject || !issue) {
      setErrorMessage('Please fill in all fields.');
      return;
    }
  
    if (issue.length > 300) {
      setErrorMessage('Issue should be a maximum of 300 characters.');
      return;
    }
  
    const data = {
      username,
      subject,
      issue,
    };
  
    try {
      const response = await axios.post('/api/submit-support', data);
  
      if (response.status === 200) {
        alert('Support request submitted successfully');
        // Clear form fields
        setUsername('');
        setSubject('');
        setIssue('');
        setErrorMessage('');
      } else {
        alert('Support request submitted successfully');
      }
    } catch (error) {
      setErrorMessage('Failed to submit support request. Please try again later.');
      console.error('Error submitting support request:', error);
    }
  };

  return (
    <div className="support-page">
      <div className="container left-container">
        <a className="discord-link" href="https://discord.gg/nPe7VspU5C">
          <img className="discord-icon" src={discordpng}/>
        </a>
      </div>
      <div className="container right-container">
        <h2>Support</h2>
        {errorMessage && <div className="error">{errorMessage}</div>}
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-field">
            <label htmlFor="username">Email:</label>
            <input
              className="input-field"
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-field">
            <label htmlFor="subject">Subject:</label>
            <input
              className="input-field"
              type="text"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="form-field">
            <label htmlFor="issue">Issue:</label>
            <textarea
              className="input-field"
              id="issue"
              value={issue}
              onChange={handleIssueChange}
            ></textarea>
          </div>
          <div className="form-field">
            <button className="submit-button" type="submit">Submit</button>
          </div>
        </form>
      </div>
      <amp-auto-ads type="adsense"
        data-ad-client="ca-pub-3504607292095587">
</amp-auto-ads>
    </div>
  );
};

export default SupportPage;