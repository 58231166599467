import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, loadUserPosts } from '../reducers/userPageReducer';
import { notify } from '../reducers/notificationReducer';
import { getCircularAvatar } from '../utils/cloudinaryTransform';
import UserPostCard from './UserPostCard';
import ErrorPage from './ErrorPage';
import LoadMoreButton from './LoadMoreButton';
import LoadingSpinner from './LoadingSpinner';
import getErrorMsg from '../utils/getErrorMsg';
import bronzeImg from '../assets/bronze.png';
import silverImg from '../assets/silver.png';
import goldImg from '../assets/gold.png';
import platinumImg from '../assets/platinum.png';
import axios from 'axios';
import gifUrl0 from '../assets/accgifs/0.gif';
import gifUrl10 from '../assets/accgifs/10.gif';
import gifUrl20 from '../assets/accgifs/20.gif';
import gifUrl30 from '../assets/accgifs/30.gif';
import gifUrl40 from '../assets/accgifs/40.gif';
import gifUrl50 from '../assets/accgifs/50.gif';
import gifUrl60 from '../assets/accgifs/60.gif';
import gifUrl70 from '../assets/accgifs/70.gif';
import gifUrl80 from '../assets/accgifs/80.gif';
import gifUrl90 from '../assets/accgifs/90.gif';
import LoveGIF from '../assets/love.jpg';
import user2Gif from '../assets/nukingdragons.gif';
import user3Gif from '../assets/RDE.gif';
import '../css/userpage.css';

import {
  Container,
  Paper,
  useMediaQuery,
  Typography,
  Avatar,
  Button
} from '@material-ui/core';
import { useUserPageStyles } from '../styles/muiStyles';
import { useTheme } from '@material-ui/core/styles';
import CakeIcon from '@material-ui/icons/Cake';
import PersonIcon from '@material-ui/icons/Person';
import FollowButton from './users/follow';
import UserEntriesSlider from './users/userentries';

function KarmaImage({ points }) {
  if (points < 100) {
    return <img src={bronzeImg} alt="Bronze Reward" />;
  } else if (points < 500) {
    return <img src={silverImg} alt="Silver Reward" />;
  } else if (points < 1000) {
    return <img src={goldImg} alt="Gold Reward" />;
  } else {
    return <img src={platinumImg} alt="Platinum Reward" />;
  }
}

const UserPage = () => {
  const classes = useUserPageStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { username } = useParams();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userPage);
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [precision, setPrecision] = useState(0);
  const [queryCount, setQueryCount] = useState(0);
  const [exploitCount, setExploitCount] = useState(0);
  const [iocCount, setIocCount] = useState(0);

  const [stacksCount, setStacksCount] = useState(0);
  const [cloudCount, setCloudCount] = useState(0);
  const [redtoolsCount, setRedtoolsCount] = useState(0);
  const [lessonsCount, setLessonsCount] = useState(0);
  const [datasciCount, setDatasciCount] = useState(0);
  const [bluetoolsCount, setBluetoolsCount] = useState(0);

  const [requestExists, setRequestExists] = useState(false); // Define the requestExists state

  useEffect(() => {
    // Function to check if a request exists
    const checkRequestExists = async () => {
      try {
        // Get the logged-in user's information from local storage
        const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
        if (!readifyUserKey) {
          // Handle the case where the user is not logged in or readifyUserKey is missing
          setRequestExists(false);
          return;
        }

        // Make an API call to check if a conversation request exists
        const response = await axios.get('/api/check-request-exists', {
          params: {
            requestor: readifyUserKey.username,
            receiver: username,
          },
        });

        setRequestExists(response.data.exists);
      } catch (error) {
        // Handle errors or show an error notification
        console.error('Error checking if request exists:', error);
        setRequestExists(false);
      }
    };

    // Execute the checkRequestExists function initially
    checkRequestExists();

    // Set up an interval to periodically check for requests (e.g., every 30 seconds)
    const intervalId = setInterval(checkRequestExists, 1000); // 30,000 milliseconds = 30 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [username]);


  const handleRequestConversation = async () => {
    try {
      // Get the logged-in user's information from local storage
      const readifyUserKey = JSON.parse(localStorage.getItem('readifyUserKey'));
      if (!readifyUserKey) {
        // Handle the case where the user is not logged in or readifyUserKey is missing
        dispatch(notify('Please log in to send a conversation request.', 'error'));
        return;
      }

      if (requestExists) {
        // If a request already exists, do nothing or show a message
        return;
      }

      // Create a conversation request object
      const conversationRequest = {
        requestor: readifyUserKey.username, // Requestor's username
        receiver: userName, // Receiver's username from the URL parameter
        // Add any other necessary information for your conversation request
      };

      // Send the conversation request to the server
      await axios.post('/api/create-request', conversationRequest);

      // Handle success or show a notification
      dispatch(notify('Conversation request sent successfully.', 'success'));
    } catch (error) {
      // Handle errors or show an error notification
      console.error('Error sending conversation request:', error);
      dispatch(notify('Error sending conversation request. Please try again later.', 'error'));
    }
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        await dispatch(fetchUser(username));
        setPageLoading(false);
      } catch (err) {
        setPageError(getErrorMsg(err), 'error');
      }
    };
    getUser();
  }, [username]);

  const userTitlesAndGifs = {
    'Love': {
      title: 'Architect',
      gif: LoveGIF,
    },
    'nukingdragons': {
      title: 'Lovely',
      gif: user2Gif,
    },
    'RDE': {
      title: 'Legend',
      gif: user3Gif,
    },
  };

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const queryResponse = await axios.get('/api/query-count', {
          params: { username: username }
        });
        
        const exploitResponse = await axios.get('/api/exploit-count', {
          params: { username: username }
        });
        
        const iocResponse = await axios.get('/api/ioc-count', {
          params: { username: username }
        });
        const stacksResponse = await axios.get('/api/stacks-count', {
          params: { username: username }
        });
        
        const cloudResponse = await axios.get('/api/cloud-count', {
          params: { username: username }
        });
        
        const redtoolsResponse = await axios.get('/api/redtools-count', {
          params: { username: username }
        });
        
        const lessonsResponse = await axios.get('/api/lessons-count', {
          params: { username: username }
        });
        
        const datasciResponse = await axios.get('/api/datasci-count', {
          params: { username: username }
        });
        
        const bluetoolsResponse = await axios.get('/api/bluetools-count', {
          params: { username: username }
        });
        setQueryCount(queryResponse.data.count);
        setExploitCount(exploitResponse.data.count);
        setIocCount(iocResponse.data.count);
        setStacksCount(stacksResponse.data.count);
setCloudCount(cloudResponse.data.count);
setRedtoolsCount(redtoolsResponse.data.count);
setLessonsCount(lessonsResponse.data.count);
setDatasciCount(datasciResponse.data.count);
setBluetoolsCount(bluetoolsResponse.data.count);
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };
  
    fetchCounts();
  }, [username]);

  const handleLoadPosts = async () => {
    try {
      setLoadingMore(true);
      await dispatch(loadUserPosts(username, page + 1));
      setPage(prevState => prevState + 1);
      setLoadingMore(false);
    } catch (err) {
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  if (pageError) {
    return (
      <Container disableGutters>
        <Paper className={classes.mainPaper}>
          <ErrorPage errorMsg={pageError} />
        </Paper>
      </Container>
    );
  }

  if (!userInfo || pageLoading) {
    return (
      <Container disableGutters>
        <Paper className={classes.mainPaper}>
          <LoadingSpinner text="Fetching user data..." />
        </Paper>
      </Container>
    );
  }

  const renderGif = () => {
    // Calculate the sum of counts
    const sumOfCounts = iocCount + exploitCount + queryCount + posts.length + totalComments;
  
    // Define the thresholds for each range
    const thresholds = [0, 50, 100, 200]; // Define your desired thresholds
  
    // Define the corresponding GIF URLs for each range
    const gifUrls = [
      gifUrl0,
      gifUrl10,
      gifUrl20,
      gifUrl30,
      gifUrl40,
      gifUrl50,
      gifUrl60,
      gifUrl70,
      gifUrl80,
      gifUrl90,
    ];
  
    // Find the appropriate range for the current sum of counts
    let rangeIndex = 0;
    while (rangeIndex < thresholds.length - 1) {
      if (sumOfCounts >= thresholds[rangeIndex] && sumOfCounts < thresholds[rangeIndex + 1]) {
        break;
      }
      rangeIndex++;
    }
  
    // Calculate precision (you can customize the logic here)
    const precision = sumOfCounts;
  
    // Define the precision color based on a threshold (e.g., 51)
    const precisionColor = precision < 11 ? 'rgb(255, 0, 0)' : 'rgb(0, 128, 0)';
  
    return (
      <div className="gif-container">
        <div style={{ display: 'flex-start', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <img src={gifUrls[rangeIndex]} alt="precision-gif" style={{ maxWidth: 200, maxHeight: 200 }} />
          <Typography variant="body1" color="secondary">
            {/* Add any additional text or elements here */}
          </Typography>
        </div>
      </div>
    );
  };

  const {
    avatar,
    username: userName,
    createdAt,
    posts,
    totalComments,
    karmaPoints,
  } = userInfo.userDetails;

  return (
   
      <Paper className="mainPaper1" style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}>
        <div className="content-container">
          <div className='user-info'>
  <div className="user-info1">
    {/* User Info Container */}
    <div className={classes.userInfoContainer}>
      <div className={classes.avatarWrapper}>
      {avatar && avatar.exists ? (
                  <Avatar
                    alt={userName}
                    src={getCircularAvatar(avatar.imageLink)}
                    className={classes.avatar}
                    style={{ width: 200, height: 200 }}
                  />
                ) : (
                  <Avatar style={{ backgroundColor: '#941a1c', width: 200, height: 200 }} className={classes.avatar}>
                    <h1>{userName[0]}</h1>
                  </Avatar>
                )}
      </div>
      <Typography variant="h6" color="secondary">
        {userName}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleRequestConversation}
        disabled={requestExists}
      >
        Request Conversation 
      </Button>
    </div>
  </div>

  {/* User Information Container */}
  <div className="user-info1">
    <div className={classes.userInformationContainer}>
      <div className={classes.twoItemsDiv}>
        <Typography variant="body1" color="secondary">
        </Typography>
      </div>
      <div className={classes.twoItemsDiv}>
        <Typography variant="body1" color="secondary">
          <strong>{lessonsCount}</strong> Lessons Learned
        </Typography>
        <Typography variant="body1" color="secondary">
          <strong>{bluetoolsCount}</strong> Blue Tools
        </Typography>
        <Typography variant="body1" color="secondary">
          <strong>{redtoolsCount}</strong> Red Tools
        </Typography>
        <Typography variant="body1" color="secondary">
          <strong>{cloudCount}</strong> Cloud
        </Typography>
        <Typography variant="body1" color="secondary">
          <strong>{datasciCount}</strong> Data Science
        </Typography>
        <Typography variant="body1" color="secondary">
          <strong>{stacksCount}</strong> Programming
        </Typography>
        <Typography variant="body1" color="secondary">
          <strong>{exploitCount}</strong> Exploits
        </Typography>
        <Typography variant="body1" color="secondary">
          <strong>{iocCount}</strong> IOCs
        </Typography>
        <Typography variant="body1" color="secondary">
          <strong>{queryCount}</strong> Queries
        </Typography>
        <Typography variant="body1" color="secondary">
          <strong>{posts.length}</strong> Posts
        </Typography>
        <Typography variant="body1" color="secondary">
          <strong>{totalComments}</strong> Comments
        </Typography>
      </div>
    </div>
  </div>

  {/* Merit and Karma */}
  <div className="user-info1">
    <div className={classes.twoItemsDiv}>
      <Typography variant="h6" color="secondary">
        <KarmaImage points={karmaPoints.commentkarma + karmaPoints.postkarma} />
      </Typography>
      <Typography variant="body1" color="secondary">
        Merit <strong>{karmaPoints.postkarma + karmaPoints.commentkarma}</strong>
      </Typography>
    </div>
  </div>

  {/* GIF Container */}
  <div className={classes.gifContainer}>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {renderGif()}
    </div>
  </div>
</div>
{isMobile && (
        <div className="mobile-entries-slider">
          <UserEntriesSlider />
        </div>
      )}
  
          {/* Posts Container */}
          <div className="posts1">
            <div className="posts1">
              {userInfo.posts.results.length !== 0 ? (
                userInfo.posts.results.map((p) => (
                  <div key={p.id}>
                    <UserPostCard post={p} user={user} isMobile={isMobile} />
  
                    {/* Render comments for the current post */}
                    {p.comments && p.comments.length > 0 && (
                      <div className={classes.comments}>
                        <strong>Comments:</strong>
                        {p.comments.map((comment) => (
                          <div key={comment.id}>
                            <span>{comment.user.username}: </span>
                            <span>{comment.text}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div style={{
                  background: 'linear-gradient(180deg, black, #ccc)', // Black to grey gradient
                  display: 'center', // Set to inline-block to fit content width
                  padding: '10px', // Add some padding for spacing
                  borderRadius: '5px', // Add rounded corners
                  color: 'white', // Text color
                  textAlign: 'center', // Center the content horizontally
                }}>
                  <PersonIcon color="primary" fontSize="large" />
                  <Typography variant="h5" color="secondary">
                    <strong>{userName}</strong> needs to share!
                  </Typography>
                </div>
              )}
            </div>
            {'next' in userInfo.posts && (
              <LoadMoreButton handleLoadPosts={handleLoadPosts} loading={loadingMore} />
            )}
          </div>
          {!isMobile && (
        <div className="user-entries-slider">
          <UserEntriesSlider />
        </div>
      )}
      </div>    
      </Paper>
  );
            };

  export default UserPage;