import React from 'react';

const GoogleCloudCertifications = () => {
  const googleCertifications = [
    {
      name: 'Google Cloud Associate Cloud Engineer',
      description: 'Designed for individuals who deploy applications, monitor operations, and manage enterprise solutions on Google Cloud.',
      link: 'https://cloud.google.com/certifications/cloud-engineer',
    },
    {
      name: 'Google Cloud Professional Data Engineer',
      description: 'For data professionals who design data processing systems and analyze data to help make informed business decisions.',
      link: 'https://cloud.google.com/certifications/data-engineer',
    },
    {
      name: 'Google Cloud Professional Cloud Architect',
      description: 'Designed for experienced cloud architects who design, develop, and manage Google Cloud solutions.',
      link: 'https://cloud.google.com/certifications/cloud-architect',
    },
    {
      name: 'Google Cloud Professional DevOps Engineer',
      description: 'For DevOps professionals who implement continuous integration and delivery (CI/CD) pipelines on Google Cloud.',
      link: 'https://cloud.google.com/certifications/devops-engineer',
    },
    {
      name: 'Google Cloud Professional Machine Learning Engineer',
      description: 'Designed for machine learning professionals who build, train, and deploy machine learning models using Google Cloud.',
      link: 'https://cloud.google.com/certifications/machine-learning-engineer',
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <header>
        <h1 className="title">Google Cloud Certifications</h1>
      </header>
      <section className="certifications">
        {googleCertifications.map((certification, index) => (
          <div key={index} className="certification">
            <h2 className="certification-name">{certification.name}</h2>
            <p className="certification-description">{certification.description}</p>
            <p className="certification-link">
              <a
                href={certification.link}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Learn more
              </a>
            </p>
          </div>
        ))}
      </section>
    </div>
  );
};

export default GoogleCloudCertifications;