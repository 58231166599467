import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loadingSpinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '12em',
    color: '#000000', // Set color to black
  },
  spinner: {
    animation: '$rotate 1.5s linear infinite', // Add rotation animation
  },
  text: {
    marginTop: '1em',
    color: '#555555', // Set color for the text
    fontWeight: 'bold',
    fontSize: '1.2em',
    textTransform: 'uppercase',
    textAlign: 'center', // Center align the text
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

const LoadingSpinner = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.loadingSpinner}>
      <CircularProgress size="6em" disableShrink className={classes.spinner} />
    </div>
  );
};

export default LoadingSpinner;