import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const FlareVMPromoBanner = () => {
  const handleButtonClick = () => {
    // Open FLARE VM's GitHub repository link in a new tab/window
    window.open('https://github.com/fireeye/flare-vm', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Supercharge Your Analysis with FLARE VM!</h2>
          <p>Utilize FireEye's Lightweight Analysis and Research Environment to enhance malware analysis and research.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose FLARE VM?</h3>
        <ul>
          <li>Access a Windows-based platform designed for malware analysis and incident response.</li>
          <li>Benefit from pre-installed tools for dynamic and static analysis.</li>
          <li>Enhance your reverse engineering capabilities.</li>
          <li>Contribute to the open-source community and learn from experts.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Users Say</h3>
        <div className="testimonial">
          <p>"FLARE VM is an essential tool for my malware analysis work. It streamlines the analysis process and saves time."</p>
          <p>- John Smith, Malware Analyst</p>
        </div>
        <div className="testimonial">
          <p>"As a reverse engineer, FLARE VM has become an invaluable part of my toolkit. It's a game-changer."</p>
          <p>- Jane Doe, Security Researcher</p>
        </div>
      </div>
    </div>
  );
};

export default FlareVMPromoBanner;