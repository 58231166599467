import React from 'react';

const LinkedInLearningInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#0077B5', // LinkedIn brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Discover Learning on LinkedIn Learning</h1>
      <p>
        LinkedIn Learning is an online platform that offers thousands of courses
        taught by industry experts. Whether you're looking to acquire new skills
        or enhance your career, LinkedIn Learning provides access to a wealth of
        knowledge.
      </p>
      <h2 style={subheadingStyle}>What LinkedIn Learning Offers:</h2>
      <ul>
        <li>Thousands of courses in various domains</li>
        <li>Expert-led instruction and tutorials</li>
        <li>Personalized course recommendations</li>
        <li>Access to courses on any device</li>
      </ul>
      <p>
        Elevate your skills and knowledge with LinkedIn Learning:
        <a
          href="https://www.linkedin.com/learning/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          LinkedIn Learning Website
        </a>
      </p>
    </div>
  );
};

export default LinkedInLearningInfo;