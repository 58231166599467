import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './exploitsearchpage.css'; // Create a CSS file for styling

const ExploitSearchPage = () => {
  const [exploitResults, setExploitResults] = useState([]);
  const [filteredExploitResults, setFilteredExploitResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 1000;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedExploitOS, setSelectedExploitOS] = useState('');

  useEffect(() => {
    // Fetch exploit results from an API endpoint
    const fetchExploitResults = async () => {
      try {
        const response = await axios.get(`/api/exploit-search?page=${currentPage}`);
        setExploitResults(response.data.results);
      } catch (error) {
        console.error('Error fetching exploit results:', error);
      }
    };

    fetchExploitResults();
  }, [currentPage]);

  useEffect(() => {
    // Filter exploit results based on search term and selected OS
    const filteredResults = exploitResults.filter((result) => {
      const searchString = `${result.username} ${result.exploitOS} ${result.exploitValue} ${result.description}`;
      const includesSearchTerm = searchString.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesExploitOS = selectedExploitOS ? result.exploitOS === selectedExploitOS : true;
      return includesSearchTerm && matchesExploitOS;
    });

    setFilteredExploitResults(filteredResults);
  }, [searchTerm, selectedExploitOS, exploitResults]);

  const handleLoadRecent = () => {
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    const recentResults = exploitResults.filter((result) => {
      const resultDate = new Date(result.currentDate);
      return resultDate.getTime() >= thirtyDaysAgo.getTime();
    });

    setFilteredExploitResults(recentResults);
  };

  const handleLoadByExploitOS = (exploitOS) => {
    setSelectedExploitOS(exploitOS);
  };

  return (
    <div className="exploit-search-container">
      <h2>Exploit Search</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="button-group">
        <div className="exploit-os-buttons">
          {Array.from(new Set(exploitResults.map((result) => result.exploitOS))).map((exploitOS) => (
            <button
              key={exploitOS}
              className={`load-button ${selectedExploitOS === exploitOS ? 'selected' : ''}`}
              onClick={() => handleLoadByExploitOS(exploitOS)}
            >
              Load {exploitOS} Exploits
            </button>
          ))}
        </div>
      </div>
      <div className="exploit-results">
        {filteredExploitResults.map((result) => (
          <div key={result.id} className="exploit-result">
            <div className="exploit-header">
              <span className="username">{result.username}</span>
              <span className="date">{result.currentDate}</span>
            </div>
            <div className="exploit-details">
              <span className="os">OS: {result.exploitOS}</span>
              <br />
              <span className="language">Language: {result.exploitLanguage}</span>
              <br />
              <span className="exploit-value">Exploit: <br />{result.exploitValue.split('#').map((value, index) => (
        <React.Fragment key={index}>
          {index > 0 && <br />} {/* Add a <br /> element except for the first item */}
          {value}
        </React.Fragment>
        ))}</span>
              <br />
              
            </div>
            <p className="description">Description: <br />{result.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExploitSearchPage;