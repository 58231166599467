import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const AWSCloudWatchPromoBanner = () => {
  const handleButtonClick = () => {
    // Open AWS CloudWatch website link in a new tab/window
    window.open('https://aws.amazon.com/cloudwatch/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Monitor with AWS CloudWatch!</h2>
          <p>Gain deep visibility and insights into your AWS resources, applications, and services using Amazon CloudWatch.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose AWS CloudWatch?</h3>
        <ul>
          <li>Collect, monitor, and visualize operational data and metrics.</li>
          <li>Gain insights into resource utilization, performance, and health.</li>
          <li>Automate actions and respond to events based on defined thresholds.</li>
          <li>Enhance the reliability and efficiency of your AWS infrastructure.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What AWS Users Say</h3>
        <div className="testimonial">
          <p>"AWS CloudWatch has transformed the way we monitor and manage our cloud resources. It's a game-changer."</p>
          <p>- John Smith, Cloud Architect</p>
        </div>
        <div className="testimonial">
          <p>"The real-time insights and alerting capabilities of AWS CloudWatch have significantly improved our operations."</p>
          <p>- Jane Doe, DevOps Engineer</p>
        </div>
      </div>
    </div>
  );
};

export default AWSCloudWatchPromoBanner;