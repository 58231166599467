import React from 'react';

const MalpediaActorsPage = () => {
  const pageStyle = {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f0f0f0',
    textAlign: 'center',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const containerStyle = {
    maxWidth: '800px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    color: 'black', // Default text color for light mode
  };

  // Media query for dark mode
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  if (darkModeMediaQuery.matches) {
    // Dark mode styles
    containerStyle.backgroundColor = '#333'; // Change background color in dark mode
    containerStyle.color = '#fff'; // Change text color in dark mode
  }

  const buttonStyle = {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
    marginTop: '20px',
  };

  const buttonHoverStyle = {
    backgroundColor: '#0056b3',
  };

  return (
    <div style={pageStyle}>
      <div style={containerStyle}>
        <h1>Welcome to Malpedia Actors</h1>
        <p>Explore a vast collection of malware actors and their details.</p>
        <a
          href="https://malpedia.caad.fkie.fraunhofer.de/actors"
          target="_blank"
          rel="noopener noreferrer"
          style={{ ...buttonStyle, ...buttonHoverStyle }}
        >
          Explore Malpedia Actors
        </a>
      </div>
    </div>
  );
};

export default MalpediaActorsPage;