import React from 'react';

const NetworkDefenseInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#4E85BD', // Network Defense theme color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore Network Defense</h1>
      <p>
        Network Defense is the practice of safeguarding computer networks from
        various threats, including cyberattacks, malware, and unauthorized
        access. It plays a critical role in ensuring the security and integrity
        of digital communication.
      </p>
      <h2 style={subheadingStyle}>Key Aspects of Network Defense:</h2>
      <ul>
        <li>Firewalls and intrusion detection systems</li>
        <li>Security policies and best practices</li>
        <li>Incident response and threat analysis</li>
        <li>Continuous monitoring and risk assessment</li>
      </ul>
      <p>
        Enhance your knowledge of Network Defense:
        <a
          href="https://www.networkdefense.co/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          NetworkDefense.co
        </a>
      </p>
    </div>
  );
};

export default NetworkDefenseInfo;