import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const BlackHatPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the Black Hat link in a new tab/window
    window.open('https://www.blackhat.com/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Explore Cybersecurity with Black Hat!</h2>
          <p>Discover cutting-edge research, trainings, and conferences at Black Hat's events.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Black Hat?</h3>
        <ul>
          <li>Access advanced cybersecurity research and insights.</li>
          <li>Attend trainings and workshops by industry experts.</li>
          <li>Connect with professionals, researchers, and practitioners.</li>
          <li>Stay at the forefront of the cybersecurity industry.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Attendees Say</h3>
        <div className="testimonial">
          <p>"Black Hat has been an incredible experience for me. The quality of content and networking opportunities are unmatched."</p>
          <p>- John Smith, Security Professional</p>
        </div>
        <div className="testimonial">
          <p>"I've gained valuable insights from attending Black Hat events. The range of topics covered is impressive."</p>
          <p>- Jane Doe, Cybersecurity Enthusiast</p>
        </div>
      </div>
    </div>
  );
};

export default BlackHatPromoBanner;