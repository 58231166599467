import React from 'react';

const HarvardOnlineCoursesInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#A51C30', // Harvard brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore Harvard's Online Courses</h1>
      <p>
        Harvard Online Courses offer a unique opportunity to learn from one of
        the world's most prestigious universities. Whether you're interested in
        arts and sciences, business, or health, Harvard provides a wide range of
        online courses to suit your interests and goals.
      </p>
      <h2 style={subheadingStyle}>What Harvard Online Courses Offer:</h2>
      <ul>
        <li>Access to Harvard's world-class faculty and resources</li>
        <li>Flexible online learning with a variety of topics</li>
        <li>Certificates of completion for select courses</li>
        <li>Engaging and interactive course materials</li>
      </ul>
      <p>
        Embark on your online learning journey with Harvard:
        <a
          href="https://online-learning.harvard.edu/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Harvard Online Courses
        </a>
      </p>
    </div>
  );
};

export default HarvardOnlineCoursesInfo;