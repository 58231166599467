import React, { useEffect, useState } from 'react';
import '../../css/homecombinedfeeds.css';

const RSSFeedsHomeLeft = () => {
  const [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFeeds = async () => {
      setLoading(true);
      try {
        const response = await fetch('/api/home-feed-left');
        if (response.ok) {
          const fetchedFeeds = await response.json();
          const sortedFeeds = sortFeedsByPubDate(fetchedFeeds); // Sort feeds by pubDate
          setFeeds(sortedFeeds);
        } else {
          console.error('Error fetching feeds:', response.status);
        }
      } catch (error) {
        console.error('Error fetching feeds:', error);
      }
      setLoading(false);
    };

    fetchFeeds();

    const interval = setInterval(fetchFeeds, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const sortFeedsByPubDate = (fetchedFeeds) => {
    return fetchedFeeds.sort((a, b) => {
      const pubDateA = new Date(a.pubDate);
      const pubDateB = new Date(b.pubDate);
      return pubDateB - pubDateA; // Sort in descending order (most recent first)
    });
  };

  return (
    <div className="app">
      <div className="feeds-container-home">
        {loading ? (
          <div className="loading-indicator">Loading...</div>
        ) : (
          feeds.map((feed, index) => (
            <div key={index}>
              {feed.items.map((item, itemIndex) => (
                <div key={item.link || itemIndex} className="rss-feed-item-combined" style={{ marginBottom: '3px' }}>
                  <div className="shadowed-container">
                    <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                      {item.title}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
      </div>
      <amp-auto-ads type="adsense"
        data-ad-client="ca-pub-3504607292095587">
</amp-auto-ads>
    </div>
  );
};

export default RSSFeedsHomeLeft;