import React, { Component } from 'react';
import './css/aboutyou.css';
import axios from 'axios'; // Import Axios for making API requests
import storageService from '../../utils/localStorage';
const loggedUser = storageService.loadUser();

class AboutYou extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bio: '',
      ethos: '',
      socialMedia: [],
      inspiredBy: [],
      likes: '',
      dislikes: '',
      selectedSocialMedia: '', // To store the selected social media platform
      socialMediaText: '', // To store the text submission for the selected social media platform
      isAddingInspiredBy: false, // Flag to track if the user is adding "Inspired By"
      isAddingDigitalPresence: false, // Flag to track if the user is adding "Digital Presence"
      // New state properties
      favoriteTVShows: [],
      favoriteMovies: [],
      favoriteBooks: [],
      hobbies: '',
      cybersecurityStart: '',
      // New input fields for adding favorites
      favoriteTVShowText: '',
      favoriteMovieText: '',
      favoriteBookText: '',
      username: loggedUser.username,
      id: loggedUser.id,
    };
  }

  handleSave = () => {
    const userData = {
      bio: this.state.bio,
      ethos: this.state.ethos,
      socialMedia: this.state.socialMedia,
      inspiredBy: this.state.inspiredBy,
      likes: this.state.likes,
      dislikes: this.state.dislikes,
      favoriteTVShows: this.state.favoriteTVShows,
      favoriteMovies: this.state.favoriteMovies,
      favoriteBooks: this.state.favoriteBooks,
      hobbies: this.state.hobbies,
      cybersecurityStart: this.state.cybersecurityStart,
      username: loggedUser.username,
      userid: loggedUser.id,
    };

    // Make a POST request to save the user data
    axios.post('/api/sayi', userData)
      .then((response) => {
        // Handle successful save (e.g., show a success message)
        console.log('User data saved successfully');
      })
      .catch((error) => {
        // Handle error (e.g., show an error message)
        console.error('Error saving user data:', error);
      });
  };


  componentDidMount() {
    const { username, id } = loggedUser;
  
    // Fetch user data for the logged-in user with both username and userid
    axios.get(`/api/ayr?username=${username}&userid=${id}`)
      .then((response) => {
        const userData = response.data;
        this.setState(userData);
      })
      .catch((error) => {
        // Handle error (e.g., show an error message)
        console.error('Error fetching user data:', error);
      });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    // Check if the input field is a social media input
    if (name.startsWith('socialMediaText_')) {
      const platform = name.split('_')[1];
      this.setState({ socialMediaText: value, selectedSocialMedia: platform });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleAddInspiredBy = () => {
    this.setState({ isAddingInspiredBy: true });
  };

  handleAddDigitalPresence = () => {
    this.setState({ isAddingDigitalPresence: true });
  };

  handleDeleteInspiredBy = (index) => {
    const inspiredByCopy = [...this.state.inspiredBy];
    inspiredByCopy.splice(index, 1);
    this.setState({ inspiredBy: inspiredByCopy });
  };

  handleDeleteSocialMedia = (index) => {
    const socialMediaCopy = [...this.state.socialMedia];
    socialMediaCopy.splice(index, 1);
    this.setState({ socialMedia: socialMediaCopy });
  };

  handleConfirmInspiredBy = () => {
    if (this.state.inspiredByText && this.state.inspiredBy.length < 10) {
      this.setState((prevState) => ({
        inspiredBy: [...prevState.inspiredBy, prevState.inspiredByText],
        inspiredByText: '',
        isAddingInspiredBy: false,
      }));
    }
  };

  handleConfirmDigitalPresence = () => {
    if (this.state.selectedSocialMedia && this.state.socialMediaText) {
      this.setState((prevState) => ({
        socialMedia: [
          ...prevState.socialMedia,
          {
            platform: prevState.selectedSocialMedia,
            text: prevState.socialMediaText,
          },
        ],
        selectedSocialMedia: '',
        socialMediaText: '',
        isAddingDigitalPresence: false,
      }));
    }
  };

  // New functions for adding and deleting favorite TV shows, movies, books
  handleAddFavoriteTVShow = () => {
    const { favoriteTVShows, favoriteTVShowText } = this.state;
    if (favoriteTVShowText && favoriteTVShows.length < 10) {
      this.setState({
        favoriteTVShows: [...favoriteTVShows, favoriteTVShowText],
        favoriteTVShowText: '',
      });
    }
  };

  handleAddFavoriteMovie = () => {
    const { favoriteMovies, favoriteMovieText } = this.state;
    if (favoriteMovieText && favoriteMovies.length < 10) {
      this.setState({
        favoriteMovies: [...favoriteMovies, favoriteMovieText],
        favoriteMovieText: '',
      });
    }
  };

  handleAddFavoriteBook = () => {
    const { favoriteBooks, favoriteBookText } = this.state;
    if (favoriteBookText && favoriteBooks.length < 10) {
      this.setState({
        favoriteBooks: [...favoriteBooks, favoriteBookText],
        favoriteBookText: '',
      });
    }
  };

  handleDeleteFavoriteTVShow = (index) => {
    const favoriteTVShowsCopy = [...this.state.favoriteTVShows];
    favoriteTVShowsCopy.splice(index, 1);
    this.setState({ favoriteTVShows: favoriteTVShowsCopy });
  };

  handleDeleteFavoriteMovie = (index) => {
    const favoriteMoviesCopy = [...this.state.favoriteMovies];
    favoriteMoviesCopy.splice(index, 1);
    this.setState({ favoriteMovies: favoriteMoviesCopy });
  };

  handleDeleteFavoriteBook = (index) => {
    const favoriteBooksCopy = [...this.state.favoriteBooks];
    favoriteBooksCopy.splice(index, 1);
    this.setState({ favoriteBooks: favoriteBooksCopy });
  };

  handleHobbiesChange = (event) => {
    this.setState({ hobbies: event.target.value });
  };

  handleCybersecurityStartChange = (event) => {
    this.setState({ cybersecurityStart: event.target.value });
  };

  render() {
    const textColorStyle = { color: '#000000' }; 
    return (
      <div className="about-container">
        <div>
          <div>
            <h3 style={textColorStyle}>How did you get started in cyber?</h3>
            <textarea
              name="cybersecurityStart"
              value={this.state.cybersecurityStart}
              onChange={this.handleCybersecurityStartChange}
            />
          </div>
          <div>
            <label style={textColorStyle}>A little about yourself:</label>
            <textarea
              name="bio"
              value={this.state.bio}
              onChange={this.handleInputChange}
            />
          </div>
          <div>
            <label style={textColorStyle}>Ethos (your general approach to cyber or life):</label>
            <textarea
              type="text"
              name="ethos"
              value={this.state.ethos}
              onChange={this.handleInputChange}
            />
          </div>
          </div>
          <div>
            <h3 style={textColorStyle}>Likes</h3>
            <textarea
              name="likes"
              value={this.state.likes}
              onChange={this.handleInputChange}
            />
          </div>
          <div>
            <h3 style={textColorStyle}>Dislikes</h3>
            <textarea
              name="dislikes"
              value={this.state.dislikes}
              onChange={this.handleInputChange}
            />
          </div>
          <p style={textColorStyle}>People that inspire you!</p>
          {/* Add input boxes for names */}
          {this.state.inspiredBy.map((name, index) => (
            <div key={index}>
              <span>{name}</span>
              <button onClick={() => this.handleDeleteInspiredBy(index)}>Delete</button>
            </div>
          ))}
          {this.state.isAddingInspiredBy ? (
            <div>
              <label>Name/Link</label>
              <textarea
                type="text"
                name="inspiredByText"
                value={this.state.inspiredByText}
                onChange={this.handleInputChange}
              />
              <button onClick={this.handleConfirmInspiredBy}>Confirm</button>
            </div>
          ) : (
            <button onClick={this.handleAddInspiredBy}>Add</button>
          )}
          <div>
            <h3 style={textColorStyle}>Digital Presence</h3>
            {this.state.socialMedia.map((item, index) => (
              <div key={index}>
                <span>{item.platform}: </span>
                <span>{item.text}</span>
                <button onClick={() => this.handleDeleteSocialMedia(index)}>Delete</button>
              </div>
            ))}
            {this.state.isAddingDigitalPresence ? (
              <div>
                <select
                  name="selectedSocialMedia"
                  value={this.state.selectedSocialMedia}
                  onChange={this.handleInputChange}
                >
                  <option value="">Select a Platform</option>
                  <option value="linkedin">LinkedIn</option>
                  <option value="github">GitHub</option>
                  <option value="facebook">Facebook</option>
                  <option value="youtube">YouTube</option>
                  <option value="devto">Dev.to</option>
                  {/* Add more options here */}
                </select>
                <textarea
                  type="text"
                  name={`socialMediaText_${this.state.selectedSocialMedia}`}
                  value={this.state.socialMediaText}
                  onChange={this.handleInputChange}
                />
                <button onClick={this.handleConfirmDigitalPresence}>Confirm</button>
              </div>
            ) : (
              <button onClick={this.handleAddDigitalPresence}>Add</button>
            )}
          {/* Sections for favorite TV shows, movies, books */}
          <div>
            <h3 style={textColorStyle}>TV Shows</h3>
            {this.state.favoriteTVShows.map((show, index) => (
              <div key={index}>
                <span>{show}</span>
                <button onClick={() => this.handleDeleteFavoriteTVShow(index)}>Delete</button>
              </div>
            ))}
            <textarea
              name="favoriteTVShowText"
              value={this.state.favoriteTVShowText}
              onChange={this.handleInputChange}
            />
            <button onClick={this.handleAddFavoriteTVShow}>Add</button>
          </div>

          <div>
            <h3 style={textColorStyle}>Movies</h3>
            {this.state.favoriteMovies.map((movie, index) => (
              <div key={index}>
                <span>{movie}</span>
                <button onClick={() => this.handleDeleteFavoriteMovie(index)}>Delete</button>
              </div>
            ))}
            <textarea
              name="favoriteMovieText"
              value={this.state.favoriteMovieText}
              onChange={this.handleInputChange}
            />
            <button onClick={this.handleAddFavoriteMovie}>Add</button>
          </div>

          <div>
            <h3 style={textColorStyle}>Books</h3>
            {this.state.favoriteBooks.map((book, index) => (
              <div key={index}>
                <span>{book}</span>
                <button onClick={() => this.handleDeleteFavoriteBook(index)}>Delete</button>
              </div>
            ))}
            <textarea
              name="favoriteBookText"
              value={this.state.favoriteBookText}
              onChange={this.handleInputChange}
            />
            <button onClick={this.handleAddFavoriteBook}>Add</button>
          </div>

          {/* Hobbies and Cybersecurity Start sections */}
          <div>
            <h3 style={textColorStyle}>Hobbies</h3>
            <textarea
              name="hobbies"
              value={this.state.hobbies}
              onChange={this.handleHobbiesChange}
            />
          </div>
          <div>
            {/* Add a "Save" button to trigger the handleSave method */}
            <button style={textColorStyle} onClick={this.handleSave}>Save</button>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutYou;