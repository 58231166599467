import React, { useState } from 'react';

const styles = {
  container1: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: 'fit-content',
    margin: '0 auto',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9'
  },
  header1: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px'
  },
  buttonContainer1: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  button1: {
    padding: '10px 20px',
    margin: '0 10px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
  },
  buttonActive1: {
    backgroundColor: '#0056b3'
  },
  instructions1: {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '15px',
    fontFamily: 'monospace',
    color: '#333',
    whiteSpace: 'pre-wrap'
  },
  tip1: {
    marginTop: '10px',
    fontStyle: 'italic',
    color: '#666',
  }
};

const instructions = {
    linux: {
      steps: [
        'Open your terminal (You can usually do this by pressing Ctrl + Alt + T).',
        'Copy and paste the following commands into your terminal:',
        `curl -L -O https://artifacts.elastic.co/downloads/beats/elastic-agent/elastic-agent-8.11.0-linux-x86_64.tar.gz
        tar xzvf elastic-agent-8.11.0-linux-x86_64.tar.gz
        cd elastic-agent-8.11.0-linux-x86_64
        sudo ./elastic-agent install --url=https://13590ea58c754100ab3204e4643bf864.fleet.us-central1.gcp.cloud.es.io:443 --enrollment-token=clBwZENJd0JtenVDMm9xUmd0SDk6OFBXd0EySl9TSkdxM1B2c0hUMDJpQQ==`
      ],
      copyPasteTip: 'Use Ctrl + C to copy and Right Click to Paste'
    },
    mac: {
      steps: [
        'Open the Terminal app (You can find it using Spotlight search with Cmd + Space).',
        'Copy and paste the following commands into the Terminal:',
        `curl -L -O https://artifacts.elastic.co/downloads/beats/elastic-agent/elastic-agent-8.11.0-darwin-x86_64.tar.gz
        tar xzvf elastic-agent-8.11.0-darwin-x86_64.tar.gz
        cd elastic-agent-8.11.0-darwin-x86_64
        sudo ./elastic-agent install --url=https://13590ea58c754100ab3204e4643bf864.fleet.us-central1.gcp.cloud.es.io:443 --enrollment-token=clBwZENJd0JtenVDMm9xUmd0SDk6OFBXd0EySl9TSkdxM1B2c0hUMDJpQQ==`
      ],
      copyPasteTip: 'Use Cmd + C to copy and Cmd + V to paste.'
    },
    windows: {
      steps: [
        'Open PowerShell as an administrator (You can search for it in the Start menu).',
        'Copy and paste the following commands into PowerShell:',
        `$ProgressPreference = 'SilentlyContinue'
        Invoke-WebRequest -Uri https://artifacts.elastic.co/downloads/beats/elastic-agent/elastic-agent-8.11.0-windows-x86_64.zip -OutFile elastic-agent-8.11.0-windows-x86_64.zip
        Expand-Archive .\\elastic-agent-8.11.0-windows-x86_64.zip -DestinationPath .
        cd elastic-agent-8.11.0-windows-x86_64
        .\\elastic-agent.exe install --url=https://13590ea58c754100ab3204e4643bf864.fleet.us-central1.gcp.cloud.es.io:443 --enrollment-token=clBwZENJd0JtenVDMm9xUmd0SDk6OFBXd0EySl9TSkdxM1B2c0hUMDJpQQ==`
      ],
      copyPasteTip: 'Use Ctrl + C to copy and then Right click to select the Powershell Terminal'
    },
  };

function SOCInstall() {
  const [os, setOs] = useState('linux');

  const renderInstructions = (osInstructions) => (
    <>
      <ol>
        {osInstructions.steps.map((step, index) => (
          <li key={index}>{step}</li>
        ))}
      </ol>
      <p><strong>Tip:</strong> {osInstructions.copyPasteTip}</p>
    </>
  );

  return (
    <div style={styles.container1}>
      <h1 style={styles.header1}>Agent Installation Instructions</h1>
      <div style={styles.buttonContainer1}>
        <button style={{ ...styles.button1, ...(os === 'linux' ? styles.buttonActive1 : {}) }} onClick={() => setOs('linux')}>Linux</button>
        <button style={{ ...styles.button1, ...(os === 'mac' ? styles.buttonActive1 : {}) }} onClick={() => setOs('mac')}>Mac</button>
        <button style={{ ...styles.button1, ...(os === 'windows' ? styles.buttonActive1 : {}) }} onClick={() => setOs('windows')}>Windows</button>
      </div>
      <div style={styles.instructions1}>
        {renderInstructions(instructions[os])}
      </div>
    </div>
  );
}

export default SOCInstall;