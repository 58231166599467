import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import your CSS for styling

const CourseraPromoBanner = () => {
  const handleClick = () => {
    // Replace with your actual link
    window.open('https://www.coursera.org/', '_blank');
  };

  const bannerImagePath = './coursera-banner-image.jpg';

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Unlock Your Potential with Coursera!</h2>
          <p>Explore a world of knowledge with Coursera's online courses, degrees, and certificates. Gain skills from top universities and institutions.</p>
          <button onClick={handleClick}>Get Started</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Coursera?</h3>
        <ul>
          <li>Access to over 5,000 online courses across various subjects.</li>
          <li>Earn degrees and professional certificates from top universities.</li>
          <li>Flexible learning options with on-demand video lectures.</li>
          <li>Collaborate with learners from around the world.</li>
          <li>Learn from instructors who are experts in their fields.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Partnerships and Institutions</h3>
        <p>Coursera collaborates with renowned universities, organizations, and industry experts to provide high-quality learning experiences. Some of our partners include:</p>
        <ul>
          <li>Stanford University</li>
          <li>Yale University</li>
          <li>Google</li>
          <li>IBM</li>
          <li>University of Michigan</li>
          {/* Add more partner institutions */}
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Learners Say</h3>
        <div className="testimonial">
          <p>"Coursera has transformed the way I learn. The courses are well-structured, and the quality of instruction is exceptional."</p>
          <p>- John Smith, Coursera Learner</p>
        </div>
        <div className="testimonial">
          <p>"The flexibility of Coursera's platform allowed me to upskill while balancing work and family commitments."</p>
          <p>- Jane Doe, Working Professional</p>
        </div>
      </div>
    </div>
  );
};

export default CourseraPromoBanner;