import React from 'react';
import { ReactComponent as Best } from '../svg/best.svg';
import { ReactComponent as Hot } from '../svg/hot.svg';
import { ReactComponent as New } from '../svg/new.svg';
import { ReactComponent as Top } from '../svg/top.svg';
import { ReactComponent as Controversial } from '../svg/controversial.svg';
import { ReactComponent as Old } from '../svg/old.svg';
import { ReactComponent as Subscribed } from '../svg/subscribed.svg';

import { Paper, Tabs, Tab, SvgIcon, makeStyles } from '@material-ui/core';
import { useSortTabStyles } from '../styles/muiStyles';
import '../css/tabbarposts.css';

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    color: 'white',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    minHeight: '32px', // Adjust the minimum height as needed
  },
  tabLabel: {
    fontWeight: 'bold',
    fontSize: '0.6rem', // Reduced the font size for labels
  },
  icon: {
    transform: 'scale(0.25)', // Adjust the scale factor to change icon size
  },
}));

const SortTabBar = ({ sortBy, handleTabChange, subscribedTab, user }) => {
  const classes = useSortTabStyles();

  return (
    <Paper className={`${classes.mainPaper} sortTabBarContainer`}>
      <Tabs
        value={sortBy}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        className="MuiTabs-scrollable"
      >
        <Tab
          icon={
            <SvgIcon fontSize="small" className="MuiSvgIcon-root">
              <Hot />
            </SvgIcon>
          }
          label="Hype"
          value="hot"
          className="MuiTab-root"
        />
        {subscribedTab && user && (
          <Tab
            icon={
              <SvgIcon fontSize="small" className="MuiSvgIcon-root">
                <Subscribed />
              </SvgIcon>
            }
            label="Selected Topics"
            value="subscribed"
            className="MuiTab-root"
          />
        )}
        <Tab
          icon={
            <SvgIcon fontSize="small" className="MuiSvgIcon-root">
              <Best />
            </SvgIcon>
          }
          label="Rising"
          value="best"
          className="MuiTab-root"
        />
        <Tab
          icon={
            <SvgIcon fontSize="small" className="MuiSvgIcon-root">
              <New />
            </SvgIcon>
          }
          label="New"
          value="new"
          className="MuiTab-root"
        />
        <Tab
          icon={
            <SvgIcon fontSize="small" className="MuiSvgIcon-root">
              <Top />
            </SvgIcon>
          }
          label="Top"
          value="top"
          className="MuiTab-root"
        />
        <Tab
          icon={
            <SvgIcon fontSize="small" className="MuiSvgIcon-root">
              <Controversial />
            </SvgIcon>
          }
          label="Most Discussed"
          value="controversial"
          className="MuiTab-root"
        />
      </Tabs>
    </Paper>
  );
};

export default SortTabBar;