import React from 'react';
import './mentorconduct.css'; // Import your CSS file for styling

function MentorshipConduct() {
  return (
    <div className="mentorship-conduct">
      <main className="mentorship-container">
      <section className="mentor-section">
          <h2>For Mentors</h2>
          <br/>
          <p>
            As a mentor, you have the opportunity to influence and guide your mentee's growth. Two prominent leadership styles to consider in mentorship are:
          </p>
          <br/>
          <ul>
            <li><strong>Transformative Leadership:</strong> This leadership style, as proposed by James MacGregor Burns, focuses on inspiring and motivating mentees to reach their full potential. Transformative mentors lead by example, stimulate creativity, and encourage critical thinking, ultimately fostering personal and professional growth.</li>
            <li><strong>Transactional Leadership:</strong> Bernard Bass introduced the concept of transactional leadership, which involves setting clear expectations, rewards, and consequences for performance. Transactional mentors emphasize structure and adherence to established guidelines, providing guidance within defined boundaries.</li>
          </ul>
          <br/>
          <p>
            Regardless of your leadership style, remember to embody the qualities mentioned earlier, such as setting clear expectations and providing constructive feedback. Both transformative and transactional elements can be integrated into your mentorship approach based on your mentee's needs and your own leadership style.
          </p>
          <br/>
          <ul>
            <li><strong>Lead by Example:</strong> As a mentor, you are a role model for your mentee. Demonstrate professionalism, ethics, and best practices in the field of cyber.</li>
            <li><strong>Set Clear Expectations:</strong> Communicate clearly with your mentee. Define the goals of the mentorship, including meeting schedules, responsibilities, and any specific objectives to achieve.</li>
            <li><strong>Provide Constructive Feedback:</strong> Offer guidance and feedback in a positive and constructive manner. Encourage growth and improvement while maintaining a supportive and respectful tone.</li>
            <li><strong>Be Patient and Supportive:</strong> Understand that everyone learns at their own pace. Be patient with your mentee's progress and provide support when they face challenges or setbacks.</li>
            <li><strong>Respect Boundaries:</strong> Respect your mentee's time and personal space. Avoid overburdening them with excessive demands, and ensure a healthy work-life balance in the mentorship relationship.</li>
            <li><strong>Encourage Independence:</strong> While providing guidance, empower your mentee to make decisions and solve problems on their own. Foster independence and critical thinking skills.</li>
          </ul>
        </section>
        <section className="mentee-section">
          <h2>For Mentees</h2>
          <br/>
          <p>
            As a mentee, understanding these leadership styles can help you adapt to your mentor's approach and maximize the benefits of the mentorship relationship. Here are some considerations:
          </p>
          <br/>
          <ul>
            <li><strong>Adaptability:</strong> Recognize that mentors may exhibit a combination of transformative and transactional leadership qualities. Be adaptable and open to different approaches based on your mentor's style and your specific goals.</li>
            <li><strong>Clarify Expectations:</strong> If your mentor's style leans toward transactional leadership, seek clarity on expectations, rewards, and consequences. For transformative mentors, focus on seeking inspiration and guidance to reach your full potential.</li>
            <li><strong>Effective Communication:</strong> Regardless of your mentor's style, effective communication is key. Ask questions, seek feedback, and express your goals and needs to ensure a successful mentorship relationship.</li>
          </ul>
          <br/>
          <ul>
            <li><strong>Be Open to Feedback:</strong> Actively listen to your mentor's advice and be receptive to constructive criticism. Use feedback as an opportunity for growth and improvement.</li>
            <li><strong>Set Clear Goals:</strong> Define your objectives and expectations for the mentorship relationship. What do you hope to achieve, and how can your mentor assist you in reaching your goals?</li>
            <li><strong>Respect Your Mentor's Time:</strong> Be punctual and respectful of your mentor's schedule. Value their time and commitment to your development.</li>
            <li><strong>Ask Questions:</strong> Don't hesitate to seek clarification or guidance when you have doubts or questions. Effective communication is essential for a successful mentorship.</li>
            <li><strong>Take Initiative:</strong> Be proactive in your learning. Take ownership of your career development and seek out opportunities for growth, even outside of scheduled mentorship sessions.</li>
            <li><strong>Express Gratitude:</strong> Show appreciation for your mentor's time, knowledge, and support. A simple thank-you can go a long way in fostering a positive mentorship relationship.</li>
          </ul>
        </section>
      </main>
    </div>
  );
}

export default MentorshipConduct;