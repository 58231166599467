import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSubscribe } from '../../reducers/subReducer';
import { notify } from '../../reducers/notificationReducer';
import SubFormModal from '../SubFormModal';
import LoadingSpinner from '../LoadingSpinner';
import getErrorMsg from '../../utils/getErrorMsg';
import storageService from '../../utils/localStorage';

import {
  Paper,
  Typography,
  useMediaQuery,
  Link,
  Button,
} from '@material-ui/core';
import { useSubPanelStyles } from '../../styles/muiStyles';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import './leaderboard.css';

const TopSubsPanel = () => {
  const { subs, user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useSubPanelStyles();
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('md'));

  if (isNotDesktop) {
    return null;
  }

  const loggedUser = storageService.loadUser() || user;

  const loadingSubs = !subs || !subs.topSubs;

  const isSubscribed = (subscribedBy, user) => {
    return subscribedBy.includes(user.id);
  };

  const handleJoinSub = async (id, subscribedBy, subredditName) => {
    try {
      let updatedSubscribedBy;

      if (subscribedBy.includes(user.id)) {
        updatedSubscribedBy = subscribedBy.filter((s) => s !== user.id);
      } else {
        updatedSubscribedBy = [...subscribedBy, user.id];
      }
      dispatch(toggleSubscribe(id, updatedSubscribedBy));

      let message = subscribedBy.includes(user.id)
        ? `Removed ${subredditName}`
        : `Selected ${subredditName}!`;
      dispatch(notify(message, 'success'));
    } catch (err) {
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  return (
    <Paper className={`mainPaper ${classes.mainPaper}`} style={{ backgroundColor: 'transparent' }}>
      {loggedUser && <SubFormModal />}
      <Paper className={`listPaper ${classes.listPaper}`}>
        <Typography variant="h5" color="secondary" className={`title ${classes.title}`}>
          Popular Topics
        </Typography>
        {loadingSubs ? (
          <LoadingSpinner />
        ) : (
          subs.topSubs.map((s, i) => (
            <div key={s.id} className={`listWrapper ${classes.listWrapper}`}>
              <Typography variant="body2" className={`listItem ${classes.listItem}`}>
                {`${i + 1}. `}
                <Link
                  component={RouterLink}
                  to={`/i/${s.subredditName}`}
                  color="primary"
                >
                  {s.subredditName}
                </Link>
              </Typography>
              {loggedUser && (
                <div className={`buttonWrapper ${classes.buttonWrapper}`}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={
                      isSubscribed(s.subscribedBy, user) ? (
                        <CheckIcon />
                      ) : (
                        <AddIcon />
                      )
                    }
                    onClick={() =>
                      handleJoinSub(s.id, s.subscribedBy, s.subredditName)
                    }
                    className={`button ${classes.button}`}
                  >
                    {isSubscribed(s.subscribedBy, user) ? 'Remove' : 'Select'}
                  </Button>
                </div>
              )}
            </div>
          ))
        )}
      </Paper>
    </Paper>
  );
};

export default TopSubsPanel;
