import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const BSidesPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the BSides website link in a new tab/window
    window.open('https://www.securitybsides.com/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Experience Community at BSides!</h2>
          <p>Explore local BSides events for grassroots security discussions, workshops, and networking.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose BSides?</h3>
        <ul>
          <li>Attend community-driven security conferences in your area.</li>
          <li>Participate in discussions, workshops, and challenges.</li>
          <li>Network with fellow security enthusiasts and professionals.</li>
          <li>Support the grassroots cybersecurity community.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Attendees Say</h3>
        <div className="testimonial">
          <p>"BSides events offer a unique and welcoming environment for sharing knowledge and connecting with like-minded individuals."</p>
          <p>- John Smith, Security Enthusiast</p>
        </div>
        <div className="testimonial">
          <p>"I've found BSides to be a great opportunity to learn from local experts and build meaningful connections."</p>
          <p>- Jane Doe, Cybersecurity Professional</p>
        </div>
      </div>
    </div>
  );
};

export default BSidesPromoBanner;