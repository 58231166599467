import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const DonMurdochPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://www.donmurdoch.net/', '_blank');
  };

  const books = [
    {
      title: 'Mastering Metasploit',
      link: 'https://www.amazon.com/Mastering-Metasploit-Attacking-Infrastructure-Don/dp/1119327788',
    },
    {
      title: 'Metasploit Penetration Testing Cookbook',
      link: 'https://www.amazon.com/Metasploit-Penetration-Testing-Cookbook-Third/dp/1786465345',
    },
    {
      title: 'Advanced Infrastructure Penetration Testing',
      link: 'https://www.amazon.com/Advanced-Infrastructure-Penetration-Testing-Don/dp/1119367689',
    },
  ];

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Explore Cybersecurity with Don Murdoch!</h2>
          <p>Discover educational resources, books, and insights on Don Murdoch's website.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Don Murdoch?</h3>
        <ul>
          <li>Expertise in cybersecurity, penetration testing, and Metasploit.</li>
          <li>Author of educational and practical books on cybersecurity.</li>
          <li>Engaging talks and workshops for cybersecurity professionals.</li>
          <li>Learn from an experienced practitioner.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Books by Don Murdoch</h3>
        <ul>
          {books.map((book, index) => (
            <li key={index}>
              <a href={book.link} target="_blank" rel="noopener noreferrer">
                {book.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Readers Say</h3>
        <div className="testimonial">
          <p>"Don Murdoch's books have been invaluable for deepening my knowledge of penetration testing and Metasploit."</p>
          <p>- John Smith, Security Professional</p>
        </div>
        <div className="testimonial">
          <p>"I've learned a lot from Don Murdoch's talks and resources. His expertise shines through in every aspect."</p>
          <p>- Jane Doe, Cybersecurity Enthusiast</p>
        </div>
      </div>
    </div>
  );
};

export default DonMurdochPromoBanner;