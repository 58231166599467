import React from 'react';

const MITOpenCourseWareInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#FF6F61', // MIT brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Access MIT's Knowledge with OpenCourseWare</h1>
      <p>
        MIT OpenCourseWare is a free and open educational resource that provides
        access to a wealth of course materials from the Massachusetts Institute
        of Technology (MIT). Explore a wide range of subjects and learn from
        MIT's world-class faculty.
      </p>
      <h2 style={subheadingStyle}>What MIT OpenCourseWare Offers:</h2>
      <ul>
        <li>Free access to course materials, lecture notes, and assignments</li>
        <li>Rich content in various academic disciplines</li>
        <li>Learn at your own pace and explore topics of interest</li>
        <li>No registration or enrollment required</li>
      </ul>
      <p>
        Dive into MIT's educational resources with OpenCourseWare:
        <a
          href="https://ocw.mit.edu/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          MIT OpenCourseWare Website
        </a>
      </p>
    </div>
  );
};

export default MITOpenCourseWareInfo;