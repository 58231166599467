import React from 'react';

const CobaltStrikeInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'black', // Default text color
  };

  // Adjust text color for dark mode
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    containerStyle.color = 'white'; // Set text color for dark mode
  }

  const headingStyle = {
    color: '#333',
  };

  const subheadingStyle = {
    color: '#555',
  };

  const linkStyle = {
    color: '#007bff',
    textDecoration: 'none',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Cobalt Strike</h1>
      <p>
        Cobalt Strike is a powerful penetration testing tool designed for
        security professionals. It offers a wide range of features for
        conducting security assessments and red teaming exercises.
      </p>
      <h2 style={subheadingStyle}>Features:</h2>
      <ul>
        <li>Beacon payload for command and control</li>
        <li>Post-exploitation modules</li>
        <li>Phishing campaigns</li>
        <li>Exploitation tools</li>
      </ul>
      <p>
        For more information and to download Cobalt Strike, please visit the
        official website:
        <a
          href="https://www.cobaltstrike.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Cobalt Strike
        </a>
      </p>
    </div>
  );
};

export default CobaltStrikeInfo;