import React from 'react';

const PluralsightInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#0055FF', // Pluralsight brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore Learning on Pluralsight</h1>
      <p>
        Pluralsight is a leading online learning platform with a vast library of
        courses covering technology, development, IT, and more. Whether you're
        looking to enhance your skills or stay up-to-date with industry trends,
        Pluralsight has you covered.
      </p>
      <h2 style={subheadingStyle}>What Pluralsight Offers:</h2>
      <ul>
        <li>Thousands of expert-led courses</li>
        <li>Hands-on labs and exercises</li>
        <li>Paths and certifications</li>
        <li>Flexible learning at your own pace</li>
      </ul>
      <p>
        Elevate your skills and career with Pluralsight's online courses:
        <a
          href="https://www.pluralsight.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Pluralsight Website
        </a>
      </p>
    </div>
  );
};

export default PluralsightInfo;