import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from './reducers/userReducer';
import { fetchPosts } from './reducers/postReducer';
import { setSubList, setTopSubsList } from './reducers/subReducer';
import { setDarkMode } from './reducers/themeReducer';
import { notify } from './reducers/notificationReducer';
import NavBar from './components/NavBar';
import HybridScroll from './components/hybridanalysis/hybridfeedscroll'
import ToastNotif from './components/ToastNotif';
import Routes from './Routes';
import getErrorMsg from './utils/getErrorMsg';
import { Paper } from '@material-ui/core/';
import customTheme from './styles/customTheme';
import { useMainPaperStyles } from './styles/muiStyles';
import { ThemeProvider } from '@material-ui/core/styles';
import Chat from './components/Chat/chat';
import maybeBG from './assets/maybebg.jpg'

const App = () => {
  const classes = useMainPaperStyles();
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state);

  useEffect(() => {
    const setPostsAndSubreddits = async () => {
      try {
        await dispatch(fetchPosts('hot'));
        await dispatch(setSubList());
        await dispatch(setTopSubsList());
      } catch (err) {
        dispatch(notify(getErrorMsg(err), 'error'));
      }
    };

    dispatch(setUser());
    dispatch(setDarkMode());
    setPostsAndSubreddits();
  }, []);

  const isUserAuthenticated = () => {
    const readifyUserKey = localStorage.getItem('readifyUserKey');
    return readifyUserKey !== null;
  };

  // Define the inline style for the background image
  const backgroundStyle = {
    backgroundImage: `url(${maybeBG})`, // Use template literal with `url()`
    backgroundSize: 'cover', // Adjust as needed
    backgroundRepeat: 'no-repeat', // Adjust as needed
    backgroundAttachment: 'fixed', // Adjust as needed
    /* You can add more CSS properties to style the background image as desired */
  };

  return (
    <ThemeProvider theme={customTheme(darkMode)}>
      <Paper className={classes.root} elevation={0} style={backgroundStyle}>
        {isUserAuthenticated() ? (
          <>
            <ToastNotif />
            <NavBar />
            <Routes />
            <Chat />
          </>
        ) : (
          <Routes />
        )}
      </Paper>
    </ThemeProvider>
  );
};

export default App;