import React, { useState } from 'react';
import './yeah.css';

const rolesData = [
    {
      name: 'Software Engineer',
      description: 'Software engineers design, develop, and maintain software applications.',
      averageSalary: 100000,
      resourceLink: 'https://example.com/software-engineer',
    },
    {
      name: 'Data Scientist',
      description: 'Data scientists analyze and interpret complex data to inform business decisions.',
      averageSalary: 120000,
      resourceLink: 'https://example.com/data-scientist',
    },
    {
      name: 'Network Administrator',
      description: 'Network administrators manage and maintain an organization\'s network infrastructure.',
      averageSalary: 85000,
      resourceLink: 'https://example.com/network-administrator',
    },
    {
      name: 'Web Developer',
      description: 'Web developers build and maintain websites and web applications.',
      averageSalary: 90000,
      resourceLink: 'https://example.com/web-developer',
    },
    {
      name: 'Database Administrator',
      description: 'Database administrators oversee the management of databases and ensure data security.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/database-administrator',
    },
    {
      name: 'System Administrator',
      description: 'System administrators manage and maintain an organization\'s computer systems and servers.',
      averageSalary: 85000,
      resourceLink: 'https://example.com/system-administrator',
    },
    {
      name: 'DevOps Engineer',
      description: 'DevOps engineers focus on automating and optimizing software development and deployment processes.',
      averageSalary: 110000,
      resourceLink: 'https://example.com/devops-engineer',
    },
    {
      name: 'Cloud Architect',
      description: 'Cloud architects design and manage cloud infrastructure and services.',
      averageSalary: 125000,
      resourceLink: 'https://example.com/cloud-architect',
    },
    {
      name: 'Data Analyst',
      description: 'Data analysts examine data to identify trends, insights, and make data-driven decisions.',
      averageSalary: 85000,
      resourceLink: 'https://example.com/data-analyst',
    },
    {
      name: 'Security Analyst',
      description: 'Security analysts monitor and protect an organization\'s IT infrastructure from security threats.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/security-analyst',
    },
    {
      name: 'Network Engineer',
      description: 'Network engineers design, implement, and manage an organization\'s computer networks.',
      averageSalary: 90000,
      resourceLink: 'https://example.com/network-engineer',
    },
    {
      name: 'Business Analyst',
      description: 'Business analysts analyze business processes and systems to improve efficiency and performance.',
      averageSalary: 90000,
      resourceLink: 'https://example.com/business-analyst',
    },
    {
      name: 'AI/ML Engineer',
      description: 'AI/ML engineers develop machine learning models and AI solutions.',
      averageSalary: 115000,
      resourceLink: 'https://example.com/ai-ml-engineer',
    },
    {
      name: 'Front-End Developer',
      description: 'Front-end developers focus on creating user interfaces for websites and applications.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/front-end-developer',
    },
    {
      name: 'Back-End Developer',
      description: 'Back-end developers work on server-side logic and databases for web applications.',
      averageSalary: 105000,
      resourceLink: 'https://example.com/back-end-developer',
    },
    {
      name: 'UX/UI Designer',
      description: 'UX/UI designers create user experiences and user interfaces for digital products.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/ux-ui-designer',
    },
    {
      name: 'IT Project Manager',
      description: 'IT project managers oversee the planning and execution of IT projects.',
      averageSalary: 110000,
      resourceLink: 'https://example.com/it-project-manager',
    },
    {
      name: 'Cybersecurity Analyst',
      description: 'Cybersecurity analysts protect an organization\'s IT systems from cyber threats and breaches.',
      averageSalary: 100000,
      resourceLink: 'https://example.com/cybersecurity-analyst',
    },
    {
      name: 'Software Quality Assurance Engineer',
      description: 'SQA engineers ensure the quality and reliability of software through testing and validation.',
      averageSalary: 90000,
      resourceLink: 'https://example.com/sqa-engineer',
    },
    {
      name: 'IT Support Specialist',
      description: 'IT support specialists provide technical assistance and support to end-users.',
      averageSalary: 75000,
      resourceLink: 'https://example.com/it-support-specialist',
    },
    {
      name: 'Cloud Administrator',
      description: 'Cloud administrators manage and optimize cloud-based infrastructure and services.',
      averageSalary: 100000,
      resourceLink: 'https://example.com/cloud-administrator',
    },
    {
      name: 'Data Engineer',
      description: 'Data engineers design and implement data pipelines and data storage solutions.',
      averageSalary: 105000,
      resourceLink: 'https://example.com/data-engineer',
    },
    {
      name: 'Mobile App Developer',
      description: 'Mobile app developers create applications for smartphones and tablets.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/mobile-app-developer',
    },
    {
      name: 'IT Auditor',
      description: 'IT auditors evaluate an organization\'s IT systems for compliance and security.',
      averageSalary: 100000,
      resourceLink: 'https://example.com/it-auditor',
    },
    {
      name: 'IT Consultant',
      description: 'IT consultants offer expert advice and solutions to organizations\' IT challenges.',
      averageSalary: 105000,
      resourceLink: 'https://example.com/it-consultant',
    },
    {
      name: 'Machine Learning Engineer',
      description: 'Machine learning engineers develop and deploy machine learning algorithms and models.',
      averageSalary: 115000,
      resourceLink: 'https://example.com/machine-learning-engineer',
    },
    {
      name: 'Business Intelligence Analyst',
      description: 'Business intelligence analysts analyze data to provide insights for business decision-making.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/business-intelligence-analyst',
    },
    {
      name: 'Network Security Engineer',
      description: 'Network security engineers focus on securing an organization\'s network infrastructure.',
      averageSalary: 105000,
      resourceLink: 'https://example.com/network-security-engineer',
    },
    {
      name: 'Full Stack Developer',
      description: 'Full stack developers work on both the front-end and back-end of web applications.',
      averageSalary: 110000,
      resourceLink: 'https://example.com/full-stack-developer',
    },
    {
      name: 'IT Systems Analyst',
      description: 'IT systems analysts assess and improve an organization\'s IT systems and processes.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/it-systems-analyst',
    },
    {
      name: 'Cloud Solutions Architect',
      description: 'Cloud solutions architects design and implement cloud-based solutions.',
      averageSalary: 120000,
      resourceLink: 'https://example.com/cloud-solutions-architect',
    },
    {
      name: 'UI/UX Designer',
      description: 'UI/UX designers create user interfaces and experiences for digital products.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/ui-ux-designer',
    },
    {
      name: 'IT Business Analyst',
      description: 'IT business analysts bridge the gap between business needs and IT solutions.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/it-business-analyst',
    },
    {
      name: 'Blockchain Developer',
      description: 'Blockchain developers build and maintain blockchain-based applications and solutions.',
      averageSalary: 115000,
      resourceLink: 'https://example.com/blockchain-developer',
    },
    {
      name: 'IT Security Manager',
      description: 'IT security managers oversee an organization\'s IT security policies and procedures.',
      averageSalary: 120000,
      resourceLink: 'https://example.com/it-security-manager',
    },
    {
      name: 'Systems Administrator',
      description: 'Systems administrators manage and maintain computer systems and servers.',
      averageSalary: 90000,
      resourceLink: 'https://example.com/systems-administrator',
    },
    {
      name: 'QA Automation Engineer',
      description: 'QA automation engineers design and implement automated testing processes for software.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/qa-automation-engineer',
    },
    {
      name: 'IT Director',
      description: 'IT directors lead and manage an organization\'s IT department and strategy.',
      averageSalary: 140000,
      resourceLink: 'https://example.com/it-director',
    },
    {
      name: 'Solutions Architect',
      description: 'Solutions architects design and implement IT solutions to meet business needs.',
      averageSalary: 130000,
      resourceLink: 'https://example.com/solutions-architect',
    },
    {
      name: 'Database Developer',
      description: 'Database developers design and develop databases and database systems.',
      averageSalary: 100000,
      resourceLink: 'https://example.com/database-developer',
    },
    {
      name: 'Technical Support Engineer',
      description: 'Technical support engineers provide technical assistance to customers and end-users.',
      averageSalary: 80000,
      resourceLink: 'https://example.com/technical-support-engineer',
    },
    {
      name: 'IT Trainer',
      description: 'IT trainers educate employees or users on IT systems and technologies.',
      averageSalary: 85000,
      resourceLink: 'https://example.com/it-trainer',
    },
    {
      name: 'Product Manager',
      description: 'Product managers oversee the development and management of IT products and services.',
      averageSalary: 120000,
      resourceLink: 'https://example.com/product-manager',
    },
    {
      name: 'Business Systems Analyst',
      description: 'Business systems analysts analyze and design IT solutions to meet business needs.',
      averageSalary: 95000,
      resourceLink: 'https://example.com/business-systems-analyst',
    },
    {
      name: 'Network Engineer',
      description: 'Network engineers design, implement, and manage an organization\'s computer networks.',
      averageSalary: 90000,
      resourceLink: 'https://example.com/network-engineer',
    },
  ];

  function ITSALARIES() {
    const [sortedRoles, setSortedRoles] = useState(rolesData);
  
    // Function to sort roles by average salary in descending order
    const sortBySalaryDescending = () => {
      const sorted = [...sortedRoles].sort((a, b) => b.averageSalary - a.averageSalary);
      setSortedRoles(sorted);
    };
  
  return (
    <div className="App1">
      <h1>IT Roles by Average Salary</h1>
      <button onClick={sortBySalaryDescending}>Sort by Salary (Descending)</button>
      <ul>
        {sortedRoles.map((role, index) => (
          <li key={index}>
            <h2>{role.name}</h2>
            <p>{role.description}</p>
            <p>Average Salary: ${role.averageSalary}</p>
           </li>
        ))}
      </ul>
    </div>
  );
}

export default ITSALARIES;