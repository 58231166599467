import React from 'react';
import './Campbell.css';


import CampbellOne from '../../assets/Campbell/1.png';
import CampbellTwo from '../../assets/Campbell/2.png';
import CampbellThree from '../../assets/Campbell/3.png';
import CampbellFive from '../../assets/Campbell/5.png';
import CampbellFour from '../../assets/Campbell/4.jpg';
import CampbellSevenTwo from '../../assets/Campbell/7.jpg'
import CampbellSix from '../../assets/Campbell/6.png'
import CampbellSeven from '../../assets/Campbell/7.png';
import CampbellEight from '../../assets/Campbell/8.png';
import CampbellNine from '../../assets/Campbell/9.png';
import CampbellTen from '../../assets/Campbell/10.png';
import CampbellEleven from '../../assets/Campbell/11.png';
import CampbellTwelve from '../../assets/Campbell/12.png';
import CampbellThirteen from '../../assets/Campbell/13.png';
import CampbellFourteen from '../../assets/Campbell/14.png';
import CampbellFifteen from '../../assets/Campbell/15.png';
import CampbellSixteen from '../../assets/Campbell/16.png';
import CampbellSeventeen from '../../assets/Campbell/17.png';
import CampbellEighteen from '../../assets/Campbell/18.png';




function Campbell() {
  return (
    <div className="campbell-container">
        <div className="campbell-title">
        <h1 className="campbell-title">C<span>a</span>m<span>p</span>b<span>e</span>l<span>l</span></h1>
      </div>
      <div className="campbell-top-date">
       <img className="campbell-border-glow" src={CampbellSeventeen} style={{ width: '690px', height:'42px' }} />
      </div>
      <br />
      <div className="campbell-header">
      <img className="campbell-border-glow" src={CampbellTwo} style={{ width: '690px', height:'69px' }} />     
      </div>
      <br />
      <div className="campbell-bottom-date">
       <img className="campbell-border-glow" src={CampbellEighteen} style={{ width: '690px', height:'42px' }} />
      </div>
      <br />
      <div className="campbell-images">
  <img className="campbell-border-glow" src={CampbellOne} />
  <br />
  <img className="campbell-border-glow" src={CampbellThree} />
  <br />
  <img className="campbell-border-glow" src={CampbellFour} />
  <br />
  <img className="campbell-border-glow" src={CampbellSevenTwo} />
  <br />
  <img className="campbell-border-glow" src={CampbellFive} />
  <br />
    <img
      className="campbell-border-glow"
      src={CampbellSeven}
      style={{ width: '1478px', height: '799px' }}
    />
  <br />
  <img className="campbell-border-glow" src={CampbellEight} />
  <br />
  <img className="campbell-border-glow" src={CampbellNine} />
  <br />
  <img className="campbell-border-glow" src={CampbellTen} />
  <br />
  <img className="campbell-border-glow" src={CampbellEleven} />
  <br />
  <img className="campbell-border-glow" src={CampbellTwelve} />
  <br />
  <img className="campbell-border-glow" src={CampbellThirteen} />
  <br />
  <img className="campbell-border-glow" src={CampbellFourteen} />
  <br />
  <img className="campbell-border-glow" src={CampbellFifteen} />
  <br />
  <img className="campbell-border-glow" src={CampbellSixteen} />
</div>
      <div className="campbell-content">
        <p>Pleb,</p>
        <br />
        <p>thanks for always being available to give insight on the projects I have
        and sharing with me the projects you had, and wanted to make</p>
        <br />
        <p>thanks for giving me a ride within minutes when I asked</p>
        <br />
        <p>thanks for making magic on discord servers so that the 
        community had something to come together for and 
        the experience was something that kept them coming back</p>
        <br />
        <p>thanks for being a calm tone when I was hype and bringing
        things down when I was over analytical</p>
        <br />
        <p>thanks for a wicked sense of humor</p>
        <br />
        <p>thanks for simplifying things</p>
        <br />
        <p>well done on the projects that people really enjoyed</p>
        <br />
        <p>I'll continue and keep you in mind</p>
        <br />
        <p>yeah I can't believe this right now
        and will have to find a new route of someone to look
        forward to sharing analysis and programming with
        I'll start helping more people in your honor
        so that maybe they can be better than us
        maybe they can be examples of your effort</p>
        <br />
        <p>I'll see ya after a while and you'll drink a coke
        and I'll drink a beer and we'll reflect, build and
        imagine, again</p>
        <br />
        <p>fuck man</p>
        <br />
        <p>A lot of adrenaline,</p>

        <p>Love</p>
      </div>
        <img
        className="campbell-clock"
            src={CampbellSix}
            style={{ width: '69%', height: '69%', borderRadius: '50%' }}
        />
    </div>
  );
}

export default Campbell;