import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../CSS/IOCSearchPage.css'; // Create a CSS file for styling

const CloudCLISearchPage = () => {
  const [iocResults, setIocResults] = useState([]);
  const [filteredIocResults, setFilteredIocResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 1000;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedqueryType, setSelectedqueryType] = useState('');

  useEffect(() => {
    // Fetch IOC results from an API endpoint
    const fetchIocResults = async () => {
      try {
        const response = await axios.get(`/api/cloudcli-search?page=${currentPage}`);
        setIocResults(response.data.results);
      } catch (error) {
        console.error('Error fetching IOC results:', error);
      }
    };

    fetchIocResults();
  }, [currentPage]);

  useEffect(() => {
    // Filter IOC results based on search term and selected IOC type
    const filteredResults = iocResults.filter((result) => {
      const searchString = `${result.username} ${result.queryType} ${result.ioc} ${result.description}`;
      const includesSearchTerm = searchString.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesqueryType = selectedqueryType ? result.queryType === selectedqueryType : true;
      return includesSearchTerm && matchesqueryType;
    });

    setFilteredIocResults(filteredResults);
  }, [searchTerm, selectedqueryType, iocResults]);

  const handleLoadRecent = () => {
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
  
    const recentResults = iocResults.filter((result) => {
      const resultDate = new Date(result.date);
      return resultDate.getTime() >= thirtyDaysAgo.getTime();
    });
  
    setFilteredIocResults(recentResults);
  };

  const handleLoadByqueryType = (queryType) => {
    setSelectedqueryType(queryType);
  };

  return (
    <div className="ioc-search-container">
      <h2>Cloud Commands Search</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="button-group">
        <div className="ioc-type-buttons">
          {Array.from(new Set(iocResults.map((result) => result.queryType))).map((queryType) => (
            <button
              key={queryType}
              className={`load-button ${selectedqueryType === queryType ? 'selected' : ''}`}
              onClick={() => handleLoadByqueryType(queryType)}
            >
              Load {queryType} Commands
            </button>
          ))}
        </div>
      </div>
      <div className="ioc-results">
  {filteredIocResults.map((result) => (
    <div key={result.id} className="ioc-result">
    <div className="ioc-header">
      <span className="username">{result.username}</span>
      <span className="date">{result.currentDate}</span>
    </div>
    <div className="ioc-details">
      <span className="ioc-type">{result.queryType}</span>
      <br />
      <div className="ioc-content">
        <pre className="ioc-scrollable">
        <strong>Command:</strong><br />
          <code>{result.query}</code>
        </pre>
      </div>
    </div>
    <div className="ioc-description">
  <pre className="ioc-scrollable">
  <strong>Description:</strong><br />
    {result.description}
  </pre>
</div>
  </div>
  ))}
</div>
    </div>
  );
};

export default CloudCLISearchPage; 