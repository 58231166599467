import React from 'react';
import './chatGPTPage.css';

function ChatGPTPage() {
  return (
    <div className="chatgpt-container">
      <h1 className="chatgpt-heading">ChatGPT - Your Conversational AI Assistant</h1>
      <p className="chatgpt-description">
        ChatGPT is a cutting-edge conversational AI powered by OpenAI's GPT-3.5 model.
        It's designed to assist you with a wide range of tasks and provide natural language interactions.
      </p>

      <h2 className="chatgpt-subheading">Key Features:</h2>
      <ul className="chatgpt-list">
        <li>Answer questions on various topics</li>
        <li>Generate human-like text</li>
        <li>Provide programming code snippets</li>
        <li>Translate languages</li>
        <li>And much more!</li>
      </ul>

      <h2 className="chatgpt-subheading">Use Cases:</h2>
      <p className="chatgpt-description">
        ChatGPT can be used for:
      </p>
      <ul className="chatgpt-list">
        <li>Research and information retrieval</li>
        <li>Content creation and writing assistance</li>
        <li>Learning new topics</li>
        <li>Prototyping chatbots</li>
        <li>And more creative applications!</li>
      </ul>

      <h2 className="chatgpt-subheading">Learn More:</h2>
      <p className="chatgpt-description">
        Ready to explore ChatGPT's capabilities in depth?
        Visit the official <a className="chatgpt-link" href="https://openai.com/chatgpt" target="_blank" rel="noopener noreferrer">ChatGPT website</a> for detailed information and documentation.
      </p>
    </div>
  );
}

export default ChatGPTPage;