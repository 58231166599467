import React from 'react';

const HackTheBoxInfo = () => {
    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        color: 'black', // Default text color
      };
    
      // Adjust text color for dark mode
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        containerStyle.color = 'white'; // Set text color for dark mode
      }
    
      const headingStyle = {
        color: '#333',
      };
    
      const subheadingStyle = {
        color: '#555',
      };
    
      const linkStyle = {
        color: '#007bff',
        textDecoration: 'none',
      };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Hack The Box (HTB)</h1>
      <p>
        Hack The Box is an online platform that provides a playground for
        penetration testers and ethical hackers to test their skills and
        knowledge. It offers a wide range of challenges and virtual labs.
      </p>
      <h2 style={subheadingStyle}>Key Features:</h2>
      <ul>
        <li>Active community and forums</li>
        <li>Challenging CTF-style machines</li>
        <li>Networking and security labs</li>
        <li>Learning resources and tutorials</li>
      </ul>
      <p>
        For more details about Hack The Box and to start your journey, please
        visit the official website:
        <a
          href="https://www.hackthebox.eu/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Hack The Box Official Page
        </a>
      </p>
    </div>
  );
};

export default HackTheBoxInfo;