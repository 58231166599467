import { createMuiTheme } from '@material-ui/core/styles';

const customTheme = (darkMode) =>
  createMuiTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#000000' : '#000000', // Invert primary colors
      },
      secondary: {
        main: darkMode ? '#000000' : '#000000', // Invert secondary colors
      },
    },
    overrides: {
      MuiTypography: {
        root: {
          wordBreak: 'break-word',
        },
      },
    },
  });

export default customTheme;
//'#ffb28a'  orange