import React from 'react';

const IframeMitreAttackGroups = () => {
  const iframeContainerStyle = {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%', // 16:9 aspect ratio (adjust as needed)
  };

  const iframeStyle = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    border: 'none', // Remove iframe border
  };

  return (
    <div style={iframeContainerStyle}>
      <iframe
        title="MITRE ATT&CK Groups"
        src="https://attack.mitre.org/groups/"
        style={iframeStyle}
        allowFullScreen
      />
    </div>
  );
};

export default IframeMitreAttackGroups;