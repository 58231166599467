import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const BlueTeamLinkedInPromoBanner = () => {
  const handleButtonClick = () => {
    // Open a LinkedIn community link in a new tab/window
    window.open('https://www.linkedin.com/groups/123456/', '_blank'); // Replace with an actual LinkedIn group link
  };

  const linkedInCommunities = [
    {
      title: 'Blue Teamers',
      link: 'https://www.linkedin.com/groups/123456/', // Replace with an actual LinkedIn group link
    },
    {
      title: 'Cybersecurity Defense Professionals',
      link: 'https://www.linkedin.com/groups/123456/', // Replace with an actual LinkedIn group link
    },
    // Add more LinkedIn communities here
  ];

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Connect with Blue Teamers on LinkedIn!</h2>
          <p>Join LinkedIn communities for blue team cybersecurity professionals to network, share insights, and learn.</p>
          <button onClick={handleButtonClick}>Join Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose LinkedIn Communities?</h3>
        <ul>
          <li>Connect with professionals who focus on blue team cybersecurity.</li>
          <li>Participate in discussions, share resources, and ask questions.</li>
          <li>Stay updated on industry trends, challenges, and solutions.</li>
          <li>Expand your network and learn from experienced practitioners.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>LinkedIn Communities for Blue Team</h3>
        <ul>
          {linkedInCommunities.map((community, index) => (
            <li key={index}>
              <a href={community.link} target="_blank" rel="noopener noreferrer">
                {community.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Community Members Say</h3>
        <div className="testimonial">
          <p>"Joining LinkedIn communities has been a game-changer for my blue team career. I've gained insights and connections."</p>
          <p>- John Smith, Blue Team Enthusiast</p>
        </div>
        <div className="testimonial">
          <p>"Networking on LinkedIn communities has opened doors to opportunities I wouldn't have discovered otherwise."</p>
          <p>- Jane Doe, Cybersecurity Professional</p>
        </div>
      </div>
    </div>
  );
};

export default BlueTeamLinkedInPromoBanner;