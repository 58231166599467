import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const CarbonBlackPromoBanner = () => {
  const handleButtonClick = () => {
    // Open Carbon Black website link in a new tab/window
    window.open('https://www.carbonblack.com/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Secure with Carbon Black Endpoint Security!</h2>
          <p>Protect your endpoints and detect threats with advanced security solutions from Carbon Black.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Carbon Black?</h3>
        <ul>
          <li>Defend against malware, ransomware, and advanced threats.</li>
          <li>Secure endpoints with real-time threat hunting and incident response capabilities.</li>
          <li>Implement robust endpoint detection and response (EDR) strategies.</li>
          <li>Enhance security posture and reduce risk in a dynamic threat landscape.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Security Professionals Say</h3>
        <div className="testimonial">
          <p>"Carbon Black's solutions provide an extra layer of protection and visibility that is critical for our organization."</p>
          <p>- John Smith, Security Manager</p>
        </div>
        <div className="testimonial">
          <p>"The endpoint security and threat intelligence provided by Carbon Black are essential components of our cybersecurity strategy."</p>
          <p>- Jane Doe, Cybersecurity Analyst</p>
        </div>
      </div>
    </div>
  );
};

export default CarbonBlackPromoBanner;