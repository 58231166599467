import React from 'react';

const TreehouseInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#4CAF50', // Treehouse brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore Technology with Treehouse</h1>
      <p>
        Treehouse is an online technology education platform that offers
        high-quality courses in web development, design, and coding. Whether
        you're a beginner or looking to advance your skills, Treehouse provides
        the tools you need to succeed in tech.
      </p>
      <h2 style={subheadingStyle}>What Treehouse Offers:</h2>
      <ul>
        <li>Interactive coding lessons and projects</li>
        <li>Expert-led instruction and quizzes</li>
        <li>Learning tracks and career-focused content</li>
        <li>Access to a supportive community</li>
      </ul>
      <p>
        Start your tech journey with Treehouse:
        <a
          href="https://teamtreehouse.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Treehouse Website
        </a>
      </p>
    </div>
  );
};

export default TreehouseInfo;