import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

function FollowButton() {
  const [isFollowing, setIsFollowing] = useState(false);
  const userIdToFollow = localStorage.getItem('readifyUserKey');

  useEffect(() => {
    if (userIdToFollow) {
      // Fetch the user's follow status when the component mounts
      checkFollowStatus();
    }
  }, [userIdToFollow]);

  const checkFollowStatus = async () => {
    if (!userIdToFollow) {
      return;
    }
    
    // You can make a GET request to your backend to check if the user is already following
    try {
      const response = await fetch(`/api/check-follow/${userIdToFollow}`);
      if (response.ok) {
        const data = await response.json();
        setIsFollowing(data.isFollowing);
      } else {
        console.error('Failed to fetch follow status');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const followUser = async () => {
    try {
      // Send a POST request to follow the user
      const response = await fetch(`/api/follow/${userIdToFollow}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setIsFollowing(true);
      } else {
        console.error('Failed to follow user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const unfollowUser = async () => {
    try {
      // Send a DELETE request to unfollow the user
      const response = await fetch(`/api/unfollow/${userIdToFollow}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setIsFollowing(false);
      } else {
        console.error('Failed to unfollow user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (!userIdToFollow) {
    return null; // Render nothing if the user identifier is not available
  }

  return (
    <button
      onClick={isFollowing ? unfollowUser : followUser}
      className={`follow-button ${isFollowing ? 'following' : 'not-following'}`}
    >
      {isFollowing ? 'Unfollow' : 'Follow'}
    </button>
  );
}

export default FollowButton;