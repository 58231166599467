import React from 'react';

const TalosInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'black',
  };

  const headingStyle = {
    color: '#333',
    fontSize: '24px',
    marginBottom: '20px',
  };

  const subheadingStyle = {
    color: '#555',
    fontSize: '18px',
    marginTop: '20px',
  };

  const linkStyle = {
    color: '#007bff',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <header>
        <h1 style={headingStyle}>Talos Threat Intelligence</h1>
      </header>
      <section>
        <p>
          Talos, powered by Cisco, is a leading threat intelligence organization that provides comprehensive security solutions and research to protect organizations from cyber threats.
        </p>
        <h2 style={subheadingStyle}>Key Features:</h2>
        <ul>
          <li>Advanced threat research</li>
          <li>Malware analysis</li>
          <li>Real-time threat intelligence feeds</li>
          <li>Security technologies and services</li>
          <li>Incident response guidance</li>
          <li>Open-source security tools</li>
        </ul>
        <p>
          For more information and to explore Talos threat intelligence, please visit their official website:
          <a
            href="https://www.talosintelligence.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={linkStyle}
          >
            Talos Threat Intelligence
          </a>
        </p>
      </section>
    </div>
  );
};

export default TalosInfo;