import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const ElasticPromoBanner = () => {
  const handleButtonClick = () => {
    // Open Elastic's official website link in a new tab/window
    window.open('https://www.elastic.co/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Unlock Insights with Elastic Stack!</h2>
          <p>Empower your organization with the power of the Elastic Stack for search, observability, and security analytics.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Elastic?</h3>
        <ul>
          <li>Utilize the Elastic Stack for search, observability, and security analytics.</li>
          <li>Aggregate and analyze data from various sources.</li>
          <li>Gain insights into your data with powerful search capabilities.</li>
          <li>Enhance security and monitor your infrastructure.</li>
        </ul>
      </div>
      <div className="coursera-documentation">
        <h3>Learn More with Elastic Documentation</h3>
        <ul>
          <li>
            <a href="https://www.elastic.co/guide/en/elasticsearch/reference/index.html" target="_blank" rel="noopener noreferrer">
              Elasticsearch Documentation
            </a>
          </li>
          <li>
            <a href="https://www.elastic.co/guide/en/kibana/current/index.html" target="_blank" rel="noopener noreferrer">
              Kibana Documentation
            </a>
          </li>
          <li>
            <a href="https://www.elastic.co/guide/en/logstash/current/index.html" target="_blank" rel="noopener noreferrer">
              Logstash Documentation
            </a>
          </li>
          <li>
            <a href="https://www.elastic.co/guide/en/beats/libbeat/current/index.html" target="_blank" rel="noopener noreferrer">
              Beats Documentation
            </a>
          </li>
          {/* Add more Elastic product documentation links here */}
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Users Say</h3>
        <div className="testimonial">
          <p>"Elastic has transformed the way we handle data, search, and security analytics. It's an indispensable platform."</p>
          <p>- John Smith, Data Engineer</p>
        </div>
        <div className="testimonial">
          <p>"Implementing Elastic Stack has allowed us to uncover insights from our data that we never thought possible."</p>
          <p>- Jane Doe, Security Analyst</p>
        </div>
      </div>
    </div>
  );
};

export default ElasticPromoBanner;