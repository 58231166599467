import React from 'react';

const MetasploitInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'black', // Default text color
  };

  // Adjust text color for dark mode
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    containerStyle.color = 'white'; // Set text color for dark mode
  }

  const headingStyle = {
    color: '#333',
  };

  const subheadingStyle = {
    color: '#555',
  };

  const linkStyle = {
    color: '#007bff',
    textDecoration: 'none',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Metasploit Framework</h1>
      <p>
        The Metasploit Framework is a powerful open-source penetration testing
        tool developed by Rapid7. It is widely used by security professionals
        and penetration testers for discovering and exploiting vulnerabilities.
      </p>
      <h2 style={subheadingStyle}>Features:</h2>
      <ul>
        <li>Exploitation of known vulnerabilities</li>
        <li>Post-exploitation modules</li>
        <li>Payload generation</li>
        <li>Community-contributed modules</li>
      </ul>
      <p>
        For more information and to download Metasploit, please visit the
        official website:
        <a
          href="https://www.metasploit.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Metasploit Official Page
        </a>
      </p>
    </div>
  );
};

export default MetasploitInfo;