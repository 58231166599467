import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../CSS/CybersecurityIOCForm.css'; // Create a CSS file for additional styling

const CybersecurityIOCForm = () => {
  const [username, setUsername] = useState('');
  const [iocType, setIOCType] = useState('');
  const [iocValue, setIOCValue] = useState('');
  const [description, setDescription] = useState('');
  const [validIOC, setValidIOC] = useState(true);
  const [currentDate, setCurrentDate] = useState('');
  const iocTypesList = [
    'File-Based Indicator',
    'Network-Based Indicator',
    'Behavioral Indicator',
    'Artifact-Based Indicator'
  ];
  
  useEffect(() => {
    const storedUser = localStorage.getItem('readifyUserKey');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUsername(parsedUser.username);
    }
  }, []);

  useEffect(() => {
    const updateCurrentDate = () => {
      const date = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      };
      const formattedDate = date.toLocaleString('en-US', options).replace(',', '');
      setCurrentDate(formattedDate);
    };
  
    updateCurrentDate();
  
    // Update the current date every minute
    const interval = setInterval(updateCurrentDate, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validIOC) {
      alert('Invalid IOC format.');
      return;
    }

    if (description.length > 1000000) {
      alert('Description exceeds the maximum limit of 500 characters.');
      return;
    }

    try {
      const response = await axios.post('/api/submit-ioc', {
        username,
        iocType,
        iocValue,
        description,
        currentDate,
      });

      if (response.status === 201) {
        alert('Well Done Defender! IOC Successfully Added');
      } else {
        alert('Error submitting IOC.');
      }
    } catch (error) {
      console.error('Error submitting IOC:', error);
    }

    // Reset the form
    setIOCType('');
    setIOCValue('');
    setDescription('');
    setValidIOC(true);
  };

  const handleIOCValueChange = (e) => {
    const { value } = e.target;
    setIOCValue(value);
    // You can implement validation logic here to check the format of the IOC value
    // For example, check if it's a valid IP address, domain name, or hash value
    // Update the validIOC state accordingly
  };

  return (
    <div className="ioc-form-container">
      <form onSubmit={handleSubmit} className="form">
        <h2 className="form-title">Submit IOC</h2>
        <div className="form-field">
          <label htmlFor="iocType">IOC Type:</label>
          <select
            id="iocType"
            value={iocType}
            onChange={(e) => setIOCType(e.target.value)}
            required
          >
            <option value="">Select an IOC type</option>
            {iocTypesList.map((iocTypeOption, index) => (
              <option key={index} value={iocTypeOption}>
                {iocTypeOption}
              </option>
            ))}
          </select>
        </div>

        <div className="form-field">
          <label htmlFor="iocValue">IOC Value:</label>
          <input
            type="text"
            id="iocValue"
            value={iocValue}
            onChange={handleIOCValueChange}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <button className="submit-button" type="submit">
            Submit IOC
          </button>
        </div>
      </form>
    </div>
  );
};

export default CybersecurityIOCForm;