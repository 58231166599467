import React from 'react';
import './yeah.css';

const dataResourcesData = [
    {
      name: 'Kaggle',
      description: 'Kaggle is a platform for data science competitions and datasets. It offers a wide range of datasets, notebooks, and machine learning challenges.',
      link: 'https://www.kaggle.com/',
    },
    {
      name: 'UCI Machine Learning Repository',
      description: 'UCI Machine Learning Repository is a collection of datasets maintained by the University of California, Irvine. It covers various domains and is widely used in machine learning research.',
      link: 'https://archive.ics.uci.edu/ml/index.php',
    },
    {
      name: 'Data.gov',
      description: 'Data.gov is the U.S. government\'s open data portal. It provides access to a vast amount of government datasets covering topics like demographics, health, and more.',
      link: 'https://www.data.gov/',
    },
    {
      name: 'World Bank Data',
      description: 'The World Bank provides a wealth of global economic, financial, and development data. It includes information on GDP, poverty, education, and more.',
      link: 'https://data.worldbank.org/',
    },
    {
      name: 'UN Data',
      description: 'UN Data offers access to a wide range of United Nations statistical databases. It covers global demographics, economic indicators, and more.',
      link: 'http://data.un.org/',
    },
    {
      name: 'Google Dataset Search',
      description: 'Google Dataset Search is a search engine for discovering datasets across the web. It helps researchers find relevant datasets for their projects.',
      link: 'https://datasetsearch.research.google.com/',
    },
    {
      name: 'NOAA National Centers for Environmental Information',
      description: 'NOAA NCEI provides environmental data, including climate, oceanography, and geophysics data. Its a valuable resource for climate researchers and meteorologists.',
      link: 'https://www.ncei.noaa.gov/',
    },
    {
      name: 'U.S. Census Bureau',
      description: 'The U.S. Census Bureau offers demographic and economic data about the United States. It includes population, housing, and business data.',
      link: 'https://www.census.gov/data.html',
    },
    {
      name: 'IMF Data',
      description: 'The International Monetary Fund (IMF) provides economic and financial data from around the world. It includes exchange rates, fiscal data, and more.',
      link: 'https://data.imf.org/',
    },
    {
      name: 'Gapminder',
      description: 'Gapminder offers a variety of global datasets related to development, health, and income. Its known for its interactive data visualization tools.',
      link: 'https://www.gapminder.org/data/',
    },
    {
      name: 'European Union Open Data Portal',
      description: 'The EU Open Data Portal provides access to datasets from the European Union. It covers topics such as agriculture, environment, and transportation.',
      link: 'https://data.europa.eu/euodp/en/home',
    },
    {
      name: 'Data Science Central',
      description: 'Data Science Central is a community for data science professionals. It offers articles, webinars, and datasets for data analysis and machine learning.',
      link: 'https://www.datasciencecentral.com/',
    },
    {
      name: 'Amazon Web Services (AWS) Public Datasets',
      description: 'AWS Public Datasets provide access to various datasets hosted on Amazon Web Services. They cover a wide range of topics and are suitable for cloud-based analysis.',
      link: 'https://registry.opendata.aws/',
    },
    {
      name: 'Quandl',
      description: 'Quandl is a platform that offers financial, economic, and alternative data. Its popular among financial analysts and quantitative researchers.',
      link: 'https://www.quandl.com/',
    },
    {
      name: 'DataHub',
      description: 'DataHub is a platform for sharing and publishing datasets. It includes datasets on various topics contributed by the community.',
      link: 'https://datahub.io/',
    },
    {
      name: 'GitHub Datasets',
      description: 'GitHub hosts a collection of interesting datasets shared by the community. Its a good place to find datasets related to open-source projects and research.',
      link: 'https://github.com/datasets',
    },
    {
      name: 'UFO Sightings Data',
      description: 'This dataset contains information about reported UFO sightings worldwide. Its often used for data analysis and visualization projects.',
      link: 'https://github.com/planetsig/ufo-reports',
    },
    {
      name: 'FiveThirtyEight Datasets',
      description: 'FiveThirtyEight offers a collection of datasets related to politics, sports, and more. Its a valuable resource for data-driven journalism and analysis.',
      link: 'https://data.fivethirtyeight.com/',
    },
    {
      name: 'Bureau of Labor Statistics (BLS) Data',
      description: 'The BLS provides economic and labor-related data for the United States. It includes information on employment, wages, and inflation.',
      link: 'https://www.bls.gov/data/',
    },
    {
      name: 'Stanford Large Network Dataset Collection (SNAP)',
      description: 'SNAP offers datasets related to social networks, graphs, and network analysis. Its valuable for research in network science and social network analysis.',
      link: 'https://snap.stanford.edu/data/',
    },
    {
      name: 'National Center for Biotechnology Information (NCBI) Databases',
      description: 'NCBI provides a wide range of biological and genetic datasets, including DNA sequences, medical literature, and protein structures.',
      link: 'https://www.ncbi.nlm.nih.gov/databases/',
    },
    {
      name: 'Federal Reserve Economic Data (FRED)',
      description: 'FRED offers economic data from the Federal Reserve, including indicators like GDP, inflation, and interest rates.',
      link: 'https://fred.stlouisfed.org/',
    },
    {
      name: 'NASA Open Data',
      description: 'NASA provides access to various space and science datasets. It includes information on planets, satellites, and space missions.',
      link: 'https://data.nasa.gov/',
    },
    {
      name: 'Open Data Network',
      description: 'Open Data Network aggregates datasets from cities and municipalities. It covers topics such as transportation, crime, and public health.',
      link: 'https://www.opendatanetwork.com/',
    },
    {
      name: 'U.S. Federal Data Catalog',
      description: 'The U.S. Federal Data Catalog provides access to datasets from various federal agencies. It includes data on education, energy, and more.',
      link: 'https://catalog.data.gov/dataset',
    },
    {
      name: 'Bureau of Economic Analysis (BEA) Data',
      description: 'The BEA offers economic data related to U.S. industries, trade, and GDP. Its useful for economic analysis and research.',
      link: 'https://www.bea.gov/data',
    },
    {
      name: 'European Social Survey (ESS) Data',
      description: 'The ESS provides data on social attitudes and behaviors in Europe. Its valuable for social science research and surveys.',
      link: 'https://www.europeansocialsurvey.org/data/',
    },
    {
      name: 'National Oceanic and Atmospheric Administration (NOAA) Data',
      description: 'NOAA provides environmental and weather-related data, including weather forecasts, ocean data, and climate information.',
      link: 'https://www.noaa.gov/data',
    },
    {
      name: 'Google Public Data Explorer',
      description: 'Google Public Data Explorer offers access to a variety of public datasets. It includes data from the World Bank, OECD, and other sources.',
      link: 'https://www.google.com/publicdata/directory',
    },
    {
      name: 'Zillow Research Data',
      description: 'Zillow Research provides real estate and housing-related data. Its useful for housing market analysis and trends.',
      link: 'https://www.zillow.com/research/data/',
    },
    {
      name: 'National Bureau of Economic Research (NBER) Data',
      description: 'NBER offers economic data and research papers. Its a valuable resource for economic and policy analysis.',
      link: 'https://www.nber.org/data/',
    },
    {
      name: 'Stanford Universitys WebBase Project',
      description: 'The WebBase Project offers web graphs and page information datasets. Its valuable for web search and graph analysis research.',
      link: 'http://web.stanford.edu/class/cs224w/data.html',
    },
  ];


  function DataResources() {
    return (
      <div className="data-resources">
        <h1>Data Resources</h1>
        <div className="resource-list">
          {dataResourcesData.map((resource, index) => (
            <div className="resource-card" key={index}>
              <h2>{resource.name}</h2>
              <p>{resource.description}</p>
              <a href={resource.link} target="_blank" rel="noopener noreferrer" className="resource-button">
                Explore
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  export default DataResources;