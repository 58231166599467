import React from 'react';

const ProxmoxInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#4D4D4D', // Proxmox brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const buttonStyle = {
    backgroundColor: '#232323',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Discover Proxmox Virtual Environment (Proxmox VE)</h1>
      <p>
        Proxmox VE is a powerful open-source server virtualization platform that combines two virtualization technologies: KVM (Kernel-based Virtual Machine) for virtual machines and LXC (Linux Containers) for lightweight container-based virtualization. It offers a high-performance, cost-effective solution for managing virtualized environments.
      </p>
      <h2 style={subheadingStyle}>Key Features of Proxmox VE:</h2>
      <ul>
        <li>Virtual machines and containers in one platform</li>
        <li>Web-based management interface (Proxmox GUI)</li>
        <li>High availability clustering</li>
        <li>Backup and restore capabilities</li>
        <li>Integration with popular storage solutions</li>
        <li>Active and growing community</li>
      </ul>
      <button onClick={() => window.open("https://www.proxmox.com/proxmox-ve", "_blank")} style={buttonStyle}>Explore Proxmox VE</button>
    </div>
  );
};

export default ProxmoxInfo;