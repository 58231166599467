import React from 'react';

function GTFOBinsIframe() {
  return (
    <div>
      <iframe
        title="GTFOBins | Your Linux PrivESC"
        src="https://gtfobins.github.io/"
        width="100%"
        height="777"
        frameBorder="0"
      ></iframe>
    </div>
  );
}

export default GTFOBinsIframe;