import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './commendisplay.css'; // Import your CSS file for styling

const CommendationDisplay = () => {
  const [commendations, setCommendations] = useState([]);

  useEffect(() => {
    // Fetch recent commendations from your API and update the commendations state
    const fetchRecentCommendations = async () => {
      try {
        const response = await axios.get('/api/get-recent-commendations');
        setCommendations(response.data); // Assuming the response contains an array of commendations
      } catch (error) {
        console.error('Error fetching recent commendations:', error);
      }
    };

    fetchRecentCommendations();
  }, []);

  return (
    <div className="commendation-display-container">
      <h2>Recent Commendations</h2>
      <ul className="commendation-list">
        {commendations.map((commendation) => (
          <li key={commendation.id} className="commendation-item">
            <span className="commendation-sender">{commendation.sender} To: {commendation.receiver}:</span>
            <span className="commendation-text">{commendation.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CommendationDisplay;