import React from 'react';

const BlackHillsSecurityInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  };

  const headingStyle = {
    color: '#ff9900',
    fontSize: '32px',
    marginBottom: '20px',
  };

  const listStyle = {
    fontSize: '18px',
    lineHeight: '1.6',
  };

  const linkStyle = {
    color: '#ff9900',
    textDecoration: 'none',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Discover Black Hills Information Security</h1>
      <p>
        Black Hills Information Security (BHIS) is a trusted cybersecurity
        consulting firm known for its expertise in penetration testing,
        security assessments, and training services. They are dedicated to
        enhancing the security posture of organizations.
      </p>
      <h2>Services and Expertise:</h2>
      <ul style={listStyle}>
        <li>
          <strong>Penetration Testing:</strong> Comprehensive testing to uncover
          vulnerabilities.
        </li>
        <li>
          <strong>Red Team Assessments:</strong> Realistic attacks to evaluate
          defenses.
        </li>
        <li>
          <strong>Incident Response:</strong> Rapid response and recovery
          services.
        </li>
        <li>
          <strong>Training and Workshops:</strong> Educational programs to
          empower security professionals.
        </li>
        <li>
          <strong>Blog and Podcast:</strong> Valuable resources for staying
          updated.
        </li>
      </ul>
      <p>
        Whether you need to assess your security, train your team, or respond to
        incidents, BHIS provides top-tier solutions.
      </p>
      <p>
        Explore their services and resources on the official Black Hills
        Information Security website:
        <a
          href="https://www.blackhillsinfosec.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          BHIS Official Website
        </a>
      </p>
    </div>
  );
};

export default BlackHillsSecurityInfo;