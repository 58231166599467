import React, { useEffect, useState } from 'react';

const RSSFeedsHomeRight = () => {
  const [feeds, setFeeds] = useState([]);

  useEffect(() => {
    const fetchFeeds = async () => {
      try {
        const response = await fetch('/api/somany'); // Update the URL to match your server endpoint
        if (response.ok) {
          const fetchedFeeds = await response.json();
          const sortedFeeds = [fetchedFeeds, ...feeds].sort((a, b) => {
            // Sort feeds based on the pubDate in descending order
            const dateA = new Date(a.pubDate);
            const dateB = new Date(b.pubDate);
            return dateB - dateA;
          });
          setFeeds(sortedFeeds);
        } else {
          console.error('Error fetching feeds:', response.status);
        }
      } catch (error) {
        console.error('Error fetching feeds:', error);
      }
    };

    fetchFeeds();

    // Refresh the feeds every 5 minutes (adjust the interval as needed)
    const interval = setInterval(fetchFeeds, 5 * 60 * 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="app">
      <div className="feeds-container">
        {feeds.map((feed, index) => (
          <div key={index} className="rss-feed-item">
            <h3>{feed.title}</h3>
            <ul>
              {feed.items.map((item) => (
                <li key={item.link}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
                    {item.title}
                  </a>
                  {item.contentSnippet && <p>{item.contentSnippet}</p>}
                  {item.enclosure && <img src={item.enclosure.url} alt="Preview" />}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RSSFeedsHomeRight;