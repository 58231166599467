import React from 'react';

const KaliLinuxInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  };

  return (
    <div style={containerStyle}>
      <h1>Welcome to Kali Linux</h1>
      <p>
        Kali Linux is a powerful and versatile penetration testing and
        cybersecurity toolkit. It's the go-to operating system for security
        professionals, ethical hackers, and enthusiasts.
      </p>
      <h2>Key Features:</h2>
      <ul>
        <li>Wide range of pre-installed security tools</li>
        <li>Regularly updated with the latest exploits and vulnerabilities</li>
        <li>Customizable and extensible for specific security tasks</li>
        <li>Community-driven with a strong support network</li>
      </ul>
      <p>
        Whether you are a beginner or an experienced security expert, Kali Linux
        provides the tools and resources you need to test, secure, and improve
        systems and networks.
      </p>
      <p>
        To get started, download Kali Linux from the official website and explore
        the vast world of cybersecurity:
        <a
          href="https://www.kali.org/downloads/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Kali Linux
        </a>
      </p>
    </div>
  );
};

export default KaliLinuxInfo;