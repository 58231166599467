import React from 'react';

const AzureCloudCertifications = () => {
  const azureCertifications = [
    {
      name: 'Microsoft Certified: Azure Fundamentals',
      description: 'Designed for individuals who are new to Azure. It covers basic cloud concepts and Azure services.',
      link: 'https://learn.microsoft.com/en-us/certifications/azure-fundamentals',
    },
    {
      name: 'Microsoft Certified: Azure Administrator Associate',
      description: 'For Azure administrators who manage cloud services. It covers implementing, monitoring, and maintaining Azure solutions.',
      link: 'https://learn.microsoft.com/en-us/certifications/azure-administrator',
    },
    {
      name: 'Microsoft Certified: Azure Developer Associate',
      description: 'For developers who build cloud applications. It covers Azure development, APIs, and Azure identity.',
      link: 'https://learn.microsoft.com/en-us/certifications/azure-developer',
    },
    {
      name: 'Microsoft Certified: Azure Solutions Architect Expert',
      description: 'Designed for experienced solutions architects. It covers designing solutions on Azure, including security and governance.',
      link: 'https://learn.microsoft.com/en-us/certifications/azure-solutions-architect',
    },
    {
      name: 'Microsoft Certified: Azure DevOps Engineer Expert',
      description: 'For DevOps engineers who design and implement DevOps practices. It covers Azure DevOps services and automation.',
      link: 'https://learn.microsoft.com/en-us/certifications/azure-devops',
    },
    {
      name: 'Microsoft Certified: Azure Security Engineer Associate',
      description: 'For security professionals who implement security controls and threat protection on Azure. It covers security best practices.',
      link: 'https://learn.microsoft.com/en-us/certifications/azure-security-engineer',
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <header>
        <h1 className="title">Azure Cloud Certifications</h1>
      </header>
      <section className="certifications">
        {azureCertifications.map((certification, index) => (
          <div key={index} className="certification">
            <h2 className="certification-name">{certification.name}</h2>
            <p className="certification-description">{certification.description}</p>
            <p className="certification-link">
              <a
                href={certification.link}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Learn more
              </a>
            </p>
          </div>
        ))}
      </section>
    </div>
  );
};

export default AzureCloudCertifications;