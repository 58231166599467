import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const CiscoStealthwatchPromoBanner = () => {
  const handleButtonClick = () => {
    // Open Cisco Stealthwatch website link in a new tab/window
    window.open('https://www.cisco.com/c/en/us/products/security/stealthwatch/index.html', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Enhance Visibility with Cisco Stealthwatch!</h2>
          <p>Gain insights into your network traffic and detect threats with Cisco's advanced network visibility and security solution.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Cisco Stealthwatch?</h3>
        <ul>
          <li>Monitor network traffic and detect anomalous behaviors and threats.</li>
          <li>Identify and respond to security incidents in real-time.</li>
          <li>Enhance your organization's security posture with network analytics.</li>
          <li>Empower security teams with actionable insights.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Security Professionals Say</h3>
        <div className="testimonial">
          <p>"Cisco Stealthwatch has given us the visibility and control we need to secure our network effectively."</p>
          <p>- John Smith, Network Security Manager</p>
        </div>
        <div className="testimonial">
          <p>"The advanced threat detection capabilities of Cisco Stealthwatch have significantly improved our incident response."</p>
          <p>- Jane Doe, Cybersecurity Specialist</p>
        </div>
      </div>
    </div>
  );
};

export default CiscoStealthwatchPromoBanner;