import React, { useEffect, useRef } from 'react';
import { Button } from '@material-ui/core';
import { usePostListStyles } from '../styles/muiStyles';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const LoadMoreButton = ({ handleLoadPosts, loading }) => {
  const classes = usePostListStyles();
  const loadMoreButtonRef = useRef(null);

  const handleIntersection = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      handleLoadPosts();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '10px',
      threshold: 1.0,
    });

    if (loadMoreButtonRef.current) {
      observer.observe(loadMoreButtonRef.current);
    }

    return () => {
      if (loadMoreButtonRef.current) {
        observer.unobserve(loadMoreButtonRef.current);
      }
    };
  }, [handleLoadPosts]);

  return (
    <div className={classes.loadBtnWrapper} ref={loadMoreButtonRef}>
    </div>
  );
};

export default LoadMoreButton;