import React from 'react';

const AWSCloudCertifications = () => {
  const awsCertifications = [
    {
      name: 'AWS Certified Solutions Architect - Associate',
      description: 'Designed for individuals who design distributed systems on AWS. It covers architectural best practices and an understanding of services.',
      link: 'https://aws.amazon.com/certifications/certified-solutions-architect-associate/',
    },
    {
      name: 'AWS Certified Developer - Associate',
      description: 'For developers who design, build, and maintain applications on AWS. It covers application deployment and security best practices.',
      link: 'https://aws.amazon.com/certifications/certified-developer-associate/',
    },
    {
      name: 'AWS Certified SysOps Administrator - Associate',
      description: 'For system administrators responsible for deploying, managing, and operating AWS systems. It covers system operation best practices.',
      link: 'https://aws.amazon.com/certifications/sysops-admin-associate/',
    },
    {
      name: 'AWS Certified Solutions Architect - Professional',
      description: 'Designed for experienced solutions architects. It covers advanced topics and requires a deep understanding of AWS services.',
      link: 'https://aws.amazon.com/certifications/certified-solutions-architect-professional/',
    },
    {
      name: 'AWS Certified DevOps Engineer - Professional',
      description: 'For DevOps engineers who automate and optimize processes. It covers continuous delivery and automation best practices.',
      link: 'https://aws.amazon.com/certifications/devops-engineer-professional/',
    },
    {
      name: 'AWS Certified Security - Specialty',
      description: 'For individuals who perform security tasks on AWS. It covers security best practices and threat detection.',
      link: 'https://aws.amazon.com/certifications/security-specialty/',
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <header>
        <h1 className="title">AWS Cloud Certifications</h1>
      </header>
      <section className="certifications">
        {awsCertifications.map((certification, index) => (
          <div key={index} className="certification">
            <h2 className="certification-name">{certification.name}</h2>
            <p className="certification-description">{certification.description}</p>
            <p className="certification-link">
              <a
                href={certification.link}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Learn more
              </a>
            </p>
          </div>
        ))}
      </section>
    </div>
  );
};

export default AWSCloudCertifications;