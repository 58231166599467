import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const JohnHammondPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://www.youtube.com/user/RootOfTheNull', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Learn Cybersecurity with John Hammond!</h2>
          <p>Explore educational videos, tutorials, and challenges on John Hammond's YouTube channel.</p>
          <button onClick={handleButtonClick}>Watch Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose John Hammond?</h3>
        <ul>
          <li>Educational content tailored for cybersecurity enthusiasts.</li>
          <li>Engaging tutorials, CTF challenges, and walkthroughs.</li>
          <li>Community interaction and Q&A sessions.</li>
          <li>Learn from a passionate cybersecurity educator.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Collaborators and Contributors</h3>
        <p>John Hammond collaborates with fellow cybersecurity experts and enthusiasts to deliver informative content and learning experiences.</p>
        <ul>
          <li>Cybersecurity educators and mentors.</li>
          <li>CTF challenge creators and competitors.</li>
          <li>Community contributors and learners.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What the Community Says</h3>
        <div className="testimonial">
          <p>"John Hammond's videos are fantastic for learning cybersecurity concepts. His enthusiasm is infectious and his explanations are top-notch."</p>
          <p>- Jane Doe, Cybersecurity Student</p>
        </div>
        <div className="testimonial">
          <p>"I owe a lot of my progress in ethical hacking to John Hammond's content. His CTF walkthroughs have been incredibly helpful."</p>
          <p>- John Smith, Security Enthusiast</p>
        </div>
      </div>
    </div>
  );
};

export default JohnHammondPromoBanner;