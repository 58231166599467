import React from 'react';

const VMwareInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#FF6600', // VMware brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const buttonStyle = {
    backgroundColor: '#232323',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Unlock the Power of VMware</h1>
      <p>
        VMware is a leading provider of virtualization and cloud infrastructure solutions. It enables organizations to modernize their IT infrastructure, run applications securely across multiple clouds, and simplify their digital transformation journey.
      </p>
      <h2 style={subheadingStyle}>Key Benefits of VMware:</h2>
      <ul>
        <li>Virtualization and cloud management solutions</li>
        <li>Multi-cloud and hybrid cloud capabilities</li>
        <li>Enhanced security and compliance</li>
        <li>Business continuity and disaster recovery</li>
        <li>Flexible and scalable infrastructure</li>
        <li>Robust ecosystem of partners and solutions</li>
      </ul>
      <button onClick={() => window.open("https://www.vmware.com/", "_blank")} style={buttonStyle}>Explore VMware</button>
    </div>
  );
};

export default VMwareInfo;