import React from 'react';

const ExploitDBInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#007bff',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Discover Exploit Database (Exploit-DB)</h1>
      <p>
        Exploit Database (Exploit-DB) is a comprehensive repository of
        cybersecurity exploits, vulnerabilities, and security resources. It
        provides a wealth of information for security researchers, pentesters,
        and enthusiasts.
      </p>
      <h2 style={subheadingStyle}>What It Offers:</h2>
      <ul>
        <li>Exploits for a wide range of software and systems</li>
        <li>Detailed vulnerability information</li>
        <li>Security papers and documentation</li>
        <li>Searchable and categorized database</li>
      </ul>
      <p>
        Explore Exploit-DB to access the latest security exploits and resources:
        <a
          href="https://www.exploit-db.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Exploit-DB Website
        </a>
      </p>
    </div>
  );
};

export default ExploitDBInfo;