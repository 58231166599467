import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const DFIRReportPromoBanner = () => {
  const handleButtonClick = () => {
    // Open The DFIR Report link in a new tab/window
    window.open('https://thedfirreport.com/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Stay Informed with The DFIR Report!</h2>
          <p>Access real-world incident response analysis, case studies, and insights from the cybersecurity community.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose The DFIR Report?</h3>
        <ul>
          <li>Read detailed incident response reports and analysis.</li>
          <li>Gain insights from real-world cybersecurity incidents.</li>
          <li>Learn from case studies and expert-contributed content.</li>
          <li>Stay updated on the latest threat trends and defense strategies.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Readers Say</h3>
        <div className="testimonial">
          <p>"The DFIR Report provides invaluable insights into real-world incidents and helps improve our incident response capabilities."</p>
          <p>- John Smith, Security Analyst</p>
        </div>
        <div className="testimonial">
          <p>"I rely on The DFIR Report to stay current with the latest attack techniques and defense strategies."</p>
          <p>- Jane Doe, Cybersecurity Professional</p>
        </div>
      </div>
    </div>
  );
};

export default DFIRReportPromoBanner;