import React from 'react';

const containerStyle = {
  fontFamily: 'Arial, sans-serif',
  textAlign: 'center',
  padding: '20px',
};

const headerStyle = {
    color: 'black',
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '10px',
};

const paragraphStyle = {
  fontSize: '1.2rem',
  margin: '0 auto',
  maxWidth: '600px',
};

const listStyle = {
  textAlign: 'left',
  fontSize: '1.2rem',
  maxWidth: '600px',
  margin: '20px auto',
};

const buttonStyle = {
  padding: '10px 20px',
  fontSize: '1.2rem',
  backgroundColor: '#FF5733',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

const RedTeamPentestingInfo = () => {
  // Function to open the website in a new tab
  const openWebsite = () => {
    window.open('https://www.redteam-pentesting.de/en/advisories/-advisories-publicised-vulnerability-analyses', '_blank');
  };

  return (
    <div style={containerStyle}>
      <header>
        <h1 style={headerStyle}>Welcome to Red Team Pentesting</h1>
        <p style={paragraphStyle}>Your Trusted Cybersecurity Partner</p>
      </header>
      <main>
        <section>
          <h2 style={headerStyle}>What We Offer</h2>
          <p style={paragraphStyle}>
            Red Team Pentesting is a world-renowned cybersecurity firm
            specializing in comprehensive penetration testing and security
            assessments. We help organizations identify vulnerabilities in their
            systems and applications, allowing them to strengthen their
            security posture.
          </p>
          <ul style={listStyle}>
            <li>Network Penetration Testing</li>
            <li>Web Application Security Assessments</li>
            <li>Mobile App Security Assessments</li>
            <li>Wireless Network Security Testing</li>
            <li>Security Training and Workshops</li>
          </ul>
        </section>
        <section>
          <p style={paragraphStyle}>
            Our team of experienced ethical hackers provides the expertise you
            need to protect your digital assets. Secure your organization
            today!
          </p>
          <button style={buttonStyle} onClick={openWebsite}>
            Visit Website
          </button>
        </section>
      </main>
    </div>
  );
};

export default RedTeamPentestingInfo;