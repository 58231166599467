import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Fab,
  Paper,
  useMediaQuery,
  MenuItem,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PostAddIcon from '@material-ui/icons/PostAdd';
import EditIcon from '@material-ui/icons/Edit';

import PostForm from './PostForm';
import HideOnScroll from './HideOnScroll';
import { getCircularAvatar } from '../utils/cloudinaryTransform';
import { useDialogStyles } from '../styles/muiStyles';

const AddPostModal = ({
  actionType,
  handleMenuClose,
  postToEditType,
  postToEditTitle,
  postToEditSub,
  postToEditId,
  textSubmission,
  imageTextSubmission,
  linkSubmission,
  fromSubreddit,
}) => {
  const [open, setOpen] = useState(false);
  const [postType, setPostType] = useState('Text');
  const user = useSelector((state) => state.user);

  const classes = useDialogStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePostType = (type) => {
    setPostType(type);
    handleClickOpen();
  };

  if (!user) {
    return null;
  }

  return (
    <div>
      {actionType === 'edit' ? (
        // Render 'Edit Post' menu item when in edit mode
        <MenuItem onClick={() => handleClickOpen(() => setOpen(true))}>
          <ListItemIcon>
            <EditIcon style={{ marginRight: 7 }} />
            Edit Post
          </ListItemIcon>
        </MenuItem>
      ) : (
        // Render the paper container for adding a new post
        isMobile ? (
          <HideOnScroll>
            <Fab
              className={classes.fab}
              color="primary"
              onClick={() => handlePostType('Text')} // Handle adding a new post
            >
              <PostAddIcon />
            </Fab>
          </HideOnScroll>
        ) : (
          <Paper className={classes.createPostWrapper}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handlePostType('Text')}
              fullWidth
              className={classes.createBtn}
              startIcon={<PostAddIcon />}
              size="large"
            >
              Add A Text Write-Up
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handlePostType('Image')}
              fullWidth
              className={classes.createBtn}
              startIcon={<PostAddIcon />}
              size="large"
            >
              Add A Text Write-Up & Image
            </Button>
          </Paper>
        )
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogWrapper }}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle onClose={handleClose}>
          {actionType === 'edit' ? '' : ''}
        </DialogTitle>
        <DialogContent>
          <PostForm
            actionType={actionType}
            postType={postType}
            postToEditType={postToEditType}
            postToEditTitle={postToEditTitle}
            postToEditSub={postToEditSub}
            postToEditId={postToEditId}
            textSubmission={textSubmission}
            imageTextSubmission={imageTextSubmission}
            linkSubmission={linkSubmission}
            fromSubreddit={fromSubreddit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddPostModal;