import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const AmandaBerlinPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://www.amandaberlin.com/', '_blank');
  };

  const books = [
    {
      title: 'Defensive Security Handbook',
      link: 'https://www.amazon.com/Defensive-Security-Handbook-Practices-Infrastructure/dp/1491960388',
    },
    {
      title: 'Blue Team Field Manual (BTFM)',
      link: 'https://www.amazon.com/Blue-Team-Field-Manual-BTFM/dp/154101636X',
    },
  ];

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Explore Cybersecurity with Amanda Berlin!</h2>
          <p>Discover educational resources, books, and insights on Amanda Berlin's website.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Amanda Berlin?</h3>
        <ul>
          <li>Expertise in defensive security and incident response.</li>
          <li>Author of insightful and practical books on cybersecurity.</li>
          <li>Engaging talks and workshops for security professionals.</li>
          <li>Learn from a prominent cybersecurity advocate.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Books by Amanda Berlin</h3>
        <ul>
          {books.map((book, index) => (
            <li key={index}>
              <a href={book.link} target="_blank" rel="noopener noreferrer">
                {book.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What the Community Says</h3>
        <div className="testimonial">
          <p>"Amanda Berlin's books are essential resources for anyone in cybersecurity. Her expertise is unmatched."</p>
          <p>- John Smith, Security Professional</p>
        </div>
        <div className="testimonial">
          <p>"I've gained a wealth of knowledge from Amanda Berlin's talks and books. Her contributions to the community are invaluable."</p>
          <p>- Jane Doe, Cybersecurity Enthusiast</p>
        </div>
      </div>
    </div>
  );
};

export default AmandaBerlinPromoBanner;