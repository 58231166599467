import React from 'react';

const HackTricksInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'black',
  };

  const headingStyle = {
    color: '#333',
  };

  const subheadingStyle = {
    color: '#555',
  };

  const linkStyle = {
    color: '#007bff',
    textDecoration: 'none',
  };

  const buttonStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const redirectToHackTricks = () => {
    // Redirect to HackTricks.xyz when the button is clicked
    window.location.href = 'https://book.hacktricks.xyz/';
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore HackTricks.xyz</h1>
      <p>
        HackTricks.xyz is a valuable resource for cybersecurity enthusiasts and
        professionals. It offers a comprehensive collection of tricks and
        techniques for penetration testing, red teaming, and cybersecurity
        research.
      </p>
      <h2 style={subheadingStyle}>What It Offers:</h2>
      <ul>
        <li>Extensive guides and tutorials</li>
        <li>Vulnerability assessment techniques</li>
        <li>Privilege escalation methods</li>
        <li>Enumeration and exploitation tips</li>
      </ul>
      <p>
        Discover the wealth of knowledge available on HackTricks.xyz by visiting
        the official website:
        <a
          href="https://book.hacktricks.xyz/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          HackTricks.xyz
        </a>
      </p>
      <button style={buttonStyle} onClick={redirectToHackTricks}>
        Explore Now
      </button>
    </div>
  );
};

export default HackTricksInfo;