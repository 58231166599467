import React from 'react';

const OverTheWireInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#333', // OverTheWire theme color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Challenge Your Cybersecurity Skills with OverTheWire</h1>
      <p>
        OverTheWire is an online platform that hosts a variety of war games and
        challenges designed to teach and test your cybersecurity skills. Whether
        you're a beginner or an experienced hacker, OverTheWire offers a range of
        interactive challenges to help you learn and improve your security
        knowledge.
      </p>
      <h2 style={subheadingStyle}>What OverTheWire Offers:</h2>
      <ul>
        <li>Capture The Flag (CTF) challenges</li>
        <li>Wargames and security puzzles</li>
        <li>Opportunities to practice Linux and network skills</li>
        <li>Community support and discussion forums</li>
      </ul>
      <p>
        Test your skills and start hacking at:
        <a
          href="https://overthewire.org/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          OverTheWire
        </a>
      </p>
    </div>
  );
};

export default OverTheWireInfo;