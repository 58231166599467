import React from 'react';

const OffensiveSecurityInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  };

  const headingStyle = {
    color: '#007bff',
    fontSize: '32px',
    marginBottom: '20px',
  };

  const listStyle = {
    fontSize: '18px',
    lineHeight: '1.6',
  };

  const linkStyle = {
    color: '#007bff',
    textDecoration: 'none',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore Offensive Security</h1>
      <p>
        Offensive Security is a leading provider of cybersecurity training and
        certification. They empower individuals and organizations with the
        knowledge and skills needed to secure systems and networks.
      </p>
      <h2>Offerings:</h2>
      <ul style={listStyle}>
        <li>
          <strong>Offensive Security Certified Professional (OSCP):</strong> The
          renowned hands-on penetration testing certification.
        </li>
        <li>
          <strong>Metasploit Unleashed (MSFU):</strong> Free Metasploit
          training.
        </li>
        <li>
          <strong>Exploit Database:</strong> A comprehensive collection of
          exploits and vulnerabilities.
        </li>
        <li>
          <strong>Online Labs:</strong> Accessible labs for hands-on practice.
        </li>
        <li>
          <strong>Kali Linux:</strong> The leading penetration testing platform.
        </li>
      </ul>
      <p>
        Whether you are looking to advance your career in cybersecurity or
        improve your organization's security posture, Offensive Security has the
        resources and expertise to help you succeed.
      </p>
      <p>
        Explore their offerings and learn more on the official Offensive
        Security website:
        <a
          href="https://www.offensive-security.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Offensive Security Official Website
        </a>
      </p>
    </div>
  );
};

export default OffensiveSecurityInfo;