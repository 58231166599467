import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const KrebsPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://krebsonsecurity.com/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Stay Informed with Krebs on Security!</h2>
          <p>Explore the latest cybersecurity news, insights, and investigations on KrebsOnSecurity.com.</p>
          <button onClick={handleButtonClick}>Read Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Krebs on Security?</h3>
        <ul>
          <li>Comprehensive coverage of cybersecurity topics.</li>
          <li>Insightful analysis of emerging threats.</li>
          <li>Investigative journalism on data breaches and cybercrime.</li>
          <li>Expert insights from Brian Krebs.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Contributors and Collaborators</h3>
        <p>Krebs on Security collaborates with cybersecurity experts and organizations to provide accurate and up-to-date information. Our contributors include:</p>
        <ul>
          <li>Cybersecurity researchers and professionals.</li>
          <li>Law enforcement agencies and experts.</li>
          <li>Industry leaders and cybersecurity firms.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Readers Say</h3>
        <div className="testimonial">
          <p>"Krebs on Security is my go-to source for cybersecurity news. Brian Krebs' reporting is thorough and reliable."</p>
          <p>- John Smith, IT Security Analyst</p>
        </div>
        <div className="testimonial">
          <p>"The investigative articles on Krebs on Security are eye-opening. It's an essential resource for anyone in cybersecurity."</p>
          <p>- Jane Doe, Cybersecurity Enthusiast</p>
        </div>
      </div>
    </div>
  );
};

export default KrebsPromoBanner;