import React from 'react';

const AWSInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#FF9900', // AWS brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const buttonStyle = {
    backgroundColor: '#232323',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Discover the Power of Amazon Web Services (AWS)</h1>
      <p>
        Amazon Web Services (AWS) is a leading cloud computing platform that provides a wide range of services for businesses and individuals. It offers flexible and scalable solutions for cloud computing, storage, machine learning, and more.
      </p>
      <h2 style={subheadingStyle}>Key Strengths of AWS:</h2>
      <ul>
        <li>Scalability and flexibility</li>
        <li>Comprehensive suite of cloud services</li>
        <li>Global reach with data centers worldwide</li>
        <li>Security and compliance capabilities</li>
        <li>Advanced machine learning and analytics</li>
        <li>Robust developer and DevOps tools</li>
        <li>Strong customer support and documentation</li>
      </ul>
      <button onClick={() => window.open("https://aws.amazon.com/", "_blank")} style={buttonStyle}>Explore AWS</button>
    </div>
  );
};

export default AWSInfo;