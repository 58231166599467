// CybersecurityQueryForm.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../CSS/CybersecurityQueryForm.css'; // Create a CSS file for additional styling

const CybersecurityQueryForm = () => {
  const [username, setUsername] = useState('');
  const [queryType, setQueryType] = useState('');
  const [query, setQuery] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [description, setDescription] = useState('');
  const queryTypesList = [
    'Kibana',
    'Splunk',
    'Powershell',
    'Bash',
    'SQL',
    'Google Cloud',
    'CloudWatch',
    'Microsoft Sentinel',
    'Carbon Black',
    'TShark',
    'TCPDUMP',
    'NMAP',
    'Other',
  ];

  useEffect(() => {
    const storedUser = localStorage.getItem('readifyUserKey');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUsername(parsedUser.username);
    }
  }, []);

  useEffect(() => {
    const updateCurrentDate = () => {
      const date = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      };
      const formattedDate = date.toLocaleString('en-US', options).replace(',', '');
      setCurrentDate(formattedDate);
    };
  
    updateCurrentDate();
  
    // Update the current date every minute
    const interval = setInterval(updateCurrentDate, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!queryType) {
      alert('Please select a query type.');
      return;
    }

    if (query.length > 1000000) {
      alert('Query exceeds maximum character limit of 500.');
      return;
    }

    if (description.length > 1000000) {
      alert('Description exceeds maximum character limit of 300.');
      return;
    }

    try {
      const response = await axios.post('/api/submit-query', {
        username,
        queryType,
        query,
        description,
        currentDate,
      });

      if (response.status === 201) {
        alert('Well Done Analyst! Query Submitted Successfully');
      } else {
        alert('Error submitting query.');
      }
    } catch (error) {
      console.error('Error submitting query:', error);
    }

    // Reset the form
    setQueryType('');
    setQuery('');
    setDescription('');
  };

  const handleQueryChange = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  return (
    <div className="query-form-container">
      <form onSubmit={handleSubmit} className="form">
        <h2 className="form-title">Submit Query</h2>
        <div className="form-field">
          <label htmlFor="queryType">Query Type:</label>
          <select
            id="queryType"
            value={queryType}
            onChange={(e) => setQueryType(e.target.value)}
            required
          >
            <option value="">Select a query type</option>
            {queryTypesList.map((queryTypeOption, index) => (
              <option key={index} value={queryTypeOption}>
                {queryTypeOption}
              </option>
            ))}
          </select>
        </div>

        <div className="form-field">
          <label htmlFor="query">Query:</label>
          <textarea
            id="query"
            value={query}
            onChange={handleQueryChange}
            required
          />
        </div>
        <div className="form-field">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <button className="submit-button" type="submit">
            Submit Query
          </button>
        </div>
      </form>
    </div>
  );
};

export default CybersecurityQueryForm;