import React from 'react';
import '../CSS/ChtSh.css'; // Import your custom CSS for styling

const ChtSh = () => {
  return (
    <div className="cht-sh">
      <div className="cht-sh-content">
        <p>Access command-line documentation and examples with cht.sh.</p>
        <a
          href="https://cheat.sh/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>Explore Now</button>
        </a>
      </div>
    </div>
  );
};

export default ChtSh;