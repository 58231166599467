import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchPostComments,
  toggleUpvote,
  toggleDownvote,
} from '../reducers/postCommentsReducer';
import { notify } from '../reducers/notificationReducer';
import CommentInput from './CommentInput';
import { UpvoteButton, DownvoteButton } from './VoteButtons';
import EditDeleteMenu from './EditDeleteMenu';
import CommentsDisplay from './CommentsDisplay';
import SortCommentsMenu from './SortCommentsMenu';
import ErrorPage from './ErrorPage';
import LoadingSpinner from './LoadingSpinner';
import TimeAgo from 'timeago-react';
import { trimLink, prettifyLink, fixUrl } from '../utils/formatUrl';
import ReactHtmlParser from 'react-html-parser';
import getErrorMsg from '../utils/getErrorMsg';
import ShareIcon from '@material-ui/icons/Share';
import Snackbar from '@material-ui/core/Snackbar';
import { getCircularAvatar } from '../utils/cloudinaryTransform';


import {
  Container,
  Paper,
  useMediaQuery,
  Typography,
  Link,
  MenuItem,
  ListItemIcon,
  Divider,
  makeStyles,
  Box,
  Avatar
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CommentIcon from '@material-ui/icons/Comment';
import ShareComp from './share/share';


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(2),
  },
  mainPaper: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  topPortion: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  votesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    width: '2vw',
  },
  postDetails: {
    flex: 1,
  },
  userAndDate: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  textContent: {
    maxWidth: '100%',
  },
  imagePost: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  image: {
    alignItems: 'center',
    maxWidth: '100%',
  },
  bottomBar: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  bottomButton: {
    minWidth: 'auto',
    padding: theme.spacing(0.5, 1),
    marginRight: theme.spacing(2),
  },
  commentIcon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const PostCommentsPage = () => {
  const [authorInfo, setAuthorInfo] = useState(null);
  const { id: postId } = useParams();
  const post = useSelector((state) => state.postComments);
  const { user } = useSelector((state) => state);
  const [pageLoading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const getComments = async () => {
      try {
        await dispatch(fetchPostComments(postId));
        setPageLoading(false);
      } catch (err) {
        setPageError(getErrorMsg(err));
      }
    };
    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  useEffect(() => {
    const fetchAuthorAvatar = async () => {
      try {
        // Make sure you have access to the author's username here
        const authorUsername = post.author.username;

        const response = await fetch(`/api/authors/avatars/${authorUsername}`);
        if (response.ok) {
          const avatarData = await response.json();
          if (avatarData.avatar) {
            // If "avatar" property exists, set the avatar from the response
            setAuthorInfo({ ...authorInfo, avatar: avatarData.avatar });
          } else {
            // If there's no "avatar" property, set a default avatar here
            setAuthorInfo({ ...authorInfo, avatar: null }); // You can replace null with a default avatar URL
          }
        } else {
          // Handle the case where the response is not OK, e.g., show an error message
          console.error('Error fetching author avatar:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching author avatar:', error);
      }
    };

    if (post && post.author) {
      fetchAuthorAvatar();
    }
  }, [post]);

  console.log("authorInfo:", authorInfo);

  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  if (pageError) {
    return (
      <Container disableGutters>
        <Paper className={classes.mainPaper}>
          <ErrorPage errorMsg={pageError} />
        </Paper>
      </Container>
    );
  }

  if (!post || pageLoading) {
    return (
      <Container disableGutters>
        <Paper className={classes.mainPaper}>
          <LoadingSpinner />
        </Paper>
      </Container>
    );
  }

  const {
    id,
    title,
    postType,
    linkSubmission,
    imageSubmission,
    imageTextSubmission,
    textSubmission,
    subreddit,
    author,
    upvotedBy,
    downvotedBy,
    pointsCount,
    comments,
    commentCount,
    createdAt,
    updatedAt,
  } = post;

  const isUpvoted = user && upvotedBy.includes(user.id);
  const isDownvoted = user && downvotedBy.includes(user.id);

  const handleUpvoteToggle = async () => {
    try {
      if (isUpvoted) {
        const updatedUpvotedBy = upvotedBy.filter((u) => u !== user.id);
        dispatch(toggleUpvote(id, updatedUpvotedBy, downvotedBy));
      } else {
        const updatedUpvotedBy = [...upvotedBy, user.id];
        const updatedDownvotedBy = downvotedBy.filter((d) => d !== user.id);
        dispatch(toggleUpvote(id, updatedUpvotedBy, updatedDownvotedBy));
      }
    } catch (err) {
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  const handleDownvoteToggle = async () => {
    try {
      if (isDownvoted) {
        const updatedDownvotedBy = downvotedBy.filter((d) => d !== user.id);
        dispatch(toggleDownvote(id, updatedDownvotedBy, upvotedBy));
      } else {
        const updatedDownvotedBy = [...downvotedBy, user.id];
        const updatedUpvotedBy = upvotedBy.filter((u) => u !== user.id);
        dispatch(toggleDownvote(id, updatedDownvotedBy, updatedUpvotedBy));
      }
    } catch (err) {
      dispatch(notify(getErrorMsg(err), 'error'));
    }
  };

  const formattedLink =
    postType === 'Link' && trimLink(prettifyLink(linkSubmission), 70);

    

  return (
    <Container disableGutters className={classes.mainContainer}>
      <Paper className={classes.mainPaper}>
        <div className={classes.topPortion}>
          <div className={classes.votesWrapper}>
            <UpvoteButton
              user={user}
              body={post}
              handleUpvote={handleUpvoteToggle}
              size={isMobile ? "small" : "medium"}
            />
            <Typography
              variant="body1"
              style={{
                color: isUpvoted ? "#33ff33" : isDownvoted ? "#ff3333" : "#333",
                fontWeight: 600,
              }}
            >
              {pointsCount}
            </Typography>
            <DownvoteButton
              user={user}
              body={post}
              handleDownvote={handleDownvoteToggle}
              size={isMobile ? "small" : "medium"}
            />
          </div>
          <div className={classes.postDetails}>
          <Typography variant="subtitle2">
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
    {authorInfo && authorInfo.avatar ? (
      <Avatar
        alt={author.username}
        src={authorInfo.avatar ? getCircularAvatar(authorInfo.avatar) : ''}
        style={{ width: 42, height: 42, marginRight: '5px' }}
      />
    ) : (
      <Avatar style={{ backgroundColor: '#941a1c', width: 33, height: 33, marginRight: '5px' }}>
        <h1>{author.username}</h1>
      </Avatar>
    )}
    <Link component={RouterLink} to={`/i/${subreddit.subredditName}`}>
      {`${subreddit.subredditName} `}
    </Link>
  </div>
  <Typography variant="caption" className={classes.userAndDate}>
    <Link component={RouterLink} to={`/u/${author.username}`}>
      {` ${author.username} `}
    </Link>
    <TimeAgo datetime={new Date(createdAt)} />
    {createdAt !== updatedAt && (
      <em>
        {" • edited"} <TimeAgo datetime={new Date(updatedAt)} />
      </em>
    )}
  </Typography>
</Typography>
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
            <div className={classes.textContainer}>
              <Box className={classes.textContent}>
                {postType === "Text" ? (
                  <div>{ReactHtmlParser(textSubmission.replace(/\n/g, '<br />').replace(/\s+/g, ' '))}</div>
                ) : postType === "Image" ? (
                  <>
                    <div>
                      <a
                        alt={title}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.imagePost}
                      >
                        <div
  style={{ textAlign: "center" }} // Add textAlign style here
  className={classes.imagePost}
>
  <img
    alt={title}
    src={imageSubmission.imageLink}
    style={{ maxWidth: "100%", height: "auto" }} // Remove textAlign from here
  />
</div>
                      </a>
                    </div>
                    <div>
                    {ReactHtmlParser(imageTextSubmission.replace(/\n/g, '<br />').replace(/\s+/g, ' '))}
</div>
                  </>
                ) : (
                  <Link href={fixUrl(linkSubmission)}>
                    {formattedLink} <OpenInNewIcon fontSize="inherit" />
                  </Link>
                )}
              </Box>
            </div>
            <div className={classes.bottomBar}>
              <MenuItem className={classes.bottomButton}>
                <ListItemIcon>
                  <CommentIcon className={classes.commentIcon} />
                  <Typography variant="subtitle2">{commentCount}</Typography>
                </ListItemIcon>
              </MenuItem>
              {user && user.id === author.id && (
                <EditDeleteMenu
                  id={id}
                  isMobile={isMobile}
                  title={title}
                  postType={postType}
                  subreddit={subreddit}
                  buttonType="buttonGroup"
                  textSubmission={textSubmission}
                  linkSubmission={linkSubmission}
                />
              )}
            </div>
            <CommentInput user={user} postId={id} isMobile={isMobile} />
            <SortCommentsMenu />
          </div>
        </div>
        <Divider className={classes.divider} />
        <CommentsDisplay comments={comments} postId={id} isMobile={isMobile} />
      </Paper>
    </Container>
  );
};

export default PostCommentsPage;
