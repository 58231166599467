import React from 'react';
import './privacy.css'; // Import your CSS file for additional styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <header className="header">
        <h1>Cyber | NuITx - Privacy Policy</h1>
      </header>

      <main className="main">
        <h2 className="policy-title">Privacy Policy</h2>
        <p className="last-updated">Last Updated: Monday, July 3rd, 2023</p>

        <div className="privacy-policy">
          <section className="policy-section">
            <h3 className="section-title">1. Information We Collect</h3>
            <p>We may collect personal information from you when you visit our Website, register an account, fill out forms, or interact with our services. The types of information we may collect include:</p>
            <ul className="policy-list">
              <li>Full name</li>
              <li>Email address</li>
              <li>Contact information</li>
              <li>Username and password</li>
              <li>Payment information</li>
              <li>Other information you provide voluntarily</li>
            </ul>
          </section>

          <section className="policy-section">
            <h3 className="section-title">2. Use of Personal Information</h3>
            <p>We may use the personal information we collect for the following purposes:</p>
            <ul className="policy-list">
              <li>Provide, operate, and maintain our Website and services</li>
              <li>Improve, personalize, and expand our Website and services</li>
              <li>Understand and analyze how you use our Website</li>
              <li>Communicate with you to provide customer support and respond to inquiries</li>
              <li>Send you promotional and marketing communications</li>
              <li>Comply with legal obligations</li>
            </ul>
          </section>

          {/* Continue with other sections... */}

          <section className="policy-section">
            <h3 className="section-title">9. Contact Us</h3>
            <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at Support</p>
          </section>
        </div>
      </main>

      <footer className="footer">
        {/* Your website footer content goes here */}
      </footer>
    </div>
  );
};

export default PrivacyPolicy;