import React from 'react';

const VirtualBoxInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#263238', // VirtualBox brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const buttonStyle = {
    backgroundColor: '#232323',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore Virtualization with Oracle VM VirtualBox</h1>
      <p>
        Oracle VM VirtualBox is a free and open-source virtualization software that allows you to run multiple operating systems on a single machine. It's a powerful tool for developers, IT professionals, and enthusiasts to create and manage virtual environments.
      </p>
      <h2 style={subheadingStyle}>Key Features of VirtualBox:</h2>
      <ul>
        <li>Support for multiple guest operating systems</li>
        <li>Easy virtual machine creation and management</li>
        <li>Snapshot and clone virtual machines</li>
        <li>Virtual networking and storage options</li>
        <li>Extensible with additional plugins</li>
        <li>Community-driven with active development</li>
      </ul>
      <button onClick={() => window.open("https://www.virtualbox.org/", "_blank")} style={buttonStyle}>Explore VirtualBox</button>
    </div>
  );
};

export default VirtualBoxInfo;