import React from 'react';

const OracleCloudCertifications = () => {
  const oracleCertifications = [
    {
      name: 'Oracle Cloud Infrastructure Foundations 2021 Certified Associate',
      description: 'Designed for individuals who have foundational knowledge of Oracle Cloud Infrastructure services and offerings.',
      link: 'https://education.oracle.com/oracle-cloud-infrastructure-foundations-2021-certified-associate',
    },
    {
      name: 'Oracle Cloud Infrastructure 2021 Certified Architect Associate',
      description: 'For architects who can design, develop, and manage solutions on Oracle Cloud Infrastructure.',
      link: 'https://education.oracle.com/oracle-cloud-infrastructure-2021-certified-architect-associate',
    },
    {
      name: 'Oracle Cloud Infrastructure 2021 Certified Architect Professional',
      description: 'Designed for experienced architects who can design complex solutions on Oracle Cloud Infrastructure.',
      link: 'https://education.oracle.com/oracle-cloud-infrastructure-2021-certified-architect-professional',
    },
    {
      name: 'Oracle Cloud Infrastructure 2021 Certified Operations Associate',
      description: 'For operations professionals who can implement and manage Oracle Cloud Infrastructure services.',
      link: 'https://education.oracle.com/oracle-cloud-infrastructure-2021-certified-operations-associate',
    },
    {
      name: 'Oracle Cloud Infrastructure 2021 Certified Database Associate',
      description: 'Designed for database professionals who can design, implement, and manage Oracle Database solutions on the cloud.',
      link: 'https://education.oracle.com/oracle-cloud-infrastructure-2021-certified-database-associate',
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <header>
        <h1 className="title">Oracle Cloud Certifications</h1>
      </header>
      <section className="certifications">
        {oracleCertifications.map((certification, index) => (
          <div key={index} className="certification">
            <h2 className="certification-name">{certification.name}</h2>
            <p className="certification-description">{certification.description}</p>
            <p className="certification-link">
              <a
                href={certification.link}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Learn more
              </a>
            </p>
          </div>
        ))}
      </section>
    </div>
  );
};

export default OracleCloudCertifications;