import React from 'react';

const PowerShellEmpireInfo = () => {
    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#f5f5f5',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        color: 'black', // Default text color
      };
    
      // Adjust text color for dark mode
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        containerStyle.color = 'white'; // Set text color for dark mode
      }
    
      const headingStyle = {
        color: '#333',
      };
    
      const subheadingStyle = {
        color: '#555',
      };
    
      const linkStyle = {
        color: '#007bff',
        textDecoration: 'none',
      };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>PowerShell Empire</h1>
      <p>
        PowerShell Empire is a post-exploitation framework that allows
        penetration testers and red teamers to maintain control over compromised
        systems. It leverages the power of PowerShell for offensive operations.
      </p>
      <h2 style={subheadingStyle}>Features:</h2>
      <ul>
        <li>Command and control (C2) infrastructure</li>
        <li>Stagers and agents for payload delivery</li>
        <li>Module system for post-exploitation tasks</li>
        <li>Extensive PowerShell scripting capabilities</li>
        <li>Active development and community contributions</li>
      </ul>
      <p>
        For more information and to access the PowerShell Empire framework,
        please visit the official GitHub repository:
        <a
          href="https://github.com/PowerShellEmpire/Empire"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          PowerShell Empire GitHub Repository
        </a>
      </p>
    </div>
  );
};

export default PowerShellEmpireInfo;