import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './datascisubmit.css'; // Create a CSS file for additional styling

const DataSciToolWriteForm = () => {
  const [username, setUsername] = useState('');
  const [datasciToolName, setdatasciToolName] = useState('');
  const [datasciToolLink, setdatasciToolLink] = useState('');
  const [description, setDescription] = useState('');
  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const storedUser = localStorage.getItem('readifyUserKey');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUsername(parsedUser.username);
    }
  }, []);

  useEffect(() => {
    const updateCurrentDate = () => {
      const date = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      };
      const formattedDate = date.toLocaleString('en-US', options).replace(',', '');
      setCurrentDate(formattedDate);
    };

    updateCurrentDate();

    // Update the current date every minute
    const interval = setInterval(updateCurrentDate, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (description.length > 1000000) {
      alert('Description exceeds the maximum limit of 500 characters.');
      return;
    }

    try {
      const response = await axios.post('/api/submit-datascitool', {
        username,
        datasciToolName,
        datasciToolLink,
        description,
        currentDate,
      });

      if (response.status === 201) {
        alert('Nice! Its in there, Tell your friends!');
      } else {
        alert('Error submitting.');
      }
    } catch (error) {
      console.error('Error submitting:', error);
    }

    // Reset the form
    setdatasciToolName('');
    setdatasciToolLink('');
    setDescription('');
  };

  return (
    <div className="exploit5-form-container">
      <form onSubmit={handleSubmit} className="form">
        <h2 className="form-title">Submit Data Science Tool</h2>
        <div className="form-field">
          <label htmlFor="datasciToolName">Tool Name:</label>
          <input
            type="text"
            id="datasciToolName"
            value={datasciToolName}
            onChange={(e) => setdatasciToolName(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="datasciToolLink">Tool Link:</label>
          <input
            type="text"
            id="datasciToolLink"
            value={datasciToolLink}
            onChange={(e) => setdatasciToolLink(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <button className="submit-button" type="submit">
            Submit Tool
          </button>
        </div>
      </form>
    </div>
  );
};

export default DataSciToolWriteForm;