import React from 'react';

function CybersecurityYouTube() {
  // List of types of cybersecurity content
  const cybersecurityContentTypes = [
    'Tutorials for Beginners',
    'Ethical Hacking Techniques',
    'Cybersecurity News Updates',
    'Penetration Testing Demos',
    'Threat Intelligence Reports',
    'Web Application Security',
    'Network Security Best Practices',
    'Incident Response Strategies',
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <h1>YouTube: Your Source for Cybersecurity Knowledge</h1>
      <p>
        YouTube is a treasure trove of cybersecurity-related content, offering valuable insights and knowledge to both beginners and experts.
        Here are some types of cybersecurity content you can find on YouTube:
      </p>

      <ul>
        {cybersecurityContentTypes.map((type, index) => (
          <li key={index}>{type}</li>
        ))}
      </ul>

      <p>
        Explore the world of cybersecurity on YouTube and enhance your knowledge to stay ahead of cyber threats.
        Click the link below to access cybersecurity content on YouTube:
      </p>

      <a
        href="https://www.youtube.com/results?search_query=cybersecurity"
        target="_blank"
        rel="noopener noreferrer"
      >
        Explore Cybersecurity on YouTube
      </a>
    </div>
  );
}

export default CybersecurityYouTube;