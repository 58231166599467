import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const TroyHuntPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://www.troyhunt.com/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Stay Informed with Troy Hunt's Blog!</h2>
          <p>Explore insightful articles on cybersecurity, privacy, and technology at TroyHunt.com.</p>
          <button onClick={handleButtonClick}>Read Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Troy Hunt's Blog?</h3>
        <ul>
          <li>Authoritative analysis of security breaches and incidents.</li>
          <li>Guidance on protecting your digital identity and privacy.</li>
          <li>Thoughtful discussions on the latest tech trends and issues.</li>
          <li>Learn from Troy Hunt, a prominent cybersecurity expert.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Contributors and Collaborators</h3>
        <p>Troy Hunt collaborates with fellow experts and researchers to provide valuable insights. Contributors include:</p>
        <ul>
          <li>Cybersecurity researchers and analysts.</li>
          <li>Data breach response experts.</li>
          <li>Technology and privacy advocates.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Readers Say</h3>
        <div className="testimonial">
          <p>"Troy Hunt's Blog is my go-to source for understanding complex security issues. His explanations are clear and accessible."</p>
          <p>- John Smith, Security Enthusiast</p>
        </div>
        <div className="testimonial">
          <p>"I appreciate Troy Hunt's commitment to educating the public about cybersecurity. His articles are both informative and engaging."</p>
          <p>- Jane Doe, Technology Professional</p>
        </div>
      </div>
    </div>
  );
};

export default TroyHuntPromoBanner;