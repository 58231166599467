import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PostFormModal from './components/PostFormModal';
import PostList from './components/PostList';
import PostCommentsPage from './components/PostCommentsPage';
import UserPage from './components/UserPage';
import SubPage from './components/SubPage';
import TopSubsPanel from './components/leaderboards/TopSubsPanel';
import SearchResults from './components/SearchResults';
import NotFoundPage from './components/NotFoundPage';
import onebyone from './assets/1x1chart.png'
import fourbyone from './assets/4x1chart.png'
import twobyone from './assets/2x1chart.png'
import RSSFeeds from './components/feeds/RSSFeeds'
import RSSFeedsUS from './components/feeds/RSSFeeds-US'
import RSSFeedsGlobal from './components/feeds/RSSFeeds-Global' 
import { Container } from '@material-ui/core/'; 
import { useMainPaperStyles } from './styles/muiStyles'; 
import SubFormModal from './components/SubFormModal';
import RSSFeedsHomeLeft from './components/feeds/LeftRssFeed';
import './css/homepage.css'
import HomeFormat from './components/home/homeformat';
import SupportPage from './components/support/support';
import AuthForm from './components/AuthForm';
import AuthTwo from './components/auth/auth2';
import CybraryPromoBanner from './components/BlueSupport/Cybrary';
import CourseraPromoBanner from './components/BlueSupport/Coursera';
import SANSPromoBanner from './components/BlueSupport/Sans';
import KrebsPromoBanner from './components/BlueSupport/Krebs';
import HackerNewsPromoBanner from './components/BlueSupport/Hackernews';
import TroyHuntPromoBanner from './components/BlueSupport/Troy';
import BlueTeamVillagePromoBanner from './components/BlueSupport/BlueTeamV';
import Hak5PromoBanner from './components/BlueSupport/Hak5';
import CyberMentorPromoBanner from './components/BlueSupport/TheCyberMentor';
import JohnHammondPromoBanner from './components/BlueSupport/JohnHammond';
import DonMurdochPromoBanner from './components/BlueSupport/DonMurdoch';
import AmandaBerlinPromoBanner from './components/BlueSupport/AmandaBerlin';
import RichardBejtlichPromoBanner from './components/BlueSupport/RichardBejt';
import RedditPromoBanner from './components/BlueSupport/AskNetSec';
import DefensiveSecurityPromoBanner from './components/BlueSupport/DefensiveSec';
import ISC2CommunityPromoBanner from './components/BlueSupport/ISC2';
import BlackHatPromoBanner from './components/BlueSupport/BlackHat';
import DEFCONPromoBanner from './components/BlueSupport/DEFCON';
import BSidesPromoBanner from './components/BlueSupport/Bsides';
import BlueTeamLinkedInPromoBanner from './components/BlueSupport/BlueLinkedin';
import BlueTeamFacebookPromoBanner from './components/BlueSupport/BlueFacebook';
import BlueTeamTwitterPromoBanner from './components/BlueSupport/BlueTwitter';
import MalpediaPromoBanner from './components/BlueSupport/Malpedia';
import DFIRReportPromoBanner from './components/BlueSupport/DFIR';
import RSSFeedsHomeRight from './components/feeds/RightRssFeed';
import ElasticPromoBanner from './components/BlueSupport/Elastic';
import SplunkPromoBanner from './components/BlueSupport/Splunk';
import FlareVMPromoBanner from './components/BlueSupport/FLARE';
import SiftVMPromoBanner from './components/BlueSupport/Swift';
import AzureSentinelPromoBanner from './components/BlueSupport/MSentinel';
import AWSCloudWatchPromoBanner from './components/BlueSupport/AWSLogs';
import GoogleCloudLoggingPromoBanner from './components/BlueSupport/GoogleLogs';
import TPotPromoBanner from './components/BlueSupport/TPOT';
import CarbonBlackPromoBanner from './components/BlueSupport/CarbonBlack';
import CiscoStealthwatchPromoBanner from './components/BlueSupport/StealthWatch';
import CertificationRoadmap from './components/BlueSupport/RoadMap';
import CheatSheets from './components/BlueSupport/CheatSheets';
import Logging from './components/BlueSupport/CheatLogging';
import ChtSh from './components/BlueSupport/chtsht';
import CybersecurityIOCForm from './components/IOCs/IOCSubmit';
import CybersecurityQueryForm from './components/Queries/QueryForm';
import IOCSearchPage from './components/IOCs/IOCSearch';
import QuerySearchPage from './components/Queries/QuerySearch';
import MitreAttackMatrix from './components/BlueSupport/MitreMatrix';
import JoeSandboxAnalysis from './components/BlueSupport/JoesSandbox';
import Capev2GitHub from './components/BlueSupport/CAPE';
import CobaltStrikeInfo from './components/redmenu/cobaltstrike';
import OSCPInfo from './components/redmenu/oscp';
import MetasploitInfo from './components/redmenu/metasploit';
import HackTheBoxInfo from './components/redmenu/hackthebox';
import PowerShellEmpireInfo from './components/redmenu/powerempire';
import BruteRatelInfo from './components/redmenu/bruteratel';
import KaliLinuxInfo from './components/redmenu/kali';
import OffensiveSecurityInfo from './components/redmenu/offsec';
import BlackHillsSecurityInfo from './components/redmenu/blackhills';
import IframeMitreAttackGroups from './components/redmenu/mitreapts';
import IframeMalpediaActors from './components/redmenu/malpediaactors';
import IframeMalpediaFamilies from './components/redmenu/malpediafamilies';
import RSSFeedsSoMany from './components/feeds/somany';
import CybersecurityExploitForm from './components/Exploits/ExploitSubmit';
import ExploitSearchPage from './components/Exploits/ExploitSearch';
import HackTricksBookIframe from './components/redmenu/HackTricks';
import HackTricksInfo from './components/redmenu/HackTricks';
import BazaarInfo from './components/redmenu/bazaar';
import ExploitDBInfo from './components/redmenu/exploitdb';
import K7ComputingBlogInfo from './components/redmenu/k7';
import SliverGitHubInfo from './components/redmenu/sliver';
import VirusTotalIframe from './components/BlueSupport/VirusTotal';
import ChainsawGitHubInfo from './components/BlueSupport/chainsaw';
import UdemyInfo from './components/Learning/udemy';
import PluralsightInfo from './components/Learning/pluralsight';
import EdXInfo from './components/Learning/edX';
import LinkedInLearningInfo from './components/Learning/llearning';
import SkillshareInfo from './components/Learning/skillshare';
import KhanAcademyInfo from './components/Learning/khanacademy';
import TreehouseInfo from './components/Learning/treehouse';
import UdacityInfo from './components/Learning/udacity';
import CodecademyInfo from './components/Learning/codecademy';
import MITOpenCourseWareInfo from './components/Learning/mitcourseware';
import HarvardOnlineCoursesInfo from './components/Learning/harvardonline';
import GoogleInformationalPage from './components/Learning/google';
import CybersecurityYouTube from './components/Learning/youtube';
import CybersecurityReddit from './components/Learning/reddit';
import StackOverflowPage from './components/Learning/stackoverflow';
import ChatGPTPage from './components/Learning/chatgpt';
import NetworkDefenseInfo from './components/Learning/networkdefense';
import OverTheWireInfo from './components/Learning/overthewire';
import UnderTheWireInfo from './components/Learning/underthewire';
import AWSInfo from './components/cloud/AWS';
import AzureInfo from './components/cloud/Azure';
import GoogleCloudInfo from './components/cloud/GoogleCloud';
import IBMCloudInfo from './components/cloud/IBMCloud';
import OracleCloudInfo from './components/cloud/OracleCloud';
import VMwareInfo from './components/cloud/vmware';
import VirtualBoxInfo from './components/cloud/VirtualBox';
import ProxmoxInfo from './components/cloud/proxmox';
import RedHatVirtualizationInfo from './components/cloud/rhel';
import KubernetesInfo from './components/cloud/kubernets';
import TerraformInfo from './components/cloud/terraform';
import CloudArchitecturePage from './components/cloud/cloudarchitectures';
import SansCloudCertifications from './components/cloud/sanscerts';
import AWSCloudCertifications from './components/cloud/awscerts';
import AzureCloudCertifications from './components/cloud/azurecerts';
import GoogleCloudCertifications from './components/cloud/gcloudcerts';
import IBMCertifications from './components/cloud/ibmcerts';
import OracleCloudCertifications from './components/cloud/oraclecloudcerts';
import RevShellsIframe from './components/redmenu/revshells';
import CVESearch from './components/cve/cvesearchpage';
import CyberDefenders from './components/BlueSupport/cyberdefenders';
import FireEyeInfo from './components/BlueSupport/FireEye';
import TalosInfo from './components/BlueSupport/Talos';
import CloudflareInfo from './components/BlueSupport/CloudFlare';
import PrivacyPolicy from './components/privacyguide/privacy';
import UserGuidelines from './components/privacyguide/guidelines';
import BlueTeamCheatSheets from './components/cheatsheets/pdfs/bluecheatsheet1';
import CrowdThreatFeed from './components/hybridanalysis/hybridfeedpage';
import HybridSearch from './components/hybridanalysis/search';
import GTFOBinsIframe from './components/redmenu/gtfobins';
import EsecurityFrame from './components/BlueSupport/esecurity';
import AdamCaudill from './components/BlueSupport/AdamCaudill';
import RedTeamPentestingInfo from './components/redmenu/redteampen';
import DataScienceDashboard from './components/DataScience/DataScienceBig';
import DataResources from './components/DataScience/dbs/datasciencedbs';
import DataScienceTools from './components/DataScience/tools/datasciencetools';
import ProgrammingOverview from './components/programming/programmingo';
import ProgrammingLanguagesAndTools from './components/programming/languages/programmingl';
import ManagementPol from './components/management/policies/policies';
import ITManagementOverview from './components/management/overviewman';
import CERTSMAN from './components/management/certs/certsman';
import ITSALARIES from './components/management/salaries/itsalaries';
import OSINTFrame from './components/redmenu/osintframe';
import RedToolSearchPage from './components/RedTools/ToolSearch';
import RedToolWriteForm from './components/RedTools/ToolSubmit';
import BlueToolWriteForm from './components/BlueTools/ToolSubmit';
import BlueToolSearchPage from './components/BlueTools/ToolSearch';
import LessonsLearnedWriteForm from './components/LessonsLearned/LessonsSubmit';
import LessonsLearnedReadForm from './components/LessonsLearned/LessonsList';
import CloudToolWriteForm from './components/CloudTools/cloudsubmit';
import CloudSearchPage from './components/CloudTools/cloudsearch';
import DataSciToolWriteForm from './components/DataScienceTools/datascisubmit';
import DataSciSearchPage from './components/DataScienceTools/datascisearch';
import ProgrammingToolWriteForm from './components/ProgrammingTools/programmingsubmit';
import ProgammingSearchPage from './components/ProgrammingTools/programmingsearch';
import CloudCLIForm from './components/CloudCLI/CloudCLIForm';
import CloudCLISearchPage from './components/CloudCLI/CloudCLISearch';
import SOCFrame from './components/SOC/socframe';
import SOCInstall from './components/SOC/instructions';
import StacksWriteForm from './components/ProgrammingStacks/PStackSubmit';
import StacksReadForm from './components/ProgrammingStacks/PStackList';
import Mentors from './components/Mentors/mentors';
import AboutYou from './components/users/aboutyou';
import MentorshipGuide from './components/Mentors/guide/mentorguide';
import MentorshipConduct from './components/Mentors/conduct/mentorconduct';
import CommendationSubmission from './components/Mentors/commendations/givecommendation';
import MentorForm from './components/Mentors/becomementor/mentorform';
import Campbell from './components/Campbell/Campbell';
//import InstrumentList from './components/DukaAPI/InstrumentCheck';
//import alphaScrollWidget from './components/StockTickerWidget/tickerWidget.js';

const Routes = () => {
  const classes = useMainPaperStyles();
  // Function to check if the user has readifyUserKey in local storage cookies
  const isUserAuthenticated = () => {
    const readifyUserKey = localStorage.getItem('readifyUserKey');
    return readifyUserKey !== null;
  };
  

  const openNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Switch>
      {/* Route for /privacy */}
    <Route exact path="/privacy" component={PrivacyPolicy} />
    
    {/* Route for /guidelines */}
    <Route exact path="/guidelines" component={UserGuidelines} />
      {/* Redirect to /start if the user is not authenticated */}
      {isUserAuthenticated() ? (
        <Route>
          <Route exact path="/">
            <HomeFormat />
          </Route>
          <Route exact path="/feeds">
            <RSSFeeds />
          </Route>
          <Route exact path="/cybrary" component={CybraryPromoBanner} />
          <Route exact path="/coursera" component={CourseraPromoBanner} />
          <Route exact path="/sans" component={SANSPromoBanner} />
          <Route exact path="/krebs" component={KrebsPromoBanner} />
          <Route exact path="/hackernews" component={HackerNewsPromoBanner} />
          <Route exact path="/troy" component={TroyHuntPromoBanner} />
          <Route exact path="/bluevillage" component={BlueTeamVillagePromoBanner} />
          <Route exact path="/hak5" component={Hak5PromoBanner} />
          <Route exact path="/cybermentor" component={CyberMentorPromoBanner} />
          <Route exact path="/johnhammond" component={JohnHammondPromoBanner} />
          <Route exact path="/donmurdoch" component={DonMurdochPromoBanner} />
          <Route exact path="/amandaberlin" component={AmandaBerlinPromoBanner} />
          <Route exact path="/richardbejt" component={RichardBejtlichPromoBanner} />
          <Route exact path="/asknetsec" component={RedditPromoBanner} />
          <Route exact path="/defensivesec" component={DefensiveSecurityPromoBanner} />
          <Route exact path="/isc2" component={ISC2CommunityPromoBanner} />
          <Route exact path="/blackhat" component={BlackHatPromoBanner} />
          <Route exact path="/defcon" component={DEFCONPromoBanner} />
          <Route exact path="/bsides" component={BSidesPromoBanner} />
          <Route exact path="/bluelinkedin" component={BlueTeamLinkedInPromoBanner} />
          <Route exact path="/bluefacebook" component={BlueTeamFacebookPromoBanner} />
          <Route exact path="/bluetwitter" component={BlueTeamTwitterPromoBanner} />
          <Route exact path="/malpedia" component={MalpediaPromoBanner} />
          <Route exact path="/dfir" component={DFIRReportPromoBanner} />
          <Route exact path="/elastic" component={ElasticPromoBanner} />
          <Route exact path="/splunk" component={SplunkPromoBanner} />
          <Route exact path="/FLARE" component={FlareVMPromoBanner} />
          <Route exact path="/SIFT" component={SiftVMPromoBanner} />
          <Route exact path="/MSentinel" component={AzureSentinelPromoBanner} />
          <Route exact path="/CloudWatch" component={AWSCloudWatchPromoBanner} />
          <Route exact path="/gcloudmonitor" component={GoogleCloudLoggingPromoBanner} />
          <Route exact path="/TPOT" component={TPotPromoBanner} />
          <Route exact path="/carbonblack" component={CarbonBlackPromoBanner} />
          <Route exact path="/stealthwatch" component={CiscoStealthwatchPromoBanner} />
          <Route exact path="/roadmap" component={CertificationRoadmap} />
          <Route exact path="/cheatsheet" component={CheatSheets} />
          <Route exact path="/cheatlogging" component={Logging} />
          <Route exact path="/chtsh" component={ChtSh} />
          <Route exact path="/mitrematrix" component={MitreAttackMatrix} />
          <Route exact path="/joessand" component={JoeSandboxAnalysis} />
          <Route exact path="/CAPE" component={Capev2GitHub} />
          <Route exact path="/IOCSub" component={CybersecurityIOCForm} />
          <Route exact path="/IOCSearch" component={IOCSearchPage} />
          <Route exact path="/QSub" component={CybersecurityQueryForm} />
          <Route exact path="/QSearch" component={QuerySearchPage} />
          <Route exact path="/support" component={SupportPage} />
          <Route exact path="/cstrike" component={CobaltStrikeInfo} />
          <Route exact path="/oscp" component={OSCPInfo} />
          <Route exact path="/metasploit" component={MetasploitInfo} />
          <Route exact path="/hackthebox" component={HackTheBoxInfo} />
          <Route exact path="/powerempire" component={PowerShellEmpireInfo} />
          <Route exact path="/ratel" component={BruteRatelInfo} />
          <Route exact path="/kali" component={KaliLinuxInfo} />
          <Route exact path="/offsec" component={OffensiveSecurityInfo} />
          <Route exact path="/blackhills" component={BlackHillsSecurityInfo} />
          <Route exact path="/mapt" component={IframeMitreAttackGroups} />
          <Route exact path="/mactors" component={IframeMalpediaActors} /> 
          <Route exact path="/mfamilies" component={IframeMalpediaFamilies} />
          <Route exact path="/rssomany" component={RSSFeedsSoMany} />
          <Route exact path="/exploitsub" component={CybersecurityExploitForm} />
          <Route exact path="/exploitsearch" component={ExploitSearchPage} />
          <Route exact path="/hacktricks" component={HackTricksInfo} />
          <Route exact path="/bazaar" component={BazaarInfo} />
          <Route exact path="/exploitdb" component={ExploitDBInfo} />
          <Route exact path="/k7" component={K7ComputingBlogInfo} />
          <Route exact path="/sliver" component={SliverGitHubInfo} />
          <Route exact path="/VT" component={VirusTotalIframe} />
          <Route exact path="/chainsaw" component={ChainsawGitHubInfo} />
          <Route exact path="/udemy" component={UdemyInfo} />
          <Route exact path="/pluralsight" component={PluralsightInfo} />
          <Route exact path="/edX" component={EdXInfo} />
          <Route exact path="/llearning" component={LinkedInLearningInfo} />
          <Route exact path="/skillshare" component={SkillshareInfo} />
          <Route exact path="/khanacademy" component={KhanAcademyInfo} />
          <Route exact path="/treehouse" component={TreehouseInfo} />
          <Route exact path="/udacity" component={UdacityInfo} />
          <Route exact path="/codecademy" component={CodecademyInfo} />
          <Route exact path="/mitopen" component={MITOpenCourseWareInfo} />
          <Route exact path="/harvardopen" component={HarvardOnlineCoursesInfo} />
          <Route exact path="/google" component={GoogleInformationalPage} />
          <Route exact path="/youtube" component={CybersecurityYouTube} />
          <Route exact path="/reddit" component={CybersecurityReddit} />
          <Route exact path="/stackoverflow" component={StackOverflowPage} />
          <Route exact path="/ChatGPT" component={ChatGPTPage} />
          <Route exact path="/NetworkDefense" component={NetworkDefenseInfo} />
          <Route exact path="/overthewire" component={OverTheWireInfo} />
          <Route exact path="/underthewire" component={UnderTheWireInfo} />
          <Route exact path="/AWSapp" component={AWSInfo} />
          <Route exact path="/Azureapp" component={AzureInfo} />
          <Route exact path="/GoogleCloudapp" component={GoogleCloudInfo} />
          <Route exact path="/IBMCloudapp" component={IBMCloudInfo} />
          <Route exact path="/OracleCloudapp" component={OracleCloudInfo} />
          <Route exact path="/vvmware" component={VMwareInfo} />
          <Route exact path="/vbox" component={VirtualBoxInfo} />
          <Route exact path="/proxmox" component={ProxmoxInfo} />
          <Route exact path="/vrh" component={RedHatVirtualizationInfo} />
          <Route exact path="/kubernetes" component={KubernetesInfo} />
          <Route exact path="/terraform" component={TerraformInfo} />
          <Route exact path="/carchitectures" component={CloudArchitecturePage} />
          <Route exact path="/SANScloud" component={SansCloudCertifications} />
          <Route exact path="/AWSCert" component={AWSCloudCertifications} />
          <Route exact path="/AzureCert" component={AzureCloudCertifications} />
          <Route exact path="/GCloudCert" component={GoogleCloudCertifications} />
          <Route exact path="/IBMCloudCert" component={IBMCertifications} />
          <Route exact path="/OracleCloudCert" component={OracleCloudCertifications} />
          <Route exact path="/revshells" component={RevShellsIframe} />
          <Route exact path="/NVDSearch" component={CVESearch} />
          <Route exact path="/cdefend" component={CyberDefenders} />
          <Route exact path="/FireEye" component={FireEyeInfo} />
          <Route exact path="/Talos" component={TalosInfo} />
          <Route exact path="/cloudflare" component={CloudflareInfo} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/guidelines" component={UserGuidelines} />
          <Route exact path="/bluecheat1" component={BlueTeamCheatSheets} />
          <Route exact path="/hybridfeed" component={CrowdThreatFeed} />
          <Route exact path="/hybridsearch" component={HybridSearch} />
          <Route exact path="/gtfobins" component={GTFOBinsIframe} />
          <Route exact path="/esecuritythreat" component={EsecurityFrame} />
          <Route exact path="/adamcaudill" component={AdamCaudill} />
          <Route exact path="/start" component={AuthTwo} />
          <Route exact path="/redteamde" component={RedTeamPentestingInfo} />
          <Route exact path="/d1" component={DataScienceDashboard} />
          <Route exact path="/d3" component={DataResources} />
          <Route exact path="/d2" component={DataScienceTools} />
          <Route exact path="/p1" component={ProgrammingOverview} />
          <Route exact path="/p2" component={ProgrammingLanguagesAndTools} />
          <Route exact path="/man1" component={ITManagementOverview} />
          <Route exact path="/man2" component={ManagementPol} />
          <Route exact path="/man4" component={CERTSMAN} />
          <Route exact path="/man3" component={ITSALARIES} />
          <Route exact path="/osintframe" component={OSINTFrame} />
          <Route exact path="/redtool-w" component={RedToolWriteForm} />
          <Route exact path="/redtool-r" component={RedToolSearchPage} />
          <Route exact path="/bluetool-w" component={BlueToolWriteForm} />
          <Route exact path="/bluetool-r" component={BlueToolSearchPage} />
          <Route exact path="/lessons-w" component={LessonsLearnedWriteForm} />
          <Route exact path="/lessons-r" component={LessonsLearnedReadForm} />
          <Route exact path="/cloud-w" component={CloudToolWriteForm} />
          <Route exact path="/cloud-r" component={CloudSearchPage} />
          <Route exact path="/datasci-w" component={DataSciToolWriteForm} />
          <Route exact path="/datasci-r" component={DataSciSearchPage} />
          <Route exact path="/prog-w" component={ProgrammingToolWriteForm} />
          <Route exact path="/prog-r" component={ProgammingSearchPage} />
          <Route exact path="/cloudcli-w" component={CloudCLIForm} />
          <Route exact path="/cloudcli-r" component={CloudCLISearchPage} />
          <Route exact path="/SOCframe" component={SOCFrame} />
          <Route exact path="/SOCinstall" component={SOCInstall} />
          <Route exact path="/stacks-w" component={StacksWriteForm} />
          <Route exact path="/stacks-r" component={StacksReadForm} />
          <Route exact path="/mentorship" component={Mentors} />
          <Route exact path="/aboutyou" component={AboutYou} />
          <Route exact path="/mguide" component={MentorshipGuide} />
          <Route exact path="/mconduct" component={MentorshipConduct} />
          <Route exact path="/gcommendation" component={CommendationSubmission} />
          <Route exact path="/mentorform" component={MentorForm} />
          <Route exact path="/Campbell" component={Campbell} />
          <Route exact path="/feedsUS">
            <RSSFeedsUS />
          </Route>
          <Route exact path="/feedsGlobal">
            <RSSFeedsGlobal />
          </Route>
          <Route exact path="/comments/:id">
            <PostCommentsPage />
          </Route>
          <Route exact path="/u/:username">
            <UserPage />
          </Route>
          <Route exact path="/i/:sub">
            <SubPage />
          </Route>
          <Route exact path="/search/:query">
            <SearchResults />
          </Route>
        </Route>
      ) : (
        <Route>
          <Route path="*">
            {/* Fallback route for unknown paths */}
            <Redirect to="/start" />
          </Route>
          <Route exact path="/start" component={AuthTwo} />
        </Route>
      )}
    </Switch>
  );
};

export default Routes;