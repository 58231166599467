import React from 'react';

const IBMCertifications = () => {
  const ibmCloudCertifications = [
    {
      name: 'IBM Certified Solution Advisor - Cloud Computing Architecture',
      description: 'Designed for individuals who can clearly explain the benefits and underlying concepts of cloud computing, as well as demonstrate how to implement the cloud.',
      link: 'https://www.ibm.com/cloud/learn/certifications/cloud-computing-architecture',
    },
    {
      name: 'IBM Certified Solution Architect - Cloud Computing Infrastructure',
      description: 'For architects who can design, plan, and architect a cloud infrastructure solution using IBM Cloud services.',
      link: 'https://www.ibm.com/cloud/learn/certifications/cloud-computing-infrastructure',
    },
    {
      name: 'IBM Certified Solution Architect - Cloud Platform Solution',
      description: 'Designed for architects who can design, plan, and architect a cloud platform solution using IBM Cloud services.',
      link: 'https://www.ibm.com/cloud/learn/certifications/cloud-platform-solution',
    },
    {
      name: 'IBM Certified Solution Architect - Watson IoT Maximo V1',
      description: 'For architects who can design, plan, and architect a Watson IoT Maximo V1 solution using IBM Cloud services.',
      link: 'https://www.ibm.com/cloud/learn/certifications/solution-architect-watson-iot-maximo-v1',
    },
    {
      name: 'IBM Certified Developer - Cloud Pak for Automation V20.0.1',
      description: 'For developers who can design, develop, and deploy cloud-based applications using Cloud Pak for Automation.',
      link: 'https://www.ibm.com/cloud/learn/certifications/developer-cloud-pak-automation-v20.0.1',
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <header>
        <h1 className="title">IBM Cloud Certifications</h1>
      </header>
      <section className="certifications">
        {ibmCloudCertifications.map((certification, index) => (
          <div key={index} className="certification">
            <h2 className="certification-name">{certification.name}</h2>
            <p className="certification-description">{certification.description}</p>
            <p className="certification-link">
              <a
                href={certification.link}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                Learn more
              </a>
            </p>
          </div>
        ))}
      </section>
    </div>
  );
};

export default IBMCertifications;