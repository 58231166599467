import React from 'react';
import './yeah.css';

function ITManagementOverview() {
  return (
    <div className="it-management-overview">
      <h1>Information Technology Management (ITM)</h1>

      {/* Section 1: Purpose of IT Management */}
      <section className="section">
        <h2>Purpose of IT Management</h2>
        <p>
          IT Management is the discipline of planning, organizing, and controlling an organization's information technology resources to achieve business goals. Its primary purpose is to align IT with business objectives and ensure efficient technology utilization.
        </p>
      </section>

      {/* Section 2: Facets of IT Management */}
      <section className="section">
        <h2>Facets of IT Management</h2>
        <ul>
          <li>Strategic Planning and Alignment</li>
          <li>IT Governance and Compliance</li>
          <li>IT Service Management (ITSM)</li>
          <li>Infrastructure and Operations Management</li>
          <li>Cybersecurity and Data Protection</li>
          <li>Project and Portfolio Management</li>
          <li>Vendor and Contract Management</li>
          {/* Add more facets */}
        </ul>
      </section>

      {/* Section 3: Roles in IT Management */}
      <section className="section">
        <h2>Roles in IT Management</h2>
        <ul>
          <li>Chief Information Officer (CIO)</li>
          <li>IT Managers and Directors</li>
          <li>IT Architects</li>
          <li>IT Project Managers</li>
          <li>Security Analysts</li>
          <li>Database Administrators</li>
          <li>Support and Helpdesk Teams</li>
          {/* Add more roles */}
        </ul>
      </section>

      {/* Section 4: Historical Examples */}
      <section className="section">
        <h2>Historical Examples</h2>
        <ol>
          <li>
            <strong>The Y2K Bug (Year 2000)</strong><br />
            IT management played a critical role in addressing the Y2K bug, where systems needed updates to handle the year 2000 correctly.
          </li>
          <li>
            <strong>The Dot-Com Bubble (Late 1990s)</strong><br />
            IT management was central in the rise and fall of numerous dot-com companies during the late 1990s.
          </li>
          <li>
            <strong>The Launch of Cloud Computing (2000s)</strong><br />
            The emergence of cloud computing transformed how IT resources were managed and accessed.
          </li>
          <li>
  <strong>The Enron Scandal (2001)</strong><br />
  The Enron scandal revealed fraudulent financial practices, showing the importance of ethical IT management in financial institutions.
</li>
<li>
  <strong>The Healthcare.gov Launch (2013)</strong><br />
  The problematic launch of the Healthcare.gov website highlighted the consequences of poor project management and testing in a high-profile government project.
</li>
<li>
  <strong>The Success of Amazon Web Services (AWS)</strong><br />
  The growth and success of AWS demonstrated effective cloud infrastructure management and its impact on modern IT operations.
</li>
<li>
  <strong>The Sony PlayStation Network Hack (2011)</strong><br />
  The Sony PlayStation Network breach underscored the significance of cybersecurity and data protection in IT management.
</li>
<li>
  <strong>The Introduction of Agile Development (2000s)</strong><br />
  The adoption of Agile methodologies revolutionized software development practices, emphasizing collaboration and adaptability.
</li>
<li>
  <strong>The Failure of the Denver International Airport Baggage System (1995)</strong><br />
  The troubled implementation of the baggage system at Denver International Airport demonstrated the importance of thorough testing and project planning.
</li>
<li>
  <strong>The Rise of DevOps (2010s)</strong><br />
  The DevOps movement emphasized the integration of development and operations, improving software delivery and infrastructure management.
</li>
<li>
  <strong>The Equifax Data Breach (2017)</strong><br />
  The Equifax breach emphasized the need for proactive security measures and the consequences of inadequate IT security management.
</li>
<li>
  <strong>The Introduction of Virtualization (2000s)</strong><br />
  The adoption of virtualization technologies transformed server management, leading to more efficient resource utilization.
</li>
<li>
  <strong>The Transition to Remote Work (2020s)</strong><br />
  The COVID-19 pandemic accelerated the adoption of remote work solutions, requiring effective IT management to support distributed teams.
</li>
<li>
  <strong>The Volkswagen Emissions Scandal (2015)</strong><br />
  The Volkswagen scandal highlighted the ethical implications of software manipulation in automobiles and the role of IT in regulatory compliance.
</li>
        </ol>
      </section>

      {/* Section 5: Critical Functions */}
      <section className="section">
        <h2>Critical Functions</h2>
        <ul>
          <li>Strategic IT Planning</li>
          <li>Budgeting and Resource Allocation</li>
          <li>Risk Management</li>
          <li>IT Security and Compliance</li>
          <li>Change Management</li>
          <li>Service Level Management</li>
          <li>Vendor and Contract Management</li>
          {/* Add more critical functions */}
        </ul>
      </section>

      {/* Section 6: Essential Knowledge */}
      <section className="section">
        <h2>Essential Knowledge</h2>
        <ul>
          <li>Technology Trends and Innovations</li>
          <li>Project Management Methodologies</li>
          <li>Legal and Regulatory Requirements</li>
          <li>Business Process Understanding</li>
          <li>Data Management and Analytics</li>
          <li>Communication and Leadership Skills</li>
          {/* Add more essential knowledge */}
        </ul>
      </section>
    </div>
  );
}

export default ITManagementOverview;