import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import SubFormModal from '../SubFormModal';
import LoadingSpinner from '../LoadingSpinner';
import storageService from '../../utils/localStorage';

import {
  Paper,
  Typography,
  useMediaQuery,
  Link,
} from '@material-ui/core';
import { useSubPanelStyles } from '../../styles/muiStyles';
import { useTheme } from '@material-ui/core/styles';
import './leaderboard.css';

const TopMeritPanel = () => {
  const { users } = useSelector((state) => state);
  const classes = useSubPanelStyles();
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(true);
  const [topUsers, setTopUsers] = useState([]);

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        const response = await fetch('/api/users/topmerit'); 
        const data = await response.json();
        if (response.ok) {
          setTopUsers(data.users);
        } else {
          console.error(data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchTopUsers();
  }, []);

  if (isNotDesktop) {
    return null;
  }

  const loggedUser = storageService.loadUser() || (users && users.user);

  const loadingUsers = !users || !users.topUsers;

  return (
    <Paper className={`mainPaper ${classes.mainPaper}`} style={{ backgroundColor: 'transparent' }}>
      <Paper className={`listPaper ${classes.listPaper}`}>
        <Typography variant="h5" color="secondary" className={`title ${classes.title}`}>
          Top Merit
        </Typography>
        {loading ? (
          <LoadingSpinner text="Fetching users data..." />
        ) : (
          topUsers.slice(0, 5).map((user, i) => (
            <div key={user.id} className={`listWrapper ${classes.listWrapper}`}>
              <Typography variant="body2" className={`listItem ${classes.listItem}`}>
                {`${i + 1}. `}
                <Link
                  component={RouterLink}
                  to={`/u/${user.username}`}
                  color="primary"
                >
                  {user.username}
                </Link>
              </Typography>
              {/* Render additional information about the user */}
              <Typography variant="body2" className={`karmaPoints ${classes.karmaPoints}`}>
                {`Merit: ${
                  user.totalKarma
                }`}
              </Typography>
            </div>
          ))
        )}
      </Paper>
    </Paper>
  );
};

export default TopMeritPanel;