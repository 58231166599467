import React from 'react';

const GoogleCloudInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#4285F4', // Google Cloud brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  const buttonStyle = {
    backgroundColor: '#232323',
    color: 'white',
    padding: '10px 20px',
    fontSize: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Innovate with Google Cloud</h1>
      <p>
        Google Cloud is a leading cloud computing platform and service offered by Google. It empowers businesses to build, deploy, and scale applications using Google's infrastructure and services.
      </p>
      <h2 style={subheadingStyle}>Key Advantages of Google Cloud:</h2>
      <ul>
        <li>Scalable and reliable cloud infrastructure</li>
        <li>Comprehensive set of cloud services</li>
        <li>Data analytics and machine learning capabilities</li>
        <li>Global network and data center presence</li>
        <li>Security and compliance features</li>
        <li>Developer-friendly tools and APIs</li>
      </ul>
      <button onClick={() => window.open("https://cloud.google.com/", "_blank")} style={buttonStyle}>Explore Google Cloud</button>
    </div>
  );
};

export default GoogleCloudInfo;