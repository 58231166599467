import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

function CVESearch() {
  const [searchParams, setSearchParams] = useState({
    cpeName: '',
    cveId: '',
    keyword: '', // Updated to match server-side (previously 'keywordSearch')
    cvssV3Severity: '', // Updated to match server-side
  });
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const queryParams = new URLSearchParams(searchParams);
      const response = await fetch(`/api/cves?${queryParams}`);
      const responseData = await response.json();

      if (responseData.vulnerabilities && responseData.vulnerabilities.length > 0) {
        setSearchResults(responseData.vulnerabilities);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Error searching for CVEs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" gutterBottom>
        CVE Search
      </Typography>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <form onSubmit={handleSearch}>
          <TextField
            label="CVE ID"
            name="cveId"
            fullWidth
            variant="outlined"
            margin="normal"
            value={searchParams.cveId}
            onChange={handleChange}
          />
          <TextField
            label="Keywords"
            name="keyword" // Updated to match server-side
            fullWidth
            variant="outlined"
            margin="normal"
            value={searchParams.keyword} // Updated to match server-side
            onChange={handleChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Severity</InputLabel>
            <Select
              label="Severity"
              name="cvssV3Severity" // Updated to match server-side
              value={searchParams.cvssV3Severity} // Updated to match server-side
              onChange={handleChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="LOW">Low</MenuItem>
              <MenuItem value="MEDIUM">Medium</MenuItem>
              <MenuItem value="HIGH">High</MenuItem>
              <MenuItem value="CRITICAL">Critical</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            onClick={handleSearch}
          >
            Search
          </Button>
        </form>

        {/* Search results */}
        {loading && <CircularProgress style={{ marginTop: '20px' }} />}
        {searchResults.length > 0 && (
  <div style={{ marginTop: '20px' }}>
    <Typography variant="h6">Search Results:</Typography>
    {searchResults.map((result, index) => (
      <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '20px' }}>
        <Typography variant="h6">CVE ID: {result?.cve?.id || 'N/A'}</Typography>
        <strong>CISA Action Due:</strong> {result?.cve?.cisaActionDue || 'N/A'} <br />
        <strong>CISA Exploit Add:</strong> {result?.cve?.cisaExploitAdd || 'N/A'} <br />
        <strong>CISA Required Action:</strong> {result?.cve?.cisaRequiredAction || 'N/A'} <br />
        <strong>CISA Vulnerability Name:</strong> {result?.cve?.cisaVulnerabilityName || 'N/A'} <br />
        <strong>Description:</strong> {result?.cve?.descriptions?.[0]?.value || 'N/A'} <br />
        <strong>Published:</strong> {result?.cve?.published || 'N/A'} <br />
        <strong>CVSS Data (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.vectorString || 'N/A'} <br /><strong>CVSS Version (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.version || 'N/A'} <br />
<strong>CVSS Version (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.version || 'N/A'} <br />

<strong>Vector String (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.vectorString || 'N/A'} <br />
<strong>Vector String (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.vectorString || 'N/A'} <br />

<strong>Attack Vector (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.attackVector || 'N/A'} <br />
<strong>Attack Vector (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.attackVector || 'N/A'} <br />

<strong>Attack Complexity (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.attackComplexity || 'N/A'} <br />
<strong>Attack Complexity (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.attackComplexity || 'N/A'} <br />

<strong>Availability Impact (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.availabilityImpact || 'N/A'} <br />
<strong>Availability Impact (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.availabilityImpact || 'N/A'} <br />

<strong>Base Score (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.baseScore || 'N/A'} <br />
<strong>Base Score (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.baseScore || 'N/A'} <br />

<strong>Base Severity (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.baseSeverity || 'N/A'} <br />
<strong>Base Severity (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.baseSeverity || 'N/A'} <br />

<strong>Confidentiality Impact (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.confidentialityImpact || 'N/A'} <br />
<strong>Confidentiality Impact (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.confidentialityImpact || 'N/A'} <br />

<strong>Integrity Impact (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.integrityImpact || 'N/A'} <br />
<strong>Integrity Impact (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.integrityImpact || 'N/A'} <br />

<strong>Privileges Required (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.privilegesRequired || 'N/A'} <br />
<strong>Privileges Required (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.privilegesRequired || 'N/A'} <br />

<strong>Scope (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.scope || 'N/A'} <br />
<strong>Scope (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.scope || 'N/A'} <br />

<strong>User Interaction (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.cvssData?.userInteraction || 'N/A'} <br />
<strong>User Interaction (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.cvssData?.userInteraction || 'N/A'} <br />

<strong>Exploitability Score (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.exploitabilityScore || 'N/A'} <br />
<strong>Exploitability Score (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.exploitabilityScore || 'N/A'} <br />

<strong>Impact Score (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.impactScore || 'N/A'} <br />
<strong>Impact Score (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.impactScore || 'N/A'} <br />

<strong>Source (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.source || 'N/A'} <br />
<strong>Source (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.source || 'N/A'} <br />

<strong>Type (Primary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[0]?.type || 'N/A'} <br />
<strong>Type (Secondary):</strong> {result?.cve?.metrics?.cvssMetricV31?.[1]?.type || 'N/A'} <br /><strong>References:</strong> <br />
        {result?.cve?.references?.map((ref, refIndex) => (
          <div key={refIndex}>
            <a href={ref?.url} target="_blank" rel="noopener noreferrer">{ref?.tags?.join(', ')}</a>
          </div>
        ))}
        <strong>Source Identifier:</strong> {result?.cve?.sourceIdentifier || 'N/A'} <br />
        <strong>Vulnerability Status:</strong> {result?.cve?.vulnStatus || 'N/A'} <br />
        <strong>Weaknesses:</strong> <br />
        {result?.cve?.weaknesses?.map((weak, weakIndex) => (
          <div key={weakIndex}>
            <strong>Weakness (Source {weakIndex + 1}):</strong> {weak?.description?.[0]?.value || 'N/A'} <br />
          </div>
        ))}
      </div>
    ))}
  </div>
)}
      </Paper>
    </Container>
  );
}

export default CVESearch;