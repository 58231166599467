import React, { useState, useEffect } from "react";
import "./chat.css";
import { Link } from "react-router-dom";
const Chat = () => {
  // State to manage messages
  const [messages, setMessages] = useState([]);
  // State to manage user input
  const [newMessage, setNewMessage] = useState("");
  // State to manage the selected receiver
  const [selectedReceiver, setSelectedReceiver] = useState(null);
  // State to store the list of users fetched from the backend
  const [users, setUsers] = useState([]);
  const [openChats, setOpenChats] = useState([]);
  const [chatRequests, setChatRequests] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [showChatRequests, setShowChatRequests] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const readifyUserKey = JSON.parse(localStorage.getItem("readifyUserKey"));
  const loggedInUser = readifyUserKey ? readifyUserKey.username : null;
  const [acceptedChatRequests, setAcceptedChatRequests] = useState([]);
  //const [chatPopupUser, setChatPopupUser] = useState(null);
  const [openChatPopups, setOpenChatPopups] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  //thanks

  useEffect(() => {
    // Check screen size and update isSmallScreen state
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767); // Adjust the screen width as needed
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchChatData = () => {
    // Fetch chat requests (unchanged)
    fetch(`/api/chat-requests/${loggedInUser}`)
      .then((response) => response.json())
      .then((data) => {
        setChatRequests(data);
      })
      .catch((error) => {
        console.error("Error fetching chat requests:", error);
      });

    // Fetch accepted chat requests (unchanged)
    fetch(`/api/chat-requests/accepted/${loggedInUser}`)
      .then((response) => response.json())
      .then((data) => {
        setAcceptedChatRequests(data);
      })
      .catch((error) => {
        console.error("Error fetching accepted chat requests:", error);
      });
  };

  // Update the timer to periodically fetch data, including messages
  useEffect(() => {
    let intervalId; // Declare intervalId variable outside of the if block

    if (isMenuOpen) {
      intervalId = setInterval(fetchChatData, 1000); // Fetch data every 5 seconds
    }

    // Clear the timer when the component unmounts
    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [isMenuOpen]);

  // Set up a timer to periodically fetch chat messages for open chats
  useEffect(() => {
    const messageUpdateInterval = setInterval(() => {
      if (isMenuOpen) {
        // Fetch data only when the menu is open
        openChatPopups.forEach((requestor) => {
          // Fetch and update chat messages
          fetch(`/api/messages/${loggedInUser}/${requestor}`)
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                // Update the chat state with the fetched messages
                const updatedChats = [...messages];
                const chatIndex = updatedChats.findIndex(
                  (chat) => chat.receiver === requestor
                );

                if (chatIndex !== -1) {
                  updatedChats[chatIndex].content = data.message;
                } else {
                  updatedChats.push({
                    receiver: requestor,
                    content: data.message,
                  });
                }

                setMessages(updatedChats);
              } else {
                console.error("Error fetching chat messages:", data.message);
              }
            })
            .catch((error) => {
              console.error("Error fetching chat messages:", error);
            });
        });
      }
    }, 1000); // Fetch messages every 5 seconds

    // Clear the timer when the component unmounts
    return () => clearInterval(messageUpdateInterval);
  }, [isMenuOpen, openChatPopups, messages, loggedInUser]);

  useEffect(() => {
    // Fetch chat requests for the logged-in user
    fetch(`/api/chat-requests/${loggedInUser}`)
      .then((response) => response.json())
      .then((data) => { 
        setChatRequests(data); // Assuming the response contains pending chat requests
      })
      .catch((error) => {
        console.error("Error fetching chat requests:", error);
      });
  }, [loggedInUser]);

  const acceptChatRequest = (requestor) => {
    // Define the sender (loggedInUser) and receiver (requestor)
    const sender = loggedInUser;

    // Make an API call to accept the chat request with both sender and receiver
    fetch(`/api/chat-requests/accept/${requestor}/${sender}`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Handle success
          console.log(requestor, "and", loggedInUser, "chat request accepted");

          // Update the acceptedChatRequests state
          setAcceptedChatRequests([...acceptedChatRequests, requestor]);

          // Optionally, you can also open the chat popup here if needed
          openChatPopup(requestor);
        } else {
          // Handle error
          console.error("Error accepting chat request:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error accepting chat request:", error);
      });
  };

  useEffect(() => {
    fetch(`/api/chat-requests/accepted/${loggedInUser}`)
      .then((response) => response.json())
      .then((data) => {
        setAcceptedChatRequests(data); // Assuming the response contains accepted chat requests
      })
      .catch((error) => {
        console.error("Error fetching accepted chat requests:", error);
      });
  }, [loggedInUser]);

  useEffect(() => {
    // Check if any chat request has been accepted
    acceptedChatRequests.forEach((acceptedRequest) => {
      // Automatically open the chat popup for each accepted request
      openChatPopup(acceptedRequest.requestor);
    });
  }, [acceptedChatRequests, chatRequests]);

  const declineChatRequest = (requestor) => {
    // Define the sender (loggedInUser) and receiver (requestor)
    const sender = loggedInUser;

    // Make an API call to accept the chat request with both sender and receiver
    fetch(`/api/chat-requests/decline/${requestor}/${sender}`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Handle success
          console.log(requestor, "and", loggedInUser, "chat request accepted");

          // You can optionally update the state of chatRequests here if needed
        } else {
          // Handle error
          console.error("Error accepting chat request:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error accepting chat request:", error);
      });
  };

  const toggleChatRequests = () => {
    setShowChatRequests(!showChatRequests);
  };

  // Function to handle receiver selection

  useEffect(() => {
    // Check if any chat request has been accepted
    acceptedChatRequests.forEach((acceptedRequest) => {
      // Automatically open the chat popup for each accepted request
      openChatPopup(acceptedRequest.requestor);
    });
  }, [acceptedChatRequests, openChatPopups]);

  // Function to open a chat popup for a specific requestor
  const openChatPopup = (requestor) => {
    if (!openChatPopups.includes(requestor)) {
      // Create a new array with the added requestor
      const updatedOpenChatPopups = [...openChatPopups, requestor];
      setOpenChatPopups(updatedOpenChatPopups);
    }
  };

  const sendMessageToChat = () => {
    if (newMessage.trim() === "") {
      return; // Prevent sending empty messages
    }

    // Make an API call to add the message to the chat entry's content
    const message = {
      sender: loggedInUser,
      receiver: selectedChat.receiver,
      content: newMessage,
      timestamp: new Date().toISOString(),
    };

    // Make an API call to add the message to the chat entry's content
    fetch(`/api/messages/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Message sent successfully, update the chat state
          const updatedChatContent = [...selectedChat.content, message];
          setSelectedChat({
            ...selectedChat,
            content: updatedChatContent,
          });

          // Clear the message input field
          setNewMessage("");
        } else {
          // Handle error
          console.error("Error sending message:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  // Function to expand the chat popup for a specific requestor
  const expandChatPopup = (requestor) => {
    // Toggle chat popup
    if (openChatPopups.includes(requestor)) {
      setOpenChatPopups(openChatPopups.filter((item) => item !== requestor));
    } else {
      setOpenChatPopups([...openChatPopups, requestor]);
    }

    // Make an API call to check messages and fetch chat history for the selected chat
    fetch(`/api/messages/${loggedInUser}/${requestor}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.message)) {
            // Check if message is an array
            if (data.message.length === 0) {
              // If no messages found, create a new chat entry
              createNewChatEntry(loggedInUser, requestor);
            } else {
              // Update the selectedChat state with the fetched messages
              setSelectedChat({
                receiver: requestor,
                content: data.message,
              });
            }
          } else {
            // Handle the case where data.message is not an array (error or unexpected response)
            console.error("Invalid response data:", data.message);
          }
        } else {
          // Handle error or display a message indicating no chat history
          console.error("Error fetching chat history:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching chat history:", error);
      });
  };

  // Function to create a new chat entry
  const createNewChatEntry = (loggedInUser, requestor) => {
    // Use requestor here
    const newChatEntry = {
      sender: loggedInUser,
      receiver: requestor, // Use requestor here
      content: [],
    };

    // Make an API call to create a new chat entry
    fetch("/api/messages/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newChatEntry),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // New chat entry created successfully, update the selectedChat state
          setSelectedChat({
            receiver: requestor, // Use requestor here
            content: [],
          });
        } else {
          // Handle error
          console.error("Error creating new chat entry:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error creating new chat entry:", error);
      });
  };
  
  const mentorChatPopups = acceptedChatRequests.filter(
    (acceptedRequest) => acceptedRequest.mentor === true
  );

  const nonMentorChatPopups = acceptedChatRequests.filter(
    (acceptedRequest) => acceptedRequest.mentor === false
  );

  return (
    <div className="chat-container">
      {/* Conversations button and menu */}
      <div className="fixed-conversations">
        <button
          onClick={toggleMenu}
          style={{
            background: "linear-gradient(135deg, #ff6b6b, #6b63ff)",
            borderRadius: "15px",
            padding: "2px",
            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.2)",
            display: "flex",
            position: "fixed",
            bottom: 7,
            left: "50%", // Center horizontally
            transform: "translateX(-50%)", // Center horizontally
            color: "black",
            fontSize: "24px",
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: "2px",
          }}
        >
          Conversations
        </button>
        {isMenuOpen && (
          <div>
            {/* Full-width menu */}
            <div className="full-width-menu">
              {chatRequests.map((chatRequest) => {
                const acceptRequest = () => acceptChatRequest(chatRequest.requestor);
                const declineRequest = () => declineChatRequest(chatRequest.requestor);

                // Check if fields are present and generate a log
                const fields = chatRequest.fields || [];
                const mentorshipHeader = chatRequest.mentor ? <h3>Mentorship</h3> : null;

                return (
                  <div key={chatRequest.requestor} className="chat-request">
                    <Link to={`/u/${chatRequest.requestor}`}>
                      <p>{chatRequest.requestor}</p>
                    </Link>

                    {mentorshipHeader}

                    {/* Display the requestText if present */}
                    {chatRequest.disciplines && (
                      <div className="chat-request-text">
                        <p>{chatRequest.disciplines}</p>
                      </div>
                    )}

                    {/* Display the requestText if present */}
                    {chatRequest.requestText && (
                      <div className="chat-request-text">
                        <p>{chatRequest.requestText}</p>
                      </div>
                    )}

                    <div className="chat-request-options">
                      <button onClick={acceptRequest}>Accept</button>
                      <button onClick={declineRequest}>Decline</button>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Chat popups */}
            <div className="chat-popups-container">
              {/* Mentor Chat Popups */}
              <div className="mentor-chat-container">
                {mentorChatPopups.map((acceptedRequest) => {
                  return (
                    <div key={acceptedRequest.requestor} className="chat-popups">
                      <div className="chat-header">
                        <button
                          onClick={() =>
                            expandChatPopup(acceptedRequest.requestor)
                          }
                        >
                          {acceptedRequest.requestor}
                        </button>
                      </div>
                      {/* Add content for the chat-with-user container here */}
                      {/* You can use acceptedRequest.requestor to display chat information */}
                      {openChatPopups.includes(acceptedRequest.requestor) && (
                        <div
                          className={`chat-with-user${
                            isSmallScreen ? " mobile" : ""
                          }`}
                        >
                          {/* Display messages for this chat here */}
                          {/* You can use the `selectedChat` state to display messages */}
                          {selectedChat &&
                            selectedChat.receiver ===
                              acceptedRequest.requestor && (
                              <div
                                className={`chat-messages-container${
                                  isSmallScreen ? " mobile" : ""
                                }`}
                              >
                                {/* Display messages here */}
                                {selectedChat.content.map((message) => (
                                  <div key={message.timestamp} style={{ textAlign: "left" }}>
                                    <p>
                                      <span style={{ color: "#00F100" }}>
                                        {message.sender}@NuITx
                                      </span>:
                                      <span style={{ color: "#0071f1" }}>~</span>
                                      $
                                      <span style={{ color: "white" }}>{message.content}</span>
                                    </p>
                                  </div>
                                ))}

                                {/* Message input and submit button */}
                                <div
                                  className={`chat-input${
                                    isSmallScreen ? " mobile" : ""
                                  }`}
                                >
                                  <div className="input-container">
                                    <textarea
                                      type="text"
                                      placeholder="Type a message..."
                                      value={newMessage}
                                      onChange={(e) =>
                                        setNewMessage(e.target.value)
                                      }
                                    />
                                  </div>
                                  <button
                                    className="send-button"
                                    onClick={sendMessageToChat}
                                  >
                                    Send
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              {/* Non-Mentor Chat Popups */}
              <div className="non-mentor-chat-container">
                {nonMentorChatPopups.map((acceptedRequest) => {
                  return (
                    <div key={acceptedRequest.requestor} className="chat-popups">
                      <div className="chat-header">
                        <button
                          onClick={() =>
                            expandChatPopup(acceptedRequest.requestor)
                          }
                        >
                          {acceptedRequest.requestor}
                        </button>
                      </div>
                      {/* Add content for the chat-with-user container here */}
                      {/* You can use acceptedRequest.requestor to display chat information */}
                      {openChatPopups.includes(acceptedRequest.requestor) && (
                        <div
                          className={`chat-with-user${
                            isSmallScreen ? " mobile" : ""
                          }`}
                        >
                          {/* Display messages for this chat here */}
                          {/* You can use the `selectedChat` state to display messages */}
                          {selectedChat &&
                            selectedChat.receiver ===
                              acceptedRequest.requestor && (
                              <div
                                className={`chat-messages-container${
                                  isSmallScreen ? " mobile" : ""
                                }`}
                              >
                                {/* Display messages here */}
                                {selectedChat.content.map((message) => (
                                  <div key={message.timestamp} style={{ textAlign: "left" }}>
                                    <p>
                                      <span style={{ color: "#00F100" }}>
                                        {message.sender}@NuITx
                                      </span>:
                                      <span style={{ color: "#0071f1" }}>~</span>
                                      $
                                      <span style={{ color: "white" }}>{message.content}</span>
                                    </p>
                                  </div>
                                ))}

                                {/* Message input and submit button */}
                                <div
                                  className={`chat-input${
                                    isSmallScreen ? " mobile" : ""
                                  }`}
                                >
                                  <div className="input-container">
                                    <textarea
                                      type="text"
                                      placeholder="Type a message..."
                                      value={newMessage}
                                      onChange={(e) =>
                                        setNewMessage(e.target.value)
                                      }
                                    />
                                  </div>
                                  <button
                                    className="send-button"
                                    onClick={sendMessageToChat}
                                  >
                                    Send
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
