import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const DefensiveSecurityPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the Defensive Security Community link in a new tab/window
    window.open('https://defensive-security.com/community/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Join the Defensive Security Community!</h2>
          <p>Connect with fellow defenders, share insights, and collaborate on security challenges in the Defensive Security Community.</p>
          <button onClick={handleButtonClick}>Join Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose the Defensive Security Community?</h3>
        <ul>
          <li>Engage in discussions and knowledge sharing with experienced defenders.</li>
          <li>Collaborate on real-world security challenges and case studies.</li>
          <li>Access resources, webinars, and insights from industry professionals.</li>
          <li>Be part of a community dedicated to improving defensive security practices.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Community Members Say</h3>
        <div className="testimonial">
          <p>"The Defensive Security Community has been an incredible resource for me. It's a great place to learn and grow."</p>
          <p>- John Smith, Security Defender</p>
        </div>
        <div className="testimonial">
          <p>"I've found solutions to complex security challenges by connecting with experts in the Defensive Security Community."</p>
          <p>- Jane Doe, Security Enthusiast</p>
        </div>
      </div>
    </div>
  );
};

export default DefensiveSecurityPromoBanner;