import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem, // Corrected import
  Select,   // Added import for Select
  Slider,
  InputLabel
} from '@mui/material';

function CrowdThreatFeed() {
  const [threatData, setThreatData] = useState([]);
  const [filterLevel, setFilterLevel] = useState(0);
  const [filterReliable, setFilterReliable] = useState(false);
  const [filterUnknown, setFilterUnknown] = useState(false);
  const [filterUrlAnalysis, setFilterUrlAnalysis] = useState(false);
  const [filterAvDetect, setFilterAvDetect] = useState(0);
  const [filterThreatScore, setFilterThreatScore] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterThreatDescription, setFilterThreatDescription] = useState('');


  useEffect(() => {
    axios
      .get('https://www.hybrid-analysis.com/feed?json')
      .then((response) => {
        // Filter the data based on various attributes and search query
        const filteredData = response.data.data.filter(
          (item) =>
            item.threatlevel >= filterLevel &&
            (!filterReliable || item.isreliable) &&
            (!filterUnknown || item.isunknown) &&
            (!filterUrlAnalysis || item.isurlanalysis) &&
            item.avdetect >= filterAvDetect &&
            item.threatscore >= filterThreatScore &&
            (filterThreatDescription === '' || item.threatlevel_human.toLowerCase() === filterThreatDescription.toLowerCase()) &&
            (searchQuery === '' ||
              item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.normalizedpath?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.commandline?.toLowerCase().includes(searchQuery.toLowerCase()))
        );
        setThreatData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [filterLevel, filterReliable, filterUnknown, filterUrlAnalysis, filterAvDetect, filterThreatScore, filterThreatDescription, searchQuery]);

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  

  return (
    <Container style={{ maxWidth: '100vw' }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
            Filters
          </Typography>
          <TextField
            label="Filter by Threat Level"
            type="number"
            value={filterLevel}
            onChange={(e) => setFilterLevel(parseInt(e.target.value))}
            style={{ marginBottom: '10px' }}
          />
          <InputLabel htmlFor="threat-description-select">Threat Description</InputLabel>
          <Select
            select
            value={filterThreatDescription}
            onChange={(e) => setFilterThreatDescription(e.target.value)}
            fullWidth
            style={{ marginBottom: '20px' }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="malicious">Malicious</MenuItem>
            <MenuItem value="suspicious">Suspicious</MenuItem>
            <MenuItem value="no specific threat">No Specific Threat</MenuItem>
          </Select>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterUrlAnalysis}
                onChange={() => setFilterUrlAnalysis(!filterUrlAnalysis)}
              />
            }
            label="URL Analysis"
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Filter by AV Detect"
            type="number"
            value={filterAvDetect}
            onChange={(e) => setFilterAvDetect(parseInt(e.target.value))}
            style={{ marginBottom: '10px' }}
          />
        </CardContent>
      </Card>
      <Grid container spacing={2}>
        {threatData.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.sha256}>
            <Card variant="outlined">
              <CardContent>
              <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                  Threat Level: {item.threatlevel}
                </Typography>
                <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                  Threat Score: {item.threatscore}
                </Typography>
                <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                  Threat Description: {item.threatlevel_human}
                </Typography>
                <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                  Family: {item.vxfamily || 'N/A'}
                </Typography>
                <Button variant="contained" color="primary" onClick={() => window.open(`https://www.hybrid-analysis.com${item.reporturl || ''}`, '_blank')}>
  Go to Report
</Button>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  AV Detect: {item.avdetect}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  MS Detect: {item.msdetect}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  MD5: {item.md5}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  SHA1: {item.sha1}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  SHA256: {item.sha256}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Type: {item.type}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Submit Name: {item.submitname}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Environment: {item.environmentDescription}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Analysis Start Time: {formatDate(item.analysis_start_time)}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Domains: {item.domains ? item.domains.join(', ') : 'N/A'}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Hosts: {item.hosts ? item.hosts.join(', ') : 'N/A'}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
        Hosts GEO:
        {item.hosts_geo ? item.hosts_geo.map((host) => (
          <span key={host.ip}> {host.ip} ({host.cc})</span>
        )) : 'N/A'}
      </Typography>

      <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
        ET Alerts:
        {item.et_alerts ? item.et_alerts.map((alert) => (
          <div key={alert.timestamp}>
            <div>
              Destination IP: {alert.destip}, Port: {alert.destport}
            </div>
            <div>
              Protocol: {alert.protocol}, Source IP: {alert.srcip}
            </div>
            <div>
              Action Category: {alert.action.category}, Severity: {alert.action.severity}
            </div>
            <div>
              Description: {alert.action.description}
            </div>
          </div>
        )) : 'N/A'}
      </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Reliable: {item.isreliable ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Unknown: {item.isunknown ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  URL Analysis: {item.isurlanalysis ? 'Yes' : 'No'}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Name: {item.name || 'N/A'}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Normalized Path: {item.normalizedpath || 'N/A'}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                  Command Line: {item.commandline || 'N/A'}
                </Typography>
                {/* Display child elements if available */}
                {item.process_list && item.process_list.length > 0 && (
                  <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                    Process List:
                    <ul>
                      {item.process_list.map((process) => (
                        <li key={process.uid}>
                          Name: {process.name || 'N/A'}
                          <br />
                          Normalized Path: {process.normalizedpath || 'N/A'}
                          <br />
                          Command Line: {process.commandline || 'N/A'}
                          <br />
                          SHA256: {process.sha256 || 'N/A'}
                          <br />
                          AV Match: {process.av_matched || 'N/A'}
                          <br />
                          AV Total: {process.av_total || 'N/A'}
                        </li>
                      ))}
                    </ul>
                  </Typography>
                )}
                {/* Display extracted files if available */}
                {item.extracted_files && item.extracted_files.length > 0 && (
                  <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                    Extracted Files:
                    <ul>
                      {item.extracted_files.map((file) => (
                        <li key={file.name}>
                          Name: {file.name || 'N/A'}
                          <br />
                          File Path: {file.file_path || 'N/A'}
                          <br />
                          File Size: {file.file_size || 'N/A'}
                        </li>
                      ))}
                    </ul>
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default CrowdThreatFeed;