import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const RedditPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the Reddit subreddit link in a new tab/window
    window.open('https://www.reddit.com/r/AskNetsec/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Join the r/AskNetsec Community on Reddit!</h2>
          <p>Connect with fellow cybersecurity enthusiasts, ask questions, and share insights on the r/AskNetsec subreddit.</p>
          <button onClick={handleButtonClick}>Visit Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose r/AskNetsec?</h3>
        <ul>
          <li>Engage in discussions with professionals and learners in the field.</li>
          <li>Ask questions, seek advice, and share your knowledge.</li>
          <li>Stay updated on the latest trends and challenges in cybersecurity.</li>
          <li>Join a supportive and knowledgeable community.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Community Members Say</h3>
        <div className="testimonial">
          <p>"r/AskNetsec has been a valuable resource for me. The community is always willing to help and share insights."</p>
          <p>- John Smith, Cybersecurity Enthusiast</p>
        </div>
        <div className="testimonial">
          <p>"I've learned a lot from discussions on r/AskNetsec. It's a great place to connect with like-minded individuals."</p>
          <p>- Jane Doe, Aspiring Security Professional</p>
        </div>
      </div>
    </div>
  );
};

export default RedditPromoBanner;