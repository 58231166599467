import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './programmingsearch.css'; // Create a CSS file for styling

const ProgammingSearchPage = () => {
  const [exploitResults, setExploitResults] = useState([]);
  const [filteredExploitResults, setFilteredExploitResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 1000;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedExploitOS, setSelectedExploitOS] = useState('');

  useEffect(() => {
    // Fetch exploit results from an API endpoint
    const fetchExploitResults = async () => {
      try {
        const response = await axios.get(`/api/progtool-search?page=${currentPage}`);
        setExploitResults(response.data.results);
      } catch (error) {
        console.error('Error fetching exploit results:', error); 
      }
    };

    fetchExploitResults();
  }, [currentPage]);

  useEffect(() => {
    // Filter exploit results based on search term and selected OS
    const filteredResults = exploitResults.filter((result) => {
      const searchString = `${result.username} ${result.programmingToolLink} ${result.programmingToolName} ${result.description}`;
      const includesSearchTerm = searchString.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesExploitOS = selectedExploitOS ? result.exploitOS === selectedExploitOS : true;
      return includesSearchTerm && matchesExploitOS;
    });

    setFilteredExploitResults(filteredResults);
  }, [searchTerm, selectedExploitOS, exploitResults]);

  const handleLoadRecent = () => {
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    const recentResults = exploitResults.filter((result) => {
      const resultDate = new Date(result.currentDate);
      return resultDate.getTime() >= thirtyDaysAgo.getTime();
    });

    setFilteredExploitResults(recentResults);
  };

  const handleLoadByExploitOS = (exploitOS) => {
    setSelectedExploitOS(exploitOS);
  };

  return (
    <div className="exploit-search-container">
      <h2>Programming Tool Search</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="exploit-results">
        {filteredExploitResults.map((result) => (
          <div key={result.id} className="exploit-result">
            <div className="exploit-header">
              <span className="username">{result.username}</span>
              <span className="date">{result.currentDate}</span>
            </div>
            <div className="exploit-details">
              <br />
              <span className="name">Name: {result.programmingToolName}</span>
              <br />
              <span className="link">Link: {result.programmingToolLink}</span>
            </div>
            <p className="description">Description: <br />{result.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgammingSearchPage;