import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
  searchField: {
    width: '40vw', // Adjust the width as needed
    marginLeft: '-4.6vw',
    background: 'white',
    //marginLeft: '-21.5vw', // Adjust the marginLeft as needed
    [theme.breakpoints.down('xs')]: {
      width: '10%', // Adjust the width for smaller screens if needed
      marginLeft: '60vw', // Adjust the marginLeft for smaller screens if needed
    },
  },
}));

const SearchBar = ({ isMobile, setSearchOpen }) => {
  const [searchInput, setSearchInput] = useState('');
  const history = useHistory();
  const classes = useStyles();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchInput === '') return;
    history.push(`/search/${searchInput}`);
  };

  const clearSearch = () => {
    if (isMobile) {
      setSearchOpen(false);
    }
    setSearchInput('');
  };

  return (
    <div>
      <form onSubmit={handleSearch}>
        <TextField
          type="search"
          style={{ color: 'black' }}
          placeholder="Search for posts…"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className={classes.searchField}
          variant="outlined"
          margin="dense"
          fullWidth
          InputProps={{
            style: { color: 'black' },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
            endAdornment: (searchInput || isMobile) && (
              <InputAdornment position="end">
                <IconButton color="primary" size="small" onClick={clearSearch}>
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    </div>
  );
};

export default SearchBar;