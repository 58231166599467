import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { logoutUser } from "../reducers/userReducer";
import { notify } from "../reducers/notificationReducer";
import MobileUserMenu from "./MobileUserMenu";
import DesktopUserMenu from "./DesktopUserMenu";
import SearchBar from "./SearchBar";
import { Box } from "@material-ui/core";
import { Popper } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import ListItem from "@material-ui/core/ListItem";

import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  Button,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Collapse,
  List,
  makeStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import SupportIcon from "@material-ui/icons/HelpOutline";
import DiscordMini from '../assets/discordlogo.png'

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.text.primary,
    "& img": {
      marginRight: theme.spacing(1),
      height: "30px",
    },
  },
  menuButton: {
    fontSize: "1.2rem",
    fontWeight: 600,
    borderRadius: "5%",
    color: theme.palette.primary.main,
    transition: "0.3s",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      padding: "0px 1px",
    },
  },
  userWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  userAvatar: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    marginRight: theme.spacing(1),
  },
  meritScore: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  userMenuContainer: {
    marginLeft: "auto",
  },
  userSearchContainer: {
    display: "flex",
    marginRight: "2vw",
  },
  menuButtonBreathing: {
    animation: '$borderAnimation 4s linear infinite',
  },
  '@keyframes borderAnimation': {
    '0%': {
      boxShadow: `0 0 7px 0px ${theme.palette.error.main}`,
    },
    '33%': {
      boxShadow: `0 0 7px 5px ${theme.palette.primary.main}`,
    },
    '66%': {
      boxShadow: `0 0 7px 5px ${theme.palette.info.main}`,
    },
    '100%': {
      boxShadow: `0 0 7px 0px ${theme.palette.error.main}`,
    },
  },
  whiteText: {
    color: 'white',
  }
}));
const NavBar = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menu2AnchorEl, setMenu2AnchorEl] = useState(null);
  const [menu3AnchorEl, setMenu3AnchorEl] = useState(null);
  const [menu4AnchorEl, setMenu4AnchorEl] = useState(null);
  const [menu5AnchorEl, setMenu5AnchorEl] = useState(null);
  const [supportOpen, setSupportOpen] = useState(false);
  const [open, setOpen] = useState({});

  const [desktopMenuAnchorEl, setDesktopMenuAnchorEl] = useState(null);
  const handleDesktopMenuOpen = (event) => {
    setDesktopMenuAnchorEl(event.currentTarget);
  };

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const menus = [
    {
      name: "Highly Suggested",
      submenus: [
        { name: "DFIR Report", route: `/dfir` },
        { name: "Malpedia", route: `/malpedia` },
        { name: "Cyber Defenders", route: `/cdefend` },
      ],
    },
    {
      name: "Supportive Resources",
      submenus: [
        { name: "Cybrary", route: `/cybrary` },
        { name: "Coursera", route: `/coursera` },
        { name: "SANS Institute", route: `/sans` },
        { name: "Krebs on Security", route: `/krebs` },
        { name: "The Hacker News", route: `/hackernews` },
        { name: "Troy Hunt's Blog", route: `/troy` },
        { name: "Blue Team Village", route: `/bluevillage` },
        { name: "Hak5", route: `/hak5` },
        { name: "The Cyber Mentor", route: `/cybermentor` },
        { name: "John Hammond", route: `/johnhammond` },
        { name: "Don Murdoch", route: `/donmurdoch` },
        { name: "Amanda Berlin", route: `/amandaberlin` },
        { name: "Richard Bejtlich", route: `/richardbejt` },
        { name: "r/AskNetsec", route: `/asknetsec` },
        { name: "Defensive Security Community", route: `/defensivesec` },
        { name: "ISC2 Community", route: `/isc2` },
        { name: "Black Hat", route: `/blackhat` },
        { name: "DEFCON", route: `/defcon` },
        { name: "BSides", route: `/bsides` },
        { name: "Linkedin", route: `/bluelinkedin` },
        { name: "Facebook", route: `/bluefacebook` },
        { name: "Twitter", route: `/bluetwitter` },
        { name: "FireEye", route: `/FireEye` },
        { name: "Talos", route: `/Talos` },
        { name: "CloudFlare", route: `/cloudflare` },
      ],
    },
    {
      name: "Threat Media",
      submenus: [
        { name: "A", route: `/feedsUS` },
        { name: "Adam Caudill", route: `/adamcaudill` },
        { name: "B", route: `/feeds` },
        { name: "C", route: `/feedsGlobal` },
        { name: "EsecurityPlanet", route: `/esecuritythreat` },
      ],
    },
    {
      name: "Tools",
      submenus: [
        { name: "Submit Tool", route: `/bluetool-w` },
        { name: "Search Tools", route: `/bluetool-r` },
        { name: "Elastic", route: `/elastic` },
        { name: "Splunk", route: `/splunk` },
        { name: "FLARE", route: `/FLARE` },
        { name: "SIFT", route: `/SIFT` },
        { name: "Microsoft Sentinel", route: `/MSentinel` },
        { name: "AWS CloudWatch", route: `/CloudWatch` },
        { name: "Google Cloud Logs", route: `/gcloudmonitor` },
        { name: "TPOT", route: `/TPOT` },
        { name: "Carbon Black", route: `/carbonblack` },
        { name: "Stealth Watch", route: `/stealthwatch` },
        { name: "Joes Sandbox", route: `/joessand` },
        { name: "MITRE ATT&CK", route: `/mitrematrix` },
        { name: "CAPE", route: `/CAPE` },
        { name: "Virus Total", route: `/VT` },
        { name: "Chainsaw", route: `/chainsaw` },
      ],
    },
    {
      name: "Cert Maps",
      submenus: [{ name: "Comprehensive", route: `/roadmap` }],
    },
    {
      name: "Cheat Sheets",
      submenus: [
        { name: "MalwareArchaeology", route: `/cheatsheet` },
        { name: "MalwareArchaeology Logging", route: `/cheatlogging` },
        { name: "Good Collection", route: `/bluecheat1` },
        { name: "Cht.sh", route: `/chtsh` },
      ],
    },
    {
      name: "IOCs",
      submenus: [
        { name: "Submit", route: `/IOCSub` },
        { name: "Search", route: `/IOCSearch` },
      ],
    },
    {
      name: "Queries",
      submenus: [
        { name: "Submit", route: `/QSub` },
        { name: "Search", route: `/QSearch` },
      ],
    },
    {
      name: "National Vulnerability Database",
      submenus: [{ name: "Search", route: `/NVDSearch` }],
    },
    {
      name: "CrowdStrike",
      submenus: [{ name: "Threats", route: `/hybridfeed` }],
    },
  ];
  const menus2 = [
    {
      name: "Highly Suggested",
      submenus: [
        { name: "Cobalt Strike", route: `/cstrike` },
        { name: "OSCP", route: `/oscp` },
        { name: "Metasploit", route: `/metasploit` },
        { name: "HackTheBox", route: `/hackthebox` },
        { name: "Powershell Empire", route: `/powerempire` },
        { name: "Brute Ratel", route: `/ratel` },
        { name: "Kali", route: `/kali` },
      ],
    },
    {
      name: "Supportive Resources",
      submenus: [
        { name: "Offensive Security", route: `/offsec` },
        { name: "Black Hills Security", route: `/blackhills` },
        { name: "HackTricks", route: `/hacktricks` }, 
        { name: "Bazaar", route: `/bazaar` },
        { name: "K7", route: `/k7` },
      ],
    },
    {
      name: "Tools",
      submenus: [
        { name: "Submit Tool", route: `/redtool-w` },
        { name: "Search Tool", route: `/redtool-r` },
      ],
    },
    {
      name: "Exploits",
      submenus: [
        { name: "Submit Exploit", route: `/exploitsub` },
        { name: "Search Exploits", route: `/exploitsearch` },
        { name: "Exploit-DB", route: `/exploitdb` },
        { name: "Reverse Shell Generator", route: `/revshells` },
        { name: "GTFO Bins", route: `/gtfobins` },
        { name: "Red Team Pentesting", route: `/redteamde` },
        { name: "OSINT Framework", route: `/osintframe` },
      ],
    },
    {
      name: "Organizations",
      submenus: [
        { name: "MITRE APTs", route: `/mapt` },
        { name: "Malpedia Actors", route: `/mactors` },
      ],
    },
    {
      name: "Types",
      submenus: [{ name: "Malpedia Families", route: `/mfamilies` }],
    },
  ];

  const menus3 = [
    {
      name: "Apply",
      submenus: [
        { name: "Coming Soon", route: `/SOCapp` },
        { name: "Application", route: `/SOCapp` },
      ],
    },
    {
      name: "Add Hosts",
      submenus: [{ name: "Instructions", route: `/SOCinstall` }],
    },
    {
      name: "View SOC",
      submenus: [{ name: "SOC", route: `/SOCframe` }],
    },
  ];

  const menus4 = [
    {
      name: "Highly Suggested",
      submenus: [
        { name: "Amazon Web Services (AWS)", route: `/AWSapp` },
        { name: "Microsoft Azure", route: `/Azureapp` },
        { name: "Google Cloud", route: `/GoogleCloudapp` },
        { name: "IBM Cloud", route: `/IBMCloudapp` },
        { name: "Oracle Cloud", route: `/OracleCloudapp` },
      ],
    },
    {
      name: "Tools",
      submenus: [
        { name: "Cloud Tool Submit", route: `/cloud-w` },
        { name: "Cloud Tool Search", route: `/cloud-r` },
      ],
    },
    {
      name: "Command Line",
      submenus: [
        { name: "Cloud CLI Submit", route: `/cloudcli-w` },
        { name: "Cloud CLI Search", route: `/cloudcli-r` },
      ],
    },
    {
      name: "Virtualization",
      submenus: [
        { name: "VMware", route: `/vvmware` },
        { name: "Virtual Box", route: `/vbox` },
        { name: "Proxmox", route: `/proxmox` },
        { name: "Red Hat", route: `/vrh` },
        { name: "Kubernetes", route: `/kubernetes` },
        { name: "Terraform", route: `/terraform` },
        { name: "Cloud Architectures", route: `/carchitectures` },
      ],
    },
    {
      name: "Certs",
      submenus: [
        { name: "SANS Cloud Certs", route: `/SANScloud` },
        { name: "Amazon Web Services (AWS)", route: `/AWSCert` },
        { name: "Microsoft Azure", route: `/AzureCert` },
        { name: "Google Cloud", route: `/GCloudCert` },
        { name: "IBM Cloud", route: `/IBMCloudCert` },
        { name: "Oracle Cloud", route: `/OracleCloudCert` },
      ],
    },
  ];

  const menus5 = [
    {
      name: "Lessons Learned",
      submenus: [
        { name: "Submit Lesson Learned", route: `/lessons-w` },
        { name: "Search Lessons Learned", route: `/lessons-r` },
      ],
    },
    {
      name: "Resources",
      submenus: [
        { name: "Udemy", route: `/udemy` },
        { name: "Pluralsight", route: `/pluralsight` },
        { name: "edX", route: `/edX` },
        { name: "LinkedIn Learning", route: `/llearning` },
        { name: "Skillshare", route: `/skillshare` },
        { name: "Khan Academy", route: `/khanacademy` },
        { name: "Treehouse", route: `/treehouse` },
        { name: "Udacity", route: `/udacity` },
        { name: "Codecademy", route: `/codecademy` },
        { name: "MIT OpenCourseWare", route: `/mitopen` },
        { name: "Harvard Online Courses", route: `/harvardopen` },
        { name: "Google", route: `/google` },
        { name: "Youtube", route: `/youtube` },
        { name: "Reddit", route: `/reddit` },
        { name: "StackOverflow", route: `/stackoverflow` },
        { name: "ChatGPT", route: `/ChatGPT` },
        { name: "Network Defense", route: `/NetworkDefense` },
        { name: "Over The Wire", route: `/overthewire` },
        { name: "Under The Wire", route: `/underthewire` },
      ],
    }
  ];

  const menus6 = [
    {
      name: "What Is Mentorship?",
      submenus: [
        { name: "Mentorship Guide", route: `/mguide` },
        { name: "Mentorship Conduct", route: `/mconduct` },
      ],
    },
    {
      name: "Find A Mentor",
      submenus: [
        { name: "Search For A Mentor", route: `/mentorship` },
      ],
    },
    {
      name: "Commendations",
      submenus: [
        { name: "Give Commendation", route: `/gcommendation` },
      ],
    },
    {
      name: "Become A Mentor",
      submenus: [
        { name: "Mentorship Form", route: `/mentorform` },
      ],
    },
  ];

  const menus7 = [
    {
      name: "Data Science Starter",
      submenus: [
        { name: "Short Guide", route: `/d1` },
        { name: "Tools", route: `/d2` },
        { name: "Databases", route: `/d3` },
      ],
    },
    {
      name: "Tools DB",
      submenus: [
        { name: "Submit DataSci Tool", route: `/datasci-w` },
        { name: "Search DataSci Tools", route: `/datasci-r` },
      ],
    },
  ];
  
  const menus8 = [
    {
      name: "Programming Starter",
      submenus: [
        { name: "Brief Overview", route: `/p1` },
        { name: "Tools & Languages", route: `/p2` },
      ],
    },
    {
      name: "Tools DB",
      submenus: [
        { name: "Submit Programming Tool", route: `/prog-w` },
        { name: "Search Programming Tools", route: `/prog-r` },
      ],
    },
    {
      name: "Stacks",
      submenus: [
        { name: "Submit Stack", route: `/stacks-w` },
        { name: "Search Stacks", route: `/stacks-r` },
      ],
    },
  ];
  
  const menus9 = [
    {
      name: "What is IT Management?",
      submenus: [
        { name: "Brief Overview", route: `/man1` },
        { name: "FrameWorks & Policies", route: `/man2` },
        { name: "Management Certs", route: `/man4` },
      ],
    },
    {
      name: "Cyber Salaries",
      submenus: [
        { name: "Good List", route: `/man3` },
      ],
    },
  ];

  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null); // New state variable

  const handleMenuClick = (menuName) => {
    if (selectedMenu === menuName) {
      setSelectedMenu(null);
    } else {
      setSelectedMenu(menuName);
    }
  };

  const handleMenu3Click = (menuLabel) => {
    if (selectedMenu === menuLabel) {
      setSelectedMenu(null); // If clicked menu is already open, then we close it
    } else {
      setSelectedMenu(menuLabel);
      setSelectedSubMenu(null); // Reset the sub menu selection when opening a new menu
    }
  };

  const handleSubMenuClick = (subMenuName) => {
    if (selectedSubMenu === subMenuName) {
      setSelectedSubMenu(null); // If clicked sub-menu is already open, then we close it
      setMobileMenuOpen(false); // Close the mobile menu when a submenu is selected
    } else {
      setSelectedSubMenu(subMenuName);
    }
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(notify(`u/${user.username} logged out`, "success"));
  };

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
    setSupportOpen(false);
  };

  const handleMenu2Open = (event) => {
    setMenu2AnchorEl(event.currentTarget);
  };

  const handleMenu3Open = (event) => {
    setMenu3AnchorEl(event.currentTarget);
  };

  const handleMenu4Open = (event) => {
    setMenu4AnchorEl(event.currentTarget);
  };

  const handleMenu5Open = (event) => {
    setMenu5AnchorEl(event.currentTarget);
  };

  const handleDesktopMenuClose = () => {
    setMenuAnchorEl(null);
    setMenu2AnchorEl(null);
    setMenu3AnchorEl(null);
    setMenu4AnchorEl(null);
    setMenu5AnchorEl(null);
  };
  const handleSubMenuClose = () => {
    setOpen((prevState) => ({
      ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),
    }));
  };

  const closeAllMenus = () => {
    setOpen((prevState) => ({
      ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),
    }));
  
    setSelectedMenu(null);
    setSelectedSubMenu(null);
    setMobileMenuOpen(false);
  };

  const closeAllMobileMenus = () => {
    setMobileMenuOpen(false);
  };

  const handleClick = (menu) => {
    setOpen((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const handleSupportOpen = () => {
    setSupportOpen(true);
  };

  const handleSupportClose = () => {
    setSupportOpen(false);
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const renderDesktopMenus = () => {
    const desktopMenus = [
      { label: "Blue", menus: menus },
      { label: "Red", menus: menus2 },
      { label: "SOC", menus: menus3 },
      { label: "Cloud", menus: menus4 },
      { label: "Learning", menus: menus5 },
      { label: "Mentorship", menus: menus6 },
      { label: "Data Science", menus: menus7 },
      { label: "Programming", menus: menus8 },
      { label: "Management", menus: menus9 },
    ];

    const isDarkMode = true; // Set this to true or false based on your dark mode state

    return (
      <div className={classes.userWrapper} >
        <div style={{ display: "flex", justifyContent: "center"}}>
          {desktopMenus.map((menuGroup, index) => (
            <div
              key={index}
              style={{
                margin: "0 10px",
                textAlign: "center",
                position: "relative",
              }}
            >
              <Button
                color="primary"
                onClick={() => handleMenuClick(menuGroup.label)}
                className={`${classes.menuButton} menu-button-breathing`} 
                style={{ backgroundColor: "white" }}
              >
                {menuGroup.label}
                {Boolean(selectedMenu === menuGroup.label) ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </Button>
              {selectedMenu === menuGroup.label && (
                <div
                  className={`menu-overlay ${isDarkMode ? "dark-mode" : ""}`}
                  style={{

                    position: "absolute",
                    top: "100%",
                    left: 0,
                    zIndex: 1000, // Adjust the zIndex to control the overlay's stacking order
                    backgroundColor: 'isDarkMode ? "black" : "black"', // Set the background color based on dark mode
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Add a shadow for a card-like effect
                    maxHeight: "777px", // Set a maximum height for the submenu container
                    overflowY: "auto", // Enable vertical scrolling when content overflows
                    color: isDarkMode ? "white" : "white", // Set text color based on dark mode
                  }}
                >
                  <List component="div" disablePadding>
                    {menuGroup.menus.map((menu) => (
                      <div key={menu.name}>
                        <ListItem
                          button
                          onClick={() => handleClick(menu.name)}
                          className={`menu-item ${
                            isDarkMode ? "dark-mode" : ""
                          }`}
                          style={{ backgroundColor: "black" }}
                        >
                          <ListItemText
                            primary={menu.name}
                            style={{ width: "200px", backgroundColor: 'black'}} // Adjust the width as needed
                          />
                          {open[menu.name] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse
                          in={open[menu.name]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {menu.submenus.map((submenu) => (
                              <ListItem
                                key={submenu.name}
                                button
                                component={RouterLink}
                                to={submenu.route}
                                onClick={() => {
                                  closeAllMenus();
                                  handleDesktopMenuClose(); // Close all menus, including on desktop
                                }}
                                className={`submenu-item ${
                                  isDarkMode ? "dark-mode" : ""
                                }`}
                                style={{ backgroundColor: "black" }}
                              >
                                <ListItemText primary={submenu.name} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </div>
                    ))}
                  </List>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderMobileMenuItems = () => {
    const mobileMenus = [
      { label: "Blue", menus: menus },
      { label: "Red", menus: menus2 },
      { label: "SOC", menus: menus3 },
      { label: "Cloud", menus: menus4 },
      { label: "Learning", menus: menus5 },
      { label: "Mentorship", menus: menus6 },
      { label: "Data Science", menus: menus7 },
      { label: "Programming", menus: menus8 },
      { label: "Management", menus: menus9 },
    ];
    const isDarkMode = true;
    return mobileMenus.map((menuGroup, index) => (
      <div key={index}>
        <MenuItem
          onClick={() => handleMenuClick(menuGroup.label)}
          style={{ background: 'black' }}
        >
          <ListItemText
            primary={menuGroup.label}
            className={classes.whiteText} // Apply the whiteText class
          />
          {selectedMenu === menuGroup.label ? <ExpandLess className={classes.whiteText} /> : <ExpandMore className={classes.whiteText} />}
        </MenuItem>
        <Collapse in={selectedMenu === menuGroup.label} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuGroup.menus.map((menu) => (
              <React.Fragment key={menu.name}>
                <MenuItem
                  button
                  onClick={() => handleSubMenuClick(menu.name)}
                  className="menu-item"
                  style={{ backgroundColor: "black" }}
                >
                  <ListItemText
                    primary={menu.name}
                    className={classes.whiteText} // Apply the whiteText class
                  />
                  {selectedSubMenu === menu.name ? <ExpandLess className={classes.whiteText} /> : <ExpandMore className={classes.whiteText} />}
                </MenuItem>
                <Collapse in={selectedSubMenu === menu.name} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {menu.submenus.map((submenu) => (
                      <ListItem
                        key={submenu.name}
                        button
                        component={RouterLink}
                        to={submenu.route}
                        onClick={() => {
                          closeAllMenus();
                          handleDesktopMenuClose(); // Close all menus, including on desktop
                        }}
                        className="submenu-item"
                        style={{ backgroundColor: "black" }}
                      >
                        <ListItemText
                          primary={submenu.name}
                          className={classes.whiteText} // Apply the whiteText class
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
        </Collapse>
      </div>
    ));
  };

  return (
    <AppBar position="sticky" color="inherit" elevation={1} style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Toolbar disableGutters={isMobile}>
      <div style={{
  background: 'linear-gradient(135deg, #ff6b6b, #6b63ff)',  // Use a gradient background
  borderRadius: '15px',       // Increase the border radius for a softer look
  padding: '2px',            // Add padding for spacing inside the div
  boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)',  // Add a slightly more pronounced box shadow
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',  // Text color
  fontSize: '24px', // Font size
  fontWeight: 'bold', // Font weight
  textTransform: 'uppercase', // Uppercase text
  letterSpacing: '2px', // Letter spacing
}}>
  <IconButton
    className={classes.logo}
    color="black"
    component={RouterLink}
    to="/"

  style={{ color: 'black' }}
  >
    Cyber | NuITx
  </IconButton>
</div>
        <Box flexGrow={1} display="flex" justifyContent="center" backgroundColor= 'white'>
          {!isMobile && <SearchBar />}
        </Box>
        {isMobile && (
          <IconButton
          color="primary"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          style={{
            background: 'linear-gradient(135deg, #ff6b6b, #6b63ff)',  // Use a gradient background
            borderRadius: '15px',       // Increase the border radius for a softer look
            padding: '2px',            // Add padding for spacing inside the div
            boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)',  // Add a slightly more pronounced box shadow
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',  // Text color
            fontSize: '24px', // Font size
            fontWeight: 'bold', // Font weight
            textTransform: 'uppercase', // Uppercase text
            letterSpacing: '2px', // Letter spacing
          }}>
        
            Menu
          </IconButton>
        )}
        <div>
      {isMobile ? (
        <a href="https://discord.gg/kmATeJxQwH" target="_blank" rel="noopener noreferrer">
          <img
            src={DiscordMini} // Replace with the actual URL of your Discord icon image
            style={{ width: '0px', height: '0px' }} // Adjust the width and margin as needed
          />
        </a>
      ) : (
        <a href="https://discord.gg/kmATeJxQwH" target="_blank" rel="noopener noreferrer">
          <img
            src={DiscordMini} // Replace with the actual URL of your Discord icon image
            style={{ width: '42px', height: '42px', marginRight: '2px'}} // Adjust the width and margin as needed
          />
        </a>
      )}
    </div>
        <div className={classes.userMenuContainer} style={{
  background: 'linear-gradient(135deg, #ff6b6b, #6b63ff)',
  borderRadius: '15px',
  padding: '2px',
  boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  justifyContent: 'space-between', // Adjust alignment
  alignItems: 'center',
  color: 'white',
  fontSize: '24px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: '2px',
}}>
  {/* DesktopUserMenu */}
  <DesktopUserMenu user={user} handleLogout={handleLogout} />
</div>
        {searchOpen && isMobile && (
          <div className={classes.userSearchContainer}>
            <SearchBar isMobile={true} setSearchOpen={setSearchOpen} />
          </div>
        )}

        {/* Mobile Menu Button */}
      </Toolbar>

      {/* Mobile Menu */}
      {isMobile && mobileMenuOpen && (
        <div>
          {/* Include your actual menu items here */}
          {renderMobileMenuItems()}
        </div>
      )}

      {/* Desktop Menus */}
      {/* Desktop Menus */}
      {!isMobile && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ margin: "0 auto" }}>{renderDesktopMenus()}</div>
        </div>
      )}
    </AppBar>
  );
};

export default NavBar;
