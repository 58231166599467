import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import your CSS for styling

const SANSPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://www.sans.org/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Unlock Your Potential with SANS Institute!</h2>
          <p>Explore a world of cybersecurity education with SANS Institute's online courses, training, and certifications.</p>
          <button onClick={handleButtonClick}>Get Started</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose SANS Institute?</h3>
        <ul>
          <li>Leading provider of cybersecurity training and education.</li>
          <li>Hands-on training by industry experts.</li>
          <li>Cutting-edge courses in various cybersecurity domains.</li>
          <li>Wide range of certifications to validate your expertise.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Collaborations and Recognition</h3>
        <p>SANS Institute collaborates with industry leaders and organizations to deliver high-quality cybersecurity education. Our partnerships include:</p>
        <ul>
          <li>Top cybersecurity professionals and instructors.</li>
          <li>Global recognition and accreditation.</li>
          <li>Certification programs recognized by employers worldwide.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Learners Say</h3>
        <div className="testimonial">
          <p>"SANS Institute's training gave me the practical skills needed to excel in the cybersecurity field. The knowledge gained is invaluable."</p>
          <p>- John Smith, Cybersecurity Professional</p>
        </div>
        <div className="testimonial">
          <p>"The depth and quality of SANS Institute's courses are unmatched. I feel confident taking on real-world security challenges."</p>
          <p>- Jane Doe, IT Specialist</p>
        </div>
      </div>
    </div>
  );
};

export default SANSPromoBanner;