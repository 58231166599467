import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const BlueTeamVillagePromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://www.blueteamvillage.org/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Enhance Your Blue Team Skills with Blue Team Village!</h2>
          <p>Explore resources, training, and events dedicated to blue team cybersecurity professionals.</p>
          <button onClick={handleButtonClick}>Learn More</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Blue Team Village?</h3>
        <ul>
          <li>Focus on defensive cybersecurity strategies and techniques.</li>
          <li>Access to workshops, talks, and training for blue teamers.</li>
          <li>Community-driven knowledge sharing and collaboration.</li>
          <li>Empowering blue team professionals worldwide.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Collaborators and Contributors</h3>
        <p>Blue Team Village collaborates with blue team experts, practitioners, and organizations to provide valuable insights and resources.</p>
        <ul>
          <li>Blue team professionals and leaders.</li>
          <li>Cybersecurity organizations and conferences.</li>
          <li>Hands-on training providers.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What the Community Says</h3>
        <div className="testimonial">
          <p>"Blue Team Village is a haven for blue team professionals. The resources and networking opportunities are invaluable."</p>
          <p>- John Smith, Blue Team Enthusiast</p>
        </div>
        <div className="testimonial">
          <p>"I've gained practical insights and knowledge from Blue Team Village's workshops and talks. It's a supportive community for defenders."</p>
          <p>- Jane Doe, Cybersecurity Analyst</p>
        </div>
      </div>
    </div>
  );
};

export default BlueTeamVillagePromoBanner;