import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './lessonread.css'; // Create a CSS file for styling

const LessonsLearnedReadForm = () => {
  const [exploitResults, setExploitResults] = useState([]);
  const [filteredExploitResults, setFilteredExploitResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 1000;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedExploitOS, setSelectedExploitOS] = useState('');

  useEffect(() => {
    // Fetch exploit results from the MongoDB collection
    const fetchExploitResults = async () => {
      try {
        const response = await axios.get(`/api/lessons-learned?page=${currentPage}`);
        setExploitResults(response.data.results);
      } catch (error) {
        console.error('Error fetching exploit results:', error);
      }
    };

    fetchExploitResults();
  }, [currentPage]);

  useEffect(() => {
    // Filter exploit results based on search term and selected OS
    const filteredResults = exploitResults.filter((result) => {
      const searchString = `${result.lessonLearned} ${result.aresult} ${result.dresult} ${result.steps}`;
      const includesSearchTerm = searchString.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesExploitOS = selectedExploitOS ? result.exploitOS === selectedExploitOS : true;
      return includesSearchTerm && matchesExploitOS;
    });

    setFilteredExploitResults(filteredResults);
  }, [searchTerm, selectedExploitOS, exploitResults]);

  const handleLoadRecent = () => {
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    const recentResults = exploitResults.filter((result) => {
      const resultDate = new Date(result.currentDate);
      return resultDate.getTime() >= thirtyDaysAgo.getTime();
    });

    setFilteredExploitResults(recentResults);
  };

  const handleLoadByExploitOS = (exploitOS) => {
    setSelectedExploitOS(exploitOS);
  };

  return (
    <div className="exploit-search-container">
      <h2>Lessons Learned</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="exploit-results">
        {filteredExploitResults.map((result, index) => (
          <div key={result._id} className="exploit-result">
            <div className="exploit-header">
              <span className="date">{result.currentDate}</span>
            </div>
            <div className="exploit-details">
              <span className="feeling">Feeling: {result.selectedFeedback}</span>
              <br />
              <span className="name">Desired Result: {result.dresult}</span>
              <br />
              <br />
              <span className="steps">
                {result.steps.map((step, stepIndex) => (
                  <span key={stepIndex}> {stepIndex + 1}: {step}</span>
                ))}
              </span>
              <br />
              <span className="result">Actual Result: {result.aresult}</span>
              <br />
              <span className="lesson-learned">Lesson Learned: {result.lessonLearned}</span>
              <br />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


export default LessonsLearnedReadForm;