import React from 'react';

const EdXInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#2D73B6', // edX brand color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore Learning on edX</h1>
      <p>
        edX is a trusted online learning platform that offers a wide range of
        courses from top universities and institutions. Whether you're looking to
        advance your career or explore new subjects, edX provides high-quality
        education accessible to all.
      </p>
      <h2 style={subheadingStyle}>What edX Offers:</h2>
      <ul>
        <li>Courses from top universities and institutions</li>
        <li>Professional certificates and degree programs</li>
        <li>Flexible learning on your schedule</li>
        <li>Global community of learners</li>
      </ul>
      <p>
        Start your journey of lifelong learning with edX:
        <a
          href="https://www.edx.org/"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          edX Website
        </a>
      </p>
    </div>
  );
};

export default EdXInfo;