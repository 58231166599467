import React from 'react';

const SansCloudCertifications = () => {
  const certifications = [
    {
      name: 'SANS Cloud Security Fundamentals (CSF)',
      description: 'The CSF certification covers the fundamentals of cloud security and is suitable for professionals new to cloud security.',
      keyTopics: ['Cloud security basics', 'Shared responsibility model', 'Compliance and regulations'],
      prerequisites: 'No specific prerequisites, but some cybersecurity knowledge is beneficial.',
    },
    {
      name: 'SANS Cloud Security Essentials (CSE)',
      description: 'The CSE certification is designed for security practitioners and focuses on cloud security challenges and solutions.',
      keyTopics: ['Cloud architecture security', 'Identity and access management', 'Security best practices'],
      prerequisites: 'Recommended to have prior experience in IT security.',
    },
    {
      name: 'SANS Cloud Security Automation (CSA)',
      description: 'The CSA certification focuses on automation and scripting for cloud security tasks and operations.',
      keyTopics: ['Scripting languages (Python, PowerShell)', 'Automation tools', 'Security automation use cases'],
      prerequisites: 'Basic understanding of cloud security and scripting languages.',
    },
  ];

  return (
    <div style={{ backgroundColor: 'white' }}>
      <header>
        <h1 className="title">SANS Cloud Certifications</h1>
      </header>
      <section className="certifications">
        {certifications.map((certification, index) => (
          <div key={index} className="certification">
            <h2 className="certification-name">{certification.name}</h2>
            <p className="certification-description">
              <strong>Description:</strong> {certification.description}
            </p>
            <p className="certification-key-topics">
              <strong>Key Topics:</strong> {certification.keyTopics.join(', ')}
            </p>
            <p className="certification-prerequisites">
              <strong>Prerequisites:</strong> {certification.prerequisites}
            </p>
          </div>
        ))}
      </section>
      <footer className="footer">
        <p>
          For more information about SANS Cloud Security certifications, visit the{' '}
          <a
            href="https://www.sans.org/cloud-security/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            SANS Cloud Security Page
          </a>
          .
        </p>
      </footer>
    </div>
  );
};

export default SansCloudCertifications;