import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const SplunkPromoBanner = () => {
  const handleButtonClick = () => {
    // Open Splunk's official website link in a new tab/window
    window.open('https://www.splunk.com/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Unleash Insights with Splunk!</h2>
          <p>Empower your organization with data-driven insights and observability using the Splunk platform.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose Splunk?</h3>
        <ul>
          <li>Harness the power of data for real-time insights and decision-making.</li>
          <li>Monitor, analyze, and visualize machine-generated data.</li>
          <li>Enhance security, compliance, and business operations.</li>
          <li>Drive innovation and gain competitive advantages.</li>
        </ul>
      </div>
      <div className="coursera-documentation">
        <h3>Learn More with Splunk Documentation</h3>
        <ul>
          <li>
            <a href="https://docs.splunk.com/" target="_blank" rel="noopener noreferrer">
              Splunk Documentation
            </a>
          </li>
          {/* Add more Splunk documentation links here */}
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Users Say</h3>
        <div className="testimonial">
          <p>"Splunk has revolutionized the way we extract value from data. It's an essential tool for modern organizations."</p>
          <p>- John Smith, Data Analyst</p>
        </div>
        <div className="testimonial">
          <p>"The insights and visibility we gained through Splunk have transformed our operations and decision-making."</p>
          <p>- Jane Doe, IT Director</p>
        </div>
      </div>
    </div>
  );
};

export default SplunkPromoBanner;