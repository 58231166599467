import React, { useState, useEffect } from "react";
import axios from "axios";
import "../CSS/IOCSearchPage.css"; // Create a CSS file for styling

const IOCSearchPage = () => {
  const [iocResults, setIocResults] = useState([]);
  const [filteredIocResults, setFilteredIocResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 1000;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIocType, setSelectedIocType] = useState("");

  useEffect(() => {
    // Fetch IOC results from an API endpoint
    const fetchIocResults = async () => {
      try {
        const response = await axios.get(`/api/ioc-search?page=${currentPage}`);
        setIocResults(response.data.results);
      } catch (error) {
        console.error("Error fetching IOC results:", error);
      }
    };

    fetchIocResults();
  }, [currentPage]);

  useEffect(() => {
    // Filter IOC results based on search term and selected IOC type
    const filteredResults = iocResults.filter((result) => {
      const searchString = `${result.username} ${result.iocType} ${result.ioc} ${result.description}`;
      const includesSearchTerm = searchString
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesIocType = selectedIocType
        ? result.iocType === selectedIocType
        : true;
      return includesSearchTerm && matchesIocType;
    });

    setFilteredIocResults(filteredResults);
  }, [searchTerm, selectedIocType, iocResults]);

  const handleLoadRecent = () => {
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    const recentResults = iocResults.filter((result) => {
      const resultDate = new Date(result.date);
      return resultDate.getTime() >= thirtyDaysAgo.getTime();
    });

    setFilteredIocResults(recentResults);
  };

  const handleLoadByIocType = (iocType) => {
    setSelectedIocType(iocType);
  };

  return (
    <div className="ioc-search-container">
      <h2>IOC Search</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="button-group">
        <div className="ioc-type-buttons">
          {Array.from(new Set(iocResults.map((result) => result.iocType))).map(
            (iocType) => (
              <button
                key={iocType}
                className={`load-button ${
                  selectedIocType === iocType ? "selected" : ""
                }`}
                onClick={() => handleLoadByIocType(iocType)}
              >
                Load {iocType} IOCs
              </button>
            )
          )}
        </div>
      </div>
      <div className="ioc-results">
        {filteredIocResults.map((result) => (
          <div key={result.id} className="ioc-result">
            <div className="ioc-header">
              <span className="username">{result.username}</span>
              <span className="date">{result.currentDate}</span>
            </div>
            <div className="ioc-details">
              <span className="ioc-type">{result.iocType}</span>
              <br />
              <span className="ioc">
                {result.iocValue.split(" ").map((value, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}{" "}
                    {/* Add a <br /> element except for the first item */}
                    {value}
                  </React.Fragment>
                ))}
              </span>
            </div>
            <p
              className="description"
              style={{ whiteSpace: "pre-line", margin: 0 }}
            >
              Description:
              <br />
              {result.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IOCSearchPage;
