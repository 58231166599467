import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './exploitsearchpage.css'; // Create a CSS file for styling

const BlueToolSearchPage = () => {
  const [exploitResults, setExploitResults] = useState([]);
  const [filteredExploitResults, setFilteredExploitResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 1000;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedExploitOS, setSelectedExploitOS] = useState('');
  const [selectedDomain, setSelectedDomain] = useState('');

  // State to track the number of "Nice" feedbacks for each result
  const [niceFeedbackCounts, setNiceFeedbackCounts] = useState({});

  useEffect(() => {
    // Fetch exploit results from an API endpoint
    const fetchExploitResults = async () => {
      try {
        const response = await axios.get(`/api/bluetool-search?page=${currentPage}`);
        setExploitResults(response.data.results);
      } catch (error) {
        console.error('Error fetching exploit results:', error);
      }
    };

    fetchExploitResults();
  }, [currentPage]);

  useEffect(() => {
    // Filter exploit results based on search term, selected OS, and selected domain
    const filteredResults = exploitResults.map((result) => {
      // Use the result id as the key to track the "Nice" feedback count for each result
      const niceFeedbackCount = niceFeedbackCounts[result.id] || 0;

      return {
        ...result,
        niceFeedbackCount,
      };
    });

    setFilteredExploitResults(filteredResults);
  }, [exploitResults, niceFeedbackCounts]);

  const handleLoadRecent = () => {
    const currentDate = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    const recentResults = exploitResults.filter((result) => {
      const resultDate = new Date(result.currentDate);
      return resultDate.getTime() >= thirtyDaysAgo.getTime();
    });

    setFilteredExploitResults(recentResults);
  };

  const handleLoadByExploitOS = (exploitOS) => {
    setSelectedExploitOS(exploitOS);
  };

  const handleLoadByDomain = (domain) => {
    setSelectedDomain(domain);
  };

  // Function to handle the "Nice" feedback button click
  const handleNiceFeedback = (resultId) => {
    // Make a request to the server to update the Nice feedback count
    axios.post(`/api/submit-nice-feedback/${resultId}`)
      .then((response) => {
        // Update the local state with the new Nice feedback count
        setNiceFeedbackCounts((prevCounts) => ({
          ...prevCounts,
          [resultId]: response.data.niceFeedbackCount,
        }));
      })
      .catch((error) => {
        console.error('Error submitting nice feedback:', error);
      });
  };

  return (
    <div className="exploit-search-container">
      <h2>Blue Tool Search</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        {/* Dropdown for selecting Exploit OS */}
        {/* Dropdown for selecting Domain */}
        <select
          value={selectedDomain}
          onChange={(e) => handleLoadByDomain(e.target.value)}
        >
          <option value="">All Domains</option>
          <option value="Network Security">Network Security</option>
          <option value="Endpoint Security">Endpoint Security</option>
          <option value="Incident Response">Incident Response</option>
          <option value="Forensics">Forensics</option>
          <option value="Security Operations Center (SOC)">Security Operations Center (SOC)</option>
          <option value="Threat Intelligence">Threat Intelligence</option>
          <option value="Vulnerability Management">Vulnerability Management</option>
          <option value="Identity and Access Management">Identity and Access Management</option>
          <option value="Data Loss Prevention">Data Loss Prevention</option>
          <option value="Security Information and Event Management (SIEM)">
            Security Information and Event Management (SIEM)
          </option>
          <option value="Security Awareness and Training">Security Awareness and Training</option>
          <option value="Patch Management">Patch Management</option>
          <option value="Security Policy and Compliance">Security Policy and Compliance</option>
          <option value="Cloud Security">Cloud Security</option>
          <option value="Mobile Device Security">Mobile Device Security</option>
          <option value="Web Application Security">Web Application Security</option>
          <option value="Email Security">Email Security</option>
          <option value="Endpoint Detection and Response (EDR)">
            Endpoint Detection and Response (EDR)
          </option>
          <option value="Physical Security">Physical Security</option>
          <option value="Industrial Control Systems (ICS) Security">
            Industrial Control Systems (ICS) Security
          </option>
          <option value="Wireless Security">Wireless Security</option>
          <option value="Other">Other</option>
          {/* Add more options as needed */}
        </select>
      </div>
      <div className="exploit-results">
        {filteredExploitResults.map((result) => (
          <div key={result.id} className="exploit-result">
            <div className="exploit-header">
              <span className="username">{result.username}</span>
              <span className="date">{result.currentDate}</span>
            </div>
            <div className="exploit-details">
              <br />
              <span className="name">Name: {result.blueToolName}</span>
              <br />
              <a
                href={result.blueToolLink}
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Link: {result.blueToolLink}
              </a>
            </div>
            <p className="description">Description: <br />{result.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlueToolSearchPage;