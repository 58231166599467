import React, { useState, useEffect } from 'react';
import './mentors.css';
import { BrowserRouter as Router, Route, Switch, useHistory, useParams } from 'react-router-dom';
import UserEntries from '../users/userentries';
import { getCircularAvatar } from '../../utils/cloudinaryTransform';
import { Avatar } from '@material-ui/core';
import axios from 'axios';
import storageService from '../../utils/localStorage';

const loggedUser = storageService.loadUser();

function UserProfile({ user, selectedUser, onRequestMentorship }) {
  const mentorProfileUrl = `/u/${user.username}`;
  const openReferenceLink = (link) => {
    window.open(link, '_blank', 'noopener noreferrer');
  };
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const [requestText, setRequestText] = useState('');

  const handleDisciplineChange = (event) => {
    const discipline = event.target.value;
    if (selectedDisciplines.includes(discipline)) {
      setSelectedDisciplines(selectedDisciplines.filter((d) => d !== discipline));
    } else {
      setSelectedDisciplines([...selectedDisciplines, discipline]);
    }
  };

  const handleRequestTextChange = (event) => {
    setRequestText(event.target.value);
  };

  const handleRequestMentorship = () => {
    // Check if at least one discipline is selected
    if (selectedDisciplines.length === 0) {
      alert('Please select at least one discipline.');
      return;
    }

    // Check if request text is not empty
    if (requestText.trim() === '') {
      alert('Please provide a request message.');
      return;
    }

    // Prepare the mentorship request object
    const mentorshipRequest = {
      receiver: user.username,
      requestor: loggedUser.username,
      selectedDisciplines,
      requestText,

    };

    // Call the parent component's callback function to send the request
    onRequestMentorship(mentorshipRequest);
  };

  
  return (
    <div className="user-profile">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        {user.avatar && user.avatar.exists ? (
          <Avatar
            alt={user.username}
            src={getCircularAvatar(user.avatar.imageLink)}
            style={{ width: 200, height: 200, margin: '0 auto' }}
          />
        ) : (
          <Avatar
            style={{ backgroundColor: '#941a1c', width: 200, height: 200 }}
          >
            <h1>{user.username[0]}</h1>
          </Avatar>
        )}
        <h2>
          <a href={mentorProfileUrl} target="_blank" rel="noopener noreferrer">
            {user.username}'s Profile
          </a>
        </h2>
      </div>
      <p>Disciplines:</p>
      <ul>
        {user.disciplines.map((discipline, index) => (
          <li key={index}>{discipline}</li>
        ))}
      </ul> 
      <p>References:</p>
      <ul>
        {user.reference1 && (
          <li>
            <a href={user.reference1} target="_blank" rel="noopener noreferrer">
              {user.reference1}
            </a>
          </li>
        )}
        {user.reference2 && (
          <li>
            <a href={user.reference2} target="_blank" rel="noopener noreferrer">
              {user.reference2}
            </a>
          </li>
        )}
        {user.reference3 && (
          <li>
            <a href={user.reference3} className="link" target="_blank" rel="noopener noreferrer">
              {user.reference3}
            </a>
          </li>
        )}
      </ul>
      <p>Select Disciplines:</p>
      <div>
        {user.disciplines.map((discipline, index) => (
          <label key={index}>
            <input
              type="checkbox"
              value={discipline}
              onChange={handleDisciplineChange}
              checked={selectedDisciplines.includes(discipline)}
            />
            {discipline}
          </label>
        ))}
      </div>
      <textarea
        rows="4"
        value={requestText}
        onChange={handleRequestTextChange}
        placeholder="What would you like help with?"
      />
      <button className="request-mentorship-button" onClick={handleRequestMentorship}>
        Request Mentorship
      </button>
     
    </div>
  );
}


function Mentors() {
  const [searchInput, setSearchInput] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortByRecent, setSortByRecent] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDiscipline, setSelectedDiscipline] = useState('');

  const handleRequestMentorship = async (mentorshipRequest) => {
    try {
      // Send the mentorship request to the server
      // Replace this with your actual API endpoint for sending mentorship requests
      await axios.post('/api/request-mentorship', mentorshipRequest);

      // Handle success or show a notification
      alert('Mentorship request sent successfully, great pick!');
    } catch (error) {
      // Handle errors or show an error notification
      console.error('Error sending mentorship request:', error);
      alert('Existing Mentorship Request Is Waiting For Response');
    }
  };

  useEffect(() => {
    // Replace with your actual API endpoint for fetching mentors
    fetch(`/api/mentors`)
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
        setFilteredUsers(data); // Initially, display all mentors
      })
      .catch((error) => console.error(error));
  }, []);

  const handleSearch = () => {
    const query = searchInput.toLowerCase().trim();
    if (query === '') {
      setFilteredUsers(users); // Display all mentors when search input is empty
      return;
    }

    const filtered = users.filter((user) =>
      user.username.toLowerCase().includes(query)
    );
    setFilteredUsers(filtered);
  };

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
    // Call handleSearch to update the filteredUsers based on the current input value
    handleSearch();
  };

  const handleFilterByDiscipline = (discipline) => {
    setSelectedDiscipline(discipline);

    if (discipline === '') {
      // Reset the filter
      setFilteredUsers(users);
    } else {
      // Filter mentors by selected discipline
      const filtered = users.filter((user) =>
        user.disciplines.includes(discipline)
      );
      setFilteredUsers(filtered);
    }
  };

  const handleSortByRecent = () => {
    const sortedUsers = [...filteredUsers];
    sortedUsers.sort((a, b) => {
      // Replace with the actual date property of your commendations
      const dateA = new Date(a.recentCommendationDate);
      const dateB = new Date(b.recentCommendationDate);
      return dateA - dateB;
    });
    setFilteredUsers(sortedUsers);
    setSortByRecent(true);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleCloseProfile = () => {
    setSelectedUser(null);
  };

  const disciplines = [...new Set(users.flatMap((user) => user.disciplines))];

  return (
    <Router>
      <div className="mentors-container1">
        <header>
        <input
  type="text"
  placeholder="Search by username"
  value={searchInput}
  onChange={handleInputChange}
  style={{ width: 'fit-content' }}
/>
          <div>
            {disciplines.map((discipline, index) => (
              <button
                key={index}
                onClick={() => handleFilterByDiscipline(discipline)}
                className={discipline === selectedDiscipline ? 'active' : ''}
              >
                {discipline}
              </button>
            ))}
            <button
              onClick={() => handleFilterByDiscipline('')}
              className={selectedDiscipline === '' ? 'active' : ''}
            >
              Clear Filter
            </button>
          </div>
        </header>
        <div className="user-card-container2">
          {filteredUsers.map((user) => (
            <div
              key={user._id}
              className="user-card1"
              onClick={() => handleUserClick(user)}
            >
              {user.avatar && user.avatar.exists ? (
                <Avatar
                  alt={user.username}
                  src={getCircularAvatar(user.avatar.imageLink)}
                  style={{ width: 200, height: 200 }}
                />
              ) : (
                <Avatar
                  style={{ backgroundColor: '#941a1c', width: 200, height: 200 }}
                >
                  <h1>{user.username[0]}</h1>
                </Avatar>
              )}
              <h2>{user.username}</h2>
              <ul>
                {user.disciplines.map((discipline, index) => (
                  <li key={index}>{discipline}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      {selectedUser && (
        <div className="user-profile-popup">
          <button className="close-profile-button" onClick={handleCloseProfile}>
            Close Profile
          </button>
          <UserProfile user={selectedUser} onRequestMentorship={handleRequestMentorship} />
        </div>
      )}
    </Router>
  );
}

export default Mentors;