import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const DEFCONPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the DEFCON link in a new tab/window
    window.open('https://www.defcon.org/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Experience Cybersecurity at DEFCON!</h2>
          <p>Explore hacker culture, cutting-edge talks, and hands-on activities at DEFCON conferences.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose DEFCON?</h3>
        <ul>
          <li>Attend talks, workshops, and villages on various cybersecurity topics.</li>
          <li>Immerse yourself in hacker culture and learn from the community.</li>
          <li>Network with professionals, researchers, and enthusiasts.</li>
          <li>Engage in hands-on activities and challenges.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Attendees Say</h3>
        <div className="testimonial">
          <p>"DEFCON is a must-attend event for anyone interested in cybersecurity. The energy and content are unmatched."</p>
          <p>- John Smith, Security Enthusiast</p>
        </div>
        <div className="testimonial">
          <p>"I've had incredible learning experiences at DEFCON. It's an opportunity to interact with experts and enthusiasts."</p>
          <p>- Jane Doe, Cybersecurity Professional</p>
        </div>
      </div>
    </div>
  );
};

export default DEFCONPromoBanner;