import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const TPotPromoBanner = () => {
  const handleButtonClick = () => {
    // Open T-Pot website link in a new tab/window
    window.open('https://github.com/telekom-security/tpotce', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Explore Threats with T-Pot Honeypot!</h2>
          <p>Enhance threat detection and gain insights into attacker behaviors using the T-Pot honeypot platform.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose T-Pot?</h3>
        <ul>
          <li>Deploy a comprehensive honeypot platform with multiple services.</li>
          <li>Collect real-time threat intelligence and attacker data.</li>
          <li>Analyze attacker tactics, techniques, and procedures (TTPs).</li>
          <li>Contribute to the security community and research.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Researchers Say</h3>
        <div className="testimonial">
          <p>"T-Pot is an excellent tool for capturing and analyzing attacker activities. It's invaluable for threat research."</p>
          <p>- John Smith, Security Researcher</p>
        </div>
        <div className="testimonial">
          <p>"The insights and data collected by T-Pot have contributed significantly to our understanding of emerging threats."</p>
          <p>- Jane Doe, Threat Analyst</p>
        </div>
      </div>
    </div>
  );
};

export default TPotPromoBanner;