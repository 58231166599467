import React from 'react';
import '../CSS/Logging.css'; // Import your custom CSS for styling

const AdamCaudill = () => {
  return (
    <div className="logging">
      <iframe
        src="https://adamcaudill.com/"
        title="Logging"
        className="logging-iframe"
      ></iframe>
    </div>
  );
};

export default AdamCaudill;