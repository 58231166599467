import React from 'react';

const MitreAttackMatrix = () => {
  return (
    <div>
      <h1>MITRE ATT&amp;CK Enterprise Matrix</h1>
      <iframe
        title="MitreAttackMatrix"
        src="https://attack.mitre.org/matrices/enterprise/"
        width="100%"
        height="800px"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default MitreAttackMatrix;