import React from 'react';
import '../CSS/Logging.css'; // Import your custom CSS for styling

const OSINTFrame = () => {
  return (
    <div className="logging">
      <iframe
        src="https://www.osintframework.com"
        title="OSINT Framework"
        className="logging-iframe"
      ></iframe>
    </div>
  );
};

export default OSINTFrame; 