import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import AuthFormModal from './AuthFormModal';
import SubFormModal from './SubFormModal';
import UpdateAvatarModal from './UpdateAvatarModal';
import DarkModeMenuItem from './DarkModeMenuItem';
import { getCircularAvatar } from '../utils/cloudinaryTransform';
import storageService from '../utils/localStorage';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import {
  Button,
  Menu,
  MenuItem,
  Avatar,
  Typography,
  ListItemIcon,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const useStyles = makeStyles((theme) => ({
  userBtn: {
    color: 'black',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
  },
  karmaWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
  },
}));

const DesktopUserMenu = ({ user, handleLogout, history }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleClose();
    handleLogout();
  };

  const loggedUser = storageService.loadUser() || user;

  const redirectToStart = () => {
    history.push('/start'); // Redirect to the /start route
  };

  return (
    <div>
      {loggedUser ? (
        <>
          <Button onClick={handleMenu} className={classes.userBtn}>
            {loggedUser?.avatar?.exists ? (
              <Avatar
                alt={loggedUser.username}
                src={getCircularAvatar(loggedUser.avatar.imageLink)}
                variant="rounded"
                className={classes.avatar}
              />
            ) : (
              <Avatar variant="rounded" className={classes.avatar} style={{ backgroundColor: 'black', color: 'white' }}>
  {loggedUser.username[0]}
</Avatar>
            )}
            <div>
              <Typography color="secondary">{loggedUser.username}</Typography>
              <Typography variant="caption" className={classes.karmaWrapper}>
                <FilterVintageIcon
                  fontSize="inherit"
                  style={{ marginRight: '0.2em' }}
                  color="secondary"
                />
                {loggedUser.karma} Merit
              </Typography>
            </div>
          </Button>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              component={RouterLink}
              to={`/u/${loggedUser.username}`}
              onClick={handleClose}
            >
              <ListItemIcon>
                <AccountCircleIcon style={{ marginRight: 7 }} /> My Profile
              </ListItemIcon>
            </MenuItem>
            <SubFormModal type="menu" handleCloseMenu={handleClose} />
            <UpdateAvatarModal
              handleCloseMenu={handleClose}
              user={loggedUser}
            />
            <MenuItem
              component={RouterLink}
              to="/aboutyou"
              onClick={handleClose}
            >
              <ListItemIcon>
                <HelpOutlineIcon style={{ marginRight: 7 }} /> About You
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              component={RouterLink}
              to="/support"
              onClick={handleClose}
            >
              <ListItemIcon>
                <HelpOutlineIcon style={{ marginRight: 7 }} /> Support
              </ListItemIcon>
            </MenuItem>
            <DarkModeMenuItem closeMenu={handleClose} />
            <MenuItem onClick={handleLogoutClick}>
              <ListItemIcon>
                <PowerSettingsNewIcon style={{ marginRight: 7 }} /> Logout
              </ListItemIcon>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <div className={classes.navItems}>
          <Button onClick={redirectToStart} className={classes.userBtn}>
            Login
          </Button>
          <DarkModeMenuItem closeMenu={handleClose} navItem={true} />
        </div>
      )}
    </div>
  );
};

export default withRouter(DesktopUserMenu);