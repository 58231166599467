import React from 'react';

const ChainsawGitHubInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#24292e', // GitHub-like background color
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'white',
  };

  const headingStyle = {
    color: 'white',
  };

  const subheadingStyle = {
    color: 'white',
  };

  const linkStyle = {
    color: '#0366d6', // GitHub link color
    textDecoration: 'none',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore Chainsaw on GitHub</h1>
      <p>
        Chainsaw is an open-source project hosted on GitHub by WithSecureLabs.
        It is a comprehensive cybersecurity tool that supports various tasks
        such as vulnerability assessment and exploitation.
      </p>
      <h2 style={subheadingStyle}>What It Offers:</h2>
      <ul>
        <li>Comprehensive cybersecurity toolset</li>
        <li>Vulnerability scanning and exploitation</li>
        <li>Active development and contributions</li>
        <li>Community support and discussions</li>
      </ul>
      <p>
        Explore the Chainsaw project on GitHub to access the source code,
        documentation, and community discussions:
        <a
          href="https://github.com/WithSecureLabs/chainsaw"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Chainsaw on GitHub
        </a>
      </p>
    </div>
  );
};

export default ChainsawGitHubInfo;