import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './exploitsubmitpage.css'; // Create a CSS file for additional styling

const CybersecurityExploitForm = () => {
  const [username, setUsername] = useState('');
  const [exploitOS, setExploitOS] = useState('');
  const [exploitLanguage, setExploitLanguage] = useState('');
  const [exploitValue, setExploitValue] = useState('');
  const [description, setDescription] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const exploitLanguagesList = [
    'C',
    'C++',
    'Python',
    'Ruby',
    'Java',
    'JavaScript',
    'Assembly',
    'Shell Script',
    'Powershell',
  ];

  useEffect(() => {
    const storedUser = localStorage.getItem('readifyUserKey');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUsername(parsedUser.username);
    }
  }, []);

  useEffect(() => {
    const updateCurrentDate = () => {
      const date = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      };
      const formattedDate = date.toLocaleString('en-US', options).replace(',', '');
      setCurrentDate(formattedDate);
    };

    updateCurrentDate();

    // Update the current date every minute
    const interval = setInterval(updateCurrentDate, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (description.length > 1000000) {
      alert('Description exceeds the maximum limit of 500 characters.');
      return;
    }

    try {
      const response = await axios.post('/api/submit-exploit', {
        username,
        exploitOS,
        exploitLanguage,
        exploitValue,
        description,
        currentDate,
      });

      if (response.status === 201) {
        alert('Well Done Attacker! Exploit Successfully Added');
      } else {
        alert('Error submitting exploit.');
      }
    } catch (error) {
      console.error('Error submitting exploit:', error);
    }

    // Reset the form
    setExploitOS('');
    setExploitLanguage('');
    setExploitValue('');
    setDescription('');
  };

  const handleExploitValueChange = (e) => {
    const { value } = e.target;
    setExploitValue(value);
    // You can implement validation logic here to check the format of the exploit value
    // Update the validExploit state accordingly
  };

  return (
    <div className="exploit-form-container">
      <form onSubmit={handleSubmit} className="form">
        <h2 className="form-title">Submit Exploit</h2>
        <div className="form-field">
          <label htmlFor="exploitOS">Exploit OS:</label>
          <select
            id="exploitOS"
            value={exploitOS}
            onChange={(e) => setExploitOS(e.target.value)}
            required
          >
            <option value="">Select an Exploit OS</option>
            <option value="Windows">Windows</option>
            <option value="Linux">Linux</option>
            <option value="Android">Android</option>
            <option value="iOS">iOS</option>
            <option value="IOS">IOS/Routers</option>
          </select>
        </div>

        <div className="form-field">
          <label htmlFor="exploitLanguage">Exploit Language:</label>
          <select
            id="exploitLanguage"
            value={exploitLanguage}
            onChange={(e) => setExploitLanguage(e.target.value)}
            required
          >
            <option value="">Select an Exploit Language</option>
            {exploitLanguagesList.map((language, index) => (
              <option key={index} value={language}>
                {language}
              </option>
            ))}
          </select>
        </div>

        <div className="form-field">
          <label htmlFor="exploitValue">Exploit Value:</label>
          <input
            type="text"
            id="exploitValue"
            value={exploitValue}
            onChange={handleExploitValueChange}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <button className="submit-button" type="submit">
            Submit Exploit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CybersecurityExploitForm;