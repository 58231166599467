import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const CyberMentorPromoBanner = () => {
  const handleButtonClick = () => {
    // Open the link in a new tab/window
    window.open('https://thecybermentor.com/', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Elevate Your Skills with The Cyber Mentor!</h2>
          <p>Discover practical cybersecurity courses and tutorials curated by The Cyber Mentor.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose The Cyber Mentor?</h3>
        <ul>
          <li>Hands-on courses taught by a seasoned cybersecurity professional.</li>
          <li>Focus on real-world skills and practical techniques.</li>
          <li>Community engagement and support for learners.</li>
          <li>Build a strong foundation in ethical hacking and security.</li>
        </ul>
      </div>
      <div className="coursera-partnerships">
        <h3>Collaborators and Contributors</h3>
        <p>The Cyber Mentor collaborates with cybersecurity experts and practitioners to deliver high-quality content and knowledge.</p>
        <ul>
          <li>Cybersecurity professionals and instructors.</li>
          <li>Hackers and penetration testers.</li>
          <li>Security researchers and educators.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Learners Say</h3>
        <div className="testimonial">
          <p>"The Cyber Mentor's courses helped me gain confidence in my ethical hacking journey. The content is comprehensive and engaging."</p>
          <p>- John Smith, Aspiring Ethical Hacker</p>
        </div>
        <div className="testimonial">
          <p>"I've learned so much from The Cyber Mentor's tutorials. The hands-on approach and practical focus make a real difference."</p>
          <p>- Jane Doe, Cybersecurity Enthusiast</p>
        </div>
      </div>
    </div>
  );
};

export default CyberMentorPromoBanner;
