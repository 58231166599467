import React from 'react';
import '../CSS/CybraryPromoBanner.css'; // Import your CSS for styling

const CybraryPromoBanner = () => {
  const handleClick = () => {
    // Replace with your actual link
    window.open('https://www.cybrary.it/', '_blank');
  };

  const bannerImagePath = '../../assets/cybrary.png';

  return (
    <div className="cybrary-promo-banner">
      <div className="cybrary-promo-content">
        <div className="cybrary-promo-text">
          <h2>Enhance Your Cybersecurity Skills with Cybrary!</h2>
          <p>Unlock a world of online courses, certifications, and hands-on labs.</p>
          <button onClick={handleClick}>Explore Now</button>
        </div>
      </div>
      <div className="cybrary-history">
        <h3>Discover Cybrary's History</h3>
        <p>Cybrary was founded in 2015 with a vision to provide free and accessible cybersecurity education to individuals worldwide. Over the years, it has grown into a leading online learning platform for cybersecurity professionals, offering a wide range of courses, certifications, and resources.</p>
      </div>
      <div className="cybrary-offerings">
        <h3>What Cybrary Offers</h3>
        <p>Cybrary offers a comprehensive library of cybersecurity courses, ranging from beginner to advanced levels. Their hands-on labs, expert instructors, and interactive learning platform make it easy for learners to gain practical skills and knowledge in areas like ethical hacking, network security, digital forensics, and more.</p>
      </div>
      <div className="cybrary-features">
        <h3>Key Features of Cybrary</h3>
        <ul>
          <li>On-demand access to a vast collection of cybersecurity courses.</li>
          <li>Interactive labs for hands-on practice and skill development.</li>
          <li>Certification programs to validate your expertise.</li>
          <li>Expert instructors from the cybersecurity industry.</li>
          <li>Engaging and user-friendly learning platform.</li>
        </ul>
      </div>
      <div className="cybrary-testimonials">
        <h3>What Their Users Say</h3>
        <div className="testimonial">
          <p>"Thanks to Cybrary, I landed my dream job in cybersecurity. The courses were incredibly insightful, and the hands-on labs prepared me for real-world challenges."</p>
          <p>- John Doe, Cybersecurity Professional</p>
        </div>
        <div className="testimonial">
          <p>"Cybrary's courses helped me transition my career from IT to cybersecurity seamlessly. The practical approach and expert guidance made all the difference."</p>
          <p>- Jane Smith, Career Changer</p>
        </div>
      </div>
    </div>
  );
};

export default CybraryPromoBanner;