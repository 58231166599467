import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, Button, Typography } from "@material-ui/core";
import storageService from '../../../utils/localStorage';
import "./mentorform.css";
const loggedUser = storageService.loadUser();


const MentorForm = () => {
  const [isMentor, setIsMentor] = useState(false);
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const [references, setReferences] = useState(["", "", ""]);

  // Fetch the user's existing data when the component loads
  useEffect(() => {
    // Fetch the user's data from the server using an API endpoint
    fetch(`/api/getMentorData?username=${loggedUser.username}`)
      .then((response) => response.json())
      .then((userData) => {
        if (userData) {
          // Populate the form fields with the data
          setIsMentor(userData.mentor);
          setSelectedDisciplines(userData.disciplines || []);
          setReferences([
            userData.reference1 || "",
            userData.reference2 || "",
            userData.reference3 || "",
          ]);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []); // The empty dependen // The empty dependency array ensures this effect runs only once when the component mounts

  const handleDisciplineChange = (event) => {
    const discipline = event.target.name;
    if (selectedDisciplines) {
      const updatedDisciplines = selectedDisciplines.includes(discipline)
        ? selectedDisciplines.filter((d) => d !== discipline)
        : [...selectedDisciplines, discipline];
      setSelectedDisciplines(updatedDisciplines);
    }
  };

  const handleReferenceChange = (index, value) => {
    const updatedReferences = [...references];
    updatedReferences[index] = value;
    setReferences(updatedReferences);
  };

  const addReference = () => {
    setReferences([...references, ""]);
  };

  const removeReference = (index) => {
    const updatedReferences = [...references];
    updatedReferences.splice(index, 1);
    setReferences(updatedReferences);
  };

  const handleSubmit = () => {
    const updatedUserData = {
      username: loggedUser.username,
      mentor: isMentor,
      disciplines: selectedDisciplines,
      reference1: references[0],
      reference2: references[1],
      reference3: references[2],
       // Include the username in the request body
    };
  
    // Send the updatedUserData to your server for database update
    fetch('/api/updateMentorData', {
      method: 'POST', // Use POST instead of PUT for updating
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ updatedUserData }), // Send the updatedUserData object in the request body
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to update user data');
        }
      })
      .then((data) => {
        // Show a success message to the user
        alert(data.message);
      })
      .catch((error) => {
        console.error('Error updating user data:', error);
        // Handle error and show an appropriate message to the user
      });
  };

  return (
    <div className="mentor-form-container" style={{ textAlign: 'center' }}>
        <div className="intro-message">
      <Typography variant="h3" style={{ color: 'blue', fontWeight: 'bold' }}>
        Take the Chance to Help!
      </Typography>
      <Typography variant="h4" style={{ color: 'green', fontStyle: 'italic' }}>
        It may surprise you =)
      </Typography>
    </div>
      <div className="checkbox-label">
        <Button
          variant="contained"
          color={isMentor ? "default" : "default"}
          onClick={() => setIsMentor(!isMentor)}
          className={`black-text ${isMentor ? "no-button" : "yes-button"}`}
        >
          {isMentor ? "No" : "Yes"}
        </Button>
        {isMentor && (
          <div>
            <div className="disciplines-list">
              {[
                "AI", "Angel Investor", "Systems Architect", "Auditing", "Blue Team", "Cloud", "Databases", "Data Science", "Entrepreneurship", "Forensics", "Internet of Things", "Linux", "Machine Learning", "Management", "Networking", "Physical Security", "Programming", "Red Team", "Reverse Engineering", "Strategy", "Systems Administration", "Virtualization", "Windows"
                // Add your list of disciplines here
              ].map((discipline) => (
                <FormControlLabel
                  key={discipline}
                  control={
                    <Checkbox
                      checked={selectedDisciplines.includes(discipline)}
                      onChange={handleDisciplineChange}
                      name={discipline}
                    />
                  }
                  label={discipline}
                />
              ))}
            </div>
            <div className="references">
              <Typography variant="body1">References:</Typography>
              {references.map((reference, index) => (
                <div key={index} className="input">
                  {/* Input for references */}
                  <input
                    type="text"
                    placeholder={`Reference ${index + 1}`}
                    value={reference}
                    onChange={(e) => handleReferenceChange(index, e.target.value)}
                    className="reference-input"
                  />
                  {/* Button to remove a reference */}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Save Changes
      </Button>
    </div>
  );
    };

    export default MentorForm;