import React from 'react';

const BazaarInfo = () => {
  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    color: 'black',
  };

  const headingStyle = {
    color: '#333',
  };

  const linkStyle = {
    color: '#007bff',
    textDecoration: 'none',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Explore Bazaar GitHub Repository</h1>
      <p>
        Bazaar is an open-source project hosted on GitHub. It offers a variety
        of resources and information. You can explore the repository and find
        more details by visiting the GitHub page:
        <a
          href="https://github.com/L-e-c-o/bazaar"
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          Bazaar GitHub Repository
        </a>
      </p>
    </div>
  );
};

export default BazaarInfo;