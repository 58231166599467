import React from 'react';
import '../CSS/CourseraPromoBanner.css'; // Import Coursera's CSS for styling

const SiftVMPromoBanner = () => {
  const handleButtonClick = () => {
    // Open SANS website link in a new tab/window
    window.open('https://digital-forensics.sans.org/community/downloads', '_blank');
  };

  return (
    <div className="coursera-promo-banner">
      <div className="coursera-promo-content">
        <div className="coursera-promo-text">
          <h2>Investigate with SIFT Virtual Machine!</h2>
          <p>Accelerate digital forensics and incident response investigations using the SANS Investigative Forensic Toolkit (SIFT) virtual machine.</p>
          <button onClick={handleButtonClick}>Explore Now</button>
        </div>
      </div>
      <div className="coursera-offerings">
        <h3>Why Choose SIFT Virtual Machine?</h3>
        <ul>
          <li>Access a comprehensive toolkit for digital forensics and incident response.</li>
          <li>Benefit from pre-installed open-source forensic tools and scripts.</li>
          <li>Enhance your investigative capabilities and skillset.</li>
          <li>Stay up-to-date with the latest forensic techniques.</li>
        </ul>
      </div>
      <div className="coursera-testimonials">
        <h3>What Investigators Say</h3>
        <div className="testimonial">
          <p>"SIFT VM is an invaluable resource for digital forensics professionals. It has saved me countless hours."</p>
          <p>- John Smith, Digital Forensics Analyst</p>
        </div>
        <div className="testimonial">
          <p>"The SIFT virtual machine streamlines our investigations and allows us to focus on analysis rather than tool setup."</p>
          <p>- Jane Doe, Incident Responder</p>
        </div>
      </div>
    </div>
  );
};

export default SiftVMPromoBanner;