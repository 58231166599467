import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PStackWrite.css'; // Create a CSS file for additional styling

const StacksWriteForm = () => {
  const [username, setUsername] = useState('');
  const [description, setDescription] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [lessonLearned, setLessonLearned] = useState('');
  const [steps, setSteps] = useState(['']); // Array to store steps
  const [selectedFeedback, setSelectedFeedback] = useState(null); // Great, Good, Bad, Other
  const [dresult, setdResult] = useState('');
  const [aresult, setaResult] = useState('');
  const [eresult, seteResult] = useState('');

  useEffect(() => {
    const storedUser = localStorage.getItem('readifyUserKey');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUsername(parsedUser.username);
    }
  }, []);

  useEffect(() => {
    const updateCurrentDate = () => {
      const date = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      };
      const formattedDate = date.toLocaleString('en-US', options).replace(',', '');
      setCurrentDate(formattedDate);
    };

    updateCurrentDate();

    // Update the current date every minute
    const interval = setInterval(updateCurrentDate, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (description.length > 1000000) {
      alert('Description exceeds the maximum limit of 500 characters.');
      return;
    }

    try {
      const response = await axios.post('/api/submit-stack', {
        username,
        lessonLearned,
        currentDate,
        steps,
        selectedFeedback,
        dresult,
        aresult,
        eresult,
      });

      if (response.status === 201) {
        alert("Well done, In Hopes it will be used and beneficial!");
      } else {
        alert('Error submitting.');
      }
    } catch (error) {
      console.error('Error submitting:', error);
    }

    // Reset the form
    setLessonLearned('');
    setSteps(['']);
    setSelectedFeedback(null);
    setdResult('');
    setaResult('');
    seteResult('');
  };

  const handleStepChange = (index, value) => {
    const updatedSteps = [...steps];
    updatedSteps[index] = value;
    setSteps(updatedSteps);
  };

  const addStep = () => {
    if (steps.length < 17) {
      setSteps([...steps, '']);
    }
  };

  const deleteStep = (index) => {
    const updatedSteps = [...steps];
    updatedSteps.splice(index, 1);
    setSteps(updatedSteps);
  };

  const handleFeedbackClick = (feedback) => {
    setSelectedFeedback(feedback);
  };

  return (
    <div className="exploit9-form-container">
      <form onSubmit={handleSubmit} className="form">
        <h2 className="form-title">Submit Programming/Tech Stack</h2>
        <h2 className="form-title">This is Almost Anything, From Well Established, to Your Idea</h2>

        <div className="form-field">
          <label htmlFor="lessonLearned">Stack Name:</label>
          <textarea
            id="lessonLearned"
            value={lessonLearned}
            onChange={(e) => setLessonLearned(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <label>Technologies (Min 2 Max 17):</label>
          {steps.map((step, index) => (
            <div key={index} className="step-field">
              <textarea
                value={step}
                onChange={(e) => handleStepChange(index, e.target.value)}
                required
              />
              <button
                type="button"
                className="delete-step-button"
                onClick={() => deleteStep(index)}
              >
                Delete
              </button>
            </div>
          ))}
          {steps.length < 17 && (
            <button className="add-step-button" type="button" onClick={addStep}>
              Add to Stack
            </button>
          )}
        </div>

        <div className="form-field">
          <label htmlFor="feedback">State:</label>
          <div className="feedback-buttons">
            <button
              className={selectedFeedback === 'Validated' ? 'selected' : ''}
              onClick={() => handleFeedbackClick('Validated')}
              type="button"
            >
              Validated
            </button>
            <button
              className={selectedFeedback === 'Promising' ? 'selected' : ''}
              onClick={() => handleFeedbackClick('Promising')}
              type="button"
            >
              Promising
            </button>
            <button
              className={selectedFeedback === 'Experimental' ? 'selected' : ''}
              onClick={() => handleFeedbackClick('Experimental')}
              type="button"
            >
              Experimental
            </button>
            <button
              className={selectedFeedback === 'Unsure' ? 'selected' : ''}
              onClick={() => handleFeedbackClick('Unsure')}
              type="button"
            >
              Unsure
            </button>
          </div>
        </div>

        <div className="form-field">
          <label htmlFor="dresult">Purpose:</label>
          <textarea
            id="dresult"
            value={dresult}
            onChange={(e) => setdResult(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="aresult">Potential:</label>
          <textarea
            id="aresult"
            value={aresult}
            onChange={(e) => setaResult(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <label htmlFor="eresult">Evidence:</label>
          <textarea
            id="eresult"
            value={eresult}
            onChange={(e) => seteResult(e.target.value)}
            required
          />
        </div>

        <div className="form-field">
          <button className="submit-button" type="submit">
            Submit Stack
          </button>
        </div>
      </form>
    </div>
  );
};

export default StacksWriteForm;